import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ContainerForm({ children }) {
    return <div className={styles.wrapperForm}>{children}</div>;
}

ContainerForm.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default ContainerForm;
