import { useFormik } from 'formik';
import { ErrorHelper, MaskHelper } from 'helpers';
import Locale from 'locale';
import { Aircraft, AircraftSolicitation } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import notify from 'components/Toast';
import {
    Button,
    HeaderPage,
    Input,
    LoadingFullPage,
    PhoneNumberInput,
    SelectedAircraftCard,
    StickyFooter,
    Switch,
    BottomSheet,
    Image,
} from 'components';
import { AircraftSolicitationType } from 'utils/enums';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';
import { AddressForm } from 'features/frontend';
import Service from 'services';
import { getDocumentLabel } from 'utils/functions';
import './styles.scss';
import Address from 'models/Address';
import { icoExclamationYellow } from 'assets/icons';

function EditOperator() {
    const navigate = useNavigate();
    const { aircraftRegistration } = useParams();
    const [aircraft, setAircraft] = useState(Aircraft.createEmpty());
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const getAircraft = () => {
        Service.listAircraft({ registration: aircraftRegistration })
            .then((response) => {
                if (!response?.length) {
                    ErrorHelper.notifyError({
                        response: {
                            data: `No aircraft found with registration ${aircraftRegistration}`,
                        },
                    });
                }
                const fetchedAircraft = Aircraft.createFromResponse(
                    response[0]
                );
                if (!fetchedAircraft.operator?.address) {
                    fetchedAircraft.operator.address = Address.createEmpty();
                }
                setAircraft(fetchedAircraft);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const patchAircraftOperator = (aircraftData) => {
        Service.patchAircraft(aircraftData)
            .then(() => {
                navigate(`/aircraft/${aircraftData.registration}`);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const createAircraftSolicitation = async (aircraftData) => {
        setIsSubmitting(true);
        try {
            const solicitationData = new AircraftSolicitation({
                type: AircraftSolicitationType.edit,
                aircraft: aircraftData,
            });
            await Service.postAircraftSolicitation(solicitationData);
        } catch (error) {
            setIsSubmitting(false);
            ErrorHelper.notifyError(error);
            return;
        }

        setIsSubmitting(false);
        navigate(`/aircraft/${aircraftData.registration}`);
    };

    const formik = useFormik({
        initialValues: aircraft,
        validationSchema: Aircraft.validationSchema,
        onSubmit: (data) => {
            const aircraftData = new Aircraft(data);
            if (AIRCRAFT_SOLICITATION_PROCESS_ENABLED) {
                setShowEditModal(true);
            } else {
                patchAircraftOperator(aircraftData);
            }
        },
    });

    const getNameLabel = () => {
        if (formik.values?.operator?.isCompany) {
            return formik.values?.operator?.isForeigner
                ? Locale.fields.companyName
                : Locale.fields.corporateName;
        }
        return Locale.fields.fullName;
    };

    const setDocument = (e) => {
        if (formik.values?.operator?.isForeigner) {
            if (formik.values?.operator?.isCompany) {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 15)
                );
            } else {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 9)
                );
            }
        } else if (formik.values?.operator?.isCompany) {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 14)
            );
        } else {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 11)
            );
        }
    };

    useEffect(() => {
        setLoading(true);
        getAircraft();
    }, [aircraftRegistration]);

    useEffect(() => {
        formik.setValues(aircraft);
    }, [aircraft]);

    return loading ? (
        <LoadingFullPage />
    ) : (
        <div className="padding--lg height--full stack--lg edit-operator-wrapper">
            {AIRCRAFT_SOLICITATION_PROCESS_ENABLED && (
                <BottomSheet
                    show={showEditModal}
                    title={Locale.actions.attention}
                    onClose={() => setShowEditModal(false)}
                >
                    <div className="stack--md padding--y-sm">
                        <Image
                            src={icoExclamationYellow}
                            size={32}
                            alt="ico-alert"
                        />
                        <p className="font-size--sm">
                            {
                                Locale.pages.aircraftEdit.information
                                    .confirmEditMessage
                            }
                        </p>
                        <p className="font-size--sm">
                            {
                                Locale.pages.aircraftEdit.information
                                    .wishToContinue
                            }
                        </p>
                        <Button
                            type="button"
                            kind="primary"
                            onClick={() =>
                                createAircraftSolicitation(
                                    new Aircraft(formik.values)
                                )
                            }
                        >
                            {Locale.actions.proceed}
                        </Button>
                        <Button
                            type="button"
                            kind="outline"
                            onClick={() => setShowEditModal(false)}
                        >
                            {Locale.actions.cancel}
                        </Button>
                    </div>
                </BottomSheet>
            )}
            <form
                onSubmit={formik.handleSubmit}
                className="height--full stack--lg"
            >
                <HeaderPage
                    title={Locale.pages.aircraftEdit.operator.title}
                    description={Locale.pages.aircraftEdit.operator.description}
                    labelBtnBack={Locale.actions.back}
                    onClickBtnBack={() =>
                        navigate(`/aircraft/${aircraftRegistration}`)
                    }
                />
                <div className="stack--lg padding-bottom-sticky-footer">
                    <SelectedAircraftCard aircraft={aircraft} />
                    <div>
                        <div className="margin--b-lg">
                            <Switch
                                label={
                                    Locale.fields.operator
                                        .useForeignDocumentation
                                }
                                id="isForeigner"
                                name="isForeigner"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'operator.isForeigner',
                                        e
                                    );
                                }}
                                checked={!!formik.values.operator?.isForeigner}
                                error={
                                    formik.touched.aircraft?.operator
                                        ?.isForeigner &&
                                    formik.errors.aircraft?.operator
                                        ?.isForeigner
                                }
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="stack--sm">
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        type="text"
                                        label={getNameLabel()}
                                        id="name"
                                        name="name"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'operator.name',
                                                e.target.value
                                            )
                                        }
                                        value={formik.values?.operator?.name}
                                        error={
                                            (formik.touched.name ||
                                                formik.touched.operator
                                                    ?.name) &&
                                            formik.errors.operator?.name
                                        }
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md 6">
                                    <Input
                                        type="text"
                                        label={getDocumentLabel(
                                            formik.values?.operator
                                                ?.isForeigner,
                                            formik.values?.operator?.isCompany
                                        )}
                                        name="document"
                                        id="document"
                                        required
                                        onChange={(e) => {
                                            setDocument(e);
                                        }}
                                        value={MaskHelper.document(
                                            formik.values?.operator?.document,
                                            formik.values?.operator
                                                ?.isForeigner,
                                            formik.values?.operator?.isCompany
                                        )}
                                        error={
                                            (formik.touched.document ||
                                                formik.touched.operator
                                                    ?.document) &&
                                            formik.errors.operator?.document
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="col-md 6">
                                    <PhoneNumberInput
                                        label={Locale.fields.phone}
                                        name="operator.phoneNumber"
                                        id="operator.phoneNumber"
                                        value={
                                            formik.values?.operator?.phoneNumber
                                        }
                                        onChange={formik.handleChange}
                                        error={
                                            (formik.touched.phoneNumber ||
                                                formik.touched.operator
                                                    ?.phoneNumber) &&
                                            formik.errors.operator?.phoneNumber
                                        }
                                        onBlur={formik.handleBlur}
                                        required
                                        countryPhoneCode={
                                            formik.values?.operator
                                                ?.countryPhoneCode
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 ">
                                    <Input
                                        type="email"
                                        label={Locale.fields.email}
                                        name="email"
                                        id="email"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'operator.email',
                                                e.target.value
                                            )
                                        }
                                        value={formik.values?.operator?.email}
                                        error={
                                            formik.touched.operator?.email &&
                                            formik.errors.operator?.email
                                        }
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 ">
                                    <Input
                                        type="email"
                                        label={Locale.fields.email}
                                        name="secondEmail"
                                        id="secondEmail"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'operator.secondEmail',
                                                e.target.value
                                            )
                                        }
                                        value={
                                            formik.values?.operator?.secondEmail
                                        }
                                        error={
                                            (formik.touched.secondEmail ||
                                                formik.touched.operator
                                                    ?.secondEmail) &&
                                            formik.errors.operator?.secondEmail
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 ">
                                    <Input
                                        type="email"
                                        label={Locale.fields.email}
                                        name="thirdEmail"
                                        id="thirdEmail"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'operator.thirdEmail',
                                                e.target.value
                                            )
                                        }
                                        value={
                                            formik.values?.operator?.thirdEmail
                                        }
                                        error={
                                            (formik.touched.thirdEmail ||
                                                formik.touched.operator
                                                    ?.thirdEmail) &&
                                            formik.errors.operator?.thirdEmail
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <AddressForm
                                title={Locale.fields.address}
                                formik={formik}
                            />
                        </div>
                    </div>
                </div>
                <StickyFooter>
                    <div className="aircraft-list-footer">
                        <Button
                            isLoading={isSubmitting}
                            kind="primary"
                            type="submit"
                        >
                            {Locale.pages.aircraftEdit.operator.saveChanges}
                        </Button>
                    </div>
                </StickyFooter>
            </form>
        </div>
    );
}

export default EditOperator;
