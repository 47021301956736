import { resetPasswordPath } from 'router';
import { get, post, patch } from './axios';

const UserService = {
    passwordReset(paramData) {
        const fullData = {
            ...paramData,
            path: resetPasswordPath,
        };
        return post('password-reset/', fullData);
    },

    passwordResetConfirm(data) {
        return post('password-reset-confirm/', data);
    },

    registerUser(data) {
        return post('register/user', data);
    },

    patchUser(data, id = '') {
        return patch(`user/${id}`, data);
    },

    getProfileData() {
        return get('profile/personal/');
    },

    patchProfileData(data) {
        return patch('profile/personal/', data);
    },

    listUsers(params) {
        return get('/user/', { params });
    },

    getUser(pk) {
        return get(`/user/${pk}`);
    },

    createBOUser(data) {
        return post('register/backoffice-user', data);
    },

    activateAccount(data) {
        return post('activate-account/', data);
    },
};

export default UserService;
