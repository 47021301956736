import { icoExpandRight } from 'assets/icons';
import noImagePlaceholder from 'assets/no-image-placeholder.svg';
import { Image, Card, ColoredTag } from 'components';
import {
    AircraftSolicitationStatus,
    getAircraftSolicitationStatus,
} from 'utils/enums';
import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';

export default function AircraftCard({ aircraft, onClick }) {
    return (
        <Card
            onClick={onClick}
            className="inline--md justify-content-around aircraft-card"
        >
            <Image
                src={aircraft.image[0]?.filepath || noImagePlaceholder}
                alt={Locale.pages.accountSettings.aircraft.edit}
                className="aircraft-card-image"
            />
            <div className="aircraft-card-content w-100">
                <div className="aircraft-card-head">
                    <div className="aircraft-card-title flex-justify--between">
                        <p>{aircraft.registration}</p>
                        <Image
                            src={icoExpandRight}
                            alt={Locale.pages.accountSettings.aircraft.edit}
                        />
                    </div>
                    <div className="aircraft-card-subtitle">
                        <p>{aircraft.icaoModel}</p>
                    </div>
                </div>
                <div className="inline--sm aircraft-card-tags">
                    <ColoredTag text={`${aircraft.mtow} kg`} type="info" />
                    {aircraft.solicitationStatus ? (
                        <ColoredTag
                            text={
                                Locale.fields.aircraft.solicitation.statusEnum[
                                    getAircraftSolicitationStatus(
                                        aircraft.solicitationStatus
                                    )
                                ]
                            }
                            type={
                                aircraft.solicitationStatus ===
                                AircraftSolicitationStatus.disapprovedValidation
                                    ? 'error'
                                    : 'alert'
                            }
                        />
                    ) : (
                        <ColoredTag
                            text={Locale.fields.aircraft.active}
                            type="active"
                        />
                    )}
                </div>
            </div>
        </Card>
    );
}

AircraftCard.propTypes = {
    aircraft: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
};
