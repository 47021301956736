import PropTypes from 'prop-types';
import { icoPlaneUpPrimary } from 'assets/icons';
import { Image } from 'components';
import Locale from 'locale';
import './styles.scss';
import { getAircraftNature } from 'utils/functions';

function SelectedAircraftCard({ aircraft }) {
    return (
        <div className="flex--column">
            <p className="color--neutral-65 font-size--sm margin--b-sm">
                {Locale.fields.aircraft.selectedAircraft}
            </p>
            <div className="selected-aircraft flex-justify--between flex-items--center">
                <div className="flex--row flex-items--center">
                    <Image
                        src={icoPlaneUpPrimary}
                        size={24}
                        alt="ico-plane"
                        className="margin--r-md"
                    />
                    <div>
                        <p className="fw-semibold font-size--md">
                            {aircraft.registration}
                        </p>
                        <p className="font-size--sm">{aircraft.icaoModel}</p>
                    </div>
                </div>
                <div className="nature-card">
                    <p className="color--brand-primary font-size--sm fw-semibold">
                        {getAircraftNature(
                            aircraft.isForeign,
                            aircraft.isMilitary
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

SelectedAircraftCard.propTypes = {
    aircraft: PropTypes.shape({
        registration: PropTypes.string,
        icaoModel: PropTypes.string,
        isForeign: PropTypes.bool,
        isMilitary: PropTypes.bool,
    }).isRequired,
};

export default SelectedAircraftCard;
