import { Button, HeaderPage, Image, Input, Table } from 'components';
import { useState, useMemo, useEffect } from 'react';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { scrollToTop } from 'utils/functions';
import { boolCell, parseOrderingToBackend } from 'components/Table';
import { OrderingParams, PaginationParams } from 'utils/enums';
import User from 'models/User';
import './styles.scss';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { icoClose, icoSearch } from 'assets/icons';

export default function Users() {
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shouldReload, setShouldReload] = useState();
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [currentOrdering, setCurrentOrdering] = useState('-id');
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(location.state?.search || '');

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: { search },
    });

    const columns = useMemo(
        () => [
            {
                Header: 'E-mail',
                accessor: 'email',
                className: 'column-email',
                sortBy: 'email',
            },
            {
                Header: Locale.fields.user.accessProfile,
                accessor: 'accessProfile.name',
                className: 'column-access-profile',
                sortBy: User.orderingParams.accessProfiles,
            },
            {
                Header: Locale.fields.user.isActive,
                accessor: 'isActive',
                Cell: boolCell,
                className: 'column-active',
                sortBy: User.orderingParams.isActive,
            },
        ],
        []
    );

    const getUsers = () => {
        setLoading(true);
        Service.listUsers({
            search,
            [PaginationParams.page]: currentPage,
            [PaginationParams.pageSize]: currentPageSize,
            [OrderingParams.ordering]: currentOrdering,
            [User.filterParams.hasAccessProfiles]: true,
        })
            .then((response) => {
                setTotalCount(response.count);
                setTotalPages(response.totalPages);
                setUsers(User.createListFromResponse(response.results));
                setLoading(false);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        if (shouldReload) {
            scrollToTop();
            getUsers();
            setShouldReload(false);
        }
    }, [shouldReload]);

    useEffect(() => {
        setShouldReload(true);
    }, [currentPage, currentPageSize]);

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            setShouldReload(true);
        }
    }, [currentOrdering, search]);

    const onSort = (newOrdering) => {
        setCurrentOrdering(parseOrderingToBackend(newOrdering));
    };

    const onPageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setCurrentPageSize(newPageSize);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearch(formik.values.search);
        }
    };

    const clearSearch = () => {
        formik.setFieldValue('search', '');
        setSearch('');
    };

    const redirectToDetails = (userId) => {
        navigate(`/backoffice/users/${userId}`);
    };

    return (
        <div className="stack--lg width--full padding--lg">
            <div className="stack--sm">
                <HeaderPage
                    title={Locale.pages.backoffice.users.title}
                    contentInfo={
                        <Button kind="primary" onClick={() => navigate('new')}>
                            {Locale.pages.backoffice.users.new}
                        </Button>
                    }
                />
                <Input
                    className="user-search"
                    label={Locale.actions.search}
                    name="search"
                    placeholder={Locale.pages.backoffice.users.search}
                    value={formik.values.search}
                    onChange={formik.handleChange}
                    onKeyDown={handleKeyDown}
                    before={
                        <Image src={icoSearch} alt={Locale.actions.search} />
                    }
                    after={
                        <Image
                            className="cursor--pointer"
                            onClick={clearSearch}
                            src={icoClose}
                            alt=""
                            size={16}
                        />
                    }
                />
            </div>
            <div className="body-2 color--grayscale-steel-gray text-transform--lowercase">
                {totalCount} {Locale.general.result}
                {totalCount !== 1 && 's'}
            </div>
            <Table
                data={users}
                columns={columns}
                onSort={onSort}
                onPageChange={onPageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                loading={loading}
                rowClick={redirectToDetails}
                rowHeight="medium"
            />
        </div>
    );
}
