import * as Yup from 'yup';
import Locale from 'locale';
import BaseModel from './BaseModel';
import State from './State';
import Country from './Country';
import WeeklyWorkSchedule from './WeeklyWorkSchedule';

class Airport extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.name = data.name;
        this.city = data.city;
        this.state = State.getValueOrNew(data.state);
        this.country = Country.getValueOrNew(data.country);
        this.icaoCode = data.icaoCode;
        this.iataCode = data.iataCode;
        this.deleted = data.deleted;
        this.status = !!data.status;
        this.isManagedByCcr = !!data.isManagedByCcr;
        this.timezone = data.timezone || '';
        this.contactData = data.contactData;
        this.documents = data.documents;
        this.contactEmail = data.contactEmail;
        this.hasWorkSchedule = data.hasWorkSchedule;
        this.weeklyWorkSchedule = WeeklyWorkSchedule.getValueOrNew(
            data.weeklyWorkSchedule
        );

        // Helper props
        this.noICAO = data.noICAO;
        this.isCreating = data.isCreating;
        this.airportdocumentSet = data.airportdocumentSet;
    }

    get icaoName() {
        if (this.icaoCode) {
            return `${this.icaoCode} - ${this.name}`;
        }
        return `${this.name} - ${this.city}/${this.state.name}`;
    }

    static createEmpty(isCreatingAirport) {
        return new Airport({
            isCreating: !!isCreatingAirport,
            state: State.createEmpty(),
            country: Country.createEmpty(),
            weeklyWorkSchedule: WeeklyWorkSchedule.createEmpty(),
            airportdocumentSet: [],
            status: true,
        });
    }

    static createEmptyNational() {
        return new Airport({
            ...Airport.createEmpty(),
            country: new Country({ geonameId: Country.filterIds.brazil }),
        });
    }

    static get validationSchema() {
        return Yup.object().shape({
            noICAO: Yup.bool(),
            id: Yup.string().when(['noICAO', 'isCreating'], {
                is: (noICAO, isCreating) => !noICAO || !isCreating,
                then: Yup.string().required(Locale.errors.required),
            }),
            name: Yup.string().when(['noICAO', 'isCreating'], {
                is: (noICAO, isCreating) => noICAO && isCreating,
                then: Yup.string().required(Locale.errors.required),
            }),
            city: Yup.string().when(['noICAO', 'isCreating'], {
                is: (noICAO, isCreating) => noICAO && isCreating,
                then: Yup.string().required(Locale.errors.required),
            }),
            state: Yup.object().when(['noICAO', 'isCreating'], {
                is: (noICAO, isCreating) => noICAO && isCreating,
                then: State.validationSchema,
            }),
            country: Yup.object().when(['noICAO', 'isCreating'], {
                is: (noICAO, isCreating) => noICAO && isCreating,
                then: Country.validationSchema,
            }),
        });
    }

    static parseDropdownOptions(list, byId = true) {
        return list.map((item) => ({
            label: item.icaoName,
            value: byId ? item.id : item.icaoCode,
        }));
    }

    parseToRequest() {
        if (this.airportdocumentSet) {
            delete this.airportdocumentSet;
        }
        return {
            ...this,
            ...(this.state && { state: this.state.parseToRequest() }),
            ...(this.country && { country: this.country.parseToRequest() }),
            weeklyWorkSchedule: this.weeklyWorkSchedule.parseToRequest(),
        };
    }

    static get filterParams() {
        return {
            hasICAO: 'has_icao',
            isActive: 'status',
            search: 'search',
            isManagedByCcr: 'is_managed_by_ccr',
            state: 'state',
            country: 'country',
        };
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new Airport({
            ...response,
            state: State.createFromResponse(response.state),
            country: Country.createFromResponse(response.country),
            weeklyWorkSchedule: WeeklyWorkSchedule.createFromResponse(
                response.weeklyWorkSchedule
            ),
        });
    }
}

export default Airport;
