import BaseModel from './BaseModel';

export default class AccessProfile extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.name = data.name;
        this.descriptionPtBr = data.descriptionPtBr;
        this.descriptionEnUS = data.descriptionEnUS;
    }
}
