import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Main } from 'components';
import TDefault from 'styles/themes/default';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'App.scss';
import Routers from 'router';
import { useState, useEffect } from 'react';
import Service from 'services';
import { AuthenticationHelper } from 'helpers';
import Locale from 'locale';
import moment from 'moment';
import 'moment/min/locales';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [ready, setReady] = useState(false);
    const [render, rerender] = useState(false);

    const verifyLogin = (navigate) => {
        Service.refreshLogin()
            .then(() => {
                Service.isLoggedIn().then((response) => {
                    setIsAuthenticated(response);
                    if (response) {
                        AuthenticationHelper.startTokenRefreshTask();
                    } else {
                        navigate('/');
                    }
                    setReady(true);
                });
            })
            .catch(() => {
                setIsAuthenticated(false);
                setReady(true);
            });
    };

    const handleSwitchLanguage = (language) => {
        rerender(!render);
        Locale.setLanguage(language);
        moment.locale(language);
    };

    useEffect(() => {
        moment.locale(Locale.getLanguage());
    }, [Locale.getLanguage()]);

    return (
        <Router>
            <ThemeProvider theme={TDefault}>
                <Main
                    isAuthenticated={isAuthenticated}
                    verifyLogin={verifyLogin}
                    setLoggedOut={() => setIsAuthenticated(false)}
                    handleSwitchLanguage={handleSwitchLanguage}
                >
                    {ready && (
                        <Routers
                            setIsAuthenticated={setIsAuthenticated}
                            isUserAuthenticated={isAuthenticated}
                        />
                    )}
                </Main>
                <ToastContainer
                    autoClose={3000}
                    position="bottom-center"
                    icon={false}
                    closeButton={false}
                    theme="dark"
                />
            </ThemeProvider>
        </Router>
    );
}

export default App;
