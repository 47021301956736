import { post, patch, remove, get } from './axios';

const PilotService = {
    registerPilot(pilot) {
        const data = pilot.parseToRequest();
        return post('pilot/', data);
    },

    patchPilot(pilot) {
        const data = pilot.parseToRequest();
        return patch(`pilot/${pilot.id}/`, data);
    },

    deletePilot(id) {
        return remove(`pilot/${id}`);
    },

    listPilots() {
        return get('pilot/');
    },

    getPilot(id) {
        return get(`pilot/${id}`);
    },
};

export default PilotService;
