import { Card, Dropdown, HeaderPage, Line, Title } from 'components';
import { useFormik } from 'formik';
import { ErrorHelper } from 'helpers';
import { Airport } from 'models';
import { useEffect, useState } from 'react';
import Service from 'services';
import Locale from 'locale';

const CCR_CONTACT_CHANNELS = [
    // {
    //     nameTag: 'commercialService',
    //     phone: '+55 (11) 91595-2444',
    //     mail: 'duvidas.reserva@grupoccr.com.br',
    //     workingHours: {
    //         daysTag: 'mondayToFriday',
    //         start: '08h00',
    //         end: '18h00',
    //     },
    // },
    {
        nameTag: 'helpCenter',
        phone: '0800-727-4720',
        mail: 'ouvidoria.aeroportos@grupoccr.com.br',
        link: 'https://ccraeroportos.omd.com.br/ccraeroportos/externo/cadastro.do',
        workingHours: { start: '24h' },
    },
];

export default function Help() {
    const [airports, setAirports] = useState(new Map());
    const [loading, setLoading] = useState(true);

    const getCCRAirports = () => {
        setLoading(true);
        Service.listAirports({
            [Airport.filterParams.isManagedByCcr]: true,
            [Airport.filterParams.isActive]: true,
        })
            .then((response) => {
                const airportsResponse =
                    Airport.createListFromResponse(response);
                setAirports(
                    new Map(
                        airportsResponse.map((item) => [
                            item.id.toString(),
                            {
                                ...item,
                                label: item.icaoName,
                                value: item.id,
                            },
                        ])
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getCCRAirports();
    }, []);

    const formik = useFormik({
        initialValues: {
            airport: '',
        },
    });

    const { airport } = formik.values;

    const getChannelWorkingHours = (channel) => {
        let workingHours = '';
        if (channel.workingHours.daysTag) {
            workingHours += `${
                Locale.pages.help[channel.workingHours.daysTag]
            }: `;
        }
        workingHours += channel.workingHours.start;
        if (channel.workingHours.end) {
            workingHours += ` ${Locale.general.to} ${channel.workingHours.end}`;
        }

        return workingHours;
    };

    return (
        <div className="stack--xl padding--lg">
            <div>
                <HeaderPage title={Locale.pages.help.title} />
                <div className="stack--md">
                    <div className="subtitle-1">
                        {Locale.pages.help.subtitle}
                    </div>
                    <Dropdown
                        autocomplete
                        id="airport"
                        name="airport"
                        value={formik.values.airport.id}
                        options={Array.from(airports.values())}
                        loading={loading}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'airport',
                                airports.get(e.target.value)
                            );
                        }}
                    />
                    {airport && (
                        <Card className="stack--lg">
                            <div className="stack--xs">
                                <Title tagName="h3">{airport.name}</Title>
                                <div className="subtitle-1">
                                    {airport.city}/{airport.state?.name}
                                </div>
                            </div>
                            <div className="stack--xs">
                                <div className="overline-1 text-transform--uppercase">
                                    {Locale.pages.help.contact}
                                </div>
                                <div className="body-2">
                                    {airport.contactData ||
                                        Locale.pages.help.noContact}
                                </div>
                            </div>
                        </Card>
                    )}
                </div>
            </div>
            <Line />
            <Card className="stack--md">
                {CCR_CONTACT_CHANNELS.map((channel) => (
                    <>
                        <Title
                            tagName="h4"
                            className="text-transform--uppercase"
                        >
                            {Locale.pages.help[channel.nameTag]}
                        </Title>
                        {channel.link ? (
                            <div className="subtitle-1">
                                <a
                                    href={channel.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="color--brand-primary-light">
                                        {Locale.pages.help.clickHere}
                                    </span>
                                </a>
                                {Locale.pages.help.helpCenterLinkText}
                            </div>
                        ) : (
                            <>
                                <div className="subtitle-1">
                                    {Locale.pages.help.phone}: {channel.phone}
                                </div>
                                <div className="subtitle-1">
                                    {Locale.pages.help.mail}:{' '}
                                    <a href={`mailto:${channel.mail}`}>
                                        {channel.mail}
                                    </a>
                                </div>
                            </>
                        )}
                        <div className="stack--xs">
                            <div className="overline-2 color--grayscale-carbono-gray">
                                {Locale.pages.help.workingHours}:
                            </div>
                            <div className="overline-2 color--grayscale-dark">
                                {getChannelWorkingHours(channel)}
                            </div>
                        </div>
                    </>
                ))}

                {/* {CONTACTS_CCR.map((contact) => (
                    <div className="subtitle-1">{contact}</div>
                ))}
                <div className="stack--xs">
                    <div className="overline-2 color--grayscale-carbono-gray">
                        {Locale.pages.help.workingHours}:
                    </div>
                    <div className="overline-2 color--grayscale-dark">
                        {`${Locale.pages.help.mondayToFriday}: ${WORKING_HOURS.start} ${Locale.general.to} ${WORKING_HOURS.end}`}
                    </div>
                </div> */}
            </Card>
        </div>
    );
}
