import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button, Image } from 'components';

import './styles.scss';

export default function ItemCarrousel(props) {
    const navigate = useNavigate();
    const {
        icon,
        title,
        subtitle,
        button,
        link,
        linkExternal,
        tab,
        imgDesktop,
        imgMobile,
        isLoggedIn,
    } = props;

    const handleRedirectOnClick = (redirectUrl, optionalState = null) => {
        if (!isLoggedIn) {
            navigate('/login', {
                state: {
                    redirectTo: redirectUrl,
                    ...optionalState,
                },
            });
        } else {
            navigate(redirectUrl, {
                state: optionalState,
            });
        }
    };

    return (
        <div className="container-item-carrousel">
            <div className="content">
                <img src={icon} alt={`ico ${title}`} />
                <h2>{title}</h2>
                <p>{subtitle}</p>
                {linkExternal ? (
                    <a href={link} target="_blank" rel="noreferrer">
                        <Button kind="primary" className="margin--t-lg">
                            {button}
                        </Button>
                    </a>
                ) : (
                    <Button
                        kind="primary"
                        className="margin--t-lg"
                        onClick={() => {
                            handleRedirectOnClick(link, {
                                initialTab: tab || null,
                            });
                        }}
                    >
                        {button}
                    </Button>
                )}
            </div>
            <picture>
                <source srcSet={imgDesktop} media="(min-width: 769px)" />
                <source srcSet={imgMobile} media="(max-width: 768px)" />
                <Image src={imgDesktop} alt={title} className="desktop" />
            </picture>
        </div>
    );
}

ItemCarrousel.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkExternal: PropTypes.bool.isRequired,
    tab: PropTypes.string,
    imgDesktop: PropTypes.string.isRequired,
    imgMobile: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool,
};

ItemCarrousel.defaultProps = {
    isLoggedIn: false,
    tab: undefined,
};
