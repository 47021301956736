import { get, post } from './axios';

const PaymentService = {
    postLandingPayment(data) {
        return post(`payment/landing_request/`, data);
    },
    getLandingPayment(landingRequestId) {
        return get(`payment/landing_request/${landingRequestId}`);
    },
    createInvoicePayment(data) {
        return post('payment/debt/', data);
    },
    checkPixTransaction(debtId) {
        return get(`payment/debt/${debtId}`);
    },
};

export default PaymentService;
