import PropTypes from 'prop-types';

import theme from '../../styles/themes/default';

import { Hr } from './styles';

function Line({ color, ...args }) {
    return <Hr color={color} {...args} />;
}

Line.propTypes = {
    color: PropTypes.string,
};

Line.defaultProps = {
    color: theme.colors.grayscale.lightGray,
};

export default Line;
