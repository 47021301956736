import styled from 'styled-components';

export const Container = styled.label`
    font-size: ${(props) => props.theme.fonts.xs};
    line-height: ${(props) => props.theme.line_height.xs};
    color: ${(props) => props.theme.colors.grayscale.steelGray};
    font-weight: ${(props) => props.theme.font_weights.medium};
    letter-spacing: ${(props) => props.theme.fonts.ls};

    span {
        color: ${(props) => props.theme.colors.brand.primary};
    }
`;
