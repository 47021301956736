import { Input, Title, Button, StickyFooter, Text } from 'components';
import Locale from 'locale';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Service from 'services';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';
import { useState, useEffect } from 'react';

export default function ResetPassword() {
    const [serverErrors, setServerErrors] = useState({});
    const navigate = useNavigate();
    const { uidb64, token } = useParams();
    const [searchParams] = useSearchParams();
    const isBackofficeUser = searchParams.get('is_backoffice_user');

    const passwordMin = 8;
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(passwordMin, Locale.errors.minLength(passwordMin))
            .matches(/[\D]/, Locale.pages.register.user.numericPassword)
            .required(Locale.errors.required),
        passwordConfirm: Yup.string()
            .required(Locale.errors.required)
            .oneOf([Yup.ref('password'), null], Locale.errors.passwordsMatch),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: '',
        },
        validationSchema,
        onSubmit: (values) => {
            Service.passwordResetConfirm({
                ...values,
                uidb64,
                token,
            })
                .then(() => {
                    notify(Locale.pages.resetPassword.success);
                    const path = isBackofficeUser ? `/backoffice` : '';
                    navigate(`${path}/login`);
                })
                .catch((e) => {
                    setServerErrors(e.response?.data);
                    if (e.response?.status !== 400) ErrorHelper.notifyError(e);
                });
        },
    });

    useEffect(() => {
        formik.setErrors({ ...formik.errors, ...serverErrors });
    }, [serverErrors]);

    return (
        <form onSubmit={formik.handleSubmit} className="stack--md">
            <div className="padding--lg flex--1 stack--md">
                <Title tagName="h2">{Locale.actions.resetPassword}</Title>
                <Input
                    label={Locale.fields.user.password}
                    type="password"
                    id="password"
                    name="password"
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password}
                />
                <Input
                    label={Locale.fields.user.confirmPassword}
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    required
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                    }
                />
                <Text kind="overline-2" className="margin--t-sm margin--x-md">
                    {/* TODO MAG-495 adicionar na string abaixo */}
                    <p>{Locale.pages.register.user.commonPassword}</p>
                    <p>{Locale.pages.register.user.lengthPassword}</p>
                    <p>{Locale.pages.register.user.numericPassword}</p>
                </Text>
            </div>

            <StickyFooter>
                <Button
                    type="button"
                    kind="outline"
                    onClick={() => navigate('/')}
                    className="d-none d-sm-block"
                >
                    {Locale.actions.back}
                </Button>
                <Button type="submit" kind="primary">
                    {Locale.actions.resetPassword}
                </Button>
            </StickyFooter>
        </form>
    );
}
