import { Button } from 'components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function ToastWithButton({ closeToast, message, buttonText, buttonOnClick }) {
    return (
        <div className="stack--md">
            <div className="toast-button-body">{message}</div>
            <div className="flex-justify--center">
                <Button
                    kind="outline"
                    onClick={() => {
                        buttonOnClick();
                        closeToast();
                    }}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}

ToastWithButton.propTypes = {
    closeToast: PropTypes.func,
    message: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func.isRequired,
};

ToastWithButton.defaultProps = {
    closeToast: () => {},
};

export default function notify(message, buttonText, buttonOnClick, autoClose) {
    const options = {
        ...(autoClose !== undefined && { autoClose }),
    };

    if (!!buttonText && !!buttonOnClick) {
        toast(
            <ToastWithButton
                message={message}
                buttonOnClick={buttonOnClick}
                buttonText={buttonText}
            />,
            options
        );
    } else {
        toast(message, options);
    }
}
