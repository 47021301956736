import { icoPlaneUpSecondary } from 'assets/icons';
import { Button, Image } from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';

export default function EmptyAircraftList({
    icon,
    title,
    body,
    onButtonClick,
}) {
    return (
        <div className="center-content stack--md aircraft-list empty-aircraft-list">
            <Image src={icon} size={40} alt="empty-aircraft-icon" />
            <div className="stack--md width--full">
                <p className="empty-aircraft-title">{title}</p>
                <p className="empty-aircraft-body">{body}</p>
            </div>
            <Button
                kind="primary"
                onClick={onButtonClick}
                className="width--full"
            >
                {Locale.pages.aircraftList.empty.goToRegister}
            </Button>
        </div>
    );
}

EmptyAircraftList.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
};

EmptyAircraftList.defaultProps = {
    icon: icoPlaneUpSecondary,
    onButtonClick: () => {},
};
