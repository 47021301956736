import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Image, UserMenu } from 'components';
import { icoClock, icoArrowLeft } from 'assets/icons';
import Logo from 'assets/logo-aeroease.svg';
import Locale from 'locale';
import './styles.scss';
import { useEffect, useState } from 'react';
import moment from 'moment';

export default function HeaderBO({ isAuthenticated, logout }) {
    const navigate = useNavigate();

    const logoutAndNavigate = () => {
        logout(() => navigate('/backoffice/login'));
    };

    const submenus = [
        {
            title: Locale.actions.logout,
            icon: icoArrowLeft,
            onClick: logoutAndNavigate,
        },
    ];

    const [utcCurrentTime, setUtcCurrentTime] = useState(moment().utc());
    useEffect(() => {
        setInterval(() => {
            setUtcCurrentTime(moment().utc());
        }, 1000);
    }, []);

    return (
        <header className="bo-header">
            <div className="flex--row align-items-center">
                <Image
                    className="logo"
                    src={Logo}
                    alt="CCR"
                    onClick={() => navigate('/backoffice')}
                />
                <div className="header-title">{Locale.general.backoffice}</div>
                <Image className="footer-ico" src={icoClock} alt="clock" />
                <div className="footer-text">
                    {`${utcCurrentTime.format('HH:mm')} UTC`}
                </div>
            </div>
            {isAuthenticated ? (
                <UserMenu
                    isAuthenticated={isAuthenticated}
                    submenus={submenus}
                />
            ) : (
                <Button
                    kind="text"
                    onClick={() => navigate('/backoffice/login')}
                >
                    {Locale.actions.login}
                </Button>
            )}
        </header>
    );
}

HeaderBO.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
};
