export default {
    title: 'TDefault',
    colors: {
        brand: {
            primary: '#144BB8',
            primaryLight: '#6495F7',
            primaryDark: '#0E3581',
            primary25: '#CDDAF3',
            secondary: '#CDDAF3',
            secondaryLight: '#FFC533',
            secondaryDark: '#E5A500',
        },
        grayscale: {
            white: '#FFFFFF',
            whiteBg: '#FCFCFD',
            light: '#F2F4F8',
            lightGray: '#E4E9F1',
            courteousGray: '#D2D3Df',
            paleShaleGray: '#C9CFDC',
            steelGray: '#6A7CA0',
            blueGray: '#546383',
            carbonoGray: '#525C6F',
            dark: '#303541',
        },
        support: {
            statusOk: '#55DF00',
            error: '#F43D00',
            timelineGreen:
                'linear-gradient(90deg, #42AE00 35.94%, #55DF00 35.95%)',
            timelineAlerta:
                'linear-gradient(90deg, #C7A000 57.81%, #F8C800 57.82%)',
            timelineUrgente:
                'linear-gradient(90deg, #C23000 83.33%, #F43D00 83.34%)',
        },
        opacity: {
            transparent: 'transparent',
            primaryOpacity: 'rgba(184, 46, 16, 0.25)',
            gray: 'rgba(109, 110, 113, 0.9)',
        },
    },
    spacing: {
        xs: '0.25rem', // 4px
        sm: '0.5rem', // 8px
        md: '1rem', // 16px
        lg: '1.5rem', // 24px
        xl: '2rem', // 32px
        xxl: '3rem', // 48px
        full: '100%',
    },
    fonts: {
        ls: '0.1em',
        xs: '0.75rem', // 12px
        sm: '0.875rem', // 14px
        md: '1rem', // 16px
        lg: '1.125rem', // 18px
        xl: '1.25rem', // 20px
        xxl: '1.5rem', // 24px
    },
    line_height: {
        xs: '1rem', // 16px
        sm: '1.313rem', // 21px
        md: '1.5rem', // 24px
        lg: '1.688rem', // 27px
        xl: '1.875rem', // 30px
        xxl: '2rem', // 32px
    },
    font_weights: {
        regular: 400,
        medium: 500,
        semibold: 600,
    },
    layouts: {
        headerHeight: '3.375rem',
    },
};
