import { useFormik } from 'formik';
import * as Yup from 'yup';
import Locale from 'locale';
import {
    HeaderPage,
    Input,
    PhoneNumberInput,
    Line,
    Title,
    Dropdown,
    TextSwitch,
    StickyFooter,
    Button,
} from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MaskHelper, ErrorHelper } from 'helpers';
import { Reservation, Billing, Country, State } from 'models';
import { getDocumentLabel } from 'utils/functions';
import Service from 'services';
import { useState, useEffect } from 'react';
import notify from 'components/Toast';
import { useCountryPhoneOptions } from 'states';

export default function AdditionalInfo() {
    const [cepSuccess, setCepSuccess] = useState(true);
    const navigate = useNavigate();
    const { reservationId } = useParams();
    const location = useLocation();
    const [isBrazilianAddress, setIsBrazilianAddress] = useState(
        location.state.billing?.country?.geonameId === Country.filterIds.brazil
    );
    const [countries, setCountries] = useState(new Map());
    const [states, setStates] = useState(new Map());
    const [requesterBillingData, setRequesterBillingData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [billingDataOptions, setBillingDataOptions] = useState([
        {
            value: '',
            label: Locale.fields.reservation.others,
        },
        {
            value: 'requester',
            label: Locale.fields.reservation.requester,
        },
        {
            value: 'operator',
            label: Locale.fields.reservation.operator,
        },
    ]);
    const [selectedBillingDataOption, setSelectedBillingDataOption] = useState(
        location.state.selectedBillingDataOption
            ? location.state.selectedBillingDataOption
            : {
                  value: '',
              }
    );
    const [customBillingData, setCustomBillingData] = useState(
        Billing.createEmpty()
    );

    const reservation = new Reservation(location.state.reservation);

    const initialValues = {
        billing: location.state.billing
            ? new Billing({
                  ...location.state.billing,
              })
            : Billing.createEmpty(),
    };

    const validationSchema = Yup.object().shape({
        billing: Billing.validationSchema,
    });

    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: (form) => {
            const billing = new Billing(form.billing);
            navigate(
                reservation.id
                    ? `/reservation/${reservationId}/checkout`
                    : '/newreservation/summary',
                {
                    state: {
                        ...location.state,
                        billing,
                        selectedBillingDataOption,
                        reservation,
                    },
                }
            );
        },
    });

    const getCountries = () => {
        Service.getCountries()
            .then((response) => {
                const responseCountries =
                    Country.createListFromResponse(response);
                setCountries(
                    new Map(
                        responseCountries.map((responseCountry) => [
                            responseCountry.value,
                            responseCountry,
                        ])
                    )
                );
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const parseOperatorDataToBilling = (operatorData) => {
        const parsedData = {
            ...operatorData,
            ...operatorData.address,
            address: operatorData.address.street,
        };
        if (operatorData?.address?.district) {
            parsedData.district = operatorData.address.district;
        }
        return new Billing(parsedData);
    };

    useEffect(() => {
        switch (selectedBillingDataOption.value) {
            case '':
                if (customBillingData) {
                    formik.setValues({
                        billing: customBillingData,
                    });
                }
                break;
            case 'requester':
                setCustomBillingData(formik.values.billing);
                formik.setValues((currentValues) => ({
                    billing: new Billing({
                        ...currentValues,
                        ...requesterBillingData,
                    }),
                }));
                break;
            case 'operator':
                setCustomBillingData(formik.values.billing);
                formik.setValues({
                    billing: parseOperatorDataToBilling(
                        reservation.aircraft?.operator
                    ),
                });
                break;
            default:
                break;
        }
    }, [selectedBillingDataOption, requesterBillingData]);

    useEffect(() => {
        if (countries) {
            const orderedCountries = new Map(
                [...countries].sort((a, b) =>
                    a[1].nameByLanguage.localeCompare(b[1].nameByLanguage)
                )
            );
            setCountries(orderedCountries);
        }
    }, [Locale.getLanguage()]);

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (!formik.values.billing.country?.id) {
            return;
        }
        Service.getStates({
            [State.filterParams.countryId]: formik.values.billing.country.id,
        })
            .then((response) => {
                const responseStates = State.createListFromResponse(response);
                setStates(
                    new Map(
                        responseStates.map((responseState) => [
                            responseState.value,
                            responseState,
                        ])
                    )
                );
            })
            .catch((error) => ErrorHelper.notifyError(error));
    }, [formik.values.billing.country?.id]);

    const setDocument = (e) => {
        if (formik.values.billing?.isForeigner) {
            if (formik.values.billing?.isCompany) {
                formik.setFieldValue(
                    'billing.document',
                    e.target.value.replace(/\D/g, '').slice(0, 15)
                );
            } else {
                formik.setFieldValue(
                    'billing.document',
                    e.target.value.replace(/\D/g, '').slice(0, 9)
                );
            }
        } else if (formik.values.billing?.isCompany) {
            formik.setFieldValue(
                'billing.document',
                e.target.value.replace(/\D/g, '').slice(0, 14)
            );
        } else {
            formik.setFieldValue(
                'billing.document',
                e.target.value.replace(/\D/g, '').slice(0, 11)
            );
        }
    };

    const setAddress = () => {
        formik.setFieldTouched('billing.postalCode', true);
        if (formik.values?.billing?.postalCode && isBrazilianAddress) {
            Service.getAddress(formik.values.billing?.postalCode)
                .then((response) => {
                    formik.setFieldValue('billing.address', response?.address);
                    formik.setFieldValue('billing.city', response?.city);
                    formik.setFieldValue(
                        'billing.state',
                        response?.state
                            ? states.get(State.brazilianStates[response?.state])
                            : State.createEmpty()
                    );
                    formik.setFieldValue(
                        'billing.district',
                        response?.district
                    );
                    setCepSuccess(response.ok);
                    if (!response.ok) {
                        notify(Locale.errors.cep);
                    }
                })
                .catch((error) => {
                    setCepSuccess(false);
                    ErrorHelper.notifyError(error);
                    notify(Locale.errors.cep);
                });
        }
    };

    const setCountry = (e) => {
        formik.setFieldValue('billing.country', countries.get(e.target.value));
        setIsBrazilianAddress(
            e.target.value === Country.filterIds.brazil.toString()
        );

        formik.setTouched({});
        formik.setFieldValue('billing.postalCode', '');
        formik.setFieldValue('billing.address', '');
        formik.setFieldValue('billing.city', '');
        formik.setFieldValue('billing.state', State.createEmpty());
        formik.setFieldValue('billing.district', '');
        formik.setFieldValue('billing.number', '');
        formik.setFieldValue('billing.complement', '');
    };

    const handleGoBack = () => {
        if (reservation.id) {
            navigate(`/reservation/${reservationId}`);
            return;
        }

        const state = {
            ...location.state,
            reservation: location.state.reservation,
            billing: formik.values.billing,
            selectedBillingDataOption,
        };

        navigate('/newreservation/flightinfo', {
            state,
        });
    };

    useEffect(() => {
        Service.getProfileData()
            .then((response) => {
                setRequesterBillingData({
                    name: response.name,
                    email: response.email,
                    document: response.document,
                    countryPhoneCode: response.countryPhoneCode,
                    phoneNumber: response.phoneNumber,
                    isForeigner: response.isForeigner,
                    isCompany: response.isCompany,
                });
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    }, []);

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="padding--lg padding-bottom-sticky-footer"
        >
            <HeaderPage
                title={Locale.pages.reservation.additionalInfo}
                labelBtnBack={
                    reservation.id
                        ? Locale.pages.reservationDetail.title
                        : Locale.fields.reservation.flightInfo
                }
                onClickBtnBack={handleGoBack}
            />

            <Line className="margin--t-sm" />
            <Title tagName="h4">{Locale.fields.billing}</Title>
            <div className="row">
                <div className="col-md-6">
                    <Dropdown
                        id=""
                        name=""
                        options={billingDataOptions}
                        label={Locale.fields.reservation.useBillingDataFrom}
                        onChange={(evt) => {
                            setSelectedBillingDataOption(
                                billingDataOptions.find(
                                    (el) => el?.value === evt.target.value
                                )
                            );
                        }}
                        value={selectedBillingDataOption.value}
                        onBlur={formik.handleBlur}
                        autocomplete
                    />
                </div>
            </div>
            {/* TODO: permitir que usuário utilize dados de cadastro para preencher campos de faturamento */}
            {/* <TextSwitch
                id="billing.reservationResponsable"
                name="billing.reservationResponsable"
                label={Locale.fields.reservation.responsable}
                onChange={(e) => {
                    formik.setFieldValue('billing.reservationResponsable', e);
                }}
                checked={formik.values.billing.reservationResponsable} */}

            <div className="row">
                <div className="col-md-4">
                    <TextSwitch
                        label={Locale.fields.isCompany}
                        id="isCompany"
                        name="isCompany"
                        onChange={(e) => {
                            formik.setFieldValue('billing.isCompany', e);
                            formik.setFieldValue('billing.document', '');
                        }}
                        checked={
                            formik.values.billing.isForeigner ||
                            formik.values.billing.isCompany
                        }
                        error={
                            formik.touched?.billing?.isCompany &&
                            formik.errors?.billing?.isCompany
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.values.billing.isForeigner}
                    />
                </div>
                <div className="col-md-4">
                    <TextSwitch
                        label={Locale.fields.isForeigner}
                        id="isForeigner"
                        name="isForeigner"
                        onChange={(e) => {
                            formik.setFieldValue('billing.isForeigner', e);
                            formik.setFieldValue('billing.isCompany', e);
                            formik.setFieldValue('billing.document', '');
                        }}
                        checked={formik.values.billing.isForeigner}
                        error={
                            formik.touched?.billing?.isForeigner &&
                            formik.errors?.billing?.isForeigner
                        }
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Input
                        id="billing.name"
                        name="billing.name"
                        value={formik.values.billing.name}
                        label={
                            formik.values.billing?.isCompany
                                ? Locale.fields.corporateName
                                : Locale.fields.fullName
                        }
                        error={
                            formik.touched?.billing?.name &&
                            formik.errors?.billing?.name
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-3">
                    <Input
                        id="billing.document"
                        name="billing.document"
                        value={MaskHelper.document(
                            formik.values.billing?.document,
                            formik.values.billing?.isForeigner,
                            formik.values.billing?.isCompany
                        )}
                        label={getDocumentLabel(
                            formik.values.billing?.isForeigner,
                            formik.values.billing?.isCompany
                        )}
                        error={
                            formik.touched?.billing?.document &&
                            formik.errors?.billing?.document
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={(e) => setDocument(e)}
                    />
                </div>
                <div className="col-4 col-md-2">
                    <Dropdown
                        id="billing.countryPhoneCode"
                        name="billing.countryPhoneCode"
                        options={countriesPhones}
                        label={Locale.fields.countryCode}
                        onChange={formik.handleChange}
                        value={formik.values.billing.countryPhoneCode}
                        error={
                            formik.touched?.billing?.countryPhoneCode &&
                            formik.errors?.billing?.countryPhoneCode
                        }
                        autocomplete
                        required
                        loading={loadingCountriesPhones}
                    />
                </div>
                <div className="col-8 col-md-4">
                    <PhoneNumberInput
                        id="billing.phoneNumber"
                        name="billing.phoneNumber"
                        type="phoneNumber"
                        value={formik.values.billing.phoneNumber}
                        label={Locale.fields.phone}
                        error={
                            formik.touched?.billing?.phoneNumber &&
                            formik.errors?.billing?.phoneNumber
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        countryPhoneCode={
                            formik.values.billing?.countryPhoneCode
                        }
                    />
                </div>

                <div className="col-12 col-md-6">
                    <Input
                        id="billing.email"
                        name="billing.email"
                        value={formik.values.billing.email}
                        label={Locale.fields.email}
                        error={
                            formik.touched?.billing?.email &&
                            formik.errors?.billing?.email
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <Dropdown
                        id="billing.country.id"
                        name="billing.country.id"
                        label={Locale.fields.country}
                        options={Array.from(countries.values())}
                        required
                        onChange={setCountry}
                        value={formik.values.billing?.country?.id}
                        error={
                            formik.touched?.billing?.country &&
                            formik.errors?.billing?.country?.id
                        }
                        onBlur={formik.handleBlur}
                        autocomplete
                        showListOnTop
                    />
                </div>
                <div className="col-12 col-md-2">
                    <Input
                        id="billing.postalCode"
                        name="billing.postalCode"
                        value={
                            isBrazilianAddress
                                ? MaskHelper.cep(
                                      formik.values.billing.postalCode
                                  )
                                : formik.values.billing.postalCode
                        }
                        label={
                            isBrazilianAddress
                                ? Locale.fields.cep
                                : Locale.fields.postalCode
                        }
                        type="text"
                        maxLength={isBrazilianAddress ? 9 : 12}
                        error={
                            formik.touched?.billing?.postalCode &&
                            formik.errors?.billing?.postalCode
                        }
                        required
                        onBlur={setAddress}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'billing.postalCode',
                                e.target.value.replace(/\D/g, '')
                            );
                        }}
                    />
                </div>

                <div className="col-12 col-md-2">
                    <Dropdown
                        id="billing.state.id"
                        name="billing.state.id"
                        label={Locale.fields.state}
                        required
                        options={Array.from(states.values())}
                        value={formik.values.billing.state?.id}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'billing.state',
                                states.get(e.target.value)
                            );
                        }}
                        error={
                            (formik.touched?.billing?.state?.id ||
                                !cepSuccess) &&
                            formik.errors?.billing?.state?.id
                        }
                        onBlur={formik.handleBlur}
                        placeholder={
                            isBrazilianAddress && cepSuccess
                                ? Locale.fields.addressPlaceholder
                                : ''
                        }
                        disabled={
                            (isBrazilianAddress && cepSuccess) ||
                            !formik.values.billing.country?.id
                        }
                        autocomplete
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Input
                        id="billing.city"
                        name="billing.city"
                        label={Locale.fields.city}
                        required
                        onChange={formik.handleChange}
                        error={
                            (formik.touched?.billing?.city || !cepSuccess) &&
                            formik.errors?.billing?.city
                        }
                        value={formik.values.billing.city}
                        onBlur={formik.handleBlur}
                        placeholder={
                            isBrazilianAddress && cepSuccess
                                ? Locale.fields.addressPlaceholder
                                : ''
                        }
                        disabled={isBrazilianAddress && cepSuccess}
                    />
                </div>
            </div>
            <div className="row">
                <div
                    className={`col-12 ${
                        isBrazilianAddress ? 'col-md-8' : 'col-md-6'
                    }`}
                >
                    <Input
                        id="billing.address"
                        name="billing.address"
                        value={formik.values.billing.address}
                        label={Locale.fields.address}
                        error={
                            (formik.touched?.billing?.address || !cepSuccess) &&
                            formik.errors?.billing?.address
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </div>
                {isBrazilianAddress && (
                    <div className="col-12 col-md-4">
                        <Input
                            id="billing.district"
                            name="billing.district"
                            value={formik.values.billing.district}
                            label={Locale.fields.district}
                            error={
                                (formik.touched?.billing?.district ||
                                    !cepSuccess) &&
                                formik.errors?.billing?.district
                            }
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </div>
                )}
                <div className="col-12 col-md-2">
                    <Input
                        id="billing.number"
                        name="billing.number"
                        value={formik.values.billing.number}
                        label={Locale.fields.number}
                        type="number"
                        error={
                            formik.touched?.billing?.number &&
                            formik.errors?.billing?.number
                        }
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Input
                        id="billing.complement"
                        name="billing.complement"
                        value={formik.values.billing.complement}
                        label={Locale.fields.complement}
                        error={
                            formik.touched?.billing?.complement &&
                            formik.errors?.billing?.complement
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>

            <StickyFooter>
                <Button type="submit">
                    {
                        Locale.actions[
                            reservation.id ? 'proceedToPayment' : 'proceed'
                        ]
                    }
                </Button>
            </StickyFooter>
        </form>
    );
}
