import PropTypes from 'prop-types';
import { Button, Image, Tooltip } from 'components';
import { icoInfoGray } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import Locale from 'locale';
import './styles.scss';

export default function OutstandingDebts(props) {
    const { className, value } = props;
    const navigate = useNavigate();
    return (
        <div className={`${className} stack--xs margin--b-md`}>
            <div className="outstanding-debts-card flex--row flex-justify--between flex-items--center">
                <div>
                    <div className="flex--row margin--b-xs">
                        <h3>
                            {Locale.pages.homeLoggedIn.outstandingDebts.title}
                        </h3>
                        <Tooltip
                            content={
                                <p className="outstanding-debts-tooltip">
                                    {
                                        Locale.pages.homeLoggedIn
                                            .outstandingDebts.tooltip
                                    }
                                </p>
                            }
                        >
                            <Image
                                className="margin--l-xs"
                                src={icoInfoGray}
                                alt="icon info"
                            />
                        </Tooltip>
                    </div>
                    <p className="color--brand-primary">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(value)}
                    </p>
                </div>
                <div>
                    <Button
                        kind="primary"
                        onClick={() => {
                            navigate('/debt-hub');
                        }}
                    >
                        {Locale.pages.homeLoggedIn.outstandingDebts.view}
                    </Button>
                </div>
            </div>
            <div className="outstanding-debts-observation">
                {`* ${Locale.pages.homeLoggedIn.outstandingDebts.observationMessage}`}
            </div>
        </div>
    );
}

OutstandingDebts.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
};

OutstandingDebts.defaultProps = {
    className: '',
};
