import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, Image } from 'components';
import { icoExpandDown, icoUser } from 'assets/icons';
import Service from 'services';
import './styles.scss';

export default function UserMenu({ isAuthenticated, submenus }) {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [dropdownMenuRef, setDropdownMenuRef] = useState(useRef());
    const [customer, setCustomer] = useState();
    const openMenuRef = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (
                showUserMenu &&
                !openMenuRef.current?.contains(event.target) &&
                !dropdownMenuRef.current?.contains(event.target)
            ) {
                document.removeEventListener('mousedown', handler);
                setShowUserMenu(false);
            }
        };
        if (showUserMenu) {
            document.addEventListener('mousedown', handler);
        }
    }, [showUserMenu]);

    useEffect(() => {
        if (isAuthenticated) {
            Service.getCustomer()
                .then((data) => {
                    setCustomer(data);
                })
                .catch(() => {});
        } else {
            setCustomer(undefined);
        }
    }, [isAuthenticated]);

    return (
        <>
            <div
                className="inline--sm flex-items--center cursor--pointer"
                ref={openMenuRef}
                onClick={() => {
                    setShowUserMenu(!showUserMenu);
                }}
            >
                <ButtonIcon
                    kind="outline"
                    name={customer?.name}
                    icon={customer?.name ? '' : icoUser}
                    className="ico-user"
                />
                <Image src={icoExpandDown} alt="Menu" size={24} />
            </div>
            <MenuItems
                setRef={setDropdownMenuRef}
                show={showUserMenu}
                submenus={submenus}
                setShow={setShowUserMenu}
            />
        </>
    );
}

UserMenu.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    submenus: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
};

function MenuItems({ show, submenus, setRef, setShow }) {
    const dropdownMenuRef = useRef();
    useEffect(() => setRef(dropdownMenuRef), [dropdownMenuRef]);
    return (
        <div
            ref={dropdownMenuRef}
            className={`dropdown-menu ${show && 'show'}`}
        >
            {submenus.map((submenu) => (
                <div
                    onClick={() => {
                        submenu.onClick();
                        setShow(false);
                    }}
                    key={submenu.title}
                    className="menu-item"
                >
                    <Image src={submenu.icon} alt={submenu.title} />
                    <div>{submenu.title}</div>
                </div>
            ))}
        </div>
    );
}

MenuItems.propTypes = {
    show: PropTypes.bool.isRequired,
    setRef: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    submenus: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
};
