import Service from '../services';

const AuthenticationHelper = {
    TOKEN_EXPIRATION_INTERVAL: 280000,

    startTokenRefreshTask() {
        setInterval(() => {
            Service.refreshLogin();
        }, this.TOKEN_EXPIRATION_INTERVAL);
    },
};

export default AuthenticationHelper;
