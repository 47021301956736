import { PropTypes } from 'prop-types';
import Locale from 'locale';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Service from 'services';
import { ButtonIcon, Label, Line } from 'components';
import { ErrorHelper } from 'helpers';

import './styles.scss';

function CustomerResponseCard({ data, name, statusDetails, timestamp }) {
    return (
        <div>
            <Label className="text-uppercase">
                {moment(timestamp).format('dddd DD/MM/YYYY - HH:mm')}
            </Label>
            <div className="margin--y-lg flex--row flex-items--center">
                <ButtonIcon kind="outline" name={name} />
                <div className="margin--sm">{name}</div>
            </div>
            <h4 className="headline-4 color--grayscale-blue-gray margin--y-sm">
                {Locale.pages.backoffice.details.customerResponse}
            </h4>
            {statusDetails?.observation ? (
                <div>
                    <p>{Locale.pages.reservationDetail.notSuitMe}</p>
                    <h5 className="headline-5 color--grayscale-blue-gray margin--t-md">
                        {Locale.fields.justification}
                    </h5>
                    <div className="margin--y-sm">
                        {statusDetails?.observation}
                    </div>
                </div>
            ) : (
                <div>
                    <p>{Locale.pages.backoffice.details.acceptedOption}</p>
                    <div className="margin--y-sm text-capitalize date-suggestion">
                        <span className="suggestion-airport-time">
                            {moment(data?.originFlight?.datetime).format(
                                'dddd DD/MM/YYYY - HH:mm'
                            )}
                        </span>
                        <span className="suggestion-utc-time">
                            {`${momentTZ(data?.originFlight?.datetime)
                                .tz(data?.airportTarget?.timezone, true)
                                .utc()
                                .format('DD/MM/YYYY - HH:mm')} UTC 0`}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

CustomerResponseCard.propTypes = {
    data: PropTypes.object.isRequired,
    name: PropTypes.string,
    statusDetails: PropTypes.object,
    timestamp: PropTypes.string.isRequired,
};

CustomerResponseCard.defaultProps = {
    name: undefined,
    statusDetails: undefined,
};

function BackofficeCard({ statusDetails, timestamp, timezone }) {
    const getDateTimeSuggestion = (dateSuggestion) => (
        <div className="margin--y-sm text-capitalize date-suggestion">
            <span className="suggestion-airport-time">
                {moment(dateSuggestion).format('dddd DD/MM/YYYY - HH:mm')}
            </span>
            <span className="suggestion-utc-time">
                {`${momentTZ(dateSuggestion)
                    .tz(timezone, true)
                    .utc()
                    .format('DD/MM/YYYY - HH:mm')} UTC 0`}
            </span>
        </div>
    );

    return (
        <div>
            <Label className="text-uppercase">
                {moment(timestamp).format('dddd DD/MM/YYYY - HH:mm')}
            </Label>
            <div className="margin--y-lg flex--row flex-items--center">
                <ButtonIcon kind="outline" name="Back Office" />
                <div className="margin--sm">{Locale.general.backoffice}</div>
            </div>
            <h4 className="headline-4 color--grayscale-blue-gray margin--y-md">
                {Locale.pages.backoffice.details.landingSuggestions}
            </h4>
            {statusDetails?.dateSuggestion1 &&
                getDateTimeSuggestion(statusDetails?.dateSuggestion1)}
            {statusDetails?.dateSuggestion2 &&
                getDateTimeSuggestion(statusDetails?.dateSuggestion2)}
        </div>
    );
}

BackofficeCard.propTypes = {
    statusDetails: PropTypes.object,
    timestamp: PropTypes.string.isRequired,
    timezone: PropTypes.string,
};

BackofficeCard.defaultProps = {
    statusDetails: undefined,
    timezone: 'America/Sao_Paulo',
};

function NegotiationHistory({ data, statusList }) {
    const [requester, setRequester] = useState();
    const getCustomerByUserId = () => {
        Service.getCustomerByUserId(data.idRequester)
            .then((response) => setRequester(response))
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        getCustomerByUserId();
    }, []);

    return (
        <div className="margin--t-xl margin--x-md">
            {!!statusList?.length && (
                <h4 className="headline-4 color--grayscale-dark margin--b-md">
                    {Locale.pages.backoffice.details.negotiationHistory}
                </h4>
            )}
            {statusList?.map((status, index) => (
                <div key={`history-item-${status.timestamp}`}>
                    {status.statusId === 1 ? (
                        <CustomerResponseCard
                            data={data}
                            name={requester?.name}
                            statusDetails={status.details}
                            timestamp={status.timestamp}
                        />
                    ) : (
                        <BackofficeCard
                            statusDetails={status.details}
                            timestamp={status.timestamp}
                            timezone={data.airportTarget.timezone}
                        />
                    )}
                    {index + 1 < statusList.length && (
                        <Line className="margin--t-md" />
                    )}
                </div>
            ))}
        </div>
    );
}

NegotiationHistory.propTypes = {
    data: PropTypes.object.isRequired,
    statusList: PropTypes.array,
};

NegotiationHistory.defaultProps = {
    statusList: [],
};

export default NegotiationHistory;
