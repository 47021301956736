import Locale from 'locale';
import * as Yup from 'yup';
import BaseModel from './BaseModel';
import Country from './Country';
import State from './State';

export default class Address extends BaseModel {
    constructor(data) {
        super();

        this.country = Country.getValueOrNew(data.country);
        this.postalCode = data.postalCode;
        this.state = State.getValueOrNew(data.state);
        this.city = data.city;
        this.street = data.street;
        this.number = data.number;
        this.complement = data.complement;
        this.district = data.district;
    }

    static createEmpty() {
        return new Address({
            country: Country.createEmpty(),
            state: State.createEmpty(),
        });
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new Address({
            ...response,
        });
    }

    static get validationSchema() {
        return Yup.object({
            country: Country.validationSchema,
            postalCode: Yup.string().required(Locale.errors.required),
            state: State.validationSchema,
            city: Yup.string().required(Locale.errors.required),
            street: Yup.string().required(Locale.errors.required),
            number: Yup.string().required(Locale.errors.required),
            complement: Yup.string(),
            district: Yup.string()
                .nullable(true)
                .when('country', (country, schema) => {
                    if (country?.geonameId === Country.filterIds.brazil) {
                        return schema.required(Locale.errors.required);
                    }
                    return schema;
                }),
        });
    }

    parseToRequest() {
        const { ...addressData } = this;
        const payload = {
            ...addressData,
            state: addressData.state.parseToRequest(),
            country: addressData.country.parseToRequest(),
        };

        return payload;
    }
}
