import PropTypes from 'prop-types';
import { Container, HiddenCheckbox, StyledCheckbox, Text } from './styles';
import { Image, Label } from '..';
import checkBlue from '../../assets/icons/check-blue.svg';
import checkGray from '../../assets/icons/check-gray.svg';

function Checkbox({
    id,
    name,
    disabled,
    checked,
    label,
    onChange,
    error,
    ...args
}) {
    return (
        <Container disabled={disabled} error={error}>
            <HiddenCheckbox
                id={id}
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                error={error}
                {...args}
            />
            <StyledCheckbox checked={checked} disabled={disabled} htmlFor={id}>
                <Image src={disabled ? checkGray : checkBlue} alt="check" />
            </StyledCheckbox>
            {label && (
                <Label htmlFor={id}>
                    <Text>{label}</Text>
                </Label>
            )}
        </Container>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
};

Checkbox.defaultProps = {
    disabled: false,
    checked: false,
    label: '',
    error: '',
};

export default Checkbox;
