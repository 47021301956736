import React from 'react';
import { PropTypes } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Radio, Line, DatePicker, TimePicker } from 'components';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusDetails } from 'models';
import notify from 'components/Toast';
import { ReservationStatus, getReservationStatus } from 'utils/enums';
import { joinDateTime } from 'utils/functions';
import momentTZ from 'moment-timezone';

import './styles.scss';

function FormApprove({ data, loadData }) {
    const navigate = useNavigate();
    const { reservationId } = useParams();
    const timeZone = data.airportTarget.timezone;

    const initialValues = {
        status: null,
        dateSuggestion1: undefined,
        timeSuggestion1: undefined,
        dateSuggestion2: undefined,
        timeSuggestion2: undefined,
        comments: '',
    };

    const validationNewFormSchema = Yup.object().shape(
        {
            status: Yup.string().required(Locale.errors.required),
            dateSuggestion1: Yup.string().when(['status'], {
                is: (status) => status !== 'approved',
                then: Yup.string().required(Locale.errors.required),
            }),
            timeSuggestion1: Yup.string().when(['status'], {
                is: (status) => status !== 'approved',
                then: Yup.string().required(Locale.errors.required),
            }),
            dateSuggestion2: Yup.string().when(['status', 'timeSuggestion2'], {
                is: (status, timeSuggestion2) =>
                    status !== 'approved' && timeSuggestion2,
                then: Yup.string().required(Locale.errors.required),
            }),
            timeSuggestion2: Yup.string().when(['status', 'dateSuggestion2'], {
                is: (status, dateSuggestion2) =>
                    status !== 'approved' && dateSuggestion2,
                then: Yup.string().required(Locale.errors.required),
            }),
        },
        ['dateSuggestion2', 'timeSuggestion2']
    );

    const formik = useFormik({
        initialValues,
        validationSchema: validationNewFormSchema,
        onSubmit: (values) => {
            if (formik.values.status === 'approved') {
                Service.approve(data.id)
                    .then(() => {
                        navigate(0);
                    })
                    .catch((error) => ErrorHelper.notifyError(error));
            }
            if (formik.values.status === 'returned') {
                const payload = new StatusDetails(values);
                Service.requestChange(reservationId, payload.parseToRequest())
                    .then(() => {
                        loadData();
                        notify(
                            Locale.pages.backoffice.details.text.changeRequested
                        );
                    })
                    .catch((error) => ErrorHelper.notifyError(error));
            }
        },
    });

    const getStatusText = (status) => {
        switch (status) {
            case ReservationStatus.changeRequested:
                return Locale.pages.backoffice.details.text.changeRequested;
            case ReservationStatus.pendingBilling:
                return Locale.pages.backoffice.details.text.approved;
            default:
                return Locale.status.title.current[
                    getReservationStatus(status)
                ];
        }
    };

    const getUtcDateTimeSuggestion = (dateSuggestion, timeSuggestion) => {
        const currentDateTime = joinDateTime(dateSuggestion, timeSuggestion);
        return `${momentTZ(currentDateTime)
            .tz(timeZone, true)
            .utc()
            .format('DD/MM/YY - HH:mm')} UTC 0`;
    };

    return (
        <div className="padding--t-xxl padding--x-md">
            <form onSubmit={formik.handleSubmit}>
                <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                    {Locale.pages.backoffice.details.status}
                </h4>
                {data?.status === ReservationStatus.pendingReview ? (
                    <div>
                        <div className="margin--b-md">
                            <div className="row">
                                <div className="col-12">
                                    <Radio
                                        id="approved"
                                        name="status"
                                        label={Locale.fields.approved}
                                        value="approved"
                                        checked={
                                            formik.values.status === 'approved'
                                        }
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.status &&
                                            formik.errors.status
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Radio
                                        id="returned"
                                        name="status"
                                        label={Locale.fields.returned}
                                        value="returned"
                                        checked={
                                            formik.values.status === 'returned'
                                        }
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.status &&
                                            formik.errors.status
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {/* DEVOLVIDA */}
                        {formik.values.status === 'returned' && (
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                                        {
                                            Locale.pages.backoffice.details
                                                .dateDevolution
                                        }
                                    </h4>
                                    <div className="row">
                                        <div className="row margin-b-md">
                                            <div className="col-3">
                                                <DatePicker
                                                    name="dateSuggestion1"
                                                    id="dateSuggestion1"
                                                    // TODO: Investigar required não funcionando, tem a ver com o customInput.
                                                    label={`${Locale.fields.alternateDate} 1*`}
                                                    value={
                                                        formik.values
                                                            .dateSuggestion1
                                                    }
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'dateSuggestion1',
                                                            date
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                            <div className="col-3">
                                                <TimePicker
                                                    name="timeSuggestion1"
                                                    id="timeSuggestion1"
                                                    // TODO: Investigar required não funcionando, tem a ver com o customInput.
                                                    label={`${Locale.fields.alternateTime} 1*`}
                                                    timeIntervals={15}
                                                    timeCaption={
                                                        Locale.general.hour
                                                    }
                                                    value={
                                                        formik.values
                                                            .timeSuggestion1
                                                    }
                                                    onChange={(time) => {
                                                        formik.setFieldValue(
                                                            'timeSuggestion1',
                                                            time
                                                        );
                                                    }}
                                                    required
                                                />
                                            </div>
                                            {formik.values.dateSuggestion1 &&
                                                formik.values
                                                    .timeSuggestion1 && (
                                                    <div className="col-3 utc-suggestion">
                                                        {getUtcDateTimeSuggestion(
                                                            formik.values
                                                                .dateSuggestion1,
                                                            formik.values
                                                                .timeSuggestion1
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    <div className="row margin-b-md">
                                        <div className="row">
                                            <div className="col-3">
                                                <DatePicker
                                                    name="dateSuggestion2"
                                                    id="dateSuggestion2"
                                                    label={`${Locale.fields.alternateDate} 2`}
                                                    value={
                                                        formik.values
                                                            .dateSuggestion2
                                                    }
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'dateSuggestion2',
                                                            date
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-3">
                                                <TimePicker
                                                    name="timeSuggestion2"
                                                    id="timeSuggestion2"
                                                    label={`${Locale.fields.alternateTime} 2`}
                                                    timeIntervals={15}
                                                    timeCaption={
                                                        Locale.general.hour
                                                    }
                                                    value={
                                                        formik.values
                                                            .timeSuggestion2
                                                    }
                                                    onChange={(time) => {
                                                        formik.setFieldValue(
                                                            'timeSuggestion2',
                                                            time
                                                        );
                                                    }}
                                                    required
                                                />
                                            </div>
                                            {formik.values.dateSuggestion2 &&
                                                formik.values
                                                    .timeSuggestion2 && (
                                                    <div className="col-3 utc-suggestion">
                                                        {getUtcDateTimeSuggestion(
                                                            formik.values
                                                                .dateSuggestion2,
                                                            formik.values
                                                                .timeSuggestion2
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <Line />
                        <div className="flex-justify--end margin--t-lg margin--b-sm">
                            <Button
                                type="submit"
                                kind="primary"
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                {Locale.actions.send}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>{getStatusText(data?.status)}</div>
                )}
            </form>
        </div>
    );
}

FormApprove.propTypes = {
    data: PropTypes.object.isRequired,
    loadData: PropTypes.func.isRequired,
};

export default FormApprove;
