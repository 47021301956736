import { Accordion, Loading, TextInfo } from 'components';
import { ErrorHelper } from 'helpers';
import Locale from 'locale';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Service from 'services';
import { getDocumentLabel, getPhoneWithCode } from 'utils/functions';

export default function RequesterAccordion({ isUserOperator, isOpen }) {
    const [requester, setRequester] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Service.getCustomer()
            .then((data) => {
                setRequester(data);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyCustomerError(error);
                setLoading(false);
            });
    }, []);

    return (
        <Accordion
            title={Locale.pages.summary.requester}
            className="margin--b-xl"
            isOpen={isOpen}
        >
            {!loading ? (
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextInfo
                            label={Locale.fields.fullName}
                            value={requester.name}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextInfo
                            label={getDocumentLabel(
                                requester.isForeigner,
                                requester.isCompany
                            )}
                            value={requester.document}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextInfo
                            label={Locale.fields.phone}
                            value={getPhoneWithCode(requester)}
                        />
                    </div>
                    <div className="col-12 col-md-4 break-word">
                        <TextInfo
                            label={Locale.fields.email}
                            value={requester.email}
                        />
                    </div>
                    {isUserOperator !== undefined && (
                        <div className="col-12 col-md-4">
                            <TextInfo
                                label={Locale.pages.summary.aircraftOperator}
                                value={
                                    isUserOperator
                                        ? Locale.general.yes
                                        : Locale.general.no
                                }
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className="width--full">
                    <Loading className="margin-auto" />
                </div>
            )}
        </Accordion>
    );
}

RequesterAccordion.propTypes = {
    isUserOperator: PropTypes.bool,
    isOpen: PropTypes.bool,
};

RequesterAccordion.defaultProps = {
    isUserOperator: undefined,
    isOpen: false,
};
