import moment from 'moment';
import { joinDateTime } from 'utils/functions';
import BaseModel from './BaseModel';

export default class StatusDetails extends BaseModel {
    dateFormat = 'DD/MM/YYYY HH:mm';

    constructor(data) {
        super();

        this.dateSuggestion1 = StatusDetails.getValueOrNew(
            joinDateTime(data.dateSuggestion1, data.timeSuggestion1),
            Date
        );
        this.dateSuggestion2 = StatusDetails.getValueOrNew(
            joinDateTime(data.dateSuggestion2, data.timeSuggestion2),
            Date
        );
        this.observation = data.observation;
    }

    get dateSuggestion1Formatted() {
        return moment(this.dateSuggestion1).format(this.dateFormat);
    }

    get dateSuggestion2Formatted() {
        return moment(this.dateSuggestion2).format(this.dateFormat);
    }
}
