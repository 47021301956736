import * as Yup from 'yup';
import moment from 'moment';
import BaseModel from './BaseModel';

export default class InvoiceOperation extends BaseModel {
    constructor(data) {
        super();

        this.aeroportoOrigemICAO = data.aeroportoOrigemICAO;
        this.aeroportoICAO = data.aeroportoICAO;
        this.aeroportoDestinoICAO = data.aeroportoDestinoICAO;
        this.vlrTotal = data.vlrTotal;
        this.dtToqueChegada = moment(
            data.dtToqueChegada,
            'DD/MM/YYYY HH:mm'
        ).toDate();
        this.dtToquePartida = moment(
            data.dtToquePartida,
            'DD/MM/YYYY HH:mm'
        ).toDate();
        this.qtdManobra = data.qtdManobra;
        this.vlrManobra = data.vlrManobra;
        this.vlrEstadia = data.vlrEstadia;
        this.qtdEstadia = data.qtdEstadia;
        this.vlrPouso = data.vlrPouso;
        this.pmdAeronave = data.pmdAeronave;
    }

    static get validationSchema() {
        return Yup.object().shape({
            aeroportoOrigemICAO: Yup.string().required(),
            aeroportoICAO: Yup.string().required(),
            aeroportoDestinoICAO: Yup.string().required(),
            vlrTotal: Yup.number().required(),
            dtToqueChegada: Yup.date().required(),
            dtToquePartida: Yup.date().required(),
            qtdManobra: Yup.number().required(),
            vlrManobra: Yup.number().required(),
            qtdEstadia: Yup.number().required(),
            vlrEstadia: Yup.number().required(),
            vlrPouso: Yup.number().required(),
            pmdAeronave: Yup.number.required(),
        });
    }
}
