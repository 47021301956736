import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
    Button,
    Input,
    TextSwitch,
    Line,
    ContainerForm,
    Dropdown,
    Text,
    PhoneNumberInput,
    BottomSheet,
    Image,
} from 'components';
import Service from 'services';
import Locale from 'locale';
import { useNavigate } from 'react-router-dom';
import { MaskHelper, ErrorHelper } from 'helpers';
import notify from 'components/Toast';
import { Aircraft, Operator, Country, State } from 'models';
import { getDocumentLabel } from 'utils/functions';
import { useCountryPhoneOptions } from 'states';
import theme from 'styles/themes/default';
import './styles.scss';
import Address from 'models/Address';
import { icoExclamationYellow } from 'assets/icons';

export default function AircraftForm({
    children,
    onSubmit,
    editAircraft,
    onDelete,
    isCreating,
    showing,
    setFormikValues,
    previousValues,
    previousFormikErrors,
    showModal,
    createdAircraft,
    ...args
}) {
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState({});
    const [countries, setCountries] = useState(new Map());
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [states, setStates] = useState(new Map());
    const [loadingStates, setLoadingStates] = useState(false);
    const [aircraft, setAircraft] = useState([]);
    const [registrationOptions, setRegistrationOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cepSuccess, setCepSuccess] = useState(true);
    const [isBrazilianAddress, setIsBrazilianAddress] = useState(false);
    const [showAddressWarningModal, setShowAddressWarningModal] =
        useState(false);

    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const initialValues = { ...Aircraft.createEmpty() };

    const formik = useFormik({
        initialValues: previousValues || initialValues,
        validationSchema: Aircraft.validationSchema,
        onSubmit: (data) => {
            onSubmit(data);
        },
    });

    const setAddress = () => {
        formik.setFieldTouched('operator.address.postalCode', true);
        if (
            formik.values?.operator?.address?.postalCode &&
            isBrazilianAddress
        ) {
            Service.getAddress(formik.values.operator.address?.postalCode)
                .then((response) => {
                    formik.setFieldValue(
                        'operator.address.street',
                        response?.address
                    );
                    formik.setFieldValue(
                        'operator.address.city',
                        response?.city
                    );
                    formik.setFieldValue(
                        'operator.address.state',
                        response?.state
                            ? states.get(State.brazilianStates[response?.state])
                            : State.createEmpty()
                    );
                    formik.setFieldValue(
                        'operator.address.district',
                        response?.district
                    );
                    setCepSuccess(response.ok);
                    if (!response.ok) {
                        notify(Locale.errors.cep);
                    }
                })
                .catch((error) => {
                    setCepSuccess(false);
                    ErrorHelper.notifyError(error);
                    notify(Locale.errors.cep);
                });
        }
    };

    const getCustomerData = () => {
        if (Object.keys(customerData).length === 0) {
            Service.getCustomer()
                .then((response) => {
                    const operator = Operator.createFromResponse(response);
                    setCustomerData(operator);
                    formik.setFieldValue('operator', operator);
                })
                .catch((error) => ErrorHelper.notifyCustomerError(error));
        } else {
            formik.setFieldValue('operator', customerData);
        }
    };

    const setValuesFromPreviousId = (aircraftList) => {
        const values = aircraftList.find(
            (aircraftFromList) => aircraftFromList.id === previousValues.id
        ) || { ...initialValues };
        formik.resetForm();
        formik.setValues(values);
        formik.setFieldValue('operator', values.operator);
    };

    const setAircraftFields = (selectedRegistration) => {
        const selectedAircraft = aircraft.find(
            (data) => data.registration === selectedRegistration
        );
        formik.setFieldValue('isForeign', selectedAircraft.isForeign);
        formik.setFieldValue('icaoModel', selectedAircraft.icaoModel);
        formik.setFieldValue('mtow', selectedAircraft.mtow);
        formik.setFieldValue('wingspan', selectedAircraft.wingspan);
        formik.setFieldValue('length', selectedAircraft.length);
        formik.setFieldValue('id', selectedAircraft.id);
        formik.setFieldValue('operator', selectedAircraft.operator);
        if (!selectedAircraft?.operator?.address) {
            formik.setFieldValue('operator.address', Address.createEmpty());
            setShowAddressWarningModal(true);
        } else {
            setIsBrazilianAddress(
                selectedAircraft.operator.address.country?.geonameId ===
                    Country.filterIds.brazil
            );
        }
        formik
            .setFieldValue('registration', selectedAircraft.registration)
            .then(() => {
                formik.validateForm();
            });
    };

    const getCountries = () => {
        setLoadingCountries(true);
        Service.getCountries()
            .then((response) => {
                const responseCountries =
                    Country.createListFromResponse(response);
                setCountries(
                    new Map(
                        responseCountries.map((responseCountry) => [
                            responseCountry.value,
                            responseCountry,
                        ])
                    )
                );
                setLoadingCountries(false);
            })
            .catch((error) => {
                setLoadingCountries(false);
                ErrorHelper.notifyError(error);
            });
    };

    const setCountry = (e) => {
        formik.setFieldValue(
            'operator.address.country',
            countries.get(e.target.value)
        );
        setIsBrazilianAddress(
            e.target.value === Country.filterIds.brazil.toString()
        );

        formik.setTouched({});
        formik.setFieldValue('operator.address.postalCode', '');
        formik.setFieldValue('operator.address.street', '');
        formik.setFieldValue('operator.address.city', '');
        formik.setFieldValue('operator.address.state', State.createEmpty());
        formik.setFieldValue('operator.address.district', '');
        formik.setFieldValue('operator.address.number', '');
        formik.setFieldValue('operator.address.complement', '');
    };

    const getAircraft = () => {
        setLoading(true);
        Service.listAircraft()
            .then((response) => {
                const aircraftList = Aircraft.createListFromResponse(response);
                setAircraft(aircraftList);
                setRegistrationOptions(Aircraft.parseDropdownOptions(response));

                if (previousValues?.id) {
                    setValuesFromPreviousId(aircraftList);
                }
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!formik.values.operator?.address?.country?.id) {
            return;
        }
        setLoadingStates(true);
        Service.getStates({
            [State.filterParams.countryId]:
                formik.values.operator.address.country.id,
        })
            .then((response) => {
                const responseStates = State.createListFromResponse(response);
                setStates(
                    new Map(
                        responseStates.map((responseState) => [
                            responseState.value,
                            responseState,
                        ])
                    )
                );
                setLoadingStates(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoadingStates(false);
            });
    }, [formik.values.operator?.address?.country?.id]);

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (countries) {
            const orderedCountries = new Map(
                [...countries].sort((a, b) =>
                    a[1].nameByLanguage.localeCompare(b[1].nameByLanguage)
                )
            );
            setCountries(orderedCountries);
        }
    }, [Locale.getLanguage()]);

    useEffect(() => {
        if (!showModal) {
            getAircraft();
        }
    }, [showModal]);
    useEffect(() => {
        if (createdAircraft) {
            setAircraftFields(createdAircraft.registration);
        }
    }, [aircraft]);

    useEffect(() => {
        if (editAircraft) {
            formik.resetForm();
            formik.setValues(editAircraft);
            formik.setFieldValue('operator', editAircraft.operator);
            setIsBrazilianAddress(
                editAircraft?.operator?.address?.country.geonameId ===
                    Country.filterIds.brazil
            );
        } else {
            formik.resetForm();
        }
    }, [editAircraft, showing]);

    useEffect(() => {
        if (setFormikValues) {
            setFormikValues(formik.values);
        }
    }, [formik.values]);

    useEffect(() => {
        if (formik.values.useData) {
            getCustomerData();
        } else if (editAircraft) {
            formik.setFieldValue('operator', editAircraft.operator);
        } else if (!previousValues?.operator) {
            formik.setFieldValue('operator', Operator.createEmpty());
        }
    }, [formik.values.useData]);

    const setDocument = (e) => {
        if (formik.values.operator?.isForeigner) {
            if (formik.values.operator?.isCompany) {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 15)
                );
            } else {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 9)
                );
            }
        } else if (formik.values.operator?.isCompany) {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 14)
            );
        } else {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 11)
            );
        }
    };

    useEffect(() => {
        if (previousFormikErrors) {
            formik.setErrors({ ...formik.errors, ...previousFormikErrors });
        }
    }, [previousFormikErrors]);

    return (
        <form onSubmit={formik.handleSubmit} {...args}>
            <BottomSheet
                show={showAddressWarningModal}
                onClose={() => setShowAddressWarningModal(false)}
            >
                <div className="stack--md padding--y-sm">
                    <Image
                        src={icoExclamationYellow}
                        size={32}
                        alt="ico-alert"
                    />
                    <p className="fw-semibold font-size--md">
                        {Locale.fields.aircraft.incompleteRegistration.title}
                    </p>
                    <p className="font-size--sm">
                        {
                            Locale.fields.aircraft.incompleteRegistration
                                .description
                        }
                    </p>
                    <Button
                        type="button"
                        kind="primary"
                        onClick={() => {
                            navigate(
                                `/aircraft/${formik.values.registration}/edit/operator`
                            );
                        }}
                    >
                        {Locale.fields.aircraft.editOperator}
                    </Button>
                    <Button
                        type="button"
                        kind="outline"
                        onClick={() => setShowAddressWarningModal(false)}
                    >
                        {Locale.fields.aircraft.chooseAnotherAircraft}
                    </Button>
                </div>
            </BottomSheet>
            <ContainerForm>
                {isCreating ? (
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                id="registration"
                                name="registration"
                                label={Locale.fields.aircraft.registration}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'registration',
                                        MaskHelper.upperAlphanumeric(
                                            e.target.value
                                        )
                                    )
                                }
                                value={formik.values.registration}
                                error={
                                    formik.touched.registration &&
                                    formik.errors.registration
                                }
                                maxLength={Aircraft.maxLength.registration}
                                onBlur={formik.handleBlur}
                                required
                                disabled={!!editAircraft}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-6">
                            <Dropdown
                                id="registration"
                                name="registration"
                                options={registrationOptions}
                                label={Locale.fields.aircraft.registration}
                                onChange={(e) =>
                                    setAircraftFields(e.target.value)
                                }
                                value={formik.values.registration}
                                error={
                                    formik.touched.registration &&
                                    formik.errors.registration
                                }
                                onBlur={formik.handleBlur}
                                required
                                autocomplete
                                loading={loading}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-3 flex-items--end">
                        <TextSwitch
                            label={Locale.fields.aircraft.foreign}
                            id="isForeign"
                            name="isForeign"
                            onChange={(e) => {
                                formik.setFieldValue('isForeign', e);
                            }}
                            checked={!!formik.values.isForeign}
                            error={
                                formik.touched.isForeign &&
                                formik.errors.isForeign
                            }
                            onBlur={formik.handleBlur}
                            disabled={!isCreating}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <Input
                            type="text"
                            label={Locale.fields.aircraft.icao}
                            id="icaoModel"
                            name="icaoModel"
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'icaoModel',
                                    MaskHelper.upperAlphanumeric(e.target.value)
                                )
                            }
                            value={formik.values.icaoModel}
                            error={
                                formik.touched.icaoModel &&
                                formik.errors.icaoModel
                            }
                            maxLength={Aircraft.maxLength.icaoModel}
                            onBlur={formik.handleBlur}
                            disabled={!isCreating}
                            required
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <Input
                            type="number"
                            label={Locale.fields.aircraft.mtow}
                            name="mtow"
                            id="mtow"
                            after={
                                <span className="body-2 color--grayscale-blue-gray">
                                    kg
                                </span>
                            }
                            onChange={formik.handleChange}
                            value={formik.values.mtow}
                            error={formik.touched.mtow && formik.errors.mtow}
                            onBlur={formik.handleBlur}
                            required
                            disabled={!isCreating}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <Input
                            type="number"
                            label={Locale.fields.aircraft.wingspan}
                            name="wingspan"
                            id="wingspan"
                            after={
                                <span className="body-2 color--grayscale-blue-gray">
                                    m
                                </span>
                            }
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'wingspan',
                                    MaskHelper.numeric(e.target.value)
                                )
                            }
                            value={formik.values.wingspan}
                            error={
                                formik.touched.wingspan &&
                                formik.errors.wingspan
                            }
                            onBlur={formik.handleBlur}
                            required
                            disabled={!isCreating}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <Input
                            type="number"
                            label={Locale.fields.aircraft.planeLength}
                            name="length"
                            id="length"
                            after={
                                <span className="body-2 color--grayscale-blue-gray">
                                    m
                                </span>
                            }
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'length',
                                    MaskHelper.numeric(e.target.value)
                                )
                            }
                            value={formik.values.length}
                            error={
                                formik.touched.length && formik.errors.length
                            }
                            onBlur={formik.handleBlur}
                            required
                            disabled={!isCreating}
                        />
                    </div>
                </div>
                <Line className="margin--t-md" />
                <div className="margin--t-md">
                    <h4 className="headline-4 color--grayscale-blue-gray margin--b-xs">
                        {Locale.pages.register.aircraft.operatorData}
                    </h4>
                    <Text
                        kind="body-2"
                        className="color--grayscale-steel-gray margin--b-md"
                        color={theme.colors.grayscale.steelGray}
                    >
                        {Locale.pages.register.aircraft.operatorDataSubtitle}
                    </Text>
                    <div className="row">
                        <div className="col-md-4">
                            <TextSwitch
                                label={Locale.fields.operator.useData}
                                id="useData"
                                name="useData"
                                onChange={(e) => {
                                    formik.setFieldValue('useData', e);
                                }}
                                checked={!!formik.values.useData}
                                error={
                                    formik.touched.useData &&
                                    formik.errors.useData
                                }
                                onBlur={formik.handleBlur}
                                disabled={!isCreating}
                            />
                        </div>
                        <div className="col-md-4">
                            <TextSwitch
                                label={Locale.fields.operatorIsForeigner}
                                id="isForeigner"
                                name="isForeigner"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'operator.isForeigner',
                                        e
                                    );
                                    formik.setFieldValue(
                                        'operator.document',
                                        ''
                                    );
                                }}
                                checked={!!formik.values.operator?.isForeigner}
                                error={
                                    (formik.touched.isForeigner ||
                                        formik.touched.operator?.isForeigner) &&
                                    formik.errors.operator?.isForeigner
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                        <div className="col-md-4">
                            <TextSwitch
                                label={Locale.fields.operatorIsCompany}
                                id="isCompany"
                                name="isCompany"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'operator.isCompany',
                                        e
                                    );
                                    formik.setFieldValue(
                                        'operator.document',
                                        ''
                                    );
                                }}
                                checked={!!formik.values.operator?.isCompany}
                                error={
                                    (formik.touched.isCompany ||
                                        formik.touched.operator?.isCompany) &&
                                    formik.errors.operator?.isCompany
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="margin--t-md col-lg-6 col-md-6 col-sm-12">
                            <Input
                                type="text"
                                label={Locale.fields.operator.name}
                                id="name"
                                name="name"
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'operator.name',
                                        e.target.value
                                    )
                                }
                                value={formik.values.operator?.name}
                                error={
                                    (formik.touched.name ||
                                        formik.touched.operator?.name) &&
                                    formik.errors.operator?.name
                                }
                                onBlur={formik.handleBlur}
                                required
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                        <div className="margin--t-md col-lg-3 col-md-6 col-sm-12">
                            <Input
                                type="text"
                                label={getDocumentLabel(
                                    formik.values.operator?.isForeigner,
                                    formik.values.operator?.isCompany
                                )}
                                name="document"
                                id="document"
                                required
                                onChange={(e) => {
                                    setDocument(e);
                                }}
                                value={MaskHelper.document(
                                    formik.values.operator?.document,
                                    formik.values.operator?.isForeigner,
                                    formik.values.operator?.isCompany
                                )}
                                error={
                                    (formik.touched.document ||
                                        formik.touched.operator?.document) &&
                                    formik.errors.operator?.document
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 ">
                            <Input
                                type="email"
                                label={Locale.fields.email}
                                name="email"
                                id="email"
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'operator.email',
                                        e.target.value
                                    )
                                }
                                value={formik.values.operator?.email}
                                error={
                                    (formik.touched.email ||
                                        formik.touched.operator?.email) &&
                                    formik.errors.operator?.email
                                }
                                onBlur={formik.handleBlur}
                                required
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 ">
                            <Input
                                type="email"
                                label={Locale.fields.email}
                                name="secondEmail"
                                id="secondEmail"
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'operator.secondEmail',
                                        e.target.value
                                    )
                                }
                                value={formik.values.operator?.secondEmail}
                                error={
                                    (formik.touched.secondEmail ||
                                        formik.touched.operator?.secondEmail) &&
                                    formik.errors.operator?.secondEmail
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 ">
                            <Input
                                type="email"
                                label={Locale.fields.email}
                                name="thirdEmail"
                                id="thirdEmail"
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'operator.thirdEmail',
                                        e.target.value
                                    )
                                }
                                value={formik.values.operator?.thirdEmail}
                                error={
                                    (formik.touched.thirdEmail ||
                                        formik.touched.operator?.thirdEmail) &&
                                    formik.errors.operator?.thirdEmail
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData || !isCreating}
                            />
                        </div>
                    </div>
                    <div className="margin--t-md">
                        <div className="row">
                            <div className="col-4 col-md-2">
                                <Dropdown
                                    id="operator.countryPhoneCode"
                                    name="operator.countryPhoneCode"
                                    options={countriesPhones}
                                    label={Locale.fields.countryCode}
                                    onChange={formik.handleChange}
                                    value={
                                        formik.values.operator?.countryPhoneCode
                                    }
                                    error={
                                        formik.touched?.operator
                                            ?.countryPhoneCode &&
                                        formik.errors?.operator
                                            ?.countryPhoneCode
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                    autocomplete
                                    disabled={
                                        formik.values.useData || !isCreating
                                    }
                                    showListOnTop
                                    loading={loadingCountriesPhones}
                                />
                            </div>
                            <div className="col-8 col-md-4">
                                <PhoneNumberInput
                                    label={Locale.fields.phone}
                                    name="operator.phoneNumber"
                                    id="operator.phoneNumber"
                                    value={formik.values.operator?.phoneNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.phoneNumber ||
                                            formik.touched.operator
                                                ?.phoneNumber) &&
                                        formik.errors.operator?.phoneNumber
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                    disabled={
                                        formik.values.useData || !isCreating
                                    }
                                    countryPhoneCode={
                                        formik.values.operator?.countryPhoneCode
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Line className="margin--t-md" />
                    <div className="margin--t-md">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <Dropdown
                                    id="operator.address.country.id"
                                    name="operator.address.country.id"
                                    label={Locale.fields.country}
                                    options={Array.from(countries.values())}
                                    value={
                                        formik.values?.operator?.address
                                            ?.country?.id
                                    }
                                    onChange={setCountry}
                                    error={
                                        formik.touched?.operator?.address
                                            ?.country?.id &&
                                        formik.errors?.operator?.address
                                            ?.country?.id
                                    }
                                    required
                                    onBlur={formik.handleBlur}
                                    autocomplete
                                    loading={loadingCountries}
                                    disabled={!isCreating}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6">
                                <Input
                                    type="text"
                                    label={Locale.fields.postalCode}
                                    name="operator.address.postalCode"
                                    id="operator.address.postalCode"
                                    value={
                                        formik.values.operator?.address
                                            ?.postalCode
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.operator?.address
                                            ?.postalCode ||
                                            formik.touched.operator?.address
                                                ?.postalCode) &&
                                        formik.errors.operator?.address
                                            ?.postalCode
                                    }
                                    onBlur={setAddress}
                                    disabled={!isCreating}
                                    required
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6">
                                <Dropdown
                                    id="operator.address.state.id"
                                    name="operator.address.state.id"
                                    label={Locale.fields.state}
                                    options={Array.from(states.values())}
                                    value={
                                        formik.values.operator?.address?.state
                                            ?.id
                                    }
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'operator.address.state',
                                            states.get(e.target.value)
                                        );
                                    }}
                                    placeholder={
                                        isBrazilianAddress && cepSuccess
                                            ? Locale.fields.addressPlaceholder
                                            : ''
                                    }
                                    error={
                                        (formik.touched?.operator?.address
                                            ?.state?.id ||
                                            !cepSuccess) &&
                                        formik.errors?.operator?.address?.state
                                            ?.id
                                    }
                                    disabled={
                                        !isCreating ||
                                        (isBrazilianAddress && cepSuccess) ||
                                        !formik.values.operator?.address
                                            ?.country?.id
                                    }
                                    required
                                    onBlur={formik.handleBlur}
                                    autocomplete
                                    loading={loadingStates}
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <Input
                                    type="text"
                                    label={Locale.fields.city}
                                    name="operator.address.city"
                                    id="operator.address.city"
                                    value={
                                        formik.values.operator?.address?.city
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.operator?.address
                                            ?.city ||
                                            !cepSuccess) &&
                                        formik.errors.operator?.address?.city
                                    }
                                    placeholder={
                                        isBrazilianAddress && cepSuccess
                                            ? Locale.fields.addressPlaceholder
                                            : ''
                                    }
                                    disabled={
                                        !isCreating ||
                                        (isBrazilianAddress && cepSuccess)
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <Input
                                    type="text"
                                    label={Locale.fields.address}
                                    name="operator.address.street"
                                    id="operator.address.street"
                                    value={
                                        formik.values.operator?.address?.street
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.operator?.address
                                            ?.street ||
                                            !cepSuccess) &&
                                        formik.errors.operator?.address?.street
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={!isCreating}
                                    required
                                />
                            </div>
                            {isBrazilianAddress && (
                                <div className="col-12 col-md-4">
                                    <Input
                                        id="operator.address.district"
                                        name="operator.address.district"
                                        value={
                                            formik.values.operator?.address
                                                ?.district
                                        }
                                        label={Locale.fields.district}
                                        error={
                                            (formik.touched?.operator?.address
                                                ?.district ||
                                                !cepSuccess) &&
                                            formik.errors?.operator?.address
                                                ?.district
                                        }
                                        required
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        disabled={!isCreating}
                                    />
                                </div>
                            )}
                            <div className="col-lg-2 col-md-2 col-sm-6">
                                <Input
                                    type="number"
                                    label={Locale.fields.number}
                                    name="operator.address.number"
                                    id="operator.address.number"
                                    value={
                                        formik.values.operator?.address?.number
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.operator?.address
                                            ?.number &&
                                        formik.errors.operator?.address?.number
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={!isCreating}
                                    required
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <Input
                                    type="text"
                                    label={Locale.fields.complement}
                                    name="operator.address.complement"
                                    id="operator.address.complement"
                                    value={
                                        formik.values.operator?.address
                                            ?.complement
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.operator?.address
                                            ?.complement ||
                                            formik.touched.operator?.address
                                                ?.complement) &&
                                        formik.errors.operator?.address
                                            ?.complement
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={!isCreating}
                                />
                            </div>
                        </div>
                    </div>
                    {editAircraft && (
                        <div className="margin--t-md width--full flex-justify--center">
                            <Button
                                type="button"
                                kind="text"
                                block
                                className="mx-auto mx-sm-auto mx-md-0 mx-lg-0 margin--y-md"
                                onClick={onDelete}
                            >
                                {Locale.actions.deleteAircraft}
                            </Button>
                        </div>
                    )}
                </div>
            </ContainerForm>
            {children}
        </form>
    );
}

AircraftForm.propTypes = {
    children: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
    editAircraft: PropTypes.object,
    onDelete: PropTypes.func,
    isCreating: PropTypes.bool,
    showing: PropTypes.bool,
    setFormikValues: PropTypes.func,
    previousValues: PropTypes.instanceOf(Aircraft),
    showModal: PropTypes.bool,
    createdAircraft: PropTypes.instanceOf(Aircraft),
    previousFormikErrors: PropTypes.object,
};

AircraftForm.defaultProps = {
    children: undefined,
    editAircraft: undefined,
    onDelete: undefined,
    isCreating: false,
    showing: undefined,
    setFormikValues: undefined,
    previousValues: undefined,
    showModal: undefined,
    createdAircraft: undefined,
    previousFormikErrors: undefined,
};
