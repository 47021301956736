import PropTypes from 'prop-types';
import { Button, Image, Line, LoadingFullPage } from 'components';
import { icoCheckGreenDuotone } from 'assets/icons';
import './styles.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Locale from 'locale';
import { Aircraft } from 'models';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';

function AircraftInfo({ label, text, className }) {
    return (
        <div className={`flex-justify--between aircraft-info ${className}`}>
            <p className="body-4 color--neutral-65">{label}</p>
            <p className="body-3 color--neutral-95">{text}</p>
        </div>
    );
}

AircraftInfo.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

AircraftInfo.defaultProps = {
    className: '',
    text: '',
};

export default function CreatedAircraft() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [aircraft, setAircraft] = useState();

    useEffect(() => {
        setAircraft(new Aircraft(location.state?.aircraft));
        setLoading(false);
    }, []);

    return loading ? (
        <LoadingFullPage />
    ) : (
        <div className="margin--lg height--full flex--column flex-justify--between created-aircraft-container">
            <div>
                <div className="flex-column">
                    <div className="flex-justify--center margin--b-xl">
                        <Image src={icoCheckGreenDuotone} alt="check" />
                    </div>
                    <div className="title">
                        {AIRCRAFT_SOLICITATION_PROCESS_ENABLED
                            ? Locale.pages.register.aircraft
                                  .registrationRequested
                            : Locale.pages.register.aircraft
                                  .registrationCompleted}
                    </div>
                    <div className="subtitle">
                        {AIRCRAFT_SOLICITATION_PROCESS_ENABLED
                            ? Locale.pages.register.aircraft
                                  .registrationRequestedDescription
                            : Locale.pages.register.aircraft
                                  .registrationCompletedDescription}
                    </div>
                </div>
                <div className="aircraft-data-title">
                    {Locale.pages.register.aircraft.aircraftData}:
                </div>
                {/* TODO: Adicionar o campo de Natureza quando campo isMilitary tiver sido criado */}
                <AircraftInfo
                    label={Locale.fields.aircraft.registration}
                    text={aircraft?.registration}
                />
                <AircraftInfo
                    label={Locale.fields.aircraft.model}
                    text={aircraft?.icaoModel}
                />
                <AircraftInfo
                    label={Locale.fields.aircraft.mtowShort}
                    text={aircraft?.mtow}
                />
                <AircraftInfo
                    label={Locale.fields.aircraft.wingspan}
                    text={aircraft?.wingspan}
                />
                <AircraftInfo
                    label={Locale.fields.aircraft.planeLength}
                    text={aircraft?.length}
                />
                <div className="margin--t-md margin--b-xxl">
                    <Line color="#F0F0F0" />
                </div>
                <div className="aircraft-data-title">
                    {Locale.pages.register.aircraft.operatorData}:
                </div>
                <AircraftInfo
                    label={Locale.fields.operatorShort}
                    text={aircraft?.operator?.name}
                />
                <AircraftInfo
                    label={aircraft.operator.documentLabel}
                    text={aircraft?.operator?.document}
                />
                <AircraftInfo
                    label={Locale.pages.help.phone}
                    text={aircraft?.operator?.phoneNumber}
                />
                <AircraftInfo
                    label={Locale.fields.email}
                    text={aircraft?.operator?.email}
                />
                {aircraft?.operator?.secondEmail && (
                    <AircraftInfo
                        label={Locale.fields.email}
                        text={aircraft?.operator?.secondEmail}
                    />
                )}
                {aircraft?.operator?.thirdEmail && (
                    <AircraftInfo
                        label={Locale.fields.email}
                        text={aircraft?.operator?.thirdEmail}
                    />
                )}
                <AircraftInfo
                    label={
                        aircraft?.operator?.isForeigner
                            ? Locale.fields.postalCode
                            : Locale.fields.cep
                    }
                    text={aircraft?.operator?.address?.postalCode}
                />
                <AircraftInfo
                    label={Locale.fields.country}
                    text={aircraft?.operator?.address?.country?.name}
                />
                <AircraftInfo
                    label={Locale.fields.state}
                    text={aircraft?.operator?.address?.state?.name}
                />
                <AircraftInfo
                    label={Locale.fields.streetAddress}
                    text={aircraft?.operator?.address?.street}
                />
                <AircraftInfo
                    label={Locale.fields.number}
                    text={aircraft?.operator?.address?.number}
                />
                {aircraft?.operator.address?.complement && (
                    <AircraftInfo
                        label={Locale.fields.complement}
                        text={aircraft?.operator?.address?.complement}
                    />
                )}
            </div>
            <div className="padding--y-xxl">
                <Button className="w-100" onClick={() => navigate('/aircraft')}>
                    Minhas aeronaves
                </Button>
            </div>
        </div>
    );
}
