import PropTypes from 'prop-types';
import { Image } from 'components';
import { icoExpandRight } from 'assets/icons';
import './styles.scss';

export default function CardDirect(props) {
    const { kind, icon, title, description, href, target, className } = props;
    return (
        <div className={`col-lg-4 col-md-12 ${className}`}>
            <a
                href={href}
                target={target}
                className={`
                    card-direct-container
                    flex--row
                    width--full
                    height--full
                    padding--md
                    flex-items--center
                    flex-justify--between
                    ${kind}
                `}
            >
                <Image className="margin--r-md" src={icon} alt={icon} />
                <div className="flex--column width--full">
                    <h5 className="title">{title}</h5>
                    <p>{description}</p>
                </div>
                <Image src={icoExpandRight} alt="ico-expand-right" />
            </a>
        </div>
    );
}

CardDirect.propTypes = {
    kind: PropTypes.oneOf(['default']),
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    className: PropTypes.string,
};

CardDirect.defaultProps = {
    kind: 'default',
    icon: undefined,
    title: undefined,
    description: undefined,
    href: undefined,
    target: '_self',
    className: '',
};
