import * as Yup from 'yup';
import Locale from 'locale';
import { BR_PHONE_CODE } from 'utils/constants';
import BaseModel from './BaseModel';
import State from './State';
import Country from './Country';

class Billing extends BaseModel {
    constructor(data) {
        super();

        this.name = data.name;
        this.email = data.email;
        this.document = data.document;
        this.countryPhoneCode = data.countryPhoneCode || BR_PHONE_CODE;
        this.phoneNumber = data.phoneNumber;

        this.isForeigner = !!data.isForeigner;
        this.isCompany = !!data.isCompany;

        this.postalCode = data.postalCode;
        this.address = data.address;
        this.number = data.number;
        this.city = data.city;
        this.state = State.getValueOrNew(data.state);
        this.country = Country.getValueOrNew(data.country);
        this.complement = data.complement;
        this.district = data.district;
    }

    static get validationSchema() {
        return Yup.object().shape({
            reservationResponsable: Yup.bool(),
            isForeigner: Yup.bool(),
            isCompany: Yup.bool(),
            name: Yup.string().required(Locale.errors.required),
            document: Yup.string().required(Locale.errors.required),
            phoneNumber: Yup.string().required(Locale.errors.required),
            email: Yup.string()
                .email(Locale.errors.validEmail)
                .required(Locale.errors.required),
            postalCode: Yup.string().required(Locale.errors.required),
            address: Yup.string().required(Locale.errors.required),
            number: Yup.string().required(Locale.errors.required),
            complement: Yup.string(),
            city: Yup.string().required(Locale.errors.required),
            state: State.validationSchema,
            country: Country.validationSchema,
            district: Yup.string()
                .nullable(true)
                .when('country', {
                    is: 'BR',
                    then: Yup.string().required(Locale.errors.required),
                }),
        });
    }

    static createEmpty() {
        return new Billing({
            state: State.createEmpty(),
            country: Country.createEmpty(),
        });
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new Billing({
            ...response.personData,
            ...response.address,
        });
    }

    parseToRequest() {
        return {
            personData: {
                name: this.name,
                email: this.email,
                document: this.document,
                countryPhoneCode: this.countryPhoneCode,
                phoneNumber: this.phoneNumber,
                isForeigner: this.isForeigner,
                isCompany: this.isCompany,
            },
            address: {
                postalCode: this.postalCode,
                address: this.address,
                number: this.number,
                city: this.city,
                state: this.state.parseToRequest(),
                country: this.country.parseToRequest(),
                complement: this.complement,
                district: this.district,
            },
        };
    }
}

export default Billing;
