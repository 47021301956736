import Locale from 'locale';
import { format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';

const DateHelper = {
    getMonthName(month) {
        const date = new Date();
        const localization = Locale.getLanguage() === 'pt' ? ptBR : enUS;
        date.setMonth(month - 1);

        return format(new Date(date), 'MMMM', {
            locale: localization,
        });
    },
};

export default DateHelper;
