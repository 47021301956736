import React from 'react';
import PropTypes from 'prop-types';

import { Title, Image } from 'components';

import './styles.scss';

function HeaderInfo({ title, description, ico, textIco, ...args }) {
    return (
        <div className="container-header-info" {...args}>
            <div className="flex-items--center title-info">
                {ico && <Image src={ico} alt="" />}
                {textIco && (
                    <p className="color--grayscale-steel-gray">{textIco}</p>
                )}
            </div>
            <Title tagName="h2" className="margin--b-xs">
                {title || '-'}
            </Title>
            {description &&
                (typeof description === 'string' ? (
                    <p>{description}</p>
                ) : (
                    <div>
                        {description.map((desc) => (
                            <p
                                key={`header-description-${desc.key}`}
                                className="gap-4"
                            >
                                <span className="description-title">
                                    {desc.label}
                                </span>
                                <span>{desc.text}</span>
                            </p>
                        ))}
                    </div>
                ))}
        </div>
    );
}

HeaderInfo.propTypes = {
    ico: PropTypes.string,
    textIco: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                text: PropTypes.string,
            })
        ),
    ]),
};

HeaderInfo.defaultProps = {
    ico: undefined,
    textIco: '',
    title: '',
    description: '',
};

export default HeaderInfo;
