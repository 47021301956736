import Locale from 'locale';
import Service from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';
import { useState, useEffect } from 'react';
import { LoadingFullPage, Text, Title } from 'components';

export default function ActivateAccount() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { uidb64, token } = useParams();

    const activateAccount = () => {
        setLoading(true);
        Service.activateAccount({ uidb64, token })
            .then(() => {
                notify(Locale.pages.activateAccount.success);
                navigate('/login');
            })
            .catch((e) => {
                setLoading(false);
                ErrorHelper.notifyError(e);
            });
    };

    useEffect(() => {
        activateAccount();
    }, []);

    return loading ? (
        <LoadingFullPage />
    ) : (
        <div className="padding--x-lg padding--y-md">
            <Title tagName="h1">
                {Locale.pages.activateAccount.error.title}
            </Title>
            <Text
                kind="subtitle-1"
                color="#6A7CA0"
                className="d-block white-space--pre-line margin--y-sm"
            >
                {Locale.pages.activateAccount.error.message}
            </Text>
        </div>
    );
}
