import styled from 'styled-components';

import closeIcon from '../../assets/close-small.svg';

export const Container = styled.button`
    border: solid 1px ${(props) => props.theme.colors.grayscale.paleShaleGray};
    background: ${(props) => props.theme.colors.grayscale.white};
    color: ${(props) => props.theme.colors.grayscale.dark};
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.spacing.xl};
    padding: 0 ${(props) => props.theme.spacing.md};
    border-radius: 100px;

    ${(props) =>
        props.onClick &&
        `
        cursor: pointer;
    `};

    ${(props) =>
        props.activated &&
        `
        border: none;
        background: ${props.theme.colors.brand.primary};
        color: ${props.theme.colors.grayscale.white};
    `};

    ${(props) =>
        props.disabled &&
        `
        color: ${props.theme.colors.grayscale.steelGray};
    `};
`;

export const Span = styled.span`
    display: flex;

    align-items: center;
`;

export const Before = styled(Span)`
    width: ${(props) => props.theme.spacing.md};
    margin-right: ${(props) => props.theme.spacing.sm};
`;

export const After = styled.div`
    background-color: ${(props) => props.theme.colors.grayscale.dark};
    width: ${(props) => props.theme.spacing.md};
    height: ${(props) => props.theme.spacing.md};
    margin-left: ${(props) => props.theme.spacing.sm};
    mask: url(${closeIcon}) no-repeat center;
    cursor: pointer;

    ${(props) =>
        props.activated &&
        `
        background-color: ${props.theme.colors.grayscale.white}
    `};

    ${(props) =>
        props.disabled &&
        `
        background-color: ${props.theme.colors.grayscale.steelGray}
    `};
`;
