import {
    HeaderPage,
    Table,
    Button,
    Input,
    Image,
    FullPageModal,
    Radio,
    Chip,
} from 'components';
import { useState, useMemo, useEffect } from 'react';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { scrollToTop } from 'utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { boolCell, parseOrderingToBackend } from 'components/Table';
import { OrderingParams, PaginationParams } from 'utils/enums';
import { Airport } from 'models';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { icoClose, icoSearch } from 'assets/icons';

const managedBy = {
    ccr: 'ccr',
    other: 'other',
};

const noFilters = {
    search: '',
    managedBy: null,
};

const initFilters = {
    search: '',
    managedBy: managedBy.ccr,
};

export default function Airports() {
    const navigate = useNavigate();
    const location = useLocation();
    const [airports, setAirports] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shouldReload, setShouldReload] = useState();
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [currentOrdering, setCurrentOrdering] = useState('-id');
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(
        location.state?.filters || initFilters
    );

    const redirectToDetails = (airportId) => {
        navigate(`/backoffice/airports/${airportId}`);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'ICAO',
                accessor: 'icaoCode',
                className: 'column-icao',
                sortBy: 'icao_code',
            },
            {
                Header: Locale.fields.reservation.airport,
                accessor: 'name',
                className: 'column-name',
            },
            {
                Header: Locale.fields.reservation.city,
                accessor: 'city',
                className: 'column-city',
            },
            {
                Header: Locale.fields.reservation.state,
                accessor: 'state.label',
                className: 'column-state',
                sortBy: Airport.filterParams.state,
            },
            {
                Header: Locale.fields.reservation.country,
                accessor: 'country.label',
                className: 'column-country',
                sortBy: Airport.filterParams.country,
            },
            {
                Header: Locale.fields.airport.managedShortName,
                accessor: 'isManagedByCcr',
                Cell: boolCell,
                className: 'column-isManagedByCcr',
                title: Locale.fields.airport.managedFullName,
                sortBy: Airport.filterParams.isManagedByCcr,
            },
            // TODO: adicionar coluna quando tiver campo de updated at e quem atualizou no model do Aeroporto
            // {
            //     Header: Locale.fields.general.updatedAt,
            //     accessor: (airport) => `${moment(airport.updatedAt).format('DD/MM/YY - HH:mm')} by ${airport.updatedBy}`,
            //     className: 'column-updated-at',
            // },
        ],
        []
    );

    const parseFilters = () => {
        const filterManaged = filters.managedBy
            ? filters.managedBy === managedBy.ccr
            : null;

        return {
            ...(filters.search && {
                [Airport.filterParams.search]: filters.search,
            }),
            ...(filterManaged !== null && {
                [Airport.filterParams.isManagedByCcr]: filterManaged,
            }),
        };
    };

    const getAirports = () => {
        setLoading(true);
        Service.listAirports({
            [PaginationParams.page]: currentPage,
            [PaginationParams.pageSize]: currentPageSize,
            [OrderingParams.ordering]: currentOrdering,
            [Airport.filterParams.hasICAO]: true,
            ...parseFilters(),
        })
            .then((response) => {
                setTotalCount(response.count);
                setTotalPages(response.totalPages);
                setAirports(Airport.createListFromResponse(response.results));
                setLoading(false);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        if (shouldReload) {
            scrollToTop();
            getAirports();
            setShouldReload(false);
        }
    }, [shouldReload]);

    useEffect(() => {
        setShouldReload(true);
    }, [currentPage, currentPageSize]);

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            setShouldReload(true);
        }
    }, [currentOrdering, filters]);

    const onSort = (newOrdering) => {
        setCurrentOrdering(parseOrderingToBackend(newOrdering));
    };

    const onPageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setCurrentPageSize(newPageSize);
    };

    const showableFilters = () => {
        const managedByValue =
            Locale.pages.backoffice.airports.filters.managedBy[
                filters.managedBy
            ];

        return [
            ...(managedByValue
                ? [{ name: '', value: managedByValue, key: 'managedBy' }]
                : []),
        ];
    };

    return (
        <div className="stack--lg width--full padding--lg">
            <div className="stack--sm">
                <HeaderPage
                    title={Locale.general.airports}
                    contentInfo={
                        <Button kind="primary" onClick={() => navigate('new')}>
                            {Locale.pages.backoffice.airports.new}
                        </Button>
                    }
                />
                <HeaderFilters setFilters={setFilters} filters={filters} />
            </div>
            <div className="flex-justify--between">
                <div className="body-2 color--grayscale-steel-gray text-transform--lowercase">
                    {totalCount} {Locale.general.result}
                    {totalCount !== 1 && 's'}
                </div>
                <div className="flex-wrap inline--sm">
                    {showableFilters().map((filter) => (
                        <Chip
                            key={filter.name || filter.value}
                            onClickCloseButton={() =>
                                setFilters({
                                    ...filters,
                                    [filter.key]: noFilters[filter.key],
                                })
                            }
                        >
                            <div>
                                {filter.name && <b>{`${filter.name}: `}</b>}
                                {filter.value}
                            </div>
                        </Chip>
                    ))}
                </div>
            </div>
            <Table
                data={airports}
                columns={columns}
                onSort={onSort}
                onPageChange={onPageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                loading={loading}
                rowClick={redirectToDetails}
                rowHeight="medium"
            />
        </div>
    );
}

function HeaderFilters({ setFilters, filters }) {
    const [showModal, setShowModal] = useState(false);
    const formik = useFormik({
        initialValues: {
            search: filters.search,
        },
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilters({
                ...filters,
                search: formik.values.search,
            });
        }
    };

    const clearSearch = () => {
        formik.setFieldValue('search', '');
        setFilters({ ...filters, search: '' });
    };

    return (
        <>
            <FilterOptions
                show={showModal}
                onClose={() => setShowModal(false)}
                setFilters={setFilters}
                filters={filters}
            />
            <div className="flex-justify--between width--full">
                <div className="inline--lg flex--1">
                    <div className="search-input">
                        <Input
                            label={Locale.actions.search}
                            name="search"
                            placeholder={
                                Locale.pages.backoffice.airports.search
                            }
                            value={formik.values.search}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyDown}
                            before={
                                <Image
                                    src={icoSearch}
                                    alt={Locale.actions.search}
                                />
                            }
                            after={
                                <Image
                                    className="cursor--pointer"
                                    onClick={clearSearch}
                                    src={icoClose}
                                    alt=""
                                    size={16}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="flex-items--end">
                    <Button kind="outline" onClick={() => setShowModal(true)}>
                        {Locale.fields.filters}
                    </Button>
                </div>
            </div>
        </>
    );
}

HeaderFilters.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};

function FilterOptions({ show, onClose, setFilters, filters }) {
    const formik = useFormik({
        initialValues: filters,
        onSubmit: (values) => {
            setFilters(values);
            onClose();
        },
    });

    useEffect(() => {
        formik.setValues(filters);
    }, [show]);

    return (
        <FullPageModal
            kind="rightSide"
            show={show}
            title={Locale.fields.filters}
            onClose={onClose}
            endPageButton={
                <>
                    <Button
                        kind="outline"
                        onClick={() => {
                            setFilters(noFilters);
                            onClose();
                        }}
                    >
                        {Locale.actions.removeFilters}
                    </Button>
                    <Button
                        type="submit"
                        onClick={(e) => {
                            formik.handleSubmit(e);
                            onClose();
                        }}
                    >
                        {Locale.actions.filter}
                    </Button>
                </>
            }
        >
            <div className="stack--xl padding-bottom-sticky-footer">
                <div className="stack--md">
                    <div className="headline-5">
                        {Locale.pages.backoffice.airports.filters.managed}
                    </div>
                    <Radio
                        id={managedBy.ccr}
                        name="managedBy"
                        label={
                            Locale.pages.backoffice.airports.filters.managedBy
                                .ccr
                        }
                        value={managedBy.ccr}
                        checked={formik.values.managedBy === managedBy.ccr}
                        onChange={formik.handleChange}
                    />
                    <Radio
                        id={managedBy.other}
                        name="managedBy"
                        label={
                            Locale.pages.backoffice.airports.filters.managedBy
                                .other
                        }
                        value={managedBy.other}
                        checked={formik.values.managedBy === managedBy.other}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
        </FullPageModal>
    );
}

FilterOptions.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};
