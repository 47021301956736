import PropTypes from 'prop-types';

import { Container } from './styles';

function Label({ children, isRequired, ...args }) {
    return (
        <Container {...args}>
            {children}
            {isRequired && <span>*</span>}
        </Container>
    );
}

Label.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    isRequired: PropTypes.bool,
};

Label.defaultProps = {
    isRequired: false,
};

export default Label;
