import * as Yup from 'yup';
import { statusFARM } from 'utils/enums';
import BaseModel from './BaseModel';

export default class InvoiceDetail extends BaseModel {
    constructor(data) {
        super();

        this.aeroportoICAO = data.aeroportoICAO;
        this.clienteCPFCNPJ = data.clienteCPFCNPJ;
        this.clienteName = data.clienteName;
        this.dtVencimento = BaseModel.getValueOrNew(data.dtVencimento, Date);
        this.noDocFaturamento = data.noDocFaturamento;
        this.noNotaFiscal = data.noNotaFiscal;
        this.vlrTotal = data.vlrTotal;
        this.notaFiscal = data.notaFiscal;
        this.boleto = data.boleto;
        this.isMasked = data.isMasked;
    }

    get isLate() {
        if (this.status === statusFARM.pago) {
            return false;
        }
        const today = new Date().setHours(0, 0, 0, 0);
        const expirationDate = this.dtVencimento.setHours(0, 0, 0, 0);
        return expirationDate < today;
    }

    static get validationSchema() {
        return Yup.object().shape({
            aeroportoICAO: Yup.string().required(),
            clienteCPFCNPJ: Yup.string().required(),
            clienteName: Yup.string().required(),
            dtVencimento: Yup.date().required(),
            noDocFaturamento: Yup.string().required(),
            noNotaFiscal: Yup.string().required(),
            vlrTotal: Yup.number().required(),
        });
    }
}
