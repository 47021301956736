import { useEffect, useState } from 'react';
import {
    ButtonIcon,
    Image,
    Button,
    Dropdown,
    Checkbox,
    DatePickerRange,
    Title,
    LoadingFullPage,
    Pagination,
    ReservationCard,
} from 'components';
import {
    icoFilter,
    icoFilterPin,
    icoCopyDuotone,
    icoCloseDuotone,
    icoExpandDown,
    icoExpandLeft,
} from 'assets/icons';
import Locale from 'locale';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import PropTypes from 'prop-types';
import FullPageModal from 'components/FullPageModal';
import {
    DeleteParams,
    OrderingParams,
    PaginationParams,
    ReservationStatus as ReservationStatusEnum,
} from 'utils/enums';
import { useFormik } from 'formik';
import Service from 'services';
import { ErrorHelper, FilterHelper } from 'helpers';
import { Aircraft, Airport, Billing, Reservation } from 'models';
import Popover from 'components/Popover';
import notify from 'components/Toast';
import { scrollToTop } from 'utils/functions';
import plane from 'assets/plane.svg';
import searchImg from 'assets/search.svg';

const FILTER_ALL = '0';
const PAGE_SIZE = 12;
const dropdownAllOption = {
    label: Locale.general.all,
    value: FILTER_ALL,
};

export default function ReservationsList() {
    const [loading, setLoading] = useState(true);
    const [reservations, setReservations] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filters, setFilters] = useState({
        idAircraft: undefined,
        idAirport: undefined,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        showDeletedAirports: true,
        showDeletedAircraft: true,
    });
    const [editingFilters, setEditingFilters] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const [showOrderingMenu, setShowOrderingMenu] = useState(false);
    const [reservationSelected, setReservationSelected] = useState();
    const [aircraftOptions, setAircraftOptions] = useState([dropdownAllOption]);
    const [airportsOptions, setAirportsOptions] = useState([dropdownAllOption]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [shouldReload, setShouldReload] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    const orderByParam = (ordering) => {
        setOrderingSelected(ordering);
        setShowOrderingMenu(false);
    };

    const [orderingSelected, setOrderingSelected] = useState(
        location.state?.orderReservationParam || OrderingParams.lastUpdated
    );
    const orderingOptions = {
        [OrderingParams.lastUpdated]: {
            title: Locale.ordering.lastUpdated,
            ordering: OrderingParams.lastUpdated,
            selected: orderingSelected === OrderingParams.lastUpdated,
            onClick: () => orderByParam(OrderingParams.lastUpdated),
        },
        [OrderingParams.aircraftRegistration]: {
            title: Locale.ordering.aircraftRegistration,
            ordering: OrderingParams.aircraftRegistration,
            selected: orderingSelected === OrderingParams.aircraftRegistration,
            onClick: () => orderByParam(OrderingParams.aircraftRegistration),
        },
        [OrderingParams.lastCreated]: {
            title: Locale.ordering.lastCreated,
            ordering: OrderingParams.lastCreated,
            selected: orderingSelected === OrderingParams.lastCreated,
            onClick: () => orderByParam(OrderingParams.lastCreated),
        },
        [OrderingParams.firstCreated]: {
            title: Locale.ordering.firstCreated,
            ordering: OrderingParams.firstCreated,
            selected: orderingSelected === OrderingParams.firstCreated,
            onClick: () => orderByParam(OrderingParams.firstCreated),
        },
    };

    const applyFilter = () => {
        setFilters(editingFilters);
    };

    const parseOptionsValues = (filterList) => {
        const correctedFilter = { ...filterList };

        correctedFilter.startDate = FilterHelper.parseDateField(
            filterList.startDate
        );
        correctedFilter.endDate = FilterHelper.parseDateField(
            filterList.endDate
        );

        Object.keys(filterList).forEach((filter) => {
            if (filterList[filter] === FILTER_ALL) {
                correctedFilter[filter] = undefined;
            }
        });

        if (
            !filterList.showDeletedAircraft &&
            filterList.idAircraft === FILTER_ALL
        ) {
            correctedFilter.idAircraft = FilterHelper.stringifyArray(
                aircraftOptions,
                'value'
            );
        }

        if (
            !filterList.showDeletedAirports &&
            filterList.idAirport === FILTER_ALL
        ) {
            correctedFilter.idAirport = FilterHelper.stringifyArray(
                airportsOptions,
                'value'
            );
        }

        return correctedFilter;
    };

    const fetchReservationData = async () => {
        setLoading(true);
        const parsedFilters = parseOptionsValues(filters);
        Service.listUserLandingRequests({
            [PaginationParams.page]: currentPage,
            [PaginationParams.pageSize]: PAGE_SIZE,
            [OrderingParams.ordering]: orderingSelected,
            [Reservation.filterParams.structured]: 'aircraft',
            [Reservation.filterParams.aircraft]: parsedFilters.idAircraft,
            [Reservation.filterParams.status]: parsedFilters.status,
            [Reservation.filterParams.startDate]: parsedFilters.startDate,
            [Reservation.filterParams.endDate]: parsedFilters.endDate,
            [Reservation.filterParams.airportTarget]: parsedFilters.idAirport,
        })
            .then((response) => {
                setTotalPages(response.totalPages);
                const reservationsResponse = Reservation.createListFromResponse(
                    response.results
                );
                setReservations(reservationsResponse);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    const optionsMenu = [
        {
            title: Locale.pages.reservationDetail.reserveAgain,
            icon: icoCopyDuotone,
            onClick: () => {
                // TODO: rever quando não tiver mais a simplificação de correntista
                const reservation = new Reservation(reservationSelected);
                if (reservation.aircraft.isForeign) {
                    navigate('/newreservation/generalinfo', {
                        state: {
                            ...reservation.prepareForReplication(),
                            isInitReplication: true,
                        },
                    });
                } else {
                    Service.getBillingData(reservationSelected.id)
                        .then((response) => {
                            const billing =
                                Billing.createFromResponse(response);
                            navigate('/newreservation/generalinfo', {
                                state: {
                                    ...reservation.prepareForReplication(
                                        billing
                                    ),
                                    isInitReplication: true,
                                },
                            });
                        })
                        .catch(() => {
                            notify(Locale.errors.replicate);
                        });
                }
            },
        },
        ...(reservationSelected?.canCancel
            ? [
                  {
                      title: Locale.pages.reservationDetail.cancel,
                      icon: icoCloseDuotone,
                      onClick: () => {
                          Service.cancel(reservationSelected.id)
                              .then(() => {
                                  setShowMenu(false);
                                  notify(
                                      Locale.status.description.done.cancelled
                                  );
                                  fetchReservationData();
                              })
                              .catch((error) => {
                                  ErrorHelper.notifyError(error);
                              });
                      },
                  },
              ]
            : []),
    ];

    useEffect(() => {
        if (shouldReload) {
            scrollToTop();
            fetchReservationData();
            setShouldReload(false);
        }
    }, [shouldReload]);

    useEffect(() => {
        setShouldReload(true);
    }, [currentPage]);

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            setShouldReload(true);
        }
    }, [filters, orderingSelected]);

    const hasFilters = Object.values(filters).some((value) =>
        Array.isArray(value)
            ? value.length !== 0
            : value === false || (!!value && value !== true && value !== '0')
    );

    const FilterModal = (
        <FullPageModal
            show={showFilterModal}
            onClose={() => {
                setEditingFilters({});
                setShowFilterModal(false);
            }}
            title={Locale.fields.filters}
            endPageButton={
                <Button
                    large
                    onClick={() => {
                        applyFilter();
                        setShowFilterModal(false);
                    }}
                >
                    {Locale.actions.filter}
                </Button>
            }
        >
            <Filters
                initialFilters={filters}
                onChange={setEditingFilters}
                isOpen={showFilterModal}
                aircraftOptions={aircraftOptions}
                setAircraftOptions={setAircraftOptions}
                airportsOptions={airportsOptions}
                setAirportsOptions={setAirportsOptions}
            />
        </FullPageModal>
    );

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <Popover
                show={showMenu}
                options={optionsMenu}
                closeMenu={() => setShowMenu(false)}
            />
            <Popover
                show={showOrderingMenu}
                options={Object.values(orderingOptions)}
                closeMenu={() => setShowOrderingMenu(false)}
            />
            <div>
                {FilterModal}
                <div className="padding--x-lg">
                    <div className="padding--y-lg flex-justify--between flex-items--center">
                        <div
                            className="flex-items--center cursor--pointer"
                            onClick={() => navigate('/')}
                        >
                            <ButtonIcon kind="no-border" icon={icoExpandLeft} />
                            <div className="headline-2 margin--l-xs">
                                {Locale.actions.myReservations}
                            </div>
                        </div>
                        <Button
                            kind="primary"
                            onClick={() =>
                                navigate('/newreservation/generalinfo')
                            }
                        >
                            + {Locale.general.new.f}
                        </Button>
                    </div>
                    <div className="flex-justify--between margin--b-sm">
                        <div
                            className="flex-items--center cursor--pointer"
                            onClick={() => setShowOrderingMenu(true)}
                        >
                            <div className="color--grayscale-blue-gray overline-1 text-transform--uppercase">
                                {Locale.ordering.orderBy}
                            </div>
                            <Image
                                className="ordering-arrow"
                                src={icoExpandDown}
                                alt="order"
                            />
                        </div>
                        <ButtonIcon
                            onClick={() => setShowFilterModal(true)}
                            icon={hasFilters ? icoFilterPin : icoFilter}
                            kind="outline"
                        />
                    </div>
                    {!loading && reservations.length === 0 ? (
                        <div className="center-content margin--t-xl stack--lg">
                            <Image
                                src={hasFilters ? searchImg : plane}
                                alt=""
                            />
                            <div className="stack--sm text-align--center padding--x-md">
                                <div className="heading-2 fw-bolder color--grayscale-dark">
                                    {
                                        Locale.pages.homeLoggedIn[
                                            hasFilters
                                                ? 'noResults'
                                                : 'noReservations'
                                        ].title
                                    }
                                </div>
                                <div className="subtitle-1 color--grayscale-carbono-gray">
                                    {
                                        Locale.pages.homeLoggedIn[
                                            hasFilters
                                                ? 'noResults'
                                                : 'noReservations'
                                        ].subtitle
                                    }
                                </div>
                            </div>
                        </div>
                    ) : !loading ? (
                        <>
                            <div className="margin--b-lg reservation-wrapper">
                                {reservations.map((reservation) => (
                                    <ReservationCard
                                        key={reservation.id.toString()}
                                        reservation={reservation}
                                        openMenu={() => setShowMenu(true)}
                                        setReservationSelected={
                                            setReservationSelected
                                        }
                                        orderingParam={orderingSelected}
                                    />
                                ))}
                            </div>
                            <div className="width--full m-auto">
                                <Pagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={onPageChange}
                                    pageSize={PAGE_SIZE}
                                />
                            </div>
                        </>
                    ) : (
                        <LoadingFullPage />
                    )}
                </div>
            </div>
        </>
    );
}

function Filters({
    initialFilters,
    onChange,
    isOpen,
    aircraftOptions,
    setAircraftOptions,
    airportsOptions,
    setAirportsOptions,
}) {
    const [aircraft, setAircraft] = useState();
    const [airports, setAirports] = useState();

    const initialFormikValues = {
        ...initialFilters,
        idAircraft: initialFilters.idAircraft || dropdownAllOption?.value,
        idAirport: initialFilters.idAirport || dropdownAllOption?.value,
        status: initialFilters.status || dropdownAllOption?.value,
        showDeletedAirports: true,
        showDeletedAircraft: true,
    };
    const formik = useFormik({
        initialValues: initialFormikValues,
    });

    const parseDropdownStatus = () => {
        const status = Object.keys(ReservationStatusEnum).map((key) => ({
            label: Locale.status[key],
            value: ReservationStatusEnum[key],
        }));
        return [dropdownAllOption, ...status];
    };

    const clearFilters = () => {
        formik.setValues({
            idAircraft: dropdownAllOption?.value,
            idAirport: dropdownAllOption?.value,
            status: dropdownAllOption?.value,
            showDeletedAirports: true,
            showDeletedAircraft: true,
            startDate: undefined,
            endDate: undefined,
        });
    };

    const getAircraft = () => {
        Service.listAircraft({ [DeleteParams.showDeleted]: true })
            .then((data) => {
                setAircraft(data);
                createAircraftOptions(data);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const createAircraftOptions = (aircraftList) => {
        setAircraftOptions([
            dropdownAllOption,
            ...Aircraft.parseDropdownOptions(aircraftList, true),
        ]);
    };

    const createAirportOptions = (airportList) => {
        const airportsResponse = Airport.createListFromResponse(airportList);
        setAirportsOptions([
            dropdownAllOption,
            ...Airport.parseDropdownOptions(airportsResponse),
        ]);
    };

    const getNotDeletedObjects = (options, isAircraft) => {
        const filter =
            formik.values[
                isAircraft ? 'showDeletedAircraft' : 'showDeletedAirports'
            ];
        return filter
            ? options
            : options.filter((object) => object.deleted === null);
    };

    useEffect(() => {
        onChange(formik.values);
    }, [formik.values]);

    useEffect(() => {
        if (isOpen) {
            getAircraft();
            searchAirport('');
        } else {
            formik.setValues({
                idAircraft: initialFilters.idAircraft || FILTER_ALL,
                idAirport: initialFilters.idAirport || FILTER_ALL,
                status: initialFilters.status || FILTER_ALL,
                startDate: initialFilters.startDate,
                endDate: initialFilters.endDate,
                showDeletedAirports: initialFilters.showDeletedAirports,
                showDeletedAircraft: initialFilters.showDeletedAircraft,
            });
        }
    }, [isOpen]);

    useEffect(() => {
        let options;
        if (aircraft) {
            options = getNotDeletedObjects(aircraft, true);
            createAircraftOptions(options);
        }
    }, [formik.values.showDeletedAircraft]);

    useEffect(() => {
        let options;
        if (airports) {
            options = getNotDeletedObjects(airports);
            createAirportOptions(options);
        }
    }, [formik.values.showDeletedAirports]);

    const searchAirport = (search) => {
        Service.listAirports({
            [PaginationParams.page]: 1,
            [PaginationParams.pageSize]: 50,
            [Airport.filterParams.search]: search,
            [DeleteParams.showDeleted]: true,
            [Airport.filterParams.hasICAO]: true,
        })
            .then((response) => {
                setAirports(response.results);
                createAirportOptions(response.results);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    return (
        <div className="stack--lg padding-bottom-sticky-footer">
            <Title tagName="h2">{Locale.fields.reservationData}</Title>
            <div className="stack--sm">
                <Dropdown
                    id="idAirport"
                    label={Locale.fields.destination}
                    name="idAirport"
                    options={airportsOptions}
                    value={formik.values.idAirport}
                    onChange={formik.handleChange}
                    placeholder=""
                    autocomplete
                    requestAutocomplete={searchAirport}
                />
                <Checkbox
                    id="showDeletedAirports"
                    name="showDeletedAirports"
                    label={Locale.fields.showDeletedAirports}
                    onChange={(e) => {
                        formik.setValues({
                            ...formik.values,
                            showDeletedAirports: e.target.checked,
                            idAirport: FILTER_ALL,
                        });
                    }}
                    checked={formik.values.showDeletedAirports}
                />
            </div>
            <div className="stack--sm">
                <Dropdown
                    id="idAircraft"
                    label={Locale.general.aircraft}
                    name="idAircraft"
                    options={aircraftOptions}
                    value={formik.values.idAircraft}
                    onChange={formik.handleChange}
                    placeholder=""
                    autocomplete
                />
                <Checkbox
                    id="showDeletedAircraft"
                    name="showDeletedAircraft"
                    label={Locale.fields.showDeletedAircraft}
                    onChange={(e) => {
                        formik.setValues({
                            ...formik.values,
                            showDeletedAircraft: e.target.checked,
                            idAircraft: FILTER_ALL,
                        });
                    }}
                    checked={formik.values.showDeletedAircraft}
                />
            </div>
            <Dropdown
                id="status"
                label={Locale.fields.reservation.status}
                name="status"
                options={parseDropdownStatus()}
                value={formik.values.status}
                onChange={formik.handleChange}
                placeholder=""
            />
            <div className="stack--md">
                <DatePickerRange
                    name="range"
                    id="range"
                    label={Locale.fields.landingPeriod}
                    startDate={formik.values.startDate}
                    endDate={formik.values.endDate}
                    onChange={(dates) => {
                        formik.setFieldValue('startDate', dates[0]);
                        formik.setFieldValue('endDate', dates[1]);
                    }}
                    popperPlacement="top-end"
                />
            </div>
            <Button kind="text" onClick={clearFilters}>
                {Locale.actions.removeFilters}
            </Button>
        </div>
    );
}

Filters.propTypes = {
    initialFilters: PropTypes.shape({
        idAircraft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        idAirport: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date),
        showDeletedAirports: PropTypes.bool,
        showDeletedAircraft: PropTypes.bool,
    }),
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    aircraftOptions: PropTypes.array.isRequired,
    setAircraftOptions: PropTypes.func.isRequired,
    airportsOptions: PropTypes.array.isRequired,
    setAirportsOptions: PropTypes.func.isRequired,
};

Filters.defaultProps = {
    initialFilters: {},
};
