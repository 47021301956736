import {
    Button,
    ButtonIcon,
    Card,
    HeaderPage,
    Image,
    List,
    LoadingFullPage,
    StickyFooter,
    Title,
} from 'components';
import PropTypes from 'prop-types';
import Locale from 'locale';
import {
    icoCheckWhite,
    icoCloseDuotone,
    icoCopyDuotone,
    icoExpandDown,
    icoExpandRight,
    icoFlightDuotone,
} from 'assets/icons';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import { useEffect, useState } from 'react';
import {
    getReservationStatus,
    PaymentOptions,
    ReservationStatus,
} from 'utils/enums';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { Aircraft, Billing, Reservation } from 'models';
import notify from 'components/Toast';
import InformationModal from './InformationModal';

export default function DetailReservation() {
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [reservation, setReservation] = useState();
    const [timelineStatus, setTimelineStatus] = useState();
    const [requester, setRequester] = useState();
    const [aircraft, setAircraft] = useState();
    const [showQrCode, setShowQrCode] = useState(false);
    const [commander, setCommander] = useState();
    const { reservationId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const getPayment = () => {
        if (timelineStatus?.status === ReservationStatus.processingPayment) {
            Service.getLandingPayment(reservationId)
                .then((response) =>
                    setShowQrCode(
                        response.paymentMethod === PaymentOptions.pix &&
                            response.success === false
                    )
                )
                .catch((error) => ErrorHelper.notifyError(error));
        }
    };

    const getReservation = () => {
        Service.getLandingRequest(reservationId)
            .then((response) => {
                const reservationResponse =
                    Reservation.createFromResponse(response);
                setReservation(reservationResponse);
                setAircraft(reservationResponse.aircraft);
                setCommander(reservationResponse.commander);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const optionsMenu = [
        {
            title: Locale.pages.reservationDetail.reserveAgain,
            icon: icoCopyDuotone,
            onClick: () => {
                // TODO: rever quando não tiver mais a simplificação de correntista
                if (aircraft.isForeign) {
                    navigate('/newreservation/generalinfo', {
                        state: {
                            ...reservation.prepareForReplication(),
                            isInitReplication: true,
                        },
                    });
                } else {
                    Service.getBillingData(reservation.id)
                        .then((response) => {
                            const billing =
                                Billing.createFromResponse(response);
                            navigate('/newreservation/generalinfo', {
                                state: {
                                    ...reservation.prepareForReplication(
                                        billing
                                    ),
                                    isInitReplication: true,
                                },
                            });
                        })
                        .catch(() => {
                            notify(Locale.errors.replicate);
                        });
                }
            },
        },
        ...(reservation?.canCancel
            ? [
                  {
                      title: Locale.pages.reservationDetail.cancel,
                      icon: icoCloseDuotone,
                      onClick: () => {
                          Service.cancel(reservationId)
                              .then(() => {
                                  notify(
                                      Locale.status.description.done.cancelled
                                  );
                                  navigate('/', {
                                      state: { ...location.state },
                                  });
                              })
                              .catch((error) => {
                                  ErrorHelper.notifyError(error);
                              });
                      },
                  },
              ]
            : []),
    ];

    const getStatusHistory = () => {
        Service.getStatusHistory(reservationId)
            .then((response) => {
                setTimelineStatus(response);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        Service.getCustomer()
            .then((data) => {
                setRequester(data);
            })
            .catch((error) => ErrorHelper.notifyCustomerError(error));
    }, []);

    useEffect(() => {
        getReservation();
        getStatusHistory();
    }, [reservationId]);

    useEffect(() => {
        getPayment();
    }, [timelineStatus]);

    const onClickContinueReservation = () => {
        // TODO: remover ternário após remover a simplificação do fluxo de reservas nacionais
        navigate(`/reservation/${reservationId}/checkout`, {
            state: {
                ...location.state,
                reservation,
            },
        });
    };

    return (
        <div>
            {reservation && requester && aircraft && (
                <InformationModal
                    reservation={reservation}
                    requester={requester}
                    aircraft={aircraft}
                    commander={commander}
                    show={showInfoModal}
                    onClose={() => setShowInfoModal(false)}
                    observation={
                        timelineStatus?.statusHistory[0]?.details?.observation
                    }
                />
            )}
            <div className="padding--lg padding-bottom-sticky-footer">
                <HeaderPage
                    title={Locale.pages.reservationDetail.title}
                    labelBtnBack={Locale.actions.myReservations}
                    onClickBtnBack={() => navigate('/reservations')}
                    optionsMenu={optionsMenu}
                />
                <div className="stack--md">
                    {reservation && aircraft && requester && timelineStatus ? (
                        <div>
                            <div className="padding--b-xl">
                                <ReservationInfo
                                    reservation={reservation}
                                    aircraft={aircraft}
                                    onSeeMoreClick={() =>
                                        setShowInfoModal(true)
                                    }
                                />
                            </div>
                            <div className="flex--row flex-wrap">
                                {timelineStatus && (
                                    <div
                                        className={`margin--y-md col-12 ${
                                            showQrCode
                                                ? 'col-md-6 padding--l-md'
                                                : ''
                                        }`}
                                    >
                                        <Timeline
                                            timelineStatus={
                                                timelineStatus.statusHistory
                                            }
                                            currentStatus={
                                                timelineStatus.status
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <LoadingFullPage />
                    )}
                    {reservation?.airportTarget?.contactData && (
                        <ContactCard
                            contactData={reservation.airportTarget.contactData}
                        />
                    )}
                    {reservation?.airportTarget?.documents?.length > 0 && (
                        <AirportDocuments
                            docs={reservation.airportTarget.documents}
                        />
                    )}
                </div>
            </div>
            {timelineStatus?.status === ReservationStatus.pendingPayment && (
                <StickyFooter>
                    <Button onClick={onClickContinueReservation}>
                        {Locale.actions.proceedToPayment}
                    </Button>
                </StickyFooter>
            )}
            {timelineStatus?.status === ReservationStatus.changeRequested && (
                <StickyFooter>
                    <Button
                        onClick={() =>
                            navigate(`requestchanges`, {
                                state: {
                                    statusDetails: reservation.statusDetails,
                                },
                            })
                        }
                    >
                        {Locale.pages.reservationDetail.adjustDate}
                    </Button>
                </StickyFooter>
            )}
        </div>
    );
}

function ReservationInfo({ reservation, aircraft, onSeeMoreClick }) {
    return (
        <Card kind="outlined">
            <div className="padding--x-sm stack--lg">
                <div className="stack--sm">
                    <div className="overline-1 text-transform--uppercase color--grayscale-carbono-gray">
                        {Locale.fields.reservation.title} #{reservation.id}
                    </div>
                    <div className="inline--sm">
                        <Image
                            src={icoFlightDuotone}
                            alt={Locale.fields.reservation.title}
                        />
                        <h3 className="color--grayscale-blue-gray">
                            {reservation.airportTarget.icaoName}
                        </h3>
                    </div>
                </div>
                <div className="row fields-stack-mobile">
                    <div className="col-md-3">
                        <InfoField
                            title={Locale.fields.reservation.landingDate}
                            value={moment(
                                reservation.originFlight.datetime
                            ).format('DD/MM/YYYY')}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoField
                            title={Locale.fields.reservation.landingHour}
                            value={moment(
                                reservation.originFlight.datetime
                            ).format('HH:mm')}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoField
                            title={Locale.fields.reservation.peopleOnBoard}
                            value={reservation.originFlight.peopleOnBoard}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoField
                            title={Locale.fields.aircraft.model}
                            value={aircraft.icaoModel}
                        />
                    </div>
                </div>
                <Button kind="text" onClick={onSeeMoreClick}>
                    {Locale.actions.seeMore}
                </Button>
            </div>
        </Card>
    );
}

ReservationInfo.propTypes = {
    reservation: PropTypes.instanceOf(Reservation).isRequired,
    aircraft: PropTypes.instanceOf(Aircraft).isRequired,
    onSeeMoreClick: PropTypes.func.isRequired,
};

export function InfoField({ title, value }) {
    return (
        <div className="stack--xs">
            <div className="overline-1 text-transform--uppercase color--grayscale-carbono-gray">
                {title}
            </div>
            <div className="body-2 color--grayscale-dark">{value}</div>
        </div>
    );
}

InfoField.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

function Timeline({ timelineStatus, currentStatus }) {
    const [timeline, setTimeline] = useState([]);

    const filterTimeline = () => {
        // Filtra quais atualizações vão ser mostradas na tela, para deixar mais compreensível pro usuário.
        // Pending Review e Pending Billing são unificados na exibição para o usuário,
        // como um status de "Em análise"/"Pedido aprovado". Os status de Pending Payment (quando já finalizado)
        // e Payment Confirmed também são unificados, por transmitirem a mesma informação.

        let filteredTimeline = {};
        // Pega a última ocorrência de cada status, pra impedir repetição na exibição
        timelineStatus.forEach((item) => {
            filteredTimeline[item.statusId] = item;
        });
        filteredTimeline = Object.keys(filteredTimeline).map(
            (key) => filteredTimeline[key]
        );

        // Ordena a timeline pelo mais antigo pro mais recente
        filteredTimeline.sort(
            (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );

        switch (currentStatus) {
            case ReservationStatus.changeRequested:
            case ReservationStatus.pendingPayment:
            case ReservationStatus.pendingBilling:
                return filteredTimeline.filter(
                    (timelineItem) =>
                        timelineItem.statusId !==
                        ReservationStatus.pendingReview
                );
            case ReservationStatus.processingPayment:
                return filteredTimeline.filter(
                    (timelineItem) =>
                        ![
                            ReservationStatus.pendingReview,
                            ReservationStatus.pendingPayment,
                        ].includes(timelineItem.statusId)
                );
            case ReservationStatus.completed:
            case ReservationStatus.cancelled:
            case ReservationStatus.paymentConfirmed:
                return filteredTimeline.filter(
                    (timelineItem) =>
                        ![
                            ReservationStatus.pendingReview,
                            ReservationStatus.pendingPayment,
                            ReservationStatus.processingPayment,
                        ].includes(timelineItem.statusId)
                );
            case ReservationStatus.denied:
                return filteredTimeline.filter(
                    (timelineItem) =>
                        timelineItem.statusId === ReservationStatus.denied
                );
            default:
                return filteredTimeline;
        }
    };

    const getStringStatus = (timelineItem) => {
        if (timelineItem.statusId) {
            return getReservationStatus(timelineItem.statusId);
        }
        return timelineItem.key.replace('timeline-', '');
    };

    const firstStatus = {
        key: 'timeline-new',
        isOpen: false,
        isDone: true,
        title: Locale.status.title.done.new,
        subtitle: moment(timelineStatus[0].timestamp).format(
            'DD/MM/YYYY - HH:mm'
        ),
        content: Locale.status.description.done.new,
    };

    const clearedStatus = {
        key: 'timeline-cleared',
        isOpen: false,
        isDone: true,
        title: Locale.status.title.done.cleared,
        subtitle: moment(
            timelineStatus[timelineStatus.length - 1].timestamp
        ).format('DD/MM/YYYY - HH:mm'),
        content: Locale.status.description.done.cleared,
    };

    const doneOrCurrent = (status) =>
        status.statusId === currentStatus ? 'current' : 'done';

    const parseTimelineItems = (items) =>
        items.map((item) => ({
            ...item,
            statusId: item.statusId,
            key: `timeline-${item.statusId}`,
            icon: Reservation.getIconByStatus(item.statusId),
            isOpen: false,
            isDone:
                item.statusId !== currentStatus ||
                [
                    ReservationStatus.completed,
                    ReservationStatus.paymentConfirmed,
                ].includes(item.statusId),
            title: Locale.status.title[doneOrCurrent(item)][
                getReservationStatus(item.statusId)
            ],
            subtitle: moment(item.timestamp).format('DD/MM/YYYY - HH:mm'),
            content:
                Locale.status.description[doneOrCurrent(item)][
                    getReservationStatus(item.statusId)
                ],
        }));

    const getUpdatedTimeline = () => {
        const filteredTimeline = filterTimeline();
        const updatedTimeline = [
            firstStatus,
            ...parseTimelineItems(filteredTimeline),
        ];
        if (
            [
                ReservationStatus.paymentConfirmed,
                ReservationStatus.completed,
            ].includes(currentStatus)
        ) {
            const completedStatusIndex = updatedTimeline.findIndex(
                (item) => item.statusId === ReservationStatus.completed
            );
            const insertIndex =
                completedStatusIndex >= 0
                    ? completedStatusIndex
                    : updatedTimeline.length;
            updatedTimeline.splice(insertIndex, 0, clearedStatus);
        }
        updatedTimeline[updatedTimeline.length - 1].isOpen = true;
        return updatedTimeline;
    };

    useEffect(() => {
        setTimeline(getUpdatedTimeline());
    }, [timelineStatus]);

    useEffect(() => {
        if (timeline.length > 0) {
            setTimeline(
                timeline.map((timelineItem) => ({
                    ...timelineItem,
                    title: Locale.status.title[
                        timelineItem.isDone ? 'done' : 'current'
                    ][getStringStatus(timelineItem)],
                    content:
                        Locale.status.description[
                            timelineItem.isDone ? 'done' : 'current'
                        ][getStringStatus(timelineItem)],
                }))
            );
        }
    }, [Locale.getLanguage()]);

    const toggleTimelineItem = (item, index) => {
        const updatedTimelineItem = {
            ...item,
            isOpen: !item.isOpen,
        };
        const updatedTimeline = [...timeline];
        updatedTimeline[index] = updatedTimelineItem;
        setTimeline(updatedTimeline);
    };

    return (
        <div className="stack--lg">
            {timeline.map((timelineItem, index) => (
                <TimelineItem
                    key={timelineItem.key}
                    title={timelineItem.title}
                    subtitle={timelineItem.subtitle}
                    content={timelineItem.content}
                    isOpen={timelineItem.isOpen}
                    icon={timelineItem.icon}
                    isDone={timelineItem.isDone}
                    toggle={() => toggleTimelineItem(timelineItem, index)}
                />
            ))}
        </div>
    );
}

Timeline.propTypes = {
    currentStatus: PropTypes.number.isRequired,
    timelineStatus: PropTypes.arrayOf(
        PropTypes.shape({
            statusId: PropTypes.number.isRequired,
            timestamp: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date),
            ]).isRequired,
        })
    ).isRequired,
};

function TimelineItem({
    title,
    subtitle,
    content,
    isOpen,
    icon,
    isDone,
    toggle,
}) {
    return (
        <div className="flex--column">
            <div className="flex-justify--between" onClick={toggle}>
                <div className="inline--md">
                    <div className="margin--t-xs">
                        {isDone ? (
                            <ButtonIcon icon={icoCheckWhite} size="small" />
                        ) : (
                            <ButtonIcon
                                kind="outline"
                                icon={icon}
                                size="small"
                            />
                        )}
                    </div>
                    <div className="stack--xs">
                        <h3 className="color--grayscale-blue-gray">{title}</h3>
                        <div className="overline-2 color--grayscale-carbono-gray">
                            {subtitle}
                        </div>
                    </div>
                </div>
                <ButtonIcon
                    kind="no-border"
                    icon={icoExpandDown}
                    className={`card ${isOpen ? 'open' : ''}`}
                />
            </div>
            <div className="timeline-content subtitle-1 padding--t-lg">
                {isOpen && <div className="padding--b-lg">{content}</div>}
            </div>
        </div>
    );
}

TimelineItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    isDone: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

TimelineItem.defaultProps = {
    icon: undefined,
};

function ContactCard({ contactData }) {
    return (
        <Card className="stack--md">
            <Title tagName="h4">{Locale.pages.help.title}</Title>
            <div className="subtitle-1">{Locale.pages.help.subtitle}</div>
            <div className="subtitle-1 white-space--pre-line">
                {contactData}
            </div>
        </Card>
    );
}

ContactCard.propTypes = {
    contactData: PropTypes.string.isRequired,
};

function AirportDocuments({ docs }) {
    return (
        <Card className="stack--md">
            <Title tagName="h4">{Locale.fields.airport.documents}</Title>
            <div className="subtitle-1">
                {Locale.fields.airport.documentsSubtitle}.
            </div>
            {docs.map((document) => (
                <div
                    className="margin--b-md documents"
                    key={`airport-document-${document.id}`}
                >
                    <a
                        href={document.filepath}
                        target="_blank"
                        download
                        rel="noreferrer"
                    >
                        <List
                            after={<img src={icoExpandRight} alt="" />}
                            text={document.name}
                            className="document-item"
                        />
                    </a>
                </div>
            ))}
        </Card>
    );
}
AirportDocuments.propTypes = {
    docs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
