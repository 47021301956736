import { get, patch, post } from './axios';

const LandingRequestService = {
    getLandingRequest(pk) {
        return get(`landing_request/${pk}/`);
    },

    getStatusHistory(pk) {
        return get(`landing_request/${pk}/status_history`);
    },

    listUserLandingRequests(params) {
        return get('/landing_request/', {
            params: { ...params, list_by_user: true },
        });
    },

    listAllLandingRequests(params) {
        return get('/landing_request/', { params });
    },

    createLandingRequest(reservation) {
        const data = reservation.parseToRequest();
        return post('landing_request/', data);
    },

    editLandingRequest(pk, data) {
        return patch(`landing_request/${pk}/`, data);
    },

    cancel(pk) {
        return patch(`landing_request/${pk}/cancel`);
    },

    requestChange(pk, data) {
        return patch(`landing_request/${pk}/request_change`, data);
    },

    fulfillChange(pk, data) {
        return patch(`landing_request/${pk}/fulfill_change`, data);
    },

    approve(pk) {
        return patch(`landing_request/${pk}/approve`);
    },

    fulfillBilling(pk, data) {
        return patch(`landing_request/${pk}/fulfill_billing`, data);
    },

    pay(pk) {
        return patch(`landing_request/${pk}/pay`);
    },

    refusePayment(pk) {
        return patch(`landing_request/${pk}/refuse_payment`);
    },

    confirmPayment(pk) {
        return patch(`landing_request/${pk}/confirm_payment`);
    },

    complete(pk) {
        return patch(`landing_request/${pk}/complete`);
    },

    deny(pk) {
        return patch(`landing_request/${pk}/deny`);
    },

    acceptChange(pk, data) {
        return patch(`/landing_request/${pk}/accept_change`, data);
    },

    refuseChange(pk, data) {
        return patch(`/landing_request/${pk}/refuse_change`, data);
    },
};
export default LandingRequestService;
