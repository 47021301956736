import * as Yup from 'yup';
import Locale from 'locale';
import { BR_PHONE_CODE } from 'utils/constants';
import BaseModel from './BaseModel';

export default class Pilot extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.name = data.name;
        this.countryPhoneCode = data.countryPhoneCode;
        this.phoneNumber = data.phoneNumber;
    }

    static get validationSchema() {
        return Yup.object().shape({
            name: Yup.string().required(Locale.errors.required),
            countryPhoneCode: Yup.string().required(Locale.errors.required),
            phoneNumber: Yup.string().required(Locale.errors.required),
        });
    }

    static createEmpty() {
        return new this({ countryPhoneCode: BR_PHONE_CODE });
    }
}
