import styled, { css } from 'styled-components';

const transition = 'transform 0.5s, visibility 0.5s';
const backdropTransition = 'opacity 0.2s, visibility 0.2s';

export const Backdrop = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.grayscale.dark};
    position: fixed;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: ${backdropTransition};
    top: 0;
    left: 0;

    ${(props) =>
        props.show &&
        `
            opacity: 0.75;
            visibility: visible;
            transition: ${backdropTransition};
    `}
`;

export const Content = styled.div`
    ${(props) =>
        `padding: ${props.theme.spacing.md} ${props.theme.spacing.lg}`};
`;

const getWidth = (kind) => {
    switch (kind) {
        case 'rightSide':
            return '30%';
        default:
            return '100%';
    }
};

const getTransformHidden = (kind) => {
    switch (kind) {
        case 'rightSide':
            return 'translateX(100vw)';
        case 'leftSide':
            return 'translateX(-100vw)';
        default:
            return 'translateY(100vh)';
    }
};

const getTransformShow = (kind) => {
    switch (kind) {
        case 'rightSide':
            return 'translateX(0)';
        case 'leftSide':
            return 'translateX(0)';
        default:
            return 'translateY(0)';
    }
};

export const Container = styled.div`
    border-radius: 5px 5px 0px 0px;
    background-color: ${(props) => props.theme.colors.grayscale.whiteBg};
    position: fixed;
    z-index: 12;
    top: 0;
    right: 0;
    width: ${({ kind }) => getWidth(kind)};
    height: 100%;
    visibility: hidden;
    transform: ${({ kind }) => getTransformHidden(kind)};
    transition: ${transition};
    display: flex;
    flex-direction: column;

    ${(props) =>
        props.show &&
        css`
            visibility: visible;
            transform: ${({ kind }) => getTransformShow(kind)};
            transition: ${transition};
        `}

    ${(props) =>
        props.kind === 'rightSide' &&
        css`
            max-width: 460px;
        `}
`;

export const ModalHeader = styled.div`
    background-color: ${(props) => props.theme.colors.grayscale.white};
    border: 1px solid ${(props) => props.theme.colors.grayscale.lightGray};

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const ModalBody = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    padding: 0 ${(props) => props.theme.spacing.lg};
    overflow: auto;

    @media only screen and (max-width: 1300px) {
        padding: 0;
    }
`;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
