import Locale from 'locale';
import moment from 'moment';
import { MaskHelper } from 'helpers';
import PropTypes from 'prop-types';
import { Button, ButtonIcon, Loading } from 'components';
import { QRCodeSVG } from 'qrcode.react';
import theme from 'styles/themes/default';
import notify from 'components/Toast';
import './styles.scss';
import { icoRefresh } from 'assets/icons';

function DebtCardInfo({ label, value }) {
    return (
        <div className="debt-card-info">
            <p className="color--grayscale-steel-gray font-size--xs fw-semibold">
                {label}
            </p>
            <p className="color--grayscale-carbono-gray font-size-sm">
                {value}
            </p>
        </div>
    );
}

DebtCardInfo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default function PaymentDetails({
    invoiceDetails,
    expired,
    expirationDate,
    QRCode,
    refreshQrCode,
}) {
    const copyQrCode = () => {
        navigator.clipboard.writeText(QRCode);
        notify(Locale.pages.reservationDetail.pixCard.copied);
    };

    return (
        <div className="invoice-payment-details">
            <div className="background-banner">
                <div className="details-card stack--md">
                    <h3 className="color--grayscale-carbono-gray font-size--md fw-semibold">
                        {`${invoiceDetails.aeroportoICAO} • ${invoiceDetails.noDocFaturamento}`}
                    </h3>
                    <div className="information-wrapper stack--md">
                        <DebtCardInfo
                            label={Locale.pages.invoiceDetails.operator}
                            value={invoiceDetails?.clienteName}
                        />
                        <DebtCardInfo
                            label={Locale.fields.document}
                            value={MaskHelper.documentWithLength(
                                invoiceDetails.clienteCPFCNPJ
                            )}
                        />
                        <DebtCardInfo
                            label={Locale.pages.invoiceDetails.invoice}
                            value={invoiceDetails.noNotaFiscal}
                        />
                        <DebtCardInfo
                            label={
                                Locale.pages.invoiceDetails.invoiceExpiration
                            }
                            value={moment(invoiceDetails.dtVencimento).format(
                                'DD/MM/YYYY'
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="pix-card stack--md">
                <div className="flex--column flex-items--start width--full">
                    <p className="color--grayscale-carbono-gray font-size--sm">
                        {Locale.pages.invoicePayments.invoiceTotal}
                    </p>
                    <p className="color--grayscale-blue-gray font-size--lg fw-semibold">
                        {`${new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(invoiceDetails.vlrTotal)}`}
                    </p>
                </div>
                <div>
                    {QRCode ? (
                        <>
                            {expired && (
                                <div className="flex-justify--center over-qr">
                                    <ButtonIcon
                                        kind="no-border"
                                        onClick={refreshQrCode}
                                        icon={icoRefresh}
                                    />
                                </div>
                            )}
                            <QRCodeSVG
                                className="width--full margin--y-md"
                                value={QRCode}
                                size={151}
                                fgColor={
                                    expired
                                        ? theme.colors.grayscale.lightGray
                                        : theme.colors.grayscale.dark
                                }
                            />
                            <p className="color--grayscale-carbono-gray font-size--sm">
                                {expired
                                    ? Locale.pages.invoicePayments.codeExpired.replace(
                                          '{}',
                                          moment(expirationDate).format('HH:mm')
                                      )
                                    : Locale.pages.invoicePayments.codeValidUntil.replace(
                                          '{}',
                                          moment(expirationDate).format('HH:mm')
                                      )}
                            </p>
                        </>
                    ) : (
                        <div className="loading-container">
                            <Loading />
                        </div>
                    )}
                </div>
                <Button
                    className="width--full"
                    onClick={copyQrCode}
                    disabled={expired || !QRCode}
                >
                    {Locale.pages.invoicePayments.copyPaste}
                </Button>
                <p className="font-size--md color--grayscale-dark">
                    {Locale.pages.invoicePayments.pixInstructions}
                </p>
            </div>
        </div>
    );
}

PaymentDetails.propTypes = {
    invoiceDetails: PropTypes.object.isRequired,
    expired: PropTypes.bool.isRequired,
    expirationDate: PropTypes.string.isRequired,
    QRCode: PropTypes.string.isRequired,
    refreshQrCode: PropTypes.func.isRequired,
};
