import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
    HeaderPage,
    Card,
    Input,
    Line,
    Button,
    LoadingFullPage,
    Radio,
    TextSwitch,
    Multiselect,
} from 'components';

import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import notify from 'components/Toast';

import { Airport, User } from 'models';

function UserDetail() {
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [accessProfiles, setAccessProfiles] = useState([]);
    const [airports, setAirports] = useState(new Map());
    const navigate = useNavigate();
    const { userId } = useParams();
    const enableLoadingRef = useRef(true);

    const goBack = () => {
        navigate('/backoffice/users');
    };

    const initialValues = User.createEmpty();

    const { validationSchema } = User;

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            const user = new User(values);
            const requestData = user.parseToRequest();
            if (!userId) {
                createUser(requestData);
            } else {
                editUser(requestData);
            }
        },
    });

    const createUser = (data) => {
        Service.createBOUser(data)
            .then(() => {
                notify(Locale.pages.backoffice.users.createSuccess);
                goBack();
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setIsLoading(false);
            });
    };

    const editUser = (data) => {
        Service.patchUser(data, userId)
            .then((response) => {
                notify(
                    response.warning ||
                        Locale.pages.backoffice.users.editSuccess
                );
                goBack();
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (userId) {
            Service.getUser(userId)
                .then((response) => {
                    const user = User.createFromResponse(response);
                    formik.setValues((values) => ({
                        ...values,
                        ...user,
                    }));
                    setIsLoadingInitial(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    goBack();
                });
        } else {
            setIsLoadingInitial(false);
        }
    }, [userId]);

    useEffect(() => {
        Service.listAccessProfiles()
            .then((response) => {
                setAccessProfiles(response);
            })
            .catch((error) => ErrorHelper.notifyError(error));

        Service.listAirports({
            [Airport.filterParams.hasICAO]: true,
            [Airport.filterParams.isManagedByCcr]: true,
        }).then((response) => {
            if (enableLoadingRef.current) {
                response?.map((airport) =>
                    setAirports((prev) => [
                        ...prev,
                        {
                            value: airport.id,
                            label: airport.icaoCode,
                        },
                    ])
                );
            }
            enableLoadingRef.current = false;
        });
    }, []);

    return !isLoadingInitial ? (
        <div className="padding--lg">
            <HeaderPage
                labelBtnBack={Locale.pages.backoffice.users.title}
                onClickBtnBack={goBack}
            />
            <Card kind="outlined">
                <div className="padding--md">
                    <form
                        id="formAirport"
                        onSubmit={formik.handleSubmit}
                        className="stack--xl"
                    >
                        <div className="stack--md">
                            <h4 className="headline-4">
                                {Locale.fields.user.personalData}
                            </h4>
                            <div className="row width--full">
                                <div className="col-md-6">
                                    <Input
                                        label="E-mail"
                                        name="email"
                                        required
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!!userId}
                                        error={
                                            formik.touched?.email &&
                                            formik.errors?.email
                                        }
                                    />
                                </div>
                                <div className="col-md-3 flex-items--end">
                                    <TextSwitch
                                        id="isActive"
                                        name="isActive"
                                        label={Locale.fields.user.isActive}
                                        checked={formik.values.isActive}
                                        required
                                        error={formik.errors.isActive}
                                        onChange={(e) =>
                                            formik.setFieldValue('isActive', e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {!!userId && (
                            <>
                                <Line />
                                <div className="stack--md">
                                    <h4 className="headline-4">
                                        {Locale.fields.user.linkedAirports}
                                    </h4>
                                    <div className="width--full stack--md">
                                        <Multiselect
                                            name="airports"
                                            options={Array.from(
                                                airports.values()
                                            )}
                                            value={formik.values.airports || []}
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    'airports',
                                                    value
                                                )
                                            }
                                            error={
                                                formik.touched?.airports &&
                                                formik.errors?.airports
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <Line />
                        <div className="stack--md">
                            <h4 className="headline-4">
                                {Locale.fields.user.accessProfile}
                            </h4>
                            <div className="width--full stack--md">
                                {accessProfiles.map((accessProfile) => (
                                    <Radio
                                        id={accessProfile.id.toString()}
                                        key={accessProfile.id}
                                        name="accessProfile.id"
                                        label={`${accessProfile.name} - ${
                                            Locale.getLanguage() === 'en'
                                                ? accessProfile.descriptionEnUS
                                                : accessProfile.descriptionPtBr
                                        }`}
                                        value={accessProfile.id}
                                        checked={
                                            formik.values.accessProfile?.id.toString() ===
                                            accessProfile.id.toString()
                                        }
                                        onChange={formik.handleChange}
                                    />
                                ))}
                            </div>
                        </div>
                        <Line />
                        <div className="flex-justify--end">
                            <div className="center-content">
                                <Button
                                    kind="outline"
                                    type="button"
                                    className="margin--r-md"
                                    onClick={goBack}
                                >
                                    {Locale.actions.cancel}
                                </Button>
                                <Button
                                    type="submit"
                                    kind="primary"
                                    disabled={!(formik.isValid && formik.dirty)}
                                    isLoading={isLoading}
                                >
                                    <p>
                                        {!userId
                                            ? Locale.actions.send
                                            : Locale.actions.edit}
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    ) : (
        <LoadingFullPage />
    );
}

export default UserDetail;
