import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {
    HeaderPage,
    ContainerForm,
    Card,
    Image,
    Input,
    Dropdown,
    Button,
    StickyFooter,
    Text,
    Label,
    Title,
    LoadingFullPage,
} from 'components';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';
import Locale from 'locale';
import { PaymentOptions } from 'utils/enums';

import Service from 'services';

import { icoStep2x2 } from 'assets/icons';

import { Reservation } from 'models';

function Checkout() {
    const options = [
        {
            label: Locale.paymentOptions.creditCard,
            value: PaymentOptions.creditCard,
        },
    ];

    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const { reservationId: landingRequestId } = useParams();

    const location = useLocation();
    const reservation = new Reservation(location.state.reservation);

    const initialValues = {
        cardDetails: {
            number: '',
            cardExpiryDate: '',
            cvv: '',
            cardholderName: '',
        },
        airportId: '',
        paymentMethod: PaymentOptions.creditCard,
        date: new Date(),
        value: '',
        dollarValue: '',
        euroValue: '',
        amountInt: '',
        landingRequestId,
    };

    const cvvMin = 3;
    const cvvMax = 4;

    const CheckoutScheme = Yup.object({
        paymentMethod: Yup.string().required(Locale.errors.required),
        cardDetails: Yup.object().when('paymentMethod', {
            is: PaymentOptions.creditCard,
            then: Yup.object({
                number: Yup.string().required(Locale.errors.required),
                cardholderName: Yup.string().required(Locale.errors.required),
                cardExpiryDate: Yup.string().required(Locale.errors.required),
                cvv: Yup.string()
                    .min(cvvMin, Locale.errors.minLength(cvvMin))
                    .max(cvvMax, Locale.errors.maxLength(cvvMax))
                    .required(Locale.errors.required),
            }),
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: CheckoutScheme,
        onSubmit: async (data) => {
            setLoadingSave(true);
            try {
                await Service.postLandingPayment(data);
                notify(Locale.pages.checkout.paymentSuccess);
                setLoadingSave(false);
                navigate(`/reservation/${landingRequestId}`); // TODO: Adicionar logs de erro do serviço de pagamentos
            } catch (error) {
                ErrorHelper.notifyError(error);
                if (
                    error.response?.data?.reason ===
                    'STATUS_UPDATE_STILL_UNAVAILABLE'
                )
                    navigate('/');
                setLoadingSave(false);
            }
        },
    });

    useEffect(() => {
        setLoadingData(true);
        // Reservation (Landing Request) vem já do state (buscado na página de details)
        formik.setValues((values) => ({
            ...values,
            airportId: reservation.airportTarget.id,
            date: reservation.createdAt,
        }));

        Service.getBillingData(landingRequestId)
            .then((data) => {
                formik.setValues((values) => ({
                    ...values,
                    value: data.pricing.priceReal,
                    dollarValue: data.pricing.priceDollar,
                    euroValue: data.pricing.priceEuro,
                    amountInt: Number(data.pricing.priceReal) * 100,
                }));
                setLoadingData(false);
            })
            .catch(() => {
                ErrorHelper.notifyError(Locale.errors.billingInfoError);
                setLoadingData(false);
            });
    }, []);

    return (
        <div className="padding--lg">
            <HeaderPage
                title={Locale.pages.checkout.title}
                onClickBtnBack={() =>
                    navigate(`/reservation/${landingRequestId}`, {
                        state: location.state,
                    })
                }
                labelBtnBack={Locale.pages.reservationDetail.title}
                contentInfo={<Image src={icoStep2x2} alt="2/2" />}
            />
            {!loadingData ? (
                <>
                    <Card isRounded kind="secondary">
                        <div>
                            <Label>
                                {Locale.pages.checkout.bookingTotal.toUpperCase()}
                            </Label>
                            <div className="flex-items--center">
                                <Title tagName="h1" className="margin--r-md">
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(formik.values.value)}
                                </Title>
                                <Text
                                    kind="subtitle-1"
                                    color="#6A7CA0"
                                    className="d-none d-sm-block"
                                >
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(formik.values.dollarValue)}
                                    <span className="margin--x-sm">|</span>
                                    {new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }).format(formik.values.euroValue)}
                                </Text>
                            </div>
                        </div>
                        <div className="row d-none d-sm-block padding--t-sm">
                            <div className="col-12">
                                {Locale.pages.checkout.description}
                            </div>
                        </div>
                        <div className="d-none d-sm-block">
                            <Text kind="overline-2">
                                {Locale.pages.checkout.reservationSentIn}
                                {moment(formik.values.date).format(
                                    `DD MMM, YYYY [${Locale.general.at}] H:mm`
                                )}
                                .
                            </Text>
                        </div>
                    </Card>
                    <ContainerForm>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="margin--t-lg">
                                <h4 className="headeline-4 color--grayscale-blue-gray margin--b-sm">
                                    {Locale.pages.checkout.paymentMethods}
                                </h4>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <Dropdown
                                            id="paymentMethod"
                                            name="paymentMethod"
                                            label={`${Locale.pages.checkout.formPayment.toUpperCase()}`}
                                            required
                                            value={formik.values.paymentMethod}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.paymentMethod &&
                                                formik.errors.paymentMethod
                                            }
                                            options={options}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                {formik.values.paymentMethod ===
                                    PaymentOptions.creditCard && (
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <Input
                                                    id="cardDetails.number"
                                                    name="cardDetails.number"
                                                    label={`${Locale.fields.checkout.cardNumber}`}
                                                    required
                                                    mask="9999 9999 9999 9999"
                                                    placeholder="0000 0000 0000 0000"
                                                    value={
                                                        formik.values
                                                            .cardDetails.number
                                                    }
                                                    onChange={(e) =>
                                                        formik.setFieldValue(
                                                            'cardDetails.number',
                                                            e.target.value.replace(
                                                                ' ',
                                                                ''
                                                            )
                                                        )
                                                    }
                                                    error={
                                                        formik.touched
                                                            .cardDetails
                                                            ?.number &&
                                                        formik.errors
                                                            .cardDetails?.number
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <Input
                                                    id="cardDetails.cardholderName"
                                                    name="cardDetails.cardholderName"
                                                    label={`${Locale.fields.checkout.cardholderName}`}
                                                    required
                                                    value={
                                                        formik.values
                                                            .cardDetails
                                                            .cardholderName
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .cardDetails
                                                            ?.cardholderName &&
                                                        formik.errors
                                                            .cardDetails
                                                            ?.cardholderName
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            <div className="col-6 col-md-3">
                                                <Input
                                                    id="cardDetails.cardExpiryDate"
                                                    name="cardDetails.cardExpiryDate"
                                                    label={`${Locale.fields.checkout.cardExpiringDate}`}
                                                    required
                                                    mask="99/99"
                                                    placeholder="00/00"
                                                    value={
                                                        formik.values
                                                            .cardDetails
                                                            .cardExpiryDate
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .cardDetails
                                                            ?.cardExpiryDate &&
                                                        formik.errors
                                                            .cardDetails
                                                            ?.cardExpiryDate
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            <div className="col-6 col-md-3">
                                                <Input
                                                    id="cardDetails.cvv"
                                                    name="cardDetails.cvv"
                                                    label={`${Locale.fields.checkout.cvc}`}
                                                    required
                                                    pattern="^[0-9]{3,4}$"
                                                    minLength={3}
                                                    maxLength={4}
                                                    placeholder="000"
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values
                                                            .cardDetails.cvv
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .cardDetails?.cvv &&
                                                        formik.errors
                                                            .cardDetails?.cvv
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <StickyFooter>
                                <Button
                                    kind="outline"
                                    type="button"
                                    className="d-none d-sm-block"
                                >
                                    {Locale.actions.back}
                                </Button>
                                <Button
                                    kind="primary"
                                    type="submit"
                                    isLoading={loadingSave}
                                >
                                    {Locale.actions.proceedToPayment}
                                </Button>
                            </StickyFooter>
                        </form>
                    </ContainerForm>
                </>
            ) : (
                <LoadingFullPage />
            )}
        </div>
    );
}

export default Checkout;
