import styled from 'styled-components';

const transition = 'transform 0.5s, visibility 0.5s';
const backdropTransition = 'opacity 0.2s, visibility 0.2s';

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.grayscale.whiteBg};
    position: fixed;
    z-index: 50;
    bottom: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    transition: ${transition};
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing.md};

    ${(props) =>
        props.show &&
        `
            visibility: visible;
            transform: translateY(0);
            transition: ${transition};
    `}

    .menu-option-selected {
        background-color: ${(props) => props.theme.colors.brand.primaryLight};

        div {
            color: ${(props) => props.theme.colors.grayscale.white};
        }
    }
`;

export const Backdrop = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.grayscale.dark};
    position: fixed;
    z-index: 49;
    opacity: 0;
    visibility: hidden;
    transition: ${backdropTransition};
    top: 0;
    left: 0;

    ${(props) =>
        props.show &&
        `
            opacity: 0.75;
            visibility: visible;
            transition: ${backdropTransition};
    `}
`;

export const PopoverTitle = styled.span`
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-block-end: 1.125rem;
    padding-inline: 1rem;
`;
