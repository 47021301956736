import { AircraftSolicitation } from 'models';
import { get, patch, post } from './axios';

const AircraftSolicitationService = {
    listAllAircraftSolicitations(params) {
        return get('aircraft/solicitation/', { params });
    },

    listLastCreationSolicitations(params) {
        return get('aircraft/solicitation/list_last_creation/', { params });
    },

    async retrieveSolicitation(pk) {
        return get(`aircraft/solicitation/${pk}`).then((response) =>
            AircraftSolicitation.createFromResponse(response)
        );
    },

    approveSolicitation(pk, data) {
        return patch(`aircraft/solicitation/${pk}/approve`, data);
    },

    disapproveSolicitation(pk, data) {
        return patch(`aircraft/solicitation/${pk}/disapprove`, data);
    },

    async postAircraftSolicitation(solicitation) {
        const data = solicitation.parseToRequest();
        return post('aircraft/solicitation/', data).then((response) =>
            AircraftSolicitation.createFromResponse(response)
        );
    },
};

const AircraftSolicitationFileService = {
    createAircraftSolicitationImage(solicitationId, data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return post(
            `aircraft/solicitation/${solicitationId}/image/`,
            formData,
            config
        );
    },

    patchAircraftSolicitationImage(solicitationId, pk, data) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return patch(
            `aircraft/solicitation/${solicitationId}/image/${pk}`,
            formData
        );
    },

    createAircraftSolicitationDocument(solicitationId, data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return post(
            `aircraft/solicitation/${solicitationId}/document/`,
            formData,
            config
        );
    },

    patchAircraftSolicitationDocument(solicitationId, pk, data) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return patch(
            `aircraft/solicitation/${solicitationId}/document/${pk}`,
            formData
        );
    },
};

export default {
    ...AircraftSolicitationService,
    ...AircraftSolicitationFileService,
};
