import Locale from 'locale';
import * as Yup from 'yup';
import moment from 'moment';
import BaseModel from './BaseModel';
import Operator from './Operator';

export default class Aircraft extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.isForeign = !!data.isForeign;
        this.registration = data.registration;
        this.icaoModel = data.icaoModel;
        this.mtow = data.mtow;
        this.wingspan = data.wingspan;
        this.length = data.length;
        this.useData = data.useData;
        this.deleted = data.deleted;
        this.operator = Operator.getValueOrNew(data.operator);
        this.document = data.document;
        this.image = data.image;
        this.isMilitary = data.isMilitary;
        this.userId = data.userId;

        this.createdAt = data.createdAt && moment(data.createdAt).toDate();
        this.updatedAt = data.updatedAt && moment(data.updatedAt).toDate();
    }

    static get maxLength() {
        return { registration: 15, icaoModel: 30 };
    }

    get registrationTitle() {
        return `${Locale.pages.accountSettings.aircraft.registration} ${this.registration}`;
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new Aircraft({
            ...response,
            operator: Operator.createFromResponse(response.operator),
        });
    }

    static createEmpty() {
        return new Aircraft({
            isForeign: false,
            useData: false,
            operator: Operator.createEmpty(),
        });
    }

    static get validationSchema() {
        return Yup.object().shape({
            isForeign: Yup.boolean(),
            registration: Yup.string()
                .max(
                    this.maxLength.registration,
                    Locale.errors.maxLength(this.maxLength.registration)
                )
                .required(Locale.errors.required),
            icaoModel: Yup.string()
                .max(
                    this.maxLength.icaoModel,
                    Locale.errors.maxLength(this.maxLength.icaoModel)
                )
                .required(Locale.errors.required),
            mtow: Yup.string().required(Locale.errors.required),
            wingspan: Yup.number()
                .min(1, `${Locale.fields.minValue} 1`)
                .max(99.99, `${Locale.fields.maxValue} 99.99`)
                .required(Locale.errors.required),
            length: Yup.number()
                .min(1, `${Locale.fields.minValue} 1`)
                .max(99.99, `${Locale.fields.maxValue} 99.99`)
                .required(Locale.errors.required),
            useData: Yup.boolean(),
            operator: Operator.validationSchema,
        });
    }

    static get noOperatorValidationSchema() {
        return Yup.object().shape({
            isForeign: Yup.boolean(),
            registration: Yup.string()
                .max(
                    this.maxLength.registration,
                    Locale.errors.maxLength(this.maxLength.registration)
                )
                .required(Locale.errors.required),
            icaoModel: Yup.string()
                .max(
                    this.maxLength.icaoModel,
                    Locale.errors.maxLength(this.maxLength.icaoModel)
                )
                .required(Locale.errors.required),
            mtow: Yup.string().required(Locale.errors.required),
            wingspan: Yup.number()
                .min(1, `${Locale.fields.minValue} 1`)
                .max(99.99, `${Locale.fields.maxValue} 99.99`)
                .required(Locale.errors.required),
            length: Yup.number()
                .min(1, `${Locale.fields.minValue} 1`)
                .max(99.99, `${Locale.fields.maxValue} 99.99`)
                .required(Locale.errors.required),
        });
    }

    static parseDropdownOptions(list, byId = false) {
        return list.map((item) => ({
            value: byId ? item.id : item.registration,
            label: item.registration,
        }));
    }

    parseToRequest() {
        const { useData, ...aircraftData } = this;

        const payload = {
            ...aircraftData,
            operator: aircraftData.operator.parseToRequest(),
            image: [],
            document: [],
        };

        return payload;
    }
}
