import PropTypes from 'prop-types';

import Locale from 'locale';

import { Tooltip, Image } from 'components';
import { icoQuestion } from 'assets/icons/';
import themes from '../../styles/themes/default';
import { Container, TitleText, Switch, CLabel } from './styles';

function TextSwitch({
    id,
    name,
    label,
    checked,
    error,
    disabled,
    onChange,
    required,
    titleChecked,
    titleUnchecked,
    tooltipContent,
}) {
    const uncheckedIcon = (
        <TitleText kind="subtitle-1">
            {titleUnchecked || Locale.general.no}
        </TitleText>
    );
    const checkedIcon = (
        <TitleText kind="subtitle-1">
            {titleChecked || Locale.general.yes}
        </TitleText>
    );

    return (
        <Container disabled={disabled}>
            <div className="inline--sm flex-items--center">
                {label && (
                    <CLabel error={error} isRequired={required}>
                        {label}
                    </CLabel>
                )}
                {tooltipContent && (
                    <Tooltip content={tooltipContent}>
                        <Image src={icoQuestion} size={15} />
                    </Tooltip>
                )}
            </div>

            <Switch
                id={id.toString()}
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                offColor={themes.colors.grayscale.paleShaleGray}
                offHandleColor={themes.colors.grayscale.blueGray}
                onColor={themes.colors.brand.primary}
                onHandleColor={themes.colors.grayscale.blueGray}
                height={40}
                uncheckedIcon={uncheckedIcon}
                checkedIcon={checkedIcon}
            />
        </Container>
    );
}

TextSwitch.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    titleChecked: PropTypes.string,
    titleUnchecked: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    tooltipContent: PropTypes.string,
};

TextSwitch.defaultProps = {
    error: false,
    disabled: false,
    onChange: () => {},
    titleChecked: '',
    titleUnchecked: '',
    label: '',
    required: false,
    tooltipContent: '',
};

export default TextSwitch;
