import AircraftService from './aircraft';
import AuthService from './auth';
import LandingRequestService from './landingRequest';
import CustomerService from './customer';
import PaymentService from './payment';
import PilotService from './pilot';
import UserService from './user';
import AirportService from './airport';
import BillingService from './billing';
import RegionsService from './regions';
import AddressService from './address';
import AccessProfilesService from './accessProfiles';
import FARMService from './farmIntegration';
import AircraftSolicitationService from './aircraftSolicitation';

const Service = {
    ...AccessProfilesService,
    ...AircraftService,
    ...AirportService,
    ...AuthService,
    ...BillingService,
    ...CustomerService,
    ...LandingRequestService,
    ...PaymentService,
    ...PilotService,
    ...RegionsService,
    ...UserService,
    ...AddressService,
    ...FARMService,
    ...AircraftSolicitationService,
};

export default Service;
