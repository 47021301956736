const getEnumKey = (object, number) =>
    Object.keys(object).find((key) => object[key] === number);

export const ReservationStatus = {
    pendingReview: 1,
    changeRequested: 2,
    pendingBilling: 3,
    pendingPayment: 4,
    processingPayment: 5,
    paymentConfirmed: 6,
    completed: 7,
    cancelled: 8,
    denied: 9,
};
Object.freeze(ReservationStatus);

export const AircraftSolicitationStatus = {
    incompleteRegistration: 1,
    pendingValidation: 2,
    approvedValidation: 3,
    disapprovedValidation: 4,
    cancelled: 5,
};
Object.freeze(AircraftSolicitationStatus);

export const AircraftSolicitationType = {
    creation: 1,
    edit: 2,
};
Object.freeze(AircraftSolicitationStatus);

export const PixItauStatus = {
    ATIVA: 'ATIVA',
    CONCLUIDA: 'CONCLUIDA',
    REMOVIDA_PELO_USUARIO_RECEBEDOR: 'REMOVIDA_PELO_USUARIO_RECEBEDOR',
    REMOVIDA_PELO_PSP: 'REMOVIDA_PELO_PSP',
    DEVOLVIDO: 'DEVOLVIDO',
    DEVOLUCAO_EM_PROCESSAMENTO: 'EM_PROCESSAMENTO',
    DEVOLUCAO_NAO_REALIZADA: 'NAO_REALIZADA',
};
Object.freeze(PixItauStatus);

export const getReservationStatus = (number) =>
    getEnumKey(ReservationStatus, number);

export const FlightType = {
    none: 1,
    instruction: 2,
    experience: 3,
    maintenance: 4,
    military: 5,
    federalPublicAdministration: 6,
    other: 7,
};
Object.freeze(FlightType);

export const getFlightType = (number) => getEnumKey(FlightType, number);

export const BackofficeSteps = {
    new: 1,
    pricing: 2,
    payment: 3,
    completed: 4,
};
Object.freeze(BackofficeSteps);

export const PaginationParams = {
    pageSize: 'page_size',
    page: 'page',
};
Object.freeze(PaginationParams);

export const OrderingParams = {
    ordering: 'ordering',
    lastUpdated: '-updated_at',
    firstCreated: 'id',
    lastCreated: '-id',
    deletedFirst: 'deleted',
    deletedLast: '-deleted',
    aircraftRegistration: 'aircraft__registration',
};
Object.freeze(OrderingParams);

export const PaymentOptions = {
    accountHolder: 'accountHolder',
    pix: 'pix',
    creditCard: 'creditCard',
};

export const DeleteParams = {
    showDeleted: 'show_deleted',
};

export const statusFARM = {
    aberto: 'ABERTO',
    pago: 'PAGO',
};
Object.freeze(statusFARM);

export const getAircraftSolicitationStatus = (number) =>
    getEnumKey(AircraftSolicitationStatus, number);

export const getAircraftSolicitationType = (number) =>
    getEnumKey(AircraftSolicitationType, number);
