import {
    ButtonIcon,
    ColoredTag,
    LoadingFullPage,
    TabGroup,
    Image,
    InformationCard,
} from 'components';
import { icoExpandLeft } from 'assets/icons';
import './styles.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Locale from 'locale';
import { Aircraft, AircraftSolicitation } from 'models';
import {
    getAircraftSolicitationStatus,
    AircraftSolicitationStatus,
} from 'utils/enums';
import { ErrorHelper } from 'helpers';
import Service from 'services';
import noImagePlaceholder from 'assets/no-image-placeholder.svg';
import AircraftInformation from './AircraftInformation';
import AircraftOperator from './AircraftOperator';

export default function AircraftDetails() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [aircraft, setAircraft] = useState();
    const { aircraftRegistration } = useParams();
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const isSolicitation = location.state?.isSolicitation;

    const tabs = [
        {
            title: Locale.pages.aircraftDetails.information,
            content: (
                <AircraftInformation
                    aircraft={aircraft}
                    isSolicitation={isSolicitation}
                    solicitationStatus={aircraft?.solicitationStatus}
                />
            ),
            name: 'aircraftInformation',
        },
        {
            title: Locale.pages.aircraftDetails.operator,
            content: (
                <AircraftOperator
                    aircraft={aircraft}
                    isSolicitation={isSolicitation}
                />
            ),
            name: 'aircraftOperator',
        },
    ];

    const getAircraft = () => {
        Service.listAircraft({ registration: aircraftRegistration })
            .then((response) => {
                if (!response?.length) {
                    ErrorHelper.notifyError({
                        response: {
                            data: `No aircraft found with registration ${aircraftRegistration}`,
                        },
                    });
                }
                setAircraft(Aircraft.createFromResponse(response[0]));
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const getAircraftFromSolicitation = () => {
        Service.listAllAircraftSolicitations({
            registration: aircraftRegistration,
            [AircraftSolicitation.filterParams
                .status]: `${AircraftSolicitationStatus.incompleteRegistration},${AircraftSolicitationStatus.pendingValidation},${AircraftSolicitationStatus.disapprovedValidation}`,
            ordering: `-${AircraftSolicitation.orderingParams.createdAt}`,
        })
            .then((response) => {
                if (!response?.length) {
                    ErrorHelper.notifyError({
                        response: {
                            data: `No solicitation found with registration ${aircraftRegistration}`,
                        },
                    });
                }
                const solicitation = AircraftSolicitation.createFromResponse(
                    response[0]
                );
                const solicitationAircraft = new Aircraft({
                    ...solicitation.aircraft,
                    image: [solicitation.image],
                    document: [solicitation.document],
                });
                solicitationAircraft.solicitationStatus = solicitation.status;
                setAircraft(solicitationAircraft);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                navigate('/aircraft');
            });
    };

    useEffect(() => {
        setLoading(true);
        if (isSolicitation) {
            getAircraftFromSolicitation();
        } else {
            getAircraft();
        }
    }, [aircraftRegistration]);

    return loading ? (
        <LoadingFullPage />
    ) : (
        <>
            <div className="aircraft-banner-container">
                <div className="flex--row flex-justify--center">
                    <Image
                        srcSet={
                            aircraft?.image?.[0]
                                ? aircraft.image[0].filepath
                                : noImagePlaceholder
                        }
                    />
                </div>
                <ButtonIcon
                    kind="no-border"
                    icon={icoExpandLeft}
                    onClick={() => navigate('/aircraft')}
                />
            </div>
            <div className="padding--lg padding-bottom-sticky-footer">
                <div className="aircraft-header">
                    <div className="aircraft-title w-100 flex--row flex-justify--between flex-items--center">
                        <div className="aircraft-card-head">
                            <div className="aircraft-title flex-justify--between">
                                <p>{aircraft.registration}</p>
                            </div>
                            <div className="aircraft-subtitle">
                                <p>{aircraft.icaoModel}</p>
                            </div>
                        </div>
                        <div className="aircraft-card-tags">
                            {aircraft.solicitationStatus ? (
                                <ColoredTag
                                    text={
                                        Locale.fields.aircraft.solicitation
                                            .statusEnum[
                                            getAircraftSolicitationStatus(
                                                aircraft.solicitationStatus
                                            )
                                        ]
                                    }
                                    type={
                                        aircraft.solicitationStatus ===
                                        AircraftSolicitationStatus.disapprovedValidation
                                            ? 'error'
                                            : 'alert'
                                    }
                                    className="font-size--xs"
                                />
                            ) : (
                                <ColoredTag
                                    text={Locale.fields.aircraft.active}
                                    type="active"
                                    className="font-size--xs"
                                />
                            )}
                        </div>
                    </div>
                    {aircraft.solicitationStatus && (
                        <div className="margin--t-lg margin--b-md">
                            <InformationCard
                                kind={
                                    aircraft.solicitationStatus ===
                                    AircraftSolicitationStatus.disapprovedValidation
                                        ? 'error'
                                        : 'alert'
                                }
                                title={
                                    Locale.pages.aircraftDetails
                                        .statusInformationCard[
                                        getAircraftSolicitationStatus(
                                            aircraft.solicitationStatus
                                        )
                                    ]?.title
                                }
                                message={
                                    Locale.pages.aircraftDetails
                                        .statusInformationCard[
                                        getAircraftSolicitationStatus(
                                            aircraft.solicitationStatus
                                        )
                                    ]?.message
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="aircraft-content">
                    <TabGroup
                        tabs={tabs}
                        activeTab={activeTabIndex}
                        onTabChange={(tab) => {
                            const idx = tabs.findIndex(
                                (currentTab) => currentTab.name === tab.name
                            );
                            setActiveTabIndex(idx > 0 ? idx : 0);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
