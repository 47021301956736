import axios from 'axios';
import Locale from 'locale';

const api = axios.create({
    baseURL: process.env.REACT_APP_BFF_URL,
    withCredentials: true,
});

// Request interceptors for API calls
api.interceptors.request.use(
    (config) => {
        const currentLanguageHeader =
            Locale.getLanguage() === 'en' ? 'en-US,en;q=0.8' : 'pt-BR,pt;q=0.9';
        const newConfig = {
            ...config,
            headers: {
                ...config.headers,
                'Accept-Language': currentLanguageHeader,
                'Requester-App': window.location.pathname.startsWith(
                    '/backoffice'
                )
                    ? 'backoffice'
                    : 'customer',
            },
        };
        return newConfig;
    },
    (error) => Promise.reject(error)
);

const get = (url, config) =>
    api.get(url, config).then((response) => response.data);

const patch = (url, data) =>
    api.patch(url, data).then((response) => response.data);

const post = (url, data, config) =>
    api.post(url, data, config || {}).then((response) => response.data);

const remove = (url, config) =>
    api.delete(url, config || {}).then((response) => response.data);

export { get, patch, post, remove };
