import { useFormik } from 'formik';
import {
    HeaderPage,
    Button,
    Image,
    BottomSheet,
    FileInputCard,
    SelectedAircraftCard,
} from 'components';
import { useNavigate, useLocation } from 'react-router-dom';
import Locale from 'locale';
import PropTypes from 'prop-types';
import { Aircraft } from 'models';
import {
    icoQuestion,
    icoAdd,
    icoCamera,
    icoExclamationYellow,
    icoClose,
} from 'assets/icons/';
import notify from 'components/Toast';
import './styles.scss';
import { useState } from 'react';

function BottomSheetContent({ setShowVisible }) {
    return (
        <div className="stack--md">
            <p>
                {
                    Locale.pages.register.aircraft
                        .airworthnessCertificateDescription
                }
            </p>
            <Button
                kind="primary"
                type="button"
                onClick={() => {
                    setShowVisible(false);
                }}
            >
                {Locale.general.understood}
            </Button>
        </div>
    );
}

BottomSheetContent.propTypes = {
    setShowVisible: PropTypes.func.isRequired,
};

function FileSection({
    title,
    cardTitle,
    cardDescription,
    cardAddIcon,
    bottomSheetContent,
    file,
    id,
    accept,
    onChange,
    clearFiles,
    onBlur,
    required,
    disabled,
    successMessage,
}) {
    return (
        <div className="stack--sm">
            <div className="flex--row flex-items--center">
                <p className="font-size--sm inline--sm">
                    {title} {required && <span>*</span>}
                </p>
                {bottomSheetContent && (
                    <>
                        <Image
                            src={icoQuestion}
                            size={15}
                            onClick={() => {
                                bottomSheetContent?.setVisible(true);
                            }}
                            className="margin--l-sm cursor--pointer"
                            alt="ico-question"
                        />
                        <BottomSheet
                            show={bottomSheetContent?.visible}
                            title={bottomSheetContent?.title}
                            onClose={() => {
                                bottomSheetContent?.setVisible(false);
                            }}
                        >
                            {bottomSheetContent?.content}
                        </BottomSheet>
                    </>
                )}
            </div>
            <FileInputCard
                id={id}
                title={cardTitle}
                description={cardDescription}
                addIcon={cardAddIcon}
                file={file}
                clearFiles={clearFiles}
                successMessage={successMessage}
                accept={accept}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
                disabled={disabled}
            />
        </div>
    );
}

FileSection.propTypes = {
    title: PropTypes.string.isRequired,
    cardTitle: PropTypes.string.isRequired,
    cardDescription: PropTypes.string.isRequired,
    cardAddIcon: PropTypes.string,
    bottomSheetContent: PropTypes.shape({
        visible: PropTypes.bool,
        setVisible: PropTypes.func,
        title: PropTypes.string,
        content: PropTypes.node,
    }),
    id: PropTypes.string.isRequired,
    accept: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    clearFiles: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    file: PropTypes.object,
    successMessage: PropTypes.string.isRequired,
};

FileSection.defaultProps = {
    cardAddIcon: icoAdd,
    bottomSheetContent: undefined,
    accept: 'application/pdf',
    onBlur: undefined,
    required: false,
    disabled: false,
    file: undefined,
};

function AircraftDocuments() {
    const navigate = useNavigate();
    const location = useLocation();
    const { aircraft } = location.state;
    const initialValues = new Aircraft(aircraft);

    const [showCertificateBottomSheet, setShowCertificateBottomSheet] =
        useState(false);
    const [showForeignAircraftAlert, setShowForeignAircraftAlert] =
        useState(true);

    const formik = useFormik({
        initialValues,
        onSubmit: (data) => {
            const aircraftData = new Aircraft(data);
            navigate('/newaircraft/operatortype', {
                state: {
                    ...location?.state,
                    aircraft: aircraftData,
                },
            });
        },
    });

    const handleSkip = () => {
        /** Premissa: o botão de pular etapa desconsidera os documentos que haviam sido preenchidos */
        navigate('/newaircraft/operatortype', {
            state: {
                ...location.state,
            },
        });
    };

    const handleAircraftFileChange = (e, formikField, acceptedExtensions) => {
        const { files } = e.target;
        const filesList = [];
        [...files].map((file) =>
            filesList.push({
                name: file.name,
                filesize: file.size,
                filepath: file,
            })
        );

        // Prevents file removal when canceled or not selecting file on edit
        if (filesList.length === 0 && formik.values[formikField]) {
            return;
        }

        // Prevents unsupported file format
        if (acceptedExtensions) {
            const submitedFilename = filesList[0].name;
            let fileExtension = '';
            if (submitedFilename.lastIndexOf('.') > 0) {
                fileExtension = submitedFilename.substring(
                    submitedFilename.lastIndexOf('.') + 1,
                    submitedFilename.length
                );
            }
            if (!acceptedExtensions.includes(fileExtension)) {
                notify(Locale.pages.register.aircraft.invalidFileFormat);
                return;
            }
        }

        formik.setFieldValue(formikField, filesList[0]);
    };

    const clearAircraftFile = (formikField) => {
        formik.setFieldValue(formikField, undefined);
    };

    return (
        <div className="padding--lg height--full stack--lg new-aircraft-document-wrapper">
            <form
                onSubmit={formik.handleSubmit}
                className="stack--lg height--full"
            >
                <div className="height--full stack--lg ">
                    <HeaderPage
                        title={
                            Locale.pages.register.aircraft.validationDocument
                        }
                        description={
                            Locale.pages.register.aircraft
                                .validationDocumentDescription
                        }
                        labelBtnBack={Locale.actions.back}
                        onClickBtnBack={() => {
                            const aircraftData = new Aircraft({
                                ...aircraft,
                                document: formik.values?.document,
                                image: formik.values?.image,
                            });
                            navigate('/newaircraft/generalinfo', {
                                state: {
                                    ...location.state,
                                    aircraft: aircraftData,
                                },
                            });
                        }}
                    />
                    <SelectedAircraftCard aircraft={aircraft} />
                    <div className="stack--md">
                        {formik.values?.isForeign && showForeignAircraftAlert && (
                            <div className="foreign-aircraft-alert">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <Image
                                        src={icoExclamationYellow}
                                        size={24}
                                        alt="ico-alert"
                                    />
                                    <p className="font-size--xs margin--x-md">
                                        {
                                            Locale.pages.register.aircraft
                                                .foreignAircraftAlert
                                        }
                                    </p>
                                </div>
                                <Image
                                    src={icoClose}
                                    size={16}
                                    alt="ico-close"
                                    className="cursor--pointer"
                                    onClick={() =>
                                        setShowForeignAircraftAlert(false)
                                    }
                                />
                            </div>
                        )}
                        <FileSection
                            title={
                                Locale.pages.register.aircraft
                                    .airworthnessCertificate
                            }
                            cardTitle={
                                formik.values?.document
                                    ? Locale.fields.aircraft.document.attached
                                    : Locale.fields.aircraft.document.attach
                            }
                            cardDescription={
                                formik.values?.document?.name ||
                                Locale.fields.aircraft.document.types
                            }
                            bottomSheetContent={{
                                title: Locale.pages.register.aircraft
                                    .airworthnessCertificate,
                                content: (
                                    <BottomSheetContent
                                        setShowVisible={
                                            setShowCertificateBottomSheet
                                        }
                                    />
                                ),
                                visible: showCertificateBottomSheet,
                                setVisible: setShowCertificateBottomSheet,
                            }}
                            id="aircraft-document"
                            file={formik.values?.document}
                            onChange={(e) =>
                                handleAircraftFileChange(e, 'document', [
                                    'pdf',
                                    'png',
                                    'jpeg',
                                    'jpg',
                                ])
                            }
                            clearFiles={() => clearAircraftFile('document')}
                            accept="application/pdf, image/png, image/jpeg, image/jpg"
                            successMessage={
                                Locale.fields.aircraft.document.success
                            }
                        />
                        <FileSection
                            title={Locale.pages.register.aircraft.aircraftImage}
                            cardTitle={
                                formik.values?.image
                                    ? Locale.fields.aircraft.image.attached
                                    : Locale.fields.aircraft.image.attach
                            }
                            cardDescription={
                                formik.values?.image?.name ||
                                Locale.fields.aircraft.image.types
                            }
                            cardAddIcon={icoCamera}
                            id="aircraft-image"
                            file={formik.values?.image}
                            onChange={(e) =>
                                handleAircraftFileChange(e, 'image', [
                                    'png',
                                    'jpeg',
                                    'jpg',
                                ])
                            }
                            clearFiles={() => clearAircraftFile('image')}
                            accept="image/png, image/jpeg, image/jpg"
                            successMessage={
                                Locale.fields.aircraft.image.success
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="width--full stack--md">
                        <Button
                            type="submit"
                            className="width--full"
                            disabled={
                                !formik.values?.document &&
                                !formik.values?.image
                            }
                        >
                            {Locale.actions.saveAndContinue}
                        </Button>
                        <Button
                            kind="outline"
                            className="width--full"
                            onClick={handleSkip}
                        >
                            {Locale.actions.sendLater}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AircraftDocuments;
