import Locale from 'locale';
import { icoWhatsappDuotone, icoExclamationSecondary } from 'assets/icons';
import { CardDirect, Image } from 'components';
import './styles.scss';
import PropTypes from 'prop-types';

function WarningCard({ className }) {
    return (
        <div
            className={`warning-card-wrapper flex--row padding--md flex-items--center ${className}`}
        >
            <Image
                size={24}
                src={icoExclamationSecondary}
                alt="ico-warning"
                className="margin--r-sm"
            />
            <p>{Locale.pages.home.whatsapp.warningMessage}</p>
        </div>
    );
}

WarningCard.propTypes = {
    className: PropTypes.string,
};

WarningCard.defaultProps = {
    className: '',
};

export default function WhatsappSection() {
    return (
        <div className="whatsapp-section-wrapper">
            <div className="flex--column stack--xs margin--b-md">
                <div className="stack--xs">
                    <p className="section-title">
                        {Locale.pages.home.whatsapp.title}
                    </p>
                    <p>{Locale.pages.home.whatsapp.description}</p>
                </div>
                <div className="stack--xs service-text">
                    <p className="fw-semibold margin--r-sm">
                        {Locale.pages.home.whatsapp.serviceTimeTitle}
                    </p>
                    <p>{Locale.pages.home.whatsapp.serviceTime}</p>
                </div>
                <WarningCard className="desktop-only margin--t-sm" />
            </div>
            <CardDirect
                icon={icoWhatsappDuotone}
                title={Locale.pages.home.whatsapp.card.title}
                description={Locale.pages.home.whatsapp.card.content}
                href="https://wa.me/5511915954100"
                target="_blank"
                className="whatsapp-card margin--b-md"
            />
            <WarningCard className="mobile-only" />
        </div>
    );
}
