import { Loading } from 'components';

function LoadingFullPage() {
    return (
        <div className="full-screen flex-items--center flex-justify--center fixed-top">
            <Loading />
        </div>
    );
}

export default LoadingFullPage;
