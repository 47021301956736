import { Image, Title, StickyFooter, Text } from 'components';
import PropTypes from 'prop-types';
import { icoClose, icoExpandLeft } from 'assets/icons';
import { useEffect } from 'react';
import theme from 'styles/themes/default';
import {
    Container,
    ModalHeader,
    CloseButton,
    ModalBody,
    Content,
    Backdrop,
} from './styles';

export default function FullPageModal({
    show,
    title,
    children,
    onClose,
    endPageButton,
    kind,
    ...args
}) {
    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => document.body.classList.remove('modal-open');
    }, [show]);

    return (
        <>
            <Backdrop show={show} onClick={onClose} />
            <Container show={show} kind={kind} {...args}>
                <ModalHeader>
                    <Content className="container-main">
                        {kind !== 'leftSide' ? (
                            <>
                                <Title
                                    color={theme.colors.grayscale.dark}
                                    tagName="h2"
                                >
                                    {title}
                                </Title>
                                <CloseButton onClick={onClose}>
                                    <Image
                                        src={icoClose}
                                        alt={`Close ${title}`}
                                    />
                                </CloseButton>
                            </>
                        ) : (
                            <div className="flex-justify--center">
                                <CloseButton
                                    className="margin--r-md"
                                    onClick={onClose}
                                >
                                    <Image
                                        src={icoExpandLeft}
                                        alt={`Close ${title}`}
                                    />
                                </CloseButton>
                                <Text
                                    kind="body-2"
                                    color={theme.colors.grayscale.steelGray}
                                >
                                    {title}
                                </Text>
                            </div>
                        )}
                    </Content>
                </ModalHeader>
                <ModalBody className="overflow-y--auto">
                    <Content className="container-main">{children}</Content>
                </ModalBody>
                {endPageButton && <StickyFooter>{endPageButton}</StickyFooter>}
            </Container>
        </>
    );
}

FullPageModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    endPageButton: PropTypes.node,
    kind: PropTypes.oneOf(['fullPage', 'rightSide', 'leftSide']),
};

FullPageModal.defaultProps = {
    endPageButton: undefined,
    kind: 'fullPage',
};
