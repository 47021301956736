import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    position: relative;

    label {
        text-transform: uppercase;
    }

    .negative-margin-before {
        margin-left: -10px;
    }

    /* DATEPICKER */
    .react-datepicker-popper {
        max-width: 20rem;
        margin: 0 !important;
        z-index: 9999 !important;
    }

    .react-datepicker.datepicker-custom {
        width: 100%;
        border: none;
        background-color: ${(props) => props.theme.colors.grayscale.white};
        border-radius: 0.2rem;
        border: 1px solid ${(props) => props.theme.colors.grayscale.lightGray};
        padding: 0.8rem 0.8rem 8px 0.8rem;
        display: flex;

        &.filled {
            border: none;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08),
                0px -1px 4px rgba(0, 0, 0, 0.04);
        }
    }

    .react-datepicker__time-container {
        width: 4.063rem;
        border: none;
        margin-top: 2.613rem;
    }

    .react-datepicker-time__header {
        padding-top: 0.5rem !important;
        padding-bottom: 1.2rem !important;
        font-size: 0.75rem !important;
    }

    .react-datepicker__time-box {
        width: 4.063rem !important;
    }

    .react-datepicker.datepicker-custom .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker.datepicker-custom .react-datepicker__navigation {
        width: 1rem;
        height: 1rem;
        border: none;
        top: 12px;
        position: absolute;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__navigation.react-datepicker__navigation--previous {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDRMOCAxMkwxNiAyMCIgc3Ryb2tlPSIjNjY2NjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
        background-size: 14px;
        background-position: center;
        left: 0.8rem;
        right: inherit;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__navigation.react-datepicker__navigation--next {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMjBMMTYgMTJMOCA0IiBzdHJva2U9IiM2NjY2NjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
        background-size: 14px;
        background-position: center;
        left: inherit;
        right: 0.8rem;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__navigation-icon--previous,
    .react-datepicker.datepicker-custom
        .react-datepicker__navigation-icon--next {
        &:before {
            display: none !important;
        }
    }

    .react-datepicker.datepicker-custom .react-datepicker__header {
        padding: 0;
        margin-bottom: 5px;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__day--keyboard-selected,
    .react-datepicker.datepicker-custom
        .react-datepicker__month-text--keyboard-selected,
    .react-datepicker.datepicker-custom
        .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker.datepicker-custom
        .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker.datepicker-custom .react-datepicker__current-month,
    .react-datepicker.datepicker-custom .react-datepicker-time__header,
    .react-datepicker.datepicker-custom .react-datepicker-year-header,
    .react-datepicker.datepicker-custom .react-datepicker__day-name,
    .react-datepicker.datepicker-custom .react-datepicker__day,
    .react-datepicker.datepicker-custom .react-datepicker__time-name {
        font-size: ${(props) => props.theme.fonts.sm};
        color: ${(props) => props.theme.colors.grayscale.dark};
        font-weight: 400;
        line-height: ${(props) => props.theme.line_height.xs};
        margin: 0;
        padding: 8px;
        border-radius: 0;
        flex: 1;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day-name {
        text-transform: uppercase;
        text-align: center;
        padding-top: 8px;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day--today {
        color: ${(props) => props.theme.colors.brand.primary};
        background-color: transparent;
        font-weight: 700;
    }

    .react-datepicker.datepicker-custom .react-datepicker__current-month {
        color: ${(props) => props.theme.colors.grayscale.dark};
        padding: 0;
        initial-letter: normal;
        text-align: center;
        margin-bottom: 8px;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__current-month::first-letter {
        text-transform: uppercase;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day--selected {
        width: 30px;
        height: 30px;
        background-color: ${(props) => props.theme.colors.brand.primaryLight};
        color: ${(props) => props.theme.colors.grayscale.white} !important;
        font-weight: 400;
        position: relative;
        border-radius: 50%;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day--disabled {
        color: ${(props) => props.theme.colors.grayscale.steelGray};
        opacity: 0.7;
    }

    .react-datepicker.datepicker-custom
        .react-datepicker__day--keyboard-selected {
        background: transparent;
    }

    .react-datepicker.datepicker-custom .react-datepicker__header {
        background-color: ${(props) => props.theme.colors.grayscale.white};
        border-color: ${(props) => props.theme.colors.grayscale.lightGray};
    }

    .react-datepicker.datepicker-custom .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker.datepicker-custom .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day-names,
    .react-datepicker.datepicker-custom .react-datepicker__week {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .react-datepicker.datepicker-custom .react-datepicker__day-names {
        margin-bottom: 0.4rem;

        .react-datepicker__day-name {
            padding: 4px 0 2px 0;
        }
    }

    .react-datepicker.datepicker-custom .react-datepicker__day--outside-month {
        visibility: hidden;
    }

    .react-datepicker__time-list {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .react-datepicker__time-list::-webkit-scrollbar {
        display: none;
    }

    .react-datepicker__time-list-item {
        color: ${(props) => props.theme.colors.grayscale.carbonoGray};
    }

    .react-datepicker__time-list-item--selected {
        background-color: ${(props) =>
            props.theme.colors.brand.primaryLight} !important;
        font-weight: bold !important;
    }

    .react-datepicker__day {
        width: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 575px) {
        .react-datepicker.datepicker-custom .react-datepicker__day-name {
            font-size: 0;
        }

        .react-datepicker.datepicker-custom
            .react-datepicker__day-name:first-letter {
            font-size: 0.75rem;
        }
    }
    /* /DATEPICKER */
`;
