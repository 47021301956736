import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
    HeaderPage,
    Card,
    ButtonIcon,
    Text,
    StickyFooter,
    Button,
    Image,
} from 'components';

import Locale from 'locale';
import { bytesToSize } from 'utils/functions';

import {
    icoPdfFile,
    icoHomeDuotone,
    icoCheckDuotone,
    icoExcalmationPrimary,
} from 'assets/icons';
import './styles.scss';

function Success() {
    const location = useLocation();
    const [documents, setDocuments] = useState([]);
    const [listDocuments, setListDocuments] = useState([]);

    useEffect(() => {
        setDocuments(location.state.documents);
    }, []);

    useEffect(() => {
        setListDocuments(
            documents.filter((item) => item.isAcceptanceRequired === false)
        );
    }, [documents]);

    return (
        <div className="success-page-wrapper padding--lg padding--b-xl">
            <Image src={icoCheckDuotone} size={56} alt="ico-check" />
            <HeaderPage title={Locale.pages.success.title} />
            <p className="description margin--t-lg margin--b-md">
                <b>{Locale.pages.success.description}</b>
            </p>
            <p className="margin--b-lg">{Locale.pages.success.emailMessage}</p>
            {listDocuments.length > 0 && (
                <Card kind="outlined" className="margin--b-lg">
                    <h3 className="headline-3 color--grayscale-blue-gray">
                        {Locale.pages.success.observation}
                    </h3>
                    {listDocuments.map((document) => (
                        <a
                            href={document.filepath}
                            target="_blank"
                            download
                            rel="noreferrer"
                        >
                            <div className="flex-items--center margin--t-lg">
                                <ButtonIcon icon={icoPdfFile} kind="outline" />
                                <div className="margin--l-sm flex-justify--center flex--column overflow--hidden">
                                    <p className="body-2 margin--b-xs text-truncate w-100">
                                        {document.name}
                                    </p>
                                    <Text kind="overline-1">
                                        {bytesToSize(document.filesize)}
                                    </Text>
                                </div>
                            </div>
                        </a>
                    ))}
                </Card>
            )}
            <div className="warning-card-wrapper flex--row padding--md flex-items--center">
                <Image
                    size={16}
                    src={icoExcalmationPrimary}
                    alt="ico-warning"
                    className="margin--r-sm"
                />
                <p>{Locale.pages.success.AISWebMessage}</p>
            </div>
            <StickyFooter>
                <Link to="/">
                    <Button icon={icoHomeDuotone} kind="outline" type="button">
                        {Locale.pages.success.backToHome}
                    </Button>
                </Link>
            </StickyFooter>
        </div>
    );
}

export default Success;
