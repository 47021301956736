import { ErrorHelper } from 'helpers';
import { Country } from 'models';
import { useState, useEffect } from 'react';
import Service from 'services';

export default function useCountryPhoneOptions() {
    const [countriesPhones, setCountriesPhones] = useState([]);
    const [loading, setLoading] = useState([]);

    const getCountriesPhones = () => {
        setLoading(true);
        Service.getCountriesPhones()
            .then((phoneList) => {
                setCountriesPhones(
                    Country.getCountriesPhonesOptions(phoneList)
                );
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getCountriesPhones();
    }, []);

    return [countriesPhones, loading];
}
