import { HeaderPage, Button, Loading, Steps, Card } from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { useState, useEffect } from 'react';
import { AircraftSolicitationStatus } from 'utils/enums';
import SolicitationGeneralInfo from './SolicitationGeneralInfo';
import { ValidateForms } from './Forms';

function AircraftSolicitationDetails() {
    const navigate = useNavigate();
    const location = useLocation();

    const { solicitationId } = useParams();
    const [aircraftSolicitation, setAircraftSolicitation] = useState();
    const [loading, setLoading] = useState(false);
    const [stepActive, setStepActive] = useState(1);
    const [stepStatus, setStepStatus] = useState('pending');

    const getAircraftSolicitation = () => {
        setLoading(true);
        Service.retrieveSolicitation(solicitationId)
            .then((response) => {
                setLoading(false);
                setAircraftSolicitation(response);
            })
            .catch((error) => {
                setLoading(false);
                ErrorHelper.notifyError(error);
            });
    };

    const getValidatedSolicitationStepTitle = () => {
        switch (aircraftSolicitation?.status) {
            case AircraftSolicitationStatus.approvedValidation:
                return Locale.pages.backoffice.solicitations.details.approved;
            case AircraftSolicitationStatus.disapprovedValidation:
                return Locale.pages.backoffice.solicitations.details
                    .disapproved;
            default:
                return Locale.pages.backoffice.solicitations.details
                    .approvedDisapproved;
        }
    };

    const steps = [
        {
            label: Locale.pages.backoffice.solicitations.details
                .newSolicitation,
        },
        {
            label: Locale.pages.backoffice.solicitations.details.validation,
            content: (
                <ValidateForms
                    loadData={getAircraftSolicitation}
                    aircraftSolicitation={aircraftSolicitation}
                />
            ),
        },
        {
            label: getValidatedSolicitationStepTitle(),
        },
    ];

    const handleGoTo = (target) => {
        document.getElementById(target)?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        getAircraftSolicitation();
    }, []);

    useEffect(() => {
        if (aircraftSolicitation?.status) {
            switch (aircraftSolicitation.status) {
                case AircraftSolicitationStatus.incompleteRegistration:
                    setStepActive(1);
                    setStepStatus('pending');
                    break;
                case AircraftSolicitationStatus.pendingValidation:
                    setStepActive(2);
                    setStepStatus('pending');
                    break;
                case AircraftSolicitationStatus.approvedValidation:
                case AircraftSolicitationStatus.disapprovedValidation:
                    setStepActive(3);
                    setStepStatus('success');
                    break;
                default:
                    break;
            }
        }
    }, [aircraftSolicitation]);

    return !loading ? (
        <div className="padding--lg scroll-margin-top" id="wrapperPage">
            <HeaderPage
                onClickBtnBack={() =>
                    navigate('/backoffice/solicitations', {
                        state: location.state,
                    })
                }
                labelBtnBack={`${aircraftSolicitation?.aircraft?.registration} ${aircraftSolicitation?.aircraft?.icaoModel}`}
                contentInfoFirst={
                    <Button
                        kind="outline"
                        onClick={() => handleGoTo('wrapperInfo')}
                        className="btn-more-info"
                    >
                        {Locale.actions.moreInformation}
                    </Button>
                }
            />
            <Card kind="outlined" className="margin--b-xl">
                <Steps
                    items={steps}
                    stepActive={stepActive}
                    stepStatus={stepStatus}
                />
            </Card>
            <div id="wrapperInfo" className="scroll-margin-top wrapper-info">
                <SolicitationGeneralInfo
                    aircraftSolicitation={aircraftSolicitation}
                />
                <div className="row padding--t-md">
                    <div className="col-12 flex-justify--end">
                        <Button
                            kind="outline"
                            float
                            onClick={() => handleGoTo('wrapperPage')}
                        >
                            {Locale.actions.backToTheTop}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="full-screen flex-items--center flex-justify--center fixed-top">
            <Loading />
        </div>
    );
}

export default AircraftSolicitationDetails;
