import styled from 'styled-components';

export const Container = styled.button`
    padding: 0.813rem
        ${(props) => (props.isLoading ? props.theme.spacing.sm : '20px')};
    border-radius: 4px;
    font-size: ${(props) => props.theme.fonts.sm};
    line-height: 1rem;
    font-weight: ${(props) => props.theme.font_weights.semibold};
    color: ${(props) => props.theme.colors.brand.primary};
    text-transform: uppercase;
    letter-spacing: ${(props) => props.theme.fonts.ls};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;

    img {
        margin-right: ${(props) => props.theme.spacing.sm};
    }

    ${(props) =>
        props.block &&
        `
        width: 100%;
    `};

    ${(props) =>
        props.large &&
        `
        padding: 1.125rem ${props.theme.spacing.md};
        font-size: ${props.theme.fonts.md};
        border-radius: 0;
    `};

    ${(props) =>
        props.disabled &&
        `
        cursor: not-allowed;
    `};

    ${(props) =>
        props.isLoading &&
        `
        transform: 0;
    `};
    /* PRIMARY */
    ${(props) =>
        props.kind === 'primary' &&
        `
        background: ${props.theme.colors.brand.primary};
        color: ${props.theme.colors.grayscale.white};
        border: 1px solid ${props.theme.colors.brand.primary};

        &:active {
            background: ${props.theme.colors.brand.primaryDark};
        }

        ${
            props.disabled === true &&
            `
            background: ${props.theme.colors.grayscale.steelGray};
            border-color: ${props.theme.colors.grayscale.steelGray};

            &:active {
                background: ${props.theme.colors.grayscale.steelGray};
            }
            `
        };
    `};

    ${(props) =>
        props.kind === 'outline' &&
        `
        border: 1px solid ${props.theme.colors.grayscale.paleShaleGray};
        background: ${props.theme.colors.grayscale.white};

        &:active {
            background: ${props.theme.colors.grayscale.light};
            border-color: ${props.theme.colors.grayscale.light};
        }

        ${
            props.disabled === true &&
            `
            color: ${props.theme.colors.grayscale.steelGray};

            &:active {
                background: ${props.theme.colors.grayscale.white};
            }
          `
        };
    `};

    ${(props) =>
        props.kind === 'text' &&
        `
            border-color: transparent;
            background-color: transparent;

            &:active {
                background-color: ${props.theme.colors.grayscale.light};
            }

            ${
                props.disabled === true &&
                `
                color: ${props.theme.colors.grayscale.steelGray};

                &:active {
                    background-color: ${props.theme.colors.grayscale.white};
                }
                `
            };

            ${
                props.large === true &&
                `
                border: none;
                border-radius: 0;
                border-top: 1px solid ${props.theme.colors.grayscale.lightGray};
            `
            };

            ${
                props.large === true &&
                props.disabled === true &&
                `
                color: ${props.theme.colors.grayscale.steelGray};
                `
            };
        `};

    ${(props) =>
        props.kind === 'alert' &&
        `
        background: ${props.theme.colors.support.error};
        color: ${props.theme.colors.grayscale.white};
        border: 1px solid ${props.theme.colors.support.error};

        &:active {
            background: ${props.theme.colors.support.timelineUrgent};
        }

        ${
            props.disabled === true &&
            `
            background: ${props.theme.colors.grayscale.steelGray};
            border-color: ${props.theme.colors.grayscale.steelGray};

            &:active {
                background: ${props.theme.colors.grayscale.steelGray};
            }
            `
        };
    `};

    ${(props) =>
        props.float === true &&
        `
        border-radius: 50px;
        border: none;
        box-shadow: 0px 4px 12px #D2D3DF;
        `};
`;
