import React from 'react';
import PropTypes from 'prop-types';

import { Container, Before, After } from './styles';

function Chip({
    before,
    activated,
    disabled,
    children,
    onClick,
    onClickCloseButton,
    ...args
}) {
    return (
        <Container
            type="button"
            onClick={onClick}
            activated={activated && !disabled}
            disabled={disabled}
            {...args}
        >
            {before && <Before>{before}</Before>}
            {children}
            {onClickCloseButton && (
                <After
                    activated={activated}
                    disabled={disabled}
                    onClick={onClickCloseButton && onClickCloseButton}
                />
            )}
        </Container>
    );
}

Chip.propTypes = {
    before: PropTypes.node || PropTypes.element,
    activated: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    onClickCloseButton: PropTypes.func,
};

Chip.defaultProps = {
    before: undefined,
    activated: false,
    disabled: false,
    onClick: undefined,
    onClickCloseButton: undefined,
};

export default Chip;
