import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function StickyFooter({ children, kind }) {
    const stylizedWrapperClassname = `wrapper__${kind}`;
    return (
        <div className={styles[stylizedWrapperClassname]}>
            <div className={styles.container}>{children}</div>
        </div>
    );
}

StickyFooter.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    kind: PropTypes.string,
};

StickyFooter.defaultProps = {
    kind: 'default',
};

export default StickyFooter;
