import {
    Button,
    Checkbox,
    Chip,
    DatePickerRange,
    FullPageModal,
    HeaderPage,
    Image,
    Input,
    Multiselect,
    Radio,
    Table,
} from 'components';
import { useState, useMemo, useEffect } from 'react';
import Locale from 'locale';
import { useFormik } from 'formik';
import './styles.scss';
import { icoClose, icoSearch } from 'assets/icons';
import {
    formatDateToTable,
    parseOrderingToBackend,
    formatDateToTableOrDefault,
} from 'components/Table';
import Service from 'services';
import { ErrorHelper, FilterHelper } from 'helpers';
import {
    DeleteParams,
    getReservationStatus,
    OrderingParams,
    PaginationParams,
    ReservationStatus,
} from 'utils/enums';
import { scrollToTop } from 'utils/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Airport, Reservation } from 'models';
import PropTypes from 'prop-types';
import moment from 'moment';

const noFilters = {
    search: '',
    status: {
        pendingReview: false,
        changeRequested: false,
        pendingBilling: false,
        pendingPayment: false,
        processingPayment: false,
        paymentConfirmed: false,
        completed: false,
        cancelled: false,
        denied: false,
    },
    airports: [],
    showFlights: null,
    originFlightDatetime: {
        startDate: null,
        endDate: null,
    },
    destinationFlightDatetime: {
        startDate: null,
        endDate: null,
    },
};

const showFlights = {
    landingAndTakeOff: 'landingAndTakeOff',
    onlyLanding: 'onlyLanding',
};

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [reservations, setReservations] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shouldReload, setShouldReload] = useState(false);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [currentOrdering, setCurrentOrdering] = useState('-id');

    const [filters, setFilters] = useState(noFilters);
    const [loading, setLoading] = useState(true);

    const redirectToDetails = (reservationId) => {
        navigate(`/backoffice/reservation/${reservationId}`, {
            state: { filters },
        });
    };

    const formatStatus = ({ value }) => {
        const statusName = getReservationStatus(value);
        return (
            <Chip className={`chip-status ${statusName}`}>
                {Locale.status[statusName]}
            </Chip>
        );
    };

    const formatNature = ({ value }) => {
        const title = value
            ? Locale.fields.reservation.international
            : Locale.fields.reservation.domestic;
        return <div title={title}>{value ? 'I' : 'D'}</div>;
    };

    const formatAirport = ({ value }) => {
        if (!value) return Locale.fields.notInformed;
        const icaoCode = value.icaoCode || Locale.fields.reservation.noICAO;
        return <div title={value.icaoName}>{icaoCode}</div>;
    };

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                className: 'column-id',
                sortBy: Reservation.orderingParams.id,
            },
            {
                Header: Locale.fields.reservation.title,
                accessor: 'createdAt',
                Cell: formatDateToTable,
                className: 'column-created-at',
                sortBy: Reservation.orderingParams.createdAt,
            },
            {
                Header: Locale.fields.destination,
                accessor: 'airportTarget',
                Cell: formatAirport,
                className: 'column-airport',
                sortBy: Reservation.orderingParams.airportTarget,
            },
            {
                Header: Locale.fields.reservation.landing,
                accessor: 'originFlight.datetime',
                Cell: formatDateToTable,
                className: 'column-landing',
                sortBy: Reservation.orderingParams.landingDate,
            },
            {
                Header: Locale.fields.reservation.origin,
                accessor: 'originFlight.airport',
                Cell: formatAirport,
                className: 'column-origin',
                sortBy: Reservation.orderingParams.originAirport,
            },
            {
                Header: Locale.fields.reservation.nextDestination,
                accessor: 'destinationFlight.airport',
                Cell: formatAirport,
                className: 'column-destination',
                sortBy: Reservation.orderingParams.destinationAirport,
            },
            {
                Header: Locale.fields.reservation.takeOff,
                accessor: 'destinationFlight.datetime',
                Cell: formatDateToTableOrDefault(''),
                className: 'column-destination-datetime',
                sortBy: Reservation.orderingParams.destinationFlightDatetime,
            },
            {
                Header: Locale.fields.aircraft.registration,
                accessor: 'aircraft.registration',
                className: 'column-registration',
                sortBy: Reservation.orderingParams.aircraftRegistration,
            },
            {
                Header: 'N',
                accessor: 'aircraft.isForeign',
                Cell: formatNature,
                className: 'column-nature',
                title: Locale.fields.reservation.nature,
                sortBy: Reservation.orderingParams.aircraftIsForeign,
            },
            // TODO: obter/armazenar metodo de pagamento do serviço
            // {
            //     Header: Locale.fields.payment.title,
            //     Cell: valueOrDefault('-'),
            //     accessor: 'paymentMethod',
            //     className: 'column-payment',
            //     disableSortBy: true,
            // },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: formatStatus,
                className: 'column-status',
                sortBy: Reservation.orderingParams.status,
            },
        ],
        []
    );

    const parseFilters = () => {
        const filterStatus = FilterHelper.stringifyObjectOfBooleans(
            filters.status,
            (key) => ReservationStatus[key]
        );
        const filterAirports = FilterHelper.stringifyArray(
            filters.airports,
            'id'
        );
        const filterHasDestination =
            filters.showFlights === showFlights.landingAndTakeOff;
        const filterOriginFlightAfter = FilterHelper.parseDateField(
            filters.originFlightDatetime.startDate
        );
        const filterOriginFlightBefore = FilterHelper.parseDateField(
            filters.originFlightDatetime.endDate
        );
        const filterDestinationFlightAfter = FilterHelper.parseDateField(
            filters.destinationFlightDatetime?.startDate
        );
        const filterDestinationFlightBefore = FilterHelper.parseDateField(
            filters.destinationFlightDatetime?.endDate
        );

        return {
            ...(filters.search && {
                [Reservation.filterParams.search]: filters.search,
            }),
            ...(filterStatus && {
                [Reservation.filterParams.status]: filterStatus,
            }),
            ...(filterAirports && {
                [Reservation.filterParams.airport]: filterAirports,
            }),
            ...(filters.showFlights && {
                [Reservation.filterParams.hasDestination]: filterHasDestination,
            }),
            ...(filterOriginFlightAfter && {
                [Reservation.filterParams.startDate]: filterOriginFlightAfter,
            }),
            ...(filterOriginFlightBefore && {
                [Reservation.filterParams.endDate]: filterOriginFlightBefore,
            }),
            ...(filterDestinationFlightAfter && {
                [Reservation.filterParams.destinationFlightStartDate]:
                    filterDestinationFlightAfter,
            }),
            ...(filterDestinationFlightBefore && {
                [Reservation.filterParams.destinationFlightEndDate]:
                    filterDestinationFlightBefore,
            }),
        };
    };

    const getReservations = () => {
        Service.listAllLandingRequests({
            [PaginationParams.page]: currentPage,
            [PaginationParams.pageSize]: currentPageSize,
            [OrderingParams.ordering]: currentOrdering,
            [Reservation.filterParams.structured]: 'aircraft',
            ...parseFilters(),
        })
            .then((response) => {
                setTotalCount(response.count);
                setTotalPages(response.totalPages);
                const reservationsResponse = Reservation.createListFromResponse(
                    response.results
                );
                setReservations(reservationsResponse);
                setLoading(false);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        if (shouldReload) {
            scrollToTop();
            getReservations();
            setShouldReload(false);
        }
    }, [shouldReload]);

    useEffect(() => {
        setShouldReload(true);
    }, [currentPage, currentPageSize]);

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            setShouldReload(true);
        }
        setSearchParams({
            target_airports: FilterHelper.stringifyArray(
                filters.airports,
                'id'
            ),
            target_airport_codes: FilterHelper.stringifyArray(
                filters.airports,
                'icaoCode'
            ),
            ...parseFilters(),
        });
    }, [currentOrdering, filters]);

    useEffect(() => {
        setFilters(location.state?.filters || getPreviousFilters());
        setShouldReload(false);
    }, []);

    const getPreviousFilters = () => {
        const status = searchParams.get('status')?.split(',') || [];
        let airports = [];
        if (searchParams.get('target_airports') !== '') {
            const airportIds =
                searchParams.get('target_airports')?.split(',') || [];
            const airpotCodes =
                searchParams.get('target_airport_codes')?.split(',') || [];
            airports = airportIds.map((airportId, idx) => ({
                id: airportId,
                icaoCode: airpotCodes[idx],
            }));
        }
        const originFlightDatetime = {
            startDate: moment(
                searchParams.get('origin_flight_range_after')
            ).isValid()
                ? moment(searchParams.get('origin_flight_range_after')).toDate()
                : null,
            endDate: moment(
                searchParams.get('origin_flight_range_before')
            ).isValid()
                ? moment(
                      searchParams.get('origin_flight_range_before')
                  ).toDate()
                : null,
        };
        const destinationFlightDatetime = {
            startDate: moment(
                searchParams.get('destination_flight_range_after')
            ).isValid()
                ? moment(
                      searchParams.get('destination_flight_range_after')
                  ).toDate()
                : null,
            endDate: moment(
                searchParams.get('destination_flight_range_before')
            ).isValid()
                ? moment(
                      searchParams.get('destination_flight_range_before')
                  ).toDate()
                : null,
        };
        let previousShowFlights = null;
        if (searchParams.get('has_destination') !== null) {
            previousShowFlights =
                searchParams.get('has_destination') === 'true'
                    ? showFlights.landingAndTakeOff
                    : showFlights.onlyLanding;
        }

        const previousStatus = Object.fromEntries(
            Object.entries(ReservationStatus).map(
                ([reservationStatus, statusId]) => [
                    reservationStatus,
                    status.includes(String(statusId)),
                ]
            )
        );

        const previousFilters = {
            search: searchParams.get('search'),
            status: previousStatus,
            airports,
            showFlights: previousShowFlights,
            originFlightDatetime,
            destinationFlightDatetime,
        };
        return previousFilters;
    };

    const onSort = (newOrdering) => {
        setCurrentOrdering(parseOrderingToBackend(newOrdering));
    };

    const onPageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setCurrentPageSize(newPageSize);
    };

    const showableFilters = () => {
        const statusValue = FilterHelper.stringifyObjectOfBooleans(
            filters.status,
            (key) => Locale.status[key],
            ', '
        );
        const airportsValue = FilterHelper.stringifyArray(
            filters.airports,
            'icaoCode',
            ', '
        );
        const showFlightsValue =
            Locale.pages.backoffice.home.filters[filters.showFlights];

        return [
            ...(statusValue
                ? [{ name: 'Status', value: statusValue, key: 'status' }]
                : []),
            ...(airportsValue
                ? [
                      {
                          name: Locale.fields.destination,
                          value: airportsValue,
                          key: 'airports',
                      },
                  ]
                : []),
            ...(showFlightsValue
                ? [{ name: '', value: showFlightsValue, key: 'showFlights' }]
                : []),
        ];
    };

    return (
        <div className="stack--lg width--full padding--lg">
            <div className="stack--sm">
                <HeaderPage title={Locale.pages.backoffice.home.title} />
                <HeaderFilters setFilters={setFilters} filters={filters} />
            </div>
            <div className="flex-justify--between">
                <div className="body-2 color--grayscale-steel-gray text-transform--lowercase flex-items--center">
                    {totalCount} {Locale.general.result}
                    {totalCount !== 1 && 's'}
                </div>
                <div className="flex-wrap inline--sm">
                    {showableFilters().map((filter) => (
                        <Chip
                            key={filter.name || filter.value}
                            onClickCloseButton={() =>
                                setFilters({
                                    ...filters,
                                    [filter.key]: noFilters[filter.key],
                                })
                            }
                        >
                            <div>
                                {filter.name && <b>{`${filter.name}: `}</b>}
                                {filter.value}
                            </div>
                        </Chip>
                    ))}
                </div>
            </div>
            <Table
                data={reservations}
                columns={columns}
                onSort={onSort}
                onPageChange={onPageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                loading={loading}
                rowClick={redirectToDetails}
            />
        </div>
    );
}

function HeaderFilters({ setFilters, filters }) {
    const [showModal, setShowModal] = useState(false);
    const formik = useFormik({
        initialValues: {
            search: filters.search,
            originFlightDatetime: filters.originFlightDatetime,
            destinationFlightDatetime: filters.destinationFlightDatetime,
        },
    });

    useEffect(() => {
        formik.setValues(filters);
    }, [filters]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilters({
                ...filters,
                search: formik.values.search,
            });
        }
    };

    const clearSearch = () => {
        formik.setFieldValue('search', '');
        setFilters({ ...filters, search: '' });
    };

    return (
        <>
            <FilterOptions
                show={showModal}
                onClose={() => setShowModal(false)}
                setFilters={setFilters}
                filters={filters}
            />
            <div className="flex-justify--between width--full">
                <div className="inline--lg flex--1">
                    <div className="search-input">
                        <Input
                            label={Locale.actions.search}
                            name="search"
                            placeholder={Locale.pages.backoffice.home.search}
                            value={formik.values.search}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyDown}
                            before={
                                <Image
                                    src={icoSearch}
                                    alt={Locale.actions.search}
                                />
                            }
                            after={
                                <Image
                                    className="cursor--pointer"
                                    onClick={clearSearch}
                                    src={icoClose}
                                    alt=""
                                    size={16}
                                />
                            }
                        />
                    </div>
                    <DatePickerRange
                        name="range"
                        id="range"
                        label={Locale.fields.reservation.landingDate}
                        startDate={formik.values.originFlightDatetime.startDate}
                        endDate={formik.values.originFlightDatetime.endDate}
                        onChange={(dates) => {
                            const startDate = dates[0];
                            const endDate = dates[1];
                            formik.setFieldValue(
                                'originFlightDatetime.startDate',
                                startDate
                            );
                            formik.setFieldValue(
                                'originFlightDatetime.endDate',
                                endDate
                            );
                            setFilters({
                                ...filters,
                                originFlightDatetime: {
                                    startDate,
                                    endDate,
                                },
                            });
                        }}
                    />
                    <DatePickerRange
                        name="destination_flight_datetime_range"
                        id="destination_flight_datetime_range"
                        label={Locale.fields.reservation.takeOffDate}
                        startDate={
                            formik.values.destinationFlightDatetime?.startDate
                        }
                        endDate={
                            formik.values.destinationFlightDatetime?.endDate
                        }
                        onChange={(dates) => {
                            const startDate = dates[0];
                            const endDate = dates[1];
                            formik.setFieldValue(
                                'destinationFlightDatetime.startDate',
                                startDate
                            );
                            formik.setFieldValue(
                                'destinationFlightDatetime.endDate',
                                endDate
                            );
                            setFilters({
                                ...filters,
                                destinationFlightDatetime: {
                                    startDate,
                                    endDate,
                                },
                            });
                        }}
                    />
                </div>
                <div className="flex-items--end margin--l-lg">
                    <Button kind="outline" onClick={() => setShowModal(true)}>
                        {Locale.fields.filters}
                    </Button>
                </div>
            </div>
        </>
    );
}

HeaderFilters.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};

function FilterOptions({ show, onClose, setFilters, filters }) {
    const [airports, setAirports] = useState(new Map());
    const [timeoutFilter, setTimeoutFilter] = useState();

    const getAirports = (search) => {
        Service.listAirports({
            [PaginationParams.page]: 1,
            [PaginationParams.pageSize]: 50,
            [Airport.filterParams.search]: search,
            [Airport.filterParams.hasICAO]: true,
            [Airport.filterParams.isManagedByCcr]: true,
            [DeleteParams.showDeleted]: true,
            [OrderingParams.ordering]: OrderingParams.deletedLast,
        })
            .then((response) => {
                const airportList = Airport.createListFromResponse(
                    response.results
                );
                const labelDeleted = ` (${Locale.general.deleted.toLowerCase()})`;
                setAirports(
                    new Map(
                        airportList.map((airport) => [
                            airport.id.toString(),
                            {
                                ...airport,
                                label: `${airport.icaoCode}${
                                    airport.deleted ? labelDeleted : ''
                                }`,
                                value: airport.id,
                            },
                        ])
                    )
                );
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        getAirports('');
    }, []);

    const formik = useFormik({
        initialValues: filters,
        onSubmit: (values) => {
            setFilters(values);
            onClose();
        },
    });

    useEffect(() => {
        formik.setValues(filters);
    }, [show]);

    const onInputChange = (input) => {
        clearTimeout(timeoutFilter);
        setTimeoutFilter(setTimeout(() => getAirports(input), 300));
    };

    return (
        <FullPageModal
            kind="rightSide"
            show={show}
            title={Locale.fields.filters}
            onClose={onClose}
            endPageButton={
                <>
                    <Button
                        kind="outline"
                        onClick={() => {
                            setFilters(noFilters);
                            onClose();
                        }}
                    >
                        {Locale.actions.removeFilters}
                    </Button>
                    <Button
                        type="submit"
                        onClick={(e) => {
                            formik.handleSubmit(e);
                            onClose();
                        }}
                    >
                        {Locale.actions.filter}
                    </Button>
                </>
            }
        >
            <div className="stack--xl padding-bottom-sticky-footer">
                <div className="stack--md">
                    <div className="headline-5">Status</div>
                    {Object.keys(ReservationStatus).map((key) => (
                        <Checkbox
                            key={`status.${key}`}
                            id={`status.${key}`}
                            name={`status.${key}`}
                            label={Locale.status[key]}
                            checked={formik.values.status[key]}
                            onChange={formik.handleChange}
                        />
                    ))}
                </div>
                <div className="stack--md">
                    <div className="headline-5">
                        {Locale.fields.destination}
                    </div>
                    <Multiselect
                        menuPlacement="top"
                        options={Array.from(airports.values())}
                        value={formik.values.airports}
                        onChange={(value) =>
                            formik.setFieldValue('airports', value)
                        }
                        onInputChange={onInputChange}
                    />
                </div>
                <div className="stack--md">
                    <div className="headline-5">
                        {Locale.pages.backoffice.home.filters.showFlights}
                    </div>
                    <Radio
                        id={showFlights.landingAndTakeOff}
                        name="showFlights"
                        label={
                            Locale.pages.backoffice.home.filters
                                .landingAndTakeOff
                        }
                        value={showFlights.landingAndTakeOff}
                        checked={
                            formik.values.showFlights ===
                            showFlights.landingAndTakeOff
                        }
                        onChange={formik.handleChange}
                    />
                    <Radio
                        id={showFlights.onlyLanding}
                        name="showFlights"
                        label={Locale.pages.backoffice.home.filters.onlyLanding}
                        value={showFlights.onlyLanding}
                        checked={
                            formik.values.showFlights ===
                            showFlights.onlyLanding
                        }
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
        </FullPageModal>
    );
}

FilterOptions.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};
