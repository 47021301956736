import {
    FullPageModal,
    Input,
    PhoneNumberInput,
    Button,
    ConfirmDialog,
    Dropdown,
} from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';
import Service from 'services';
import { Pilot } from 'models';
import { useState } from 'react';
import { useCountryPhoneOptions } from 'states';

export default function PilotDetailModal({
    show,
    onClose,
    editPilot,
    updateList,
    setCreatedPilot,
}) {
    const newOrEdit = editPilot ? 'edit' : 'new';
    const { validationSchema } = Pilot;
    const emptyValues = { ...Pilot.createEmpty() };
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const createPilot = (values, resetForm) => {
        setIsLoadingSave(true);
        const pilot = new Pilot(values);
        Service.registerPilot(pilot)
            .then((response) => {
                if (typeof setCreatedPilot === 'function') {
                    setCreatedPilot(Pilot.createFromResponse(response));
                }
                resetForm();
                notify(Locale.pages.accountSettings.pilots.createSuccess);
                updateList();
                onClose();
                setIsLoadingSave(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setIsLoadingSave(false);
            });
    };

    const patchPilot = (values) => {
        setIsLoadingSave(true);
        const pilot = new Pilot(values);
        Service.patchPilot(pilot)
            .then(() => {
                notify(Locale.pages.accountSettings.pilots.editSuccess);
                updateList();
                onClose();
                setIsLoadingSave(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setIsLoadingSave(false);
            });
    };

    const deletePilot = () => {
        setIsLoadingDelete(true);
        Service.deletePilot(editPilot.id)
            .then(() => {
                setIsLoadingDelete(false);
                setShowDeleteDialog(false);
                notify(Locale.pages.accountSettings.pilots.deleteSuccess);
                updateList();
                onClose();
            })
            .catch((error) => {
                setIsLoadingDelete(false);
                setShowDeleteDialog(false);
                ErrorHelper.notifyError(error);
            });
    };

    const formik = useFormik({
        initialValues: editPilot || emptyValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (editPilot) {
                patchPilot(values);
            } else {
                createPilot(values, resetForm);
            }
        },
    });

    return (
        <FullPageModal
            show={show}
            title={Locale.pages.accountSettings.pilots[newOrEdit]}
            onClose={() => {
                formik.resetForm();
                onClose();
            }}
            endPageButton={
                <Button
                    type="submit"
                    onClick={formik.handleSubmit}
                    isLoading={isLoadingSave}
                >
                    {Locale.actions.save}
                </Button>
            }
        >
            <div className="col-12 col-md-6">
                <Input
                    type="text"
                    label={Locale.fields.fullName}
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    required
                />
            </div>
            <div className="margin--t-md">
                <div className="row">
                    <div className="col-4 col-md-2">
                        <Dropdown
                            id="countryPhoneCode"
                            name="countryPhoneCode"
                            options={countriesPhones}
                            label={Locale.fields.countryCode}
                            onChange={formik.handleChange}
                            value={formik.values.countryPhoneCode}
                            error={
                                formik.touched?.countryPhoneCode &&
                                formik.errors?.countryPhoneCode
                            }
                            onBlur={formik.handleBlur}
                            required
                            autocomplete
                            loading={loadingCountriesPhones}
                        />
                    </div>
                    <div className="col-8 col-md-4">
                        <PhoneNumberInput
                            label={Locale.fields.phone}
                            id="phoneNumber"
                            name="phoneNumber"
                            maxLength={15}
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            error={
                                formik.touched.phoneNumber &&
                                formik.errors.phoneNumber
                            }
                            onBlur={formik.handleBlur}
                            required
                            countryPhoneCode={formik.values.countryPhoneCode}
                        />
                    </div>
                </div>
            </div>
            {editPilot && (
                <div className="margin--t-md width--full flex-justify--center">
                    <Button
                        kind="text"
                        block
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        {Locale.pages.accountSettings.pilots.delete}
                    </Button>
                </div>
            )}

            <ConfirmDialog
                title={Locale.actions.deletePilot}
                description={
                    Locale.pages.accountSettings.pilots.textDeleteConfirm
                }
                show={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                actionsButton={
                    <>
                        <Button
                            kind="outline"
                            type="button"
                            className="margin--r-md"
                            onClick={() => setShowDeleteDialog(false)}
                        >
                            {Locale.actions.cancel}
                        </Button>
                        <Button
                            type="button"
                            kind="primary"
                            onClick={deletePilot}
                            isLoading={isLoadingDelete}
                        >
                            <p>{Locale.actions.delete}</p>
                        </Button>
                    </>
                }
            />
        </FullPageModal>
    );
}

PilotDetailModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editPilot: PropTypes.instanceOf(Pilot),
    updateList: PropTypes.func.isRequired,
    setCreatedPilot: PropTypes.func,
};

PilotDetailModal.defaultProps = {
    editPilot: undefined,
    setCreatedPilot: undefined,
};
