import { useFormik } from 'formik';
import * as Yup from 'yup';
import Locale from 'locale';
import {
    HeaderPage,
    Radio,
    Text,
    Textarea,
    Title,
    StickyFooter,
    Button,
} from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import theme from 'styles/themes/default';
import Service from 'services';
import { useState, useEffect } from 'react';
import { StatusDetails } from 'models';
import notify from 'components/Toast';
import LocalTimeModal from 'features/frontend/LocalTimeModal';

export default function RequestChanges() {
    const navigate = useNavigate();
    const { reservationId } = useParams();
    const [dateSuggestions, setDateSuggestions] = useState();
    const location = useLocation();
    const dontSuit = 'dont suit';
    const [showLocalTimeModal, setShowLocalTimeModal] = useState(false);

    const initialValues = {
        newDatetimeSuggestion: null,
        observation: '',
    };

    const validationSchema = Yup.object().shape({
        newDatetimeSuggestion: Yup.string().required(Locale.errors.required),
        observation: Yup.string().when('newDatetimeSuggestion', {
            is: (newDatetimeSuggestion) => newDatetimeSuggestion === dontSuit,
            then: Yup.string().required(Locale.errors.required),
        }),
    });

    const navigateAndNotify = () => {
        navigate(`/reservation/${reservationId}`);
        notify(Locale.status.description.current.pendingReview);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (data) => {
            if (data.newDatetimeSuggestion === dontSuit) {
                Service.refuseChange(reservationId, {
                    observation: data.observation,
                }).then(() => navigateAndNotify());
            } else {
                Service.acceptChange(reservationId, {
                    datetime: data.newDatetimeSuggestion,
                }).then(() => navigateAndNotify());
            }
        },
    });

    useEffect(() => {
        if (!location.state) {
            navigate(`/reservation/${reservationId}`);
            notify(Locale.status.noChangesRequested);
        } else {
            setDateSuggestions(new StatusDetails(location.state.statusDetails));
        }
    }, []);

    return (
        <>
            <LocalTimeModal
                show={showLocalTimeModal}
                onClose={() => setShowLocalTimeModal(false)}
            />
            <form
                className="padding--lg stack--md padding-bottom-sticky-footer"
                onSubmit={formik.handleSubmit}
            >
                <HeaderPage
                    title={Locale.pages.reservationDetail.dateOptions}
                    labelBtnBack={Locale.pages.reservationDetail.title}
                    onClickBtnBack={() =>
                        navigate(`/reservation/${reservationId}`)
                    }
                />
                <Text
                    kind="subtitle-1"
                    color={theme.colors.grayscale.carbonoGray}
                >
                    {Locale.pages.reservationDetail.requestChangeSubtitle}
                </Text>
                {(dateSuggestions?.dateSuggestion1 ||
                    dateSuggestions?.dateSuggestion2) && (
                    <>
                        <Title tagName="h4">
                            {Locale.fields.reservation.landingDate}
                        </Title>
                        <Radio
                            id="date1"
                            name="newDatetimeSuggestion"
                            label={dateSuggestions?.dateSuggestion1Formatted}
                            value={dateSuggestions?.dateSuggestion1Formatted}
                            checked={
                                formik.values.newDatetimeSuggestion ===
                                dateSuggestions?.dateSuggestion1
                            }
                            onChange={() =>
                                formik.setFieldValue(
                                    'newDatetimeSuggestion',
                                    dateSuggestions?.dateSuggestion1
                                )
                            }
                        />
                        {dateSuggestions.dateSuggestion2 && (
                            <Radio
                                id="date2"
                                name="newDatetimeSuggestion"
                                label={
                                    dateSuggestions?.dateSuggestion2Formatted
                                }
                                value={
                                    dateSuggestions?.dateSuggestion2Formatted
                                }
                                checked={
                                    formik.values.newDatetimeSuggestion ===
                                    dateSuggestions?.dateSuggestion2
                                }
                                onChange={() =>
                                    formik.setFieldValue(
                                        'newDatetimeSuggestion',
                                        dateSuggestions?.dateSuggestion2
                                    )
                                }
                            />
                        )}
                    </>
                )}
                <span className="overline-2 color--grayscale-steel-gray">
                    <span>{Locale.pages.reservation.localTime}. </span>
                    <span
                        onClick={() => setShowLocalTimeModal(true)}
                        className="color--brand-primary cursor--pointer"
                    >
                        {Locale.actions.readMore}
                    </span>
                </span>
                <Radio
                    id="dontSuit"
                    name="newDatetimeSuggestion"
                    label={Locale.pages.reservationDetail.notSuitMe}
                    value="dont suit"
                    checked={formik.values.newDatetimeSuggestion === dontSuit}
                    onChange={formik.handleChange}
                    className="padding--t-xl"
                />
                <Textarea
                    name="observation"
                    label={Locale.fields.justification}
                    value={formik.values.observation}
                    onChange={formik.handleChange}
                    error={
                        formik.touched?.observation &&
                        formik.errors?.observation
                    }
                    required
                />
                <StickyFooter>
                    <Button type="submit" large>
                        {Locale.actions.send}
                    </Button>
                </StickyFooter>
            </form>
        </>
    );
}
