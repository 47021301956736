import {
    checkGray,
    icoClock,
    icoClose,
    icoExclamationRed,
    icoWallet,
} from 'assets/icons';
import Locale from 'locale';
import {
    FlightType,
    getFlightType,
    getReservationStatus,
    ReservationStatus as ReservationStatusEnum,
} from 'utils/enums';
import * as Yup from 'yup';
import momentTZ from 'moment-timezone';
import Aircraft from './Aircraft';
import Airport from './Airport';
import BaseModel from './BaseModel';
import Flight from './Flight';
import Pilot from './Pilot';
import StatusDetails from './StatusDetails';

class Reservation extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.airportTarget = Airport.getValueOrNew(data.airportTarget);
        this.originFlight = Flight.getValueOrNew(data.originFlight);
        this.hasPatioReservation = data.hasPatioReservation;
        this.hasTakeOffPrevision = data.hasTakeOffPrevision;
        this.destinationFlight = Flight.getValueOrNew(data.destinationFlight);
        this.aircraft = Aircraft.getValueOrNew(data.aircraft);
        this.confirmGuideline = data.confirmGuideline;
        this.status = data.status;
        this.createdAt = Reservation.getValueOrNew(data.createdAt, Date);
        this.updatedAt = Reservation.getValueOrNew(data.updatedAt, Date);
        this.isNationalizationFlight = data.isNationalizationFlight;
        this.hasEmbarkationTransport = data.hasEmbarkationTransport;
        this.hasDisembarkationTransport = data.hasDisembarkationTransport;
        this.idRequester = data.idRequester;
        this.idCommander = data.idCommander;
        this.commander = Reservation.getValueOrNew(data.commander, Pilot);

        this.flightType = data.flightType;
        this.isNationalizationFlight = !!data.isNationalizationFlight;
        this.legalRepresentativeName = data.legalRepresentativeName;
        this.legalRepresentativeCountryPhoneCode =
            data.legalRepresentativeCountryPhoneCode;
        this.legalRepresentativePhoneNumber =
            data.legalRepresentativePhoneNumber;
        this.isAlternativeReserve = !!data.isAlternativeReserve;
        this.hasTrailerFork = !!data.hasTrailerFork;
        this.hasEmbarkationTransport = !!data.hasEmbarkationTransport;
        this.hasDisembarkationTransport = !!data.hasDisembarkationTransport;
        this.handlingCompany = data.handlingCompany;
        this.paymentMethod = data.paymentMethod;
        this.statusDetails = StatusDetails.getValueOrNew(data.statusDetails);
        this.observation = data.observation;
        this.canCancel = data.canCancel;
        this.comments = data.comments;
    }

    get statusName() {
        return getReservationStatus(this.status);
    }

    get flightTypeLabel() {
        if (!this.flightType) {
            return '';
        }

        const flightTypeKey = getFlightType(+this.flightType);
        return Locale.flightTypes[flightTypeKey] || '';
    }

    get iconByStatus() {
        return this.getIconByStatus(this.status);
    }

    static getIconByStatus(status) {
        switch (status) {
            case ReservationStatusEnum.changeRequested:
                return icoExclamationRed;
            case ReservationStatusEnum.pendingPayment:
                return icoWallet;
            case ReservationStatusEnum.paymentConfirmed:
            case ReservationStatusEnum.completed:
                return checkGray;
            case ReservationStatusEnum.cancelled:
            case ReservationStatusEnum.denied:
                return icoClose;
            default:
                return icoClock;
        }
    }

    static createEmpty(isUserAuthenticated) {
        const shouldCreateNewFlightAirport = !isUserAuthenticated;
        return new Reservation({
            hasPatioReservation: false,
            hasTakeOffPrevision: false,
            airportTarget: Airport.createEmpty(),
            originFlight: Flight.createEmpty(shouldCreateNewFlightAirport),
            aircraft: Aircraft.createEmpty(),
        });
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        const aircraftData = response.aircraft
            ? { ...response.aircraft, id: response.aircraft.aircraftId }
            : { id: response.idAircraft };
        const commanderData = response.commander
            ? { ...response.commander, id: response.commander.pilotId }
            : null;

        response.originFlight.airport = {
            ...response.originFlight.airport,
            id: response.originFlight.airport.airportId,
        };
        response.airportTarget = {
            ...response.airportTarget,
            id: response.airportTarget.airportId,
        };
        if (response.destinationFlight) {
            response.destinationFlight.airport = {
                ...response.destinationFlight.airport,
                id: response.destinationFlight.airport.airportId,
            };
        }

        return new Reservation({
            ...response,
            aircraft: Aircraft.createFromResponse(aircraftData),
            commander: Pilot.createFromResponse(commanderData),
            paymentMethod: response.aircraft?.isForeign // TODO Buscar do serviço
                ? Locale.paymentOptions.creditCard
                : Locale.paymentOptions.accountHolder,
            idCommander: commanderData?.id,
        });
    }

    parseToRequest() {
        const payload = {
            ...this,
            idAircraft: this.aircraft.id,
            idCommander: this.commander?.id,
            airportTarget: {
                id: this.airportTarget.id,
            },
            originFlight: {
                ...this.originFlight.parseToRequest(),
                datetime: momentTZ(this.originFlight.datetime)
                    .tz(this.airportTarget.timezone, true)
                    .toDate(),
            },
            ...(this.destinationFlight && {
                destinationFlight: {
                    ...this.destinationFlight.parseToRequest(),
                    datetime: momentTZ(this.destinationFlight.datetime)
                        .tz(this.airportTarget.timezone, true)
                        .toDate(),
                },
            }),
        };

        if (!payload.legalRepresentativeCountryPhoneCode) {
            delete payload.legalRepresentativeCountryPhoneCode;
        }

        return payload;
    }

    static get validationSchema() {
        return Yup.object().shape({
            airportTarget: Yup.object().shape({
                id: Yup.string().required(Locale.errors.required),
            }),
            originFlight: Flight.validationSchema,
            hasPatioReservation: Yup.bool(),
            hasTakeOffPrevision: Yup.bool(),

            destinationFlight: Yup.object()
                .nullable()
                .when('hasTakeOffPrevision', {
                    is: true,
                    then: Flight.validationSchema,
                }),
        });
    }

    static get validationSchemaNewReservation() {
        return Yup.object().shape({
            airportTarget: Yup.object().shape({
                id: Yup.string().required(Locale.errors.required),
            }),
            originFlight: Flight.validationSchema,
            hasPatioReservation: Yup.bool().required(Locale.errors.required),
            selectedPatioReservationOption: Yup.string().required(
                Locale.errors.required
            ),
            hasTakeOffPrevision: Yup.bool(),

            destinationFlight: Yup.object()
                .nullable()
                .when('hasTakeOffPrevision', {
                    is: true,
                    then: Flight.validationSchema,
                }),
        });
    }

    static get validationSchemaFlightInfo() {
        return Yup.object().shape({
            flightType: Yup.string().required(Locale.errors.required),
            isNationalizationFlight: Yup.bool(),
            legalRepresentativeName: Yup.string().when(
                'isNationalizationFlight',
                {
                    is: true,
                    then: Yup.string().required(Locale.errors.required),
                }
            ),
            legalRepresentativeCountryPhoneCode: Yup.string().when(
                'isNationalizationFlight',
                {
                    is: true,
                    then: Yup.string().required(Locale.errors.required),
                }
            ),
            legalRepresentativePhoneNumber: Yup.string().when(
                'isNationalizationFlight',
                {
                    is: true,
                    then: Yup.string().required(Locale.errors.required),
                }
            ),
            isAlternativeReserve: Yup.bool(),
            hasTrailerFork: Yup.bool(),
            hasEmbarkationTransport: Yup.bool(),
            hasDisembarkationTransport: Yup.bool(),
            handlingCompany: Yup.string()
                .nullable()
                .when(
                    ['hasEmbarkationTransport', 'hasDisembarkationTransport'],
                    {
                        is: (boarding, deboarding) => boarding || deboarding,
                        then: Yup.string().required(Locale.errors.required),
                    }
                ),
        });
    }

    getFlightInfo() {
        return {
            flightType: this.flightType || FlightType.none,
            isNationalizationFlight: this.isNationalizationFlight,
            legalRepresentativeName: this.legalRepresentativeName,
            legalRepresentativeCountryPhoneCode:
                this.legalRepresentativeCountryPhoneCode,
            legalRepresentativePhoneNumber: this.legalRepresentativePhoneNumber,
            isAlternativeReserve: this.isAlternativeReserve,
            hasTrailerFork: this.hasTrailerFork,
            hasEmbarkationTransport: this.hasEmbarkationTransport,
            hasDisembarkationTransport: this.hasDisembarkationTransport,
            handlingCompany: this.handlingCompany,
        };
    }

    setFlightInfo(data) {
        this.flightType = data.flightType;
        this.isNationalizationFlight = !!data.isNationalizationFlight;
        this.legalRepresentativeName = data.legalRepresentativeName;
        this.legalRepresentativeCountryPhoneCode =
            data.legalRepresentativeCountryPhoneCode;
        this.legalRepresentativePhoneNumber =
            data.legalRepresentativePhoneNumber;
        this.isAlternativeReserve = !!data.isAlternativeReserve;
        this.hasTrailerFork = !!data.hasTrailerFork;
        this.hasEmbarkationTransport = !!data.hasEmbarkationTransport;
        this.hasDisembarkationTransport = !!data.hasDisembarkationTransport;
        this.handlingCompany = data.handlingCompany;
    }

    setObservation(data) {
        this.observation = data.observation;
    }

    prepareForReplication(billing) {
        return {
            billing: billing || {},
            reservation: {
                ...this,
                id: undefined,
                ...(this.aircraft?.deleted
                    ? { aircraft: {} }
                    : { aircraft: this.aircraft }),
            },
            pilot: {
                id: this.idCommander,
            },
        };
    }

    static prepareGeneralInfo(values, isInitReplication) {
        return new Reservation({
            ...values,
            orientation: {
                ...values.orientation,
                ...(isInitReplication && undefined),
            },
            originFlight: {
                ...values.originFlight,
                ...(isInitReplication && { datetime: undefined }),
                hour: isInitReplication
                    ? undefined
                    : values.originFlight?.datetime,
                airport: {
                    ...values.originFlight.airport,
                    ...(!values.originFlight.airport.icaoCode && {
                        noICAO: true,
                    }),
                },
            },
            ...(values.destinationFlight && {
                destinationFlight: {
                    ...values.destinationFlight,
                    ...(isInitReplication && { datetime: undefined }),
                    hour: isInitReplication
                        ? undefined
                        : values.destinationFlight?.datetime,
                    airport: {
                        ...values.destinationFlight.airport,
                        ...(!values.destinationFlight.airport.icaoCode && {
                            noICAO: true,
                        }),
                    },
                },
            }),
        });
    }

    static get filterParams() {
        return {
            structured: 'structured',
            search: 'search',
            aircraft: 'aircraft',
            status: 'status',
            startDate: 'origin_flight_range_after',
            endDate: 'origin_flight_range_before',
            airportTarget: 'airport_target',
            airport: 'target_airports',
            hasDestination: 'has_destination',
            destinationFlightStartDate: 'destination_flight_range_after',
            destinationFlightEndDate: 'destination_flight_range_before',
        };
    }

    static get orderingParams() {
        return {
            id: 'id',
            createdAt: 'created_at',
            airportTarget: 'airport_target__icao_code,airport_target__name',
            landingDate: 'origin_flight__datetime',
            originAirport:
                'origin_flight__airport__icao_code,origin_flight__airport__name',
            destinationAirport:
                'destination_flight__airport__icao_code,destination_flight__airport__name',
            status: 'status',
            destinationFlightDatetime: 'destination_flight__datetime',
            aircraftRegistration: 'aircraft__registration',
            aircraftIsForeign: 'aircraft__is_foreign',
        };
    }
}

export default Reservation;
