import './styles.scss';
import Locale from 'locale';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    icoFlightDuotone,
    icoPerson,
    icoReservation,
    icoWalletDuotone,
    icoPlusCircle,
    // TODO: HABILITAR QUANDO TIVER MAIS DO QUE 4 SERVIÇOS
    // iconChevronRightPrimary,
} from 'assets/icons';
import { Button, Image, OurServiceItem } from 'components';
import { BILLING_INTEGRATION_ENABLED } from 'utils/constants';

export default function OurServices(props) {
    const { isLoggedIn } = props;
    const navigate = useNavigate();

    const handleRedirectOnClick = (redirectUrl, optionalState = null) => {
        if (!isLoggedIn) {
            navigate('/login', {
                state: {
                    redirectTo: redirectUrl,
                    ...optionalState,
                },
            });
        } else {
            navigate(redirectUrl, {
                state: optionalState,
            });
        }
    };

    return (
        <div className="services-container">
            <div>
                {isLoggedIn ? (
                    <div className="header-logged">
                        <div>
                            <h3>{Locale.pages.homeLoggedIn.services.title}</h3>
                            {/* TODO: HABILITAR QUANDO TIVER MAIS DO QUE 4 SERVIÇOS */}
                            {/* <a href="/">
                                Mostrar todos{' '}
                                <Image
                                    src={iconChevronRightPrimary}
                                    alt="Ícone acessar"
                                />
                            </a> */}
                        </div>
                        <Button
                            type="primary"
                            className="button-only-desktop"
                            onClick={() =>
                                handleRedirectOnClick(
                                    '/newreservation/generalinfo'
                                )
                            }
                        >
                            <Image src={icoPlusCircle} alt="Ícone de mais" />
                            {Locale.pages.homeLoggedIn.services.new}
                        </Button>
                    </div>
                ) : (
                    <div className="header-not-logged">
                        <h3 className="title color--grayscale-dark">
                            {Locale.pages.home.useOurServices}
                        </h3>
                        <p className="color--grayscale-blue-gray margin--b-lg">
                            {Locale.pages.home.useOurServicesDescription}
                        </p>
                    </div>
                )}
                <div className="row">
                    <OurServiceItem
                        label={Locale.pages.home.myReservations}
                        icon={icoReservation}
                        onClick={() => {
                            handleRedirectOnClick('/reservations');
                        }}
                    />
                    <OurServiceItem
                        label={Locale.pages.home.myAircrafts}
                        icon={icoFlightDuotone}
                        onClick={() => {
                            handleRedirectOnClick('/aircraft');
                        }}
                    />
                    <OurServiceItem
                        label={Locale.pages.home.pilotRegistration}
                        icon={icoPerson}
                        onClick={() => {
                            handleRedirectOnClick('/pilots');
                        }}
                    />
                    <OurServiceItem
                        label={Locale.pages.home.debtsAndInvoices}
                        icon={icoWalletDuotone}
                        onClick={() => {
                            if (isLoggedIn && BILLING_INTEGRATION_ENABLED) {
                                handleRedirectOnClick('/debt-hub');
                            } else {
                                window.open(
                                    'https://ccraeroportos.omd.com.br/ccrfinanceiro/externo/cadastro.do',
                                    '_blank'
                                );
                            }
                        }}
                    />
                </div>
                {isLoggedIn && (
                    <Button
                        type="primary"
                        className="button-only-mobile"
                        onClick={() =>
                            handleRedirectOnClick('/newreservation/generalinfo')
                        }
                    >
                        <Image src={icoPlusCircle} alt="Ícone de mais" />
                        {Locale.pages.homeLoggedIn.services.new}
                    </Button>
                )}
            </div>
        </div>
    );
}

OurServices.propTypes = {
    isLoggedIn: PropTypes.bool,
};

OurServices.defaultProps = {
    isLoggedIn: false,
};
