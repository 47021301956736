import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Image,
    ButtonIcon,
    Text,
    Switch,
    ConfirmDialog,
    Button,
    Loading,
} from 'components';

import {
    icoPdfFile,
    icoKebabMenu,
    icoEdit,
    checkGray,
    icoEditDuotone,
    icoTrashDuotone,
    icoUpload,
    icoDownload,
} from 'assets/icons';
import Locale from 'locale';
import { outsideClick, bytesToSize } from 'utils/functions';

import './styles.scss';

function ItemFile({
    title,
    size,
    filepath,
    isNew,
    onDelete,
    accept,
    onAccept,
    onChangeName,
    loading,
}) {
    const randomLabel = Math.floor(Math.random() * 50);
    const wrapperButton = useRef(null);
    const wrapperEdit = useRef(null);
    const iptEdit = useRef(null);

    const [value, setValue] = useState(title);
    const [edit, setEdit] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [icon, setIcon] = useState(isNew ? icoUpload : icoPdfFile);

    const toggleEdit = () => setEdit(!edit);
    const togglePopup = () => setShowPopup(!showPopup);
    const toggleDialog = () => {
        setShowDialog(!showDialog);
    };
    const editItem = () => {
        setEdit(true);
        setShowPopup(false);
        setTimeout(() => {
            iptEdit.current?.select();
        }, 200);
    };
    const handleChangeText = () => {
        setValue(iptEdit.current.value);
        if (!inputError) onChangeName(iptEdit.current.value);
        toggleEdit();
    };
    const handleDelete = () => {
        onDelete();
        toggleDialog();
    };

    useEffect(() => {
        if (isNew) {
            setIcon(icoUpload);
        } else if (hovering) {
            setIcon(icoDownload);
        } else setIcon(icoPdfFile);
    }, [hovering]);

    outsideClick(wrapperButton, () => setShowPopup(false));

    return (
        <>
            <div className="container-item-file flex-items--center flex-justify--between">
                <div className="flex--1">
                    {loading ? (
                        <div className="padding--l-md">
                            <Loading size={16} />
                        </div>
                    ) : (
                        <a
                            href={filepath}
                            target="_blank"
                            download
                            rel="noreferrer"
                            onMouseOver={() => setHovering(true)}
                            onMouseOut={() => setHovering(false)}
                            onFocus={() => setHovering(true)}
                            onBlur={() => setHovering(false)}
                        >
                            <ButtonIcon
                                icon={icon}
                                showLoading={loading}
                                kind="no-border"
                                className="margin--r-sm"
                                type="button"
                            />
                        </a>
                    )}
                </div>
                <div className="flex--8" ref={wrapperEdit}>
                    {edit ? (
                        <div className="flex-items--center wrapper-input">
                            <input
                                type="text"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                onBlur={() => {
                                    setTimeout(() => {
                                        if (!value.match(/.(pdf|doc|docx)$/i)) {
                                            editItem();
                                            setInputError(true);
                                        } else {
                                            setInputError(false);
                                            toggleEdit();
                                        }
                                    }, 1000);
                                }}
                                ref={iptEdit}
                                className={clsx(inputError && 'error')}
                            />
                            <button
                                type="button"
                                className="cursor--pointer"
                                onClick={() => handleChangeText()}
                            >
                                <Image
                                    src={checkGray}
                                    alt={
                                        Locale.pages.backoffice.airports.files
                                            .rename
                                    }
                                />
                            </button>
                        </div>
                    ) : (
                        <div className="label-file margin--r-md">
                            <a
                                href={filepath}
                                target="_blank"
                                download
                                rel="noreferrer"
                                onMouseOver={() => setHovering(true)}
                                onMouseOut={() => setHovering(false)}
                                onFocus={() => setHovering(true)}
                                onBlur={() => setHovering(false)}
                            >
                                <div
                                    className={
                                        !isNew
                                            ? 'color--brand-primary text-decoration-underline'
                                            : ''
                                    }
                                >
                                    {value}
                                </div>
                            </a>
                            <button type="button" onClick={() => editItem()}>
                                <Image
                                    src={icoEdit}
                                    alt={
                                        Locale.pages.backoffice.airports.files
                                            .rename
                                    }
                                />
                            </button>
                        </div>
                    )}
                    <Text kind="overline-1">{bytesToSize(size)}</Text>
                </div>
                <div className="flex--4">
                    <Switch
                        id={randomLabel}
                        checked={accept}
                        label={Locale.pages.backoffice.airports.files.accept}
                        name="toggleLabel"
                        onChange={onAccept}
                    />
                </div>
                <div className="position-relative flex--1" ref={wrapperButton}>
                    {showPopup && (
                        <div className="popup">
                            <button type="button" onClick={() => editItem()}>
                                <Image
                                    src={icoEditDuotone}
                                    alt={
                                        Locale.pages.backoffice.airports.files
                                            .rename
                                    }
                                />
                                {Locale.pages.backoffice.airports.files.rename}
                            </button>
                            <button
                                type="button"
                                onClick={() => toggleDialog()}
                            >
                                <Image
                                    src={icoTrashDuotone}
                                    alt={
                                        Locale.pages.backoffice.airports.files
                                            .delete
                                    }
                                />
                                {Locale.pages.backoffice.airports.files.delete}
                            </button>
                        </div>
                    )}
                    <ButtonIcon
                        icon={icoKebabMenu}
                        kind="no-border"
                        className="margin--l-md"
                        onClick={() => togglePopup()}
                        type="button"
                    />
                </div>
            </div>
            <ConfirmDialog
                title={Locale.pages.backoffice.airports.files.dialog.title}
                description={
                    Locale.pages.backoffice.airports.files.dialog.description
                }
                show={showDialog}
                onClose={toggleDialog}
                actionsButton={
                    <>
                        <Button
                            kind="outline"
                            type="button"
                            className="margin--r-md"
                            onClick={toggleDialog}
                        >
                            {Locale.actions.cancel}
                        </Button>
                        <Button
                            type="button"
                            kind="primary"
                            onClick={handleDelete}
                        >
                            {Locale.actions.delete}
                        </Button>
                    </>
                }
            />
        </>
    );
}

ItemFile.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    filepath: PropTypes.string,
    isNew: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    accept: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

ItemFile.defaultProps = {
    isNew: false,
    loading: false,
    filepath: undefined,
};

export default ItemFile;
