import PropTypes from 'prop-types';

import { getInitials } from 'utils/functions';
import { Image } from '../index';

import { Container } from './styles';

function ButtonIcon({
    kind,
    size,
    disabled,
    onClick,
    float,
    icon,
    iconSize,
    text,
    name,
    ...args
}) {
    return (
        <Container
            kind={kind}
            size={size}
            disabled={disabled}
            float={float}
            icon={icon}
            onClick={onClick}
            iconSize={iconSize}
            name={name}
            {...args}
        >
            {icon && <Image src={icon} alt={icon} />}
            {text && <div>{text}</div>}
            {name && <div>{getInitials(name)}</div>}
        </Container>
    );
}

ButtonIcon.propTypes = {
    kind: PropTypes.oneOf(['primary', 'outline', 'no-border']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool,
    float: PropTypes.bool,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
};

ButtonIcon.defaultProps = {
    kind: 'primary',
    size: 'medium',
    disabled: false,
    float: false,
    iconSize: undefined,
    onClick: undefined,
    icon: undefined,
    text: undefined,
    name: undefined,
};

export default ButtonIcon;
