import axios from 'axios';

const CEP_API_URL = 'https://ws.apicep.com/cep/';

const api = axios.create({
    baseURL: CEP_API_URL,
});

const get = (url, config) =>
    api.get(url, config).then((response) => response.data);

const AddressService = {
    getAddress(cep) {
        return get(`/${cep}.json`);
    },
};

export default AddressService;
