import './styles.scss';
import { Image } from 'components';
import PropTypes from 'prop-types';

export default function OurServiceItem(props) {
    const { onClick, label, icon } = props;

    return (
        <div className="col-6 col-md-3 col-lg-3">
            <button
                type="button"
                onClick={onClick}
                className="our-services-item flex-justify--center flex-items--center height--full"
            >
                <Image src={icon} size={24} alt={icon} />
                <p className="color--grayscale-blue-gray">{label}</p>
            </button>
        </div>
    );
}

OurServiceItem.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    icon: PropTypes.string,
};

OurServiceItem.defaultProps = {
    onClick: undefined,
    label: undefined,
    icon: undefined,
};
