import { useEffect, useState } from 'react';
import Locale from 'locale';
import { ErrorHelper } from 'helpers';
import notify from 'components/Toast';
import PropTypes from 'prop-types';
import { Country, State } from 'models';
import { Dropdown, Input } from 'components';
import Service from 'services';

function AddressForm({ formik, title }) {
    const [countries, setCountries] = useState(new Map());
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [states, setStates] = useState(new Map());
    const [loadingStates, setLoadingStates] = useState(false);

    const [isBrazilianAddress, setIsBrazilianAddress] = useState(false);
    const [cepSuccess, setCepSuccess] = useState(true);

    const getCountries = () => {
        setLoadingCountries(true);
        Service.getCountries()
            .then((response) => {
                const responseCountries =
                    Country.createListFromResponse(response);
                setCountries(
                    new Map(
                        responseCountries.map((responseCountry) => [
                            responseCountry.value,
                            responseCountry,
                        ])
                    )
                );
                setLoadingCountries(false);
            })
            .catch((error) => {
                setLoadingCountries(false);
                ErrorHelper.notifyError(error);
            });
    };

    const setCountry = (e) => {
        formik.setFieldValue(
            'operator.address.country',
            countries.get(e.target.value)
        );
        setIsBrazilianAddress(
            e.target.value === Country.filterIds.brazil.toString()
        );

        formik.setTouched({});
        formik.setFieldValue('operator.address.postalCode', '');
        formik.setFieldValue('operator.address.street', '');
        formik.setFieldValue('operator.address.city', '');
        formik.setFieldValue('operator.address.state', State.createEmpty());
        formik.setFieldValue('operator.address.district', '');
        formik.setFieldValue('operator.address.number', '');
        formik.setFieldValue('operator.address.complement', '');
    };

    const setAddress = () => {
        formik.setFieldTouched('operator.address.postalCode', true);
        if (
            formik.values?.operator?.address?.postalCode &&
            isBrazilianAddress
        ) {
            Service.getAddress(formik.values.operator.address?.postalCode)
                .then((response) => {
                    formik.setFieldValue(
                        'operator.address.street',
                        response?.address
                    );
                    formik.setFieldValue(
                        'operator.address.city',
                        response?.city
                    );
                    formik.setFieldValue(
                        'operator.address.state',
                        response?.state
                            ? states.get(State.brazilianStates[response?.state])
                            : State.createEmpty()
                    );
                    formik.setFieldValue(
                        'operator.address.district',
                        response?.district
                    );
                    setCepSuccess(response.ok);
                    if (!response.ok) {
                        notify(Locale.errors.cep);
                    }
                })
                .catch((error) => {
                    setCepSuccess(false);
                    ErrorHelper.notifyError(error);
                    notify(Locale.errors.cep);
                });
        }
    };

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (countries) {
            const orderedCountries = new Map(
                [...countries].sort((a, b) =>
                    a[1].nameByLanguage.localeCompare(b[1].nameByLanguage)
                )
            );
            setCountries(orderedCountries);
        }
    }, [Locale.getLanguage()]);

    useEffect(() => {
        if (!formik.values.operator?.address?.country?.id) {
            return;
        }
        setLoadingStates(true);
        Service.getStates({
            [State.filterParams.countryId]:
                formik.values.operator.address.country.id,
        })
            .then((response) => {
                const responseStates = State.createListFromResponse(response);
                setStates(
                    new Map(
                        responseStates.map((responseState) => [
                            responseState.value,
                            responseState,
                        ])
                    )
                );
                setLoadingStates(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoadingStates(false);
            });
    }, [formik.values.operator?.address?.country?.id]);

    return (
        <div className="margin--t-md">
            <h4 className="headline-4 margin--b-md">{title}</h4>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <Dropdown
                        id="operator.address.country.id"
                        name="operator.address.country.id"
                        label={Locale.fields.countryRegion}
                        options={Array.from(countries.values())}
                        value={formik.values?.operator?.address?.country?.id}
                        onChange={setCountry}
                        error={
                            formik.touched?.operator?.address?.country?.id &&
                            formik.errors?.operator?.address?.country?.id
                        }
                        required
                        onBlur={formik.handleBlur}
                        autocomplete
                        loading={loadingCountries}
                    />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6">
                    <Input
                        type="text"
                        label={Locale.fields.zipCode}
                        name="operator.address.postalCode"
                        id="operator.address.postalCode"
                        value={formik.values.operator?.address?.postalCode}
                        onChange={formik.handleChange}
                        error={
                            (formik.touched.operator?.address?.postalCode ||
                                formik.touched.operator?.address?.postalCode) &&
                            formik.errors.operator?.address?.postalCode
                        }
                        onBlur={setAddress}
                        required
                    />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6">
                    <Dropdown
                        id="operator.address.state.id"
                        name="operator.address.state.id"
                        label={Locale.fields.stateTerritory}
                        options={Array.from(states.values())}
                        value={formik.values.operator?.address?.state?.id}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'operator.address.state',
                                states.get(e.target.value)
                            );
                        }}
                        placeholder={
                            isBrazilianAddress && cepSuccess
                                ? Locale.fields.addressPlaceholder
                                : ''
                        }
                        error={
                            (formik.touched?.operator?.address?.state?.id ||
                                !cepSuccess) &&
                            formik.errors?.operator?.address?.state?.id
                        }
                        disabled={
                            (isBrazilianAddress && cepSuccess) ||
                            !formik.values.operator?.address?.country?.id
                        }
                        required
                        onBlur={formik.handleBlur}
                        autocomplete
                        loading={loadingStates}
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <Input
                        type="text"
                        label={Locale.fields.city}
                        name="operator.address.city"
                        id="operator.address.city"
                        value={formik.values.operator?.address?.city}
                        onChange={formik.handleChange}
                        error={
                            (formik.touched.operator?.address?.city ||
                                !cepSuccess) &&
                            formik.errors.operator?.address?.city
                        }
                        placeholder={
                            isBrazilianAddress && cepSuccess
                                ? Locale.fields.addressPlaceholder
                                : ''
                        }
                        disabled={isBrazilianAddress && cepSuccess}
                        onBlur={formik.handleBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <Input
                        type="text"
                        label={Locale.fields.streetAddress}
                        name="operator.address.street"
                        id="operator.address.street"
                        value={formik.values.operator?.address?.street}
                        onChange={formik.handleChange}
                        error={
                            (formik.touched.operator?.address?.street ||
                                !cepSuccess) &&
                            formik.errors.operator?.address?.street
                        }
                        onBlur={formik.handleBlur}
                        required
                    />
                </div>
                {isBrazilianAddress && (
                    <div className="col-12 col-md-4">
                        <Input
                            id="operator.address.district"
                            name="operator.address.district"
                            value={formik.values.operator?.address?.district}
                            label={Locale.fields.district}
                            error={
                                (formik.touched?.operator?.address?.district ||
                                    !cepSuccess) &&
                                formik.errors?.operator?.address?.district
                            }
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </div>
                )}
                <div className="col-lg-2 col-md-2 col-sm-6">
                    <Input
                        type="number"
                        label={Locale.fields.number}
                        name="operator.address.number"
                        id="operator.address.number"
                        value={formik.values.operator?.address?.number}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.operator?.address?.number &&
                            formik.errors.operator?.address?.number
                        }
                        onBlur={formik.handleBlur}
                        required
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <Input
                        type="text"
                        label={Locale.fields.complement}
                        name="operator.address.complement"
                        id="operator.address.complement"
                        value={formik.values.operator?.address?.complement}
                        onChange={formik.handleChange}
                        error={
                            (formik.touched.operator?.address?.complement ||
                                formik.touched.operator?.address?.complement) &&
                            formik.errors.operator?.address?.complement
                        }
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
        </div>
    );
}

AddressForm.propTypes = {
    formik: PropTypes.object.isRequired,
    title: PropTypes.string,
};

AddressForm.defaultProps = {
    title: '',
};

export default AddressForm;
