import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonIcon, Image, UserMenu } from 'components';
import Locale from 'locale';

import './styles.scss';

import Logo from 'assets/logo-aeroease.svg';
import { icoArrowLeft, icoMenu, icoUser } from 'assets/icons';

function Header({ isUserAuthenticated, toggle, logout }) {
    const navigate = useNavigate();

    const logoutAndNavigate = () => {
        logout(() => navigate('/'));
    };

    const submenus = [
        {
            title: Locale.actions.profile,
            icon: icoUser,
            onClick: () => {
                navigate('accountsettings');
            },
        },
        {
            title: Locale.actions.logout,
            icon: icoArrowLeft,
            onClick: logoutAndNavigate,
        },
    ];

    return (
        <header className="header flex-items--center flex-justify--between">
            <div className="flex-items--center">
                <ButtonIcon
                    kind="no-border"
                    icon={icoMenu}
                    className="margin--r-sm"
                    onClick={toggle}
                />
                <Image
                    src={Logo}
                    alt="CCR"
                    className="logo"
                    onClick={() => navigate('/')}
                />
            </div>
            {isUserAuthenticated ? (
                <UserMenu
                    isAuthenticated={isUserAuthenticated}
                    submenus={submenus}
                />
            ) : (
                <Button kind="text" onClick={() => navigate('/login')}>
                    {Locale.actions.login}
                </Button>
            )}
        </header>
    );
}

Header.propTypes = {
    isUserAuthenticated: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    logout: PropTypes.func.isRequired,
};

Header.defaultProps = {
    toggle: undefined,
};

export default Header;
