import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { icoChevronRightWhite } from 'assets/icons';
import { Image } from 'components';

import './styles.scss';

export default function Banner(props) {
    const {
        title,
        description,
        imgDesktop,
        imgMobile,
        link,
        tab,
        icon,
        rightSideComponent,
        className,
        loading,
    } = props;
    const navigate = useNavigate();

    const handleRedirectOnClick = (redirectUrl, optionalState = null) => {
        navigate(redirectUrl, {
            state: optionalState,
        });
    };

    return (
        <div
            className={`container-banner ${className}`}
            onClick={() =>
                handleRedirectOnClick(link, {
                    initialTab: tab,
                    showModal: true,
                })
            }
        >
            {!loading && (
                <div
                    className={`content flex-justify--between padding--x-lg ${
                        link && 'content-link'
                    }`}
                >
                    <div className="inner-content">
                        {icon && <img src={icon} alt="icon" />}
                        <h2>{title}</h2>
                        <div className="flex-items--center">
                            <p>{description}</p>
                            {link && (
                                <Image src={icoChevronRightWhite} alt={title} />
                            )}
                        </div>
                    </div>
                    {rightSideComponent && (
                        <div className="width--full flex-justify--end">
                            {rightSideComponent}
                        </div>
                    )}
                </div>
            )}
            <picture>
                <source srcSet={imgDesktop} media="(min-width: 769px)" />
                <source srcSet={imgMobile} media="(max-width: 768px)" />
                <Image src={imgDesktop} alt={title} />
            </picture>
        </div>
    );
}

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imgDesktop: PropTypes.string.isRequired,
    imgMobile: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    tab: PropTypes.string,
    icon: PropTypes.string,
    rightSideComponent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    loading: PropTypes.bool,
};

Banner.defaultProps = {
    icon: undefined,
    tab: undefined,
    rightSideComponent: undefined,
    className: '',
    loading: false,
};
