import PropTypes from 'prop-types';

import { Image, Loading } from '../index';

import { Container } from './styles';

function Button({
    kind,
    large,
    disabled,
    onClick,
    float,
    icon,
    block,
    isLoading,
    children,
    ...args
}) {
    return (
        <Container
            kind={kind}
            large={large}
            disabled={disabled || isLoading}
            float={float}
            icon={icon}
            block={block}
            onClick={onClick}
            isLoading={isLoading}
            {...args}
        >
            {icon && <Image src={icon} alt={children} />}
            {children}
            {isLoading && <Loading className="margin--l-sm" size={16} />}
        </Container>
    );
}

Button.propTypes = {
    kind: PropTypes.oneOf(['primary', 'outline', 'text', 'alert']),
    large: PropTypes.bool,
    disabled: PropTypes.bool,
    float: PropTypes.bool,
    block: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

Button.defaultProps = {
    kind: 'primary',
    large: false,
    disabled: false,
    block: false,
    float: false,
    icon: undefined,
    onClick: undefined,
    isLoading: false,
};

export default Button;
