import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${(props) =>
        props.disabled &&
        `
        > * {
            opacity: 0.5;
        }
    `};

    label {
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        text-transform: none;
        font-size: ${(props) => props.theme.fonts.sm};
        line-height: ${(props) => props.theme.line_height.sm};
        color: ${(props) => props.theme.colors.grayscale.carbonoGray};
        font-weight: ${(props) => props.theme.font_weights.regular};
        letter-spacing: normal;
    }

    ${(props) =>
        props.error !== '' &&
        `
        label {
            color: ${props.theme.colors.support.error};
        }
    `}
`;
