import { get, post } from './axios';

const AuthService = {
    login(data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };
        return post('login/', data, config);
    },

    logout() {
        return get('logout/');
    },

    isLoggedIn() {
        return get('isloggedin/');
    },

    refreshLogin() {
        return get('/token/refresh/');
    },

    sendActivationEmail(data) {
        return post('/activation-email/', data);
    },
};

export default AuthService;
