import PropTypes from 'prop-types';

import { Container } from './styles';

function Image({ src, alt, size, ...args }) {
    return <Container src={src} alt={alt} size={size} {...args} />;
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    size: PropTypes.number,
};

Image.defaultProps = {
    size: undefined,
};

export default Image;
