export const setItem = (itemKey, itemValue) => {
    localStorage.setItem(itemKey, JSON.stringify(itemValue));
};

export const getItem = (itemKey) => {
    const itemValue = localStorage.getItem(itemKey);

    if (itemValue) {
        return JSON.parse(itemValue);
    }
    return itemValue;
};
