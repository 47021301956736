import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { icoEyeOff, icoEyeOn, icoQuestion } from 'assets/icons/';
import { Tooltip, Label, Image, BottomSheet, Loading } from 'components';

import {
    Container,
    FormGroup,
    CInput,
    TextError,
    Before,
    After,
} from './styles';

function Input({
    label,
    placeholder,
    type,
    name,
    mask,
    value,
    required,
    disabled,
    error,
    before,
    after,
    onChange,
    onBlur,
    className,
    isInt,
    countryPhoneCode,
    tooltipContent,
    bottomSheetContent,
    isLoading,
    ...args
}) {
    const [filled, setFilled] = useState(!!value);
    const [reveal, setReveal] = useState(false);

    const inputType = type === 'phoneNumber' ? 'string' : type;
    const isNumber = type === 'number';
    const notAllowedChars = ['e', 'E', '+', '-', ...(isInt ? [',', '.'] : [])];

    useEffect(() => {
        setFilled(!!value);
    }, [value]);

    return (
        <Container label={label} className={className}>
            <div className="inline--sm">
                {label && <Label isRequired={required}>{label}</Label>}
                {tooltipContent && (
                    <Tooltip content={tooltipContent}>
                        <Image src={icoQuestion} size={15} alt={icoQuestion} />
                    </Tooltip>
                )}
                {bottomSheetContent && (
                    <>
                        <Image
                            src={icoQuestion}
                            size={15}
                            onClick={() => {
                                bottomSheetContent?.setVisible(true);
                            }}
                            alt={icoQuestion}
                        />
                        <BottomSheet
                            show={bottomSheetContent?.visible}
                            title={bottomSheetContent?.title}
                            onClose={() => {
                                bottomSheetContent?.setVisible(false);
                            }}
                        >
                            {bottomSheetContent?.content}
                        </BottomSheet>
                    </>
                )}
            </div>
            <FormGroup
                error={error}
                filled={filled}
                before={before}
                disabled={disabled}
            >
                {before && <Before>{before}</Before>}
                <CInput
                    type={reveal ? 'text' : inputType}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    mask={mask}
                    disabled={disabled}
                    onKeyDown={(evt) =>
                        isNumber &&
                        notAllowedChars.includes(evt.key) &&
                        evt.preventDefault()
                    }
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                        setFilled(!!e.currentTarget.value);
                    }}
                    onBlur={(e) => {
                        if (onBlur) {
                            onBlur(e);
                        }
                        setFilled(!!e.currentTarget.value);
                    }}
                    {...args}
                />
                {isLoading && <Loading className="margin--l-sm" size={16} />}
                {after && type !== 'password' && <After>{after}</After>}
                {type === 'password' && (
                    <button type="button" onClick={() => setReveal(!reveal)}>
                        <Image src={reveal ? icoEyeOn : icoEyeOff} alt="" />
                    </button>
                )}
            </FormGroup>
            {error && (
                <TextError kind="overline-2" className="white-space--pre-line">
                    {Array.isArray(error) ? error.join('\n') : error}
                </TextError>
            )}
        </Container>
    );
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    mask: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    before: PropTypes.node || PropTypes.element,
    after: PropTypes.node || PropTypes.element,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    isInt: PropTypes.bool,
    countryPhoneCode: PropTypes.string,
    tooltipContent: PropTypes.string,
    bottomSheetContent: PropTypes.shape({
        visible: PropTypes.bool.isRequired,
        setVisible: PropTypes.func.isRequired,
        title: PropTypes.string,
        content: PropTypes.node.isRequired,
    }),
    isLoading: PropTypes.bool,
};

Input.defaultProps = {
    type: 'text',
    value: '',
    label: undefined,
    placeholder: undefined,
    mask: undefined,
    required: false,
    disabled: false,
    error: '',
    before: undefined,
    after: undefined,
    onChange: undefined,
    onBlur: undefined,
    className: undefined,
    countryPhoneCode: undefined,
    isInt: false,
    tooltipContent: '',
    bottomSheetContent: undefined,
    isLoading: false,
};

export default Input;
