import PropTypes from 'prop-types';
import './styles.scss';

function Modal({ children, show, onBackdropPress }) {
    return (
        <div className={`modal-component ${show && 'show'}`}>
            <div
                className={`backdrop ${show && 'show'}`}
                onClick={onBackdropPress}
            />
            <div className="content">{children}</div>
        </div>
    );
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    show: PropTypes.bool,
    onBackdropPress: PropTypes.func,
};

Modal.defaultProps = {
    show: false,
    onBackdropPress: undefined,
};

export default Modal;
