import {
    Button,
    StickyFooter,
    FileInputCard,
    KeyValueText,
    Line,
} from 'components';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAircraftNature } from 'utils/functions';
import Locale from 'locale';
import { AircraftSolicitationStatus } from 'utils/enums';
import SolicitationsHistory from './SolicitationsHistory';

export default function AircraftInformation({
    aircraft,
    isSolicitation,
    solicitationStatus,
}) {
    const navigate = useNavigate();

    return (
        <div className="stack--lg">
            <div className="margin--t-lg stack--sm">
                <KeyValueText
                    label={Locale.pages.aircraftDetails.nature}
                    value={getAircraftNature(
                        aircraft.isForeign,
                        aircraft.isMilitary
                    )}
                />
                <KeyValueText
                    label={Locale.pages.aircraftDetails.registration}
                    value={aircraft.registration}
                />
                <KeyValueText
                    label={Locale.pages.aircraftDetails.model}
                    value={aircraft.icaoModel}
                />
                <KeyValueText
                    label={Locale.pages.aircraftDetails.mtow}
                    value={`${aircraft.mtow}kg`}
                />
                <KeyValueText
                    label={Locale.pages.aircraftDetails.wingspan}
                    value={`${aircraft.wingspan}m`}
                />
                <KeyValueText
                    label={Locale.pages.aircraftDetails.planeLength}
                    value={`${aircraft.length}m`}
                />
            </div>
            <div className="stack--sm">
                <div className="flex--row flex-items--center">
                    <p className="font-size--sm inline--sm">
                        {Locale.fields.file.airworthnessCertification}
                    </p>
                </div>
                <FileInputCard
                    id="aircraftDocument"
                    title={
                        aircraft?.document?.[0]
                            ? Locale.fields.file.documentAttached
                            : Locale.fields.file.attachCertification
                    }
                    description={
                        aircraft?.document?.[0]
                            ? aircraft?.document?.[0]?.name
                            : 'PDF, JPG ou PNG'
                    }
                    file={aircraft?.document?.[0]}
                    accept="application/pdf, image/png, image/jpeg, image/jpg"
                    download={aircraft?.document?.[0]?.filepath}
                    readOnly
                />
            </div>
            <div className="stack--sm">
                <div className="flex--row flex-items--center">
                    <p className="font-size--sm inline--sm">
                        {Locale.fields.file.aircraftImage}
                    </p>
                </div>
                <FileInputCard
                    id="aircraftImage"
                    title={
                        aircraft?.image?.[0]
                            ? Locale.fields.file.imageAttached
                            : Locale.fields.file.attachImage
                    }
                    description={
                        aircraft?.image?.[0]
                            ? aircraft?.image?.[0]?.name
                            : 'JPG ou PNG'
                    }
                    file={aircraft?.image?.[0]}
                    accept="image/png, image/jpeg, image/jpg"
                    download={aircraft?.image?.[0]?.filepath}
                    readOnly
                />
            </div>
            <Line />
            <SolicitationsHistory aircraft={aircraft} />
            {!isSolicitation && (
                <StickyFooter>
                    <div className="aircraft-list-footer">
                        <Button
                            kind="primary"
                            onClick={() =>
                                navigate(
                                    `/aircraft/${aircraft.registration}/edit/information`
                                )
                            }
                        >
                            {Locale.pages.aircraftDetails.editAircraft}
                        </Button>
                    </div>
                </StickyFooter>
            )}
            {solicitationStatus ===
                AircraftSolicitationStatus.incompleteRegistration && (
                <StickyFooter>
                    <div className="aircraft-list-footer">
                        <Button
                            kind="primary"
                            onClick={() =>
                                navigate(
                                    `/aircraft/${aircraft.registration}/edit/information`,
                                    {
                                        state: {
                                            isSolicitation: true,
                                        },
                                    }
                                )
                            }
                        >
                            {Locale.pages.aircraftDetails.completeRegistration}
                        </Button>
                    </div>
                </StickyFooter>
            )}
        </div>
    );
}

AircraftInformation.propTypes = {
    aircraft: PropTypes.object.isRequired,
    isSolicitation: PropTypes.bool,
    solicitationStatus: PropTypes.string,
};

AircraftInformation.defaultProps = {
    isSolicitation: false,
    solicitationStatus: '',
};
