import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Button,
    HeaderPage,
    ContainerForm,
    TextInfo,
    StickyFooter,
    Accordion,
    Card,
    List,
    Checkbox,
    Textarea,
    Loading,
} from 'components';
import notify from 'components/Toast';

import Locale from 'locale';
import { icoExpandRight } from 'assets/icons';
import { RequesterAccordion } from 'features/frontend';
import {
    getDocumentLabel,
    getPhoneWithCode,
    scrollToTop,
} from 'utils/functions';
import { ErrorHelper } from 'helpers';
import Service from 'services';
import { Billing, Reservation } from 'models';

function Summary() {
    const navigate = useNavigate();
    const location = useLocation();

    const [documents, setDocuments] = useState([]);
    const [listDocuments, setListDocuments] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingDocs, setLoadingDocs] = useState(true);

    useEffect(() => {
        scrollToTop();
    }, [location]);

    useEffect(() => {
        setLoadingDocs(true);
        Service.getAirport(formik.values.airportTarget.id)
            .then((data) => {
                setDocuments(data.airportdocumentSet);
                setLoadingDocs(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoadingDocs(false);
            });
    }, []);

    useEffect(() => {
        setListDocuments(documents.filter((item) => item.isAcceptanceRequired));
    }, [documents]);

    const hasTakeOff = location.state.reservation.hasTakeOffPrevision;
    const reservation = new Reservation({
        ...location.state.reservation,
        commander: location.state.pilot,
    });

    const initialValues = {
        ...reservation,
        pilot: location.state.pilot,
        confirmGuideline: false,
    };

    const observationMax = 1000;

    const validationSchema = Yup.object().shape({
        confirmGuideline: Yup.boolean(),
        observation: Yup.string().max(
            observationMax,
            Locale.errors.maxLength(observationMax)
        ),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form) => {
            reservation.setObservation(form);

            setLoadingSave(true);
            Service.createLandingRequest(reservation)
                .then((response) =>
                    setTimeout(() => {
                        const reservationId = response?.data?.id;
                        const billing = new Billing(location.state.billing);

                        return Service.insertAdditionalData(reservationId, {
                            billingData: billing.parseToRequest(),
                        });
                    }, 100)
                )
                .then(() => {
                    notify(Locale.pages.summary.messageSuccess);
                    navigate(`/newreservation/success`, {
                        state: { documents },
                    });
                    setLoadingSave(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    setLoadingSave(false);
                });
        },
    });

    const handleGoBack = () => {
        reservation.setObservation(formik.values);
        navigate('/newreservation/additionalinfo', {
            state: {
                ...location.state,
                reservation,
            },
        });
    };

    return (
        <div className="padding--lg padding--b-xl">
            <HeaderPage
                title={Locale.pages.summary.summaryReservation}
                labelBtnBack={
                    reservation.aircraft.isForeign
                        ? Locale.fields.reservation.flightInfo
                        : Locale.pages.reservation.additionalInfo
                }
                onClickBtnBack={() => handleGoBack()}
            />
            <form onSubmit={formik.handleSubmit}>
                <ContainerForm>
                    <div className="margin--b-xl">
                        <h4 className="color--grayscale-blue-gray margin--b-md">
                            {hasTakeOff
                                ? Locale.pages.summary.takeoffLanding
                                : Locale.fields.reservation.landing}
                        </h4>
                        <div className="row">
                            <div className="col-md-3">
                                <TextInfo
                                    label={Locale.fields.destination}
                                    value={formik.values.airportTarget.name}
                                />
                            </div>
                            <div className="col-6 col-md-3">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation.landingDate
                                    }
                                    value={moment(
                                        formik.values.originFlight.datetime
                                    ).format('DD/MM/YYYY')}
                                />
                            </div>
                            <div className="col-6 col-md-3">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation.landingHour
                                    }
                                    value={moment(
                                        formik.values.originFlight.datetime
                                    ).format('HH:mm')}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <TextInfo
                                    label={Locale.fields.reservation.origin}
                                    value={
                                        formik.values.originFlight.airport.name
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation.peopleOnBoard
                                    }
                                    value={
                                        formik.values.originFlight.peopleOnBoard
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation
                                            .patioReservation
                                    }
                                    value={
                                        formik.values.hasPatioReservation
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                        </div>
                        {hasTakeOff && (
                            <div className="row">
                                <div className="col-md-3">
                                    <TextInfo
                                        label={
                                            Locale.fields.reservation
                                                .nextDestination
                                        }
                                        value={
                                            formik.values.destinationFlight
                                                .airport.name
                                        }
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <TextInfo
                                        label={
                                            Locale.fields.reservation
                                                .takeOffDate
                                        }
                                        value={moment(
                                            formik.values.destinationFlight
                                                .datetime
                                        ).format('DD/MM/YYYY')}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <TextInfo
                                        label={
                                            Locale.fields.reservation
                                                .takeOffHour
                                        }
                                        value={moment(
                                            formik.values.destinationFlight
                                                .datetime
                                        ).format('HH:mm')}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <TextInfo
                                        label={
                                            Locale.fields.reservation
                                                .peopleOnBoard
                                        }
                                        value={
                                            formik.values.destinationFlight
                                                .peopleOnBoard
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <RequesterAccordion
                        isUserOperator={formik.values.aircraft.useData}
                    />
                    <Accordion
                        title={Locale.general.aircraft}
                        className="margin--b-xl"
                    >
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.aircraft.registration}
                                    value={formik.values.aircraft.registration}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.aircraft.icao}
                                    value={formik.values.aircraft.icaoModel}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.aircraft.mtow}
                                    value={formik.values.aircraft.mtow}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.aircraft.foreign}
                                    value={
                                        formik.values.aircraft.isForeign
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                        </div>
                    </Accordion>
                    <Accordion
                        title={Locale.pages.summary.operator}
                        className="margin--b-xl"
                    >
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.fullName}
                                    value={formik.values.aircraft.operator.name}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={getDocumentLabel(
                                        formik.values.aircraft.operator
                                            .isForeigner,
                                        formik.values.aircraft.operator
                                            .isCompany
                                    )}
                                    value={
                                        formik.values.aircraft.operator.document
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.phone}
                                    value={getPhoneWithCode(
                                        formik.values.aircraft.operator
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.email}
                                    value={
                                        formik.values.aircraft.operator.email
                                    }
                                />
                            </div>
                        </div>
                    </Accordion>
                    <Accordion
                        title={Locale.fields.reservation.flightInfo}
                        className="margin--b-xl"
                    >
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.reservation.flightType}
                                    value={reservation.flightTypeLabel}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.nationalizationFlight}
                                    value={
                                        formik.values.isNationalizationFlight
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                            {formik.values.isNationalizationFlight && (
                                <>
                                    <div className="col-12 col-md-4">
                                        <TextInfo
                                            label={
                                                Locale.fields.reservation
                                                    .legalRepresentative.name
                                            }
                                            value={
                                                formik.values
                                                    .legalRepresentativeName
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <TextInfo
                                            label={
                                                Locale.fields.reservation
                                                    .legalRepresentative.phone
                                            }
                                            value={`+${formik.values.legalRepresentativeCountryPhoneCode} ${formik.values.legalRepresentativePhoneNumber}`}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation.alternative
                                    }
                                    value={
                                        formik.values.isAlternativeReserve
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={
                                        Locale.fields.reservation.trailerFork
                                    }
                                    value={
                                        formik.values.hasTrailerFork
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.boarding}
                                    value={
                                        formik.values.hasEmbarkationTransport
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.deboarding}
                                    value={
                                        formik.values.hasDisembarkationTransport
                                            ? Locale.general.yes
                                            : Locale.general.no
                                    }
                                />
                            </div>
                            {(formik.values.hasDisembarkationTransport ||
                                formik.values.hasEmbarkationTransport) && (
                                <div className="col-12 col-md-4">
                                    <TextInfo
                                        label={
                                            Locale.fields.reservation
                                                .handlingCompany
                                        }
                                        value={formik.values.handlingCompany}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.pilot.name}
                                    value={
                                        formik.values.pilot.name ||
                                        Locale.fields.notInformed
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextInfo
                                    label={Locale.fields.phone}
                                    value={
                                        formik.values.pilot.name
                                            ? getPhoneWithCode(
                                                  formik.values.pilot
                                              )
                                            : Locale.fields.notInformed
                                    }
                                />
                            </div>
                        </div>
                    </Accordion>
                    <div className="margin--b-xl">
                        <h3 className="headline-3 color--grayscale-blue-gray margin--b-lg">
                            {Locale.pages.reservation.additionalInfo}
                        </h3>
                        <Textarea
                            id="observation"
                            name="observation"
                            value={formik.values.observation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors?.observation}
                        />
                    </div>
                    {(listDocuments.length > 0 || loadingDocs) && (
                        <Card kind="outlined">
                            <h3 className="color--grayscale-blue-gray margin--b-md">
                                {Locale.pages.summary.guidelines}
                            </h3>
                            {!loadingDocs ? (
                                listDocuments.map((document) => (
                                    <div
                                        className="margin--b-md"
                                        key={`airport-document-${document.id}`}
                                    >
                                        <a
                                            href={document.filepath}
                                            target="_blank"
                                            download
                                            rel="noreferrer"
                                        >
                                            <List
                                                after={
                                                    <img
                                                        src={icoExpandRight}
                                                        alt=""
                                                    />
                                                }
                                                text={document.name}
                                                className="rounded background-color--grayscale-white"
                                            />
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div className="padding--b-md">
                                    <Loading />
                                </div>
                            )}
                            <Checkbox
                                id="confirmGuideline"
                                name="confirmGuideline"
                                label={Locale.fields.user.confirmGuideline}
                                checked={formik.values.confirmGuideline}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.confirmGuideline &&
                                    formik.errors.confirmGuideline
                                }
                            />
                        </Card>
                    )}
                </ContainerForm>
                <StickyFooter>
                    <Button
                        kind="primary"
                        type="submit"
                        disabled={
                            !formik.values.confirmGuideline &&
                            listDocuments?.length > 0
                        }
                        isLoading={loadingSave}
                    >
                        {Locale.actions.finishReservation}
                    </Button>
                </StickyFooter>
            </form>
        </div>
    );
}

export default Summary;
