import BaseRegion from './BaseRegion';

export default class State extends BaseRegion {
    constructor(data) {
        super();

        this.baseConstructor(data);
    }

    getPtName(data) {
        if (data.geonameId === State.filterIds.federalDistrict) {
            return data.alternateNames;
        }

        return this.name;
    }

    static get filterParams() {
        return {
            countryId: 'country__geoname_id',
        };
    }

    static get filterIds() {
        return {
            federalDistrict: 3463504,
        };
    }

    static get brazilianStates() {
        return {
            AC: '3665474',
            AL: '3408096',
            AP: '3407762',
            AM: '3665361',
            BA: '3471168',
            CE: '3402362',
            ES: '3463930',
            GO: '3462372',
            MA: '3395443',
            MT: '3457419',
            MS: '3457415',
            MG: '3457153',
            PA: '3393129',
            PB: '3393098',
            PR: '3455077',
            PE: '3392268',
            PI: '3392213',
            RJ: '3451189',
            RN: '3390290',
            RS: '3451133',
            RO: '3924825',
            RR: '3662560',
            SC: '3450387',
            SP: '3448433',
            SE: '3447799',
            TO: '3474575',
            DF: '3463504',
        };
    }
}
