import { Aircraft } from 'models';
import { post, patch, remove, get } from './axios';

const AircraftService = {
    async getAircraft(pk, params = null) {
        return get(`aircraft/${pk}`, { params }).then((response) =>
            Aircraft.createFromResponse(response[0] || response)
        );
    },

    deleteAircraft(pk) {
        return remove(`aircraft/${pk}`);
    },

    listAircraft(params) {
        return get('aircraft/', { params });
    },

    patchAircraft(aircraft) {
        const data = aircraft.parseToRequest();
        delete data.registration;
        return patch(`aircraft/${aircraft.id}`, data);
    },

    async postAircraft(aircraft) {
        const data = aircraft.parseToRequest();
        return post('aircraft/', data).then((response) =>
            Aircraft.createFromResponse(response)
        );
    },

    checkRegistration(aircraftRegistration) {
        return get(`aircraft/check_registration/${aircraftRegistration}`);
    },
};

const AircraftDocumentService = {
    listAircraftDocuments(aircraftId, params) {
        return get(`aircraft/${aircraftId}/document/`, { params });
    },

    createAircraftDocument(aircraftId, data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return post(`aircraft/${aircraftId}/document/`, formData, config);
    },

    getAircraftDocument(aircraftId, pk) {
        return get(`aircraft/${aircraftId}/document/${pk}`);
    },

    patchAircraftDocument(aircraftId, pk, data) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return patch(`aircraft/${aircraftId}/document/${pk}`, formData);
    },

    removeAircraftDocument(aircraftId, pk) {
        return remove(`aircraft/${aircraftId}/document/${pk}`);
    },
};

const AircraftImageService = {
    listAircraftImages(aircraftId, params) {
        return get(`aircraft/${aircraftId}/image/`, { params });
    },

    createAircraftImage(aircraftId, data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return post(`aircraft/${aircraftId}/image/`, formData, config);
    },

    getAircraftImage(aircraftId, pk) {
        return get(`aircraft/${aircraftId}/image/${pk}`);
    },

    patchAircraftImage(aircraftId, pk, data) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        return patch(`aircraft/${aircraftId}/image/${pk}`, formData);
    },

    removeAircraftImage(aircraftId, pk) {
        return remove(`aircraft/${aircraftId}/image/${pk}`);
    },
};

export default {
    ...AircraftService,
    ...AircraftDocumentService,
    ...AircraftImageService,
};
