import moment from 'moment';
import Aircraft from './Aircraft';
import BaseModel from './BaseModel';

class AircraftSolicitation extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;

        this.status = data.status;
        this.type = data.type;
        this.aircraft = Aircraft.getValueOrNew(data.aircraft);
        this.comments = data.comments;

        this.image = data.image;
        this.document = data.document;

        this.createdAt = data.createdAt && moment(data.createdAt).toDate();
        this.updatedAt = data.updatedAt && moment(data.updatedAt).toDate();
        this.dataFillingDate =
            data.dataFillingDate && moment(data.dataFillingDate).toDate();
        this.validationDate =
            data.validationDate && moment(data.validationDate).toDate();
    }

    static createEmpty() {
        return new AircraftSolicitation({
            aircraft: Aircraft.createEmpty(),
        });
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new AircraftSolicitation({
            ...response,
            aircraft: Aircraft.createFromResponse(response.aircraftData),
        });
    }

    parseToRequest() {
        return { ...this, aircraftData: this.aircraft.parseToRequest() };
    }

    static get orderingParams() {
        return {
            id: 'id',
            registration: 'aircraft_data__registration',
            icaoModel: 'aircraft_data__icao_model',
            type: 'type',
            status: 'status',
            createdAt: 'created_at',
        };
    }

    static get filterParams() {
        return {
            status: 'status',
            notStatus: 'not_status',
            type: 'type',
            search: 'search',
            dataFillingStartDate: 'data_filling_date_range_after',
            dataFillingEndDate: 'data_filling_date_range_before',
            isForeign: 'is_foreign',
            isMilitary: 'is_military',
        };
    }
}

export default AircraftSolicitation;
