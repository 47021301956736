import { Line, Textarea, Button, Radio } from 'components';
import { useFormik } from 'formik';
import Locale from 'locale';
import './styles.scss';
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Service from 'services';
import * as Yup from 'yup';
import { AircraftSolicitationStatus } from 'utils/enums';

function ValidateForms({ loadData, aircraftSolicitation }) {
    const [isLoading, setIsLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const initialValues = {
        comments: '',
        selectedOption: '',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            setIsLoading(true);
            const data = { comments: values.comments };
            if (values.selectedOption === 'approve') {
                Service.approveSolicitation(aircraftSolicitation.id, data).then(
                    () => {
                        loadData();
                    }
                );
            } else if (values.selectedOption === 'disapprove') {
                Service.disapproveSolicitation(
                    aircraftSolicitation.id,
                    data
                ).then(() => {
                    loadData();
                });
            }
            setIsLoading(false);
        },
        validationSchema: Yup.object().shape({
            selectedOption: Yup.string().required(),
            comments: Yup.string().when('selectedOption', {
                is: 'disapprove',
                then: Yup.string().required(Locale.errors.required),
            }),
        }),
    });

    const handleSelectedOptionClick = (selectedValue) => {
        formik.setFieldValue('selectedOption', selectedValue);
    };

    useEffect(() => {
        if (
            aircraftSolicitation.status !==
            AircraftSolicitationStatus.pendingValidation
        ) {
            formik.setFieldValue(
                'selectedOption',
                aircraftSolicitation.status ===
                    AircraftSolicitationStatus.approvedValidation
                    ? 'approve'
                    : 'disapprove'
            );
            formik.setFieldValue('comments', aircraftSolicitation.comments);
            setDisableSubmit(true);
        }
    }, [aircraftSolicitation]);

    return (
        <form
            className="validate-forms stack--md padding--sm margin--t-md"
            onSubmit={formik.handleSubmit}
        >
            <p className="color--grayscale-dark font-size--md fw-semibold">
                {
                    Locale.pages.backoffice.solicitations.details
                        .changeAircraftStatus
                }
            </p>
            <div className="flex--row inline--md">
                <Radio
                    id="approve"
                    name="approve"
                    label={
                        Locale.pages.backoffice.solicitations.details
                            .validateAircraft
                    }
                    checked={formik.values.selectedOption === 'approve'}
                    onClick={() => handleSelectedOptionClick('approve')}
                    disabled={disableSubmit}
                />
                <Radio
                    id="disapprove"
                    name="disapprove"
                    label={
                        Locale.pages.backoffice.solicitations.details
                            .reproveAircraft
                    }
                    checked={formik.values.selectedOption === 'disapprove'}
                    onClick={() => handleSelectedOptionClick('disapprove')}
                    disabled={disableSubmit}
                />
            </div>
            <Textarea
                label={
                    formik.values?.selectedOption === 'disapproved'
                        ? Locale.pages.backoffice.solicitations.details
                              .justification
                        : Locale.pages.backoffice.solicitations.details
                              .observation
                }
                id="comments"
                name="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                disabled={disableSubmit}
                required={formik.values?.selectedOption === 'disapproved'}
                error={formik.errors?.comments}
            />
            <Line className="divider margin--t-md margin--b-lg" />
            <div className="margin--y-sm flex--row flex-justify--end">
                <Button
                    type="submit"
                    kind="primary"
                    disabled={disableSubmit || !formik.values.selectedOption}
                    isLoading={isLoading}
                >
                    {Locale.actions.send}
                </Button>
            </div>
        </form>
    );
}

ValidateForms.propTypes = {
    aircraftSolicitation: PropTypes.object.isRequired,
    loadData: PropTypes.func.isRequired,
};

export default ValidateForms;
