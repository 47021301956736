import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import TabGroup from 'components/TabGroup';
import Locale from 'locale';
import { Aircraft, Airport } from 'models';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import DebtList from './DebtList';
import DebtHistory from './DebtHistory';

function DebtHub() {
    const location = useLocation();
    const [aircraftList, setAircraftList] = useState([]);
    const [airportList, setAirportList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const listAircraft = () => {
        Service.listAircraft({})
            .then((data) => {
                setAircraftList(Aircraft.createListFromResponse(data));
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const listAirports = () => {
        Service.listAirports({
            [Airport.filterParams.isActive]: true,
            [Airport.filterParams.isManagedByCcr]: true,
        })
            .then((response) => {
                const airportsResponse =
                    Airport.createListFromResponse(response);
                setAirportList(airportsResponse);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    useEffect(() => {
        listAircraft();
        listAirports();
    }, []);

    const tabs = [
        {
            title: Locale.pages.debt.tabs.debt,
            content: (
                <DebtList
                    aircraftList={aircraftList}
                    airportList={airportList}
                />
            ),
            name: 'debt',
        },
        {
            title: Locale.pages.debt.tabs.history,
            content: (
                <DebtHistory
                    aircraftList={aircraftList}
                    airportList={airportList}
                />
            ),
            name: 'history',
        },
    ];

    const getInitialTabIndex = () => {
        if (location.state?.initialTab) {
            return tabs.findIndex(
                (tab) => tab.name === location.state.initialTab
            );
        }
        if (searchParams.get('activeTab')) {
            return tabs.findIndex(
                (tab) => tab.name === searchParams.get('activeTab')
            );
        }

        return 0;
    };

    const [activeTabIndex] = useState(getInitialTabIndex());

    return (
        <TabGroup
            tabs={tabs}
            activeTab={activeTabIndex}
            onTabChange={(tab) => {
                setSearchParams({
                    activeTab: tab.name,
                });
            }}
        />
    );
}

export default DebtHub;
