import { Button, FullPageModal, TextInfo } from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import momentTZ from 'moment-timezone';
import { Aircraft, Pilot, Reservation } from 'models';
import { getDocumentLabel, getPhoneWithCode } from 'utils/functions';
import { InfoField } from '..';

export default function InformationModal({
    reservation,
    requester,
    aircraft,
    commander,
    observation,
    show,
    onClose,
}) {
    const info = [
        {
            title: `${Locale.fields.reservation.landing} ${Locale.general.and} ${Locale.fields.reservation.takeOff}`,
            fields: [
                {
                    title: Locale.fields.reservation.origin,
                    value: reservation.originFlight.airport.icaoName,
                },
                {
                    title: Locale.fields.destination,
                    value: reservation.airportTarget.icaoName,
                },
                {
                    title: Locale.fields.reservation.landingDate,
                    value: momentTZ(reservation.originFlight.datetime)
                        .tz(reservation.airportTarget.timezone)
                        .format('DD/MM/YYYY'),
                },
                {
                    title: Locale.fields.reservation.landingHour,
                    value: momentTZ(reservation.originFlight.datetime)
                        .tz(reservation.airportTarget.timezone)
                        .format('HH:mm'),
                },
                {
                    title: Locale.fields.reservation.peopleOnBoard,
                    value: reservation.originFlight.peopleOnBoard,
                },
                {
                    title: Locale.fields.reservation.patioReservation,
                    value: reservation.hasPatioReservation
                        ? Locale.general.yes
                        : Locale.general.no,
                },
                ...(reservation.hasTakeOffPrevision
                    ? [
                          {
                              title: Locale.fields.reservation.nextDestination,
                              value: reservation.destinationFlight.airport
                                  .icaoName,
                          },
                          {
                              title: Locale.fields.reservation.takeOffDate,
                              value: momentTZ(
                                  reservation.destinationFlight.datetime
                              )
                                  .tz(reservation.airportTarget.timezone)
                                  .format('DD/MM/YYYY'),
                          },
                          {
                              title: Locale.fields.reservation.takeOffHour,
                              value: momentTZ(
                                  reservation.destinationFlight.datetime
                              )
                                  .tz(reservation.airportTarget.timezone)
                                  .format('HH:mm'),
                          },
                          {
                              title: Locale.fields.reservation.peopleOnBoard,
                              value: reservation.destinationFlight
                                  .peopleOnBoard,
                              key: 'destination',
                          },
                      ]
                    : []),
            ],
        },
        {
            title: Locale.fields.requester,
            fields: [
                {
                    title: Locale.fields.fullName,
                    value: requester.name,
                },
                {
                    title: getDocumentLabel(
                        requester.isForeigner,
                        requester.isCompany
                    ),
                    value: requester.document,
                },
                {
                    title: Locale.fields.phone,
                    value: getPhoneWithCode(requester),
                },
                {
                    title: Locale.fields.email,
                    value: requester.email,
                },
            ],
        },
        {
            title: Locale.fields.aircraft.title,
            fields: [
                {
                    title: Locale.fields.aircraft.registration,
                    value: aircraft.registration,
                },
                {
                    title: Locale.fields.aircraft.icao,
                    value: aircraft.icaoModel,
                },
                {
                    title: Locale.fields.aircraft.mtow,
                    value: aircraft.mtow,
                },
                {
                    title: Locale.fields.aircraft.foreign,
                    value: aircraft.isForeign
                        ? Locale.general.yes
                        : Locale.general.no,
                },
            ],
        },
        {
            title: Locale.fields.commander,
            fields: [
                {
                    title: Locale.fields.pilot.name,
                    value: commander?.name || Locale.fields.notInformed,
                },
                {
                    title: Locale.fields.phone,
                    value: commander
                        ? getPhoneWithCode(commander)
                        : Locale.fields.notInformed,
                },
            ],
        },
        {
            title: Locale.fields.operator.title,
            fields: [
                {
                    title: Locale.fields.fullName,
                    value: aircraft.operator.name,
                },
                {
                    title: getDocumentLabel(
                        requester.isForeigner,
                        requester.isCompany
                    ),
                    value: aircraft.operator.document,
                },
                {
                    title: Locale.fields.phone,
                    value: getPhoneWithCode(aircraft.operator),
                },
                {
                    title: Locale.fields.email,
                    value: aircraft.operator.email,
                },
            ],
        },
    ];

    return (
        <FullPageModal
            title={Locale.pages.reservationDetail.modal}
            show={show}
            onClose={onClose}
            endPageButton={
                <Button kind="text" onClick={onClose}>
                    {Locale.actions.back}
                </Button>
            }
        >
            <div className="stack--lg padding-bottom-sticky-footer">
                {info.map((category) => (
                    <div
                        key={`category-${category.title}`}
                        className="stack--md"
                    >
                        <div className="headline-4 color--grayscale-blue-gray">
                            {category.title}
                        </div>
                        <div className="row fields-stack-mobile">
                            {category.fields.map((field) => (
                                <div
                                    key={`${field.key ? `${field.key}-` : ''}${
                                        field.title
                                    }-${field.value}`}
                                    className="col-md-3 break-word"
                                >
                                    <InfoField
                                        title={field.title}
                                        value={field.value}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {observation && (
                    <div className="stack--md">
                        <div className="headline-4 color--grayscale-blue-gray">
                            {Locale.pages.reservation.additionalInfo}
                        </div>
                        <div className="row fields-stack-mobile margin--b-md">
                            <TextInfo value={observation} />
                        </div>
                    </div>
                )}
                <div className="overline-2">
                    {`${Locale.pages.reservationDetail.createdAt} ${momentTZ(
                        reservation.createdAt
                    ).format(`DD MMM, YYYY [${Locale.general.at}] HH:mm`)}`}
                </div>
            </div>
        </FullPageModal>
    );
}

InformationModal.propTypes = {
    reservation: PropTypes.instanceOf(Reservation).isRequired,
    requester: PropTypes.any.isRequired, // TODO: mudar pra ser o shape da classe de Requester
    aircraft: PropTypes.instanceOf(Aircraft).isRequired,
    commander: PropTypes.instanceOf(Pilot),
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    observation: PropTypes.string,
};

InformationModal.defaultProps = {
    observation: '',
    commander: null,
};
