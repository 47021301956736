import BaseModel from './BaseModel';
import DailyWorkSchedule from './DailyWorkSchedule';

class WeeklyWorkSchedule extends BaseModel {
    constructor(data) {
        super();

        this.monday = DailyWorkSchedule.getValueOrNew(data.monday);
        this.tuesday = DailyWorkSchedule.getValueOrNew(data.tuesday);
        this.wednesday = DailyWorkSchedule.getValueOrNew(data.wednesday);
        this.thursday = DailyWorkSchedule.getValueOrNew(data.thursday);
        this.friday = DailyWorkSchedule.getValueOrNew(data.friday);
        this.saturday = DailyWorkSchedule.getValueOrNew(data.saturday);
        this.sunday = DailyWorkSchedule.getValueOrNew(data.sunday);
    }

    static createEmpty() {
        return new WeeklyWorkSchedule({
            monday: DailyWorkSchedule.createEmpty(),
            tuesday: DailyWorkSchedule.createEmpty(),
            wednesday: DailyWorkSchedule.createEmpty(),
            thursday: DailyWorkSchedule.createEmpty(),
            friday: DailyWorkSchedule.createEmpty(),
            saturday: DailyWorkSchedule.createEmpty(),
            sunday: DailyWorkSchedule.createEmpty(),
        });
    }

    parseToRequest() {
        return {
            monday: this.monday.parseToRequest(),
            tuesday: this.tuesday.parseToRequest(),
            wednesday: this.wednesday.parseToRequest(),
            thursday: this.thursday.parseToRequest(),
            friday: this.friday.parseToRequest(),
            saturday: this.saturday.parseToRequest(),
            sunday: this.sunday.parseToRequest(),
        };
    }

    static createFromResponse(response) {
        if (!response) {
            return WeeklyWorkSchedule.createEmpty();
        }

        return new WeeklyWorkSchedule({
            monday: DailyWorkSchedule.createFromResponse(response.monday),
            tuesday: DailyWorkSchedule.createFromResponse(response.tuesday),
            wednesday: DailyWorkSchedule.createFromResponse(response.wednesday),
            thursday: DailyWorkSchedule.createFromResponse(response.thursday),
            friday: DailyWorkSchedule.createFromResponse(response.friday),
            saturday: DailyWorkSchedule.createFromResponse(response.saturday),
            sunday: DailyWorkSchedule.createFromResponse(response.sunday),
        });
    }
}

export default WeeklyWorkSchedule;
