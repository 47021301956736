import Joyride, { STATUS } from 'react-joyride';
import PropTypes from 'prop-types';
import { TourTooltip } from 'components';

export default function Tour(props) {
    const { onTourFinish, ...args } = props;

    const handleCallback = (data) => {
        const { status } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            onTourFinish();
        }
    };

    return (
        <Joyride
            continuous
            autoStart
            disableScrolling
            disableOverlayClose
            disableCloseOnEsc
            tooltipComponent={TourTooltip}
            callback={handleCallback}
            styles={{
                options: {
                    arrowColor: '#000000',
                    overlayColor: '#303541F0',
                },
            }}
            {...args}
        />
    );
}

Tour.propTypes = {
    onTourFinish: PropTypes.func,
};

Tour.defaultProps = {
    onTourFinish: undefined,
};
