import { get } from './axios';

const CustomerService = {
    getCustomer() {
        return get('customer/');
    },

    getCustomerByUserId(id) {
        return get(`customer_by_user_id/${id}`);
    },
};

export default CustomerService;
