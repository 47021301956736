import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function TabGroup({ tabs, activeTab, onTabChange }) {
    const activeTabIndex =
        activeTab <= tabs.length && activeTab >= 0 ? activeTab : 0;
    const [active, setActive] = useState(activeTabIndex);

    const getTabClassName = (tabName) => (tabName ? `tab__${tabName}` : '');

    return (
        <>
            <div className="button-group">
                {tabs.map((tab, index) => (
                    <div
                        key={tab.title}
                        onClick={() => {
                            onTabChange(tab, index);
                            setActive(index);
                        }}
                        className={`tab ${getTabClassName(tab?.name)} ${
                            active === index ? 'tab-active' : ''
                        }`}
                    >
                        {tab.title}
                    </div>
                ))}
            </div>
            {tabs[active].content}
        </>
    );
}

TabGroup.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.node),
                PropTypes.node,
                PropTypes.string,
            ]).isRequired,
        }).isRequired
    ).isRequired,
    activeTab: PropTypes.number,
    onTabChange: PropTypes.func,
};

TabGroup.defaultProps = {
    activeTab: 0,
    onTabChange: () => {},
};

export default TabGroup;
