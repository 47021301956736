import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    position: relative;

    .negative-margin-before {
        margin-left: -13px;
    }

    /* DATEPICKER */
    .react-datepicker-popper {
        width: auto;
        margin: 0 !important;
        z-index: 9999 !important;
    }

    .react-datepicker.timepicker-custom {
        width: 100%;
        border: none;
        background-color: ${(props) => props.theme.colors.grayscale.white};
        border-radius: 0.2rem;
        border: 1px solid ${(props) => props.theme.colors.grayscale.lightGray};
        padding: 0;
        display: flex;

        &.filled {
            border: none;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08),
                0px -1px 4px rgba(0, 0, 0, 0.04);
        }
    }

    .react-datepicker__time-container {
        border: none;
    }

    .react-datepicker.timepicker-custom .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker.timepicker-custom .react-datepicker__header {
        padding: 0;
        margin-bottom: 0;
    }

    .react-datepicker.timepicker-custom .react-datepicker-time__header,
    .react-datepicker.timepicker-custom .react-datepicker__time-name {
        font-size: ${(props) => props.theme.fonts.sm};
        color: ${(props) => props.theme.colors.grayscale.dark};
        font-weight: 400;
        line-height: ${(props) => props.theme.line_height.sm};
        margin: 0;
        padding: ${(props) => props.theme.spacing.sm};
        border-radius: 0;
        flex: 1;
    }

    .react-datepicker.timepicker-custom .react-datepicker__header {
        background-color: ${(props) => props.theme.colors.grayscale.white};
        border-color: ${(props) => props.theme.colors.grayscale.lightGray};
    }

    .react-datepicker__time-list-item {
        color: ${(props) => props.theme.colors.grayscale.carbonoGray};
    }

    .react-datepicker__time-list-item--selected {
        background-color: ${(props) =>
            props.theme.colors.brand.primaryLight} !important;
        font-weight: bold !important;
    }

    .react-datepicker.timepicker-custom
        .react-datepicker__time-list
        .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
        color: ${(props) => props.theme.colors.grayscale.steelGray};
        opacity: 0.7;
    }
    /* /DATEPICKER */
`;
