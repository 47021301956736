import PropTypes from 'prop-types';
import DtPicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { icoCalendarDuotone } from 'assets/icons';
import { Image, Input } from '..';

import { Container } from './styles';

registerLocale('ptBR', ptBR);

function DatePickerRange({
    id,
    label,
    name,
    startDate,
    endDate,
    dateFormat,
    error,
    required,
    disabled,
    onChange,
    ...args
}) {
    let calendar = {};

    return (
        <Container>
            <DtPicker
                id={id}
                name={name}
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                disabled={disabled}
                calendarClassName="datepicker-custom"
                dateFormat={dateFormat}
                locale={ptBR}
                selectsRange
                isClearable
                formatWeekDay={(d) => d.substring(0, 1)}
                customInput={
                    <Input
                        name="dtName"
                        error={error}
                        label={label}
                        before={
                            <Image
                                src={icoCalendarDuotone}
                                onClick={() => calendar.setOpen(true)}
                                alt=""
                            />
                        }
                    />
                }
                ref={(ref) => {
                    calendar = ref;
                }}
                {...args}
            />
        </Container>
    );
}

DatePickerRange.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    startDate: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.oneOf([null]),
    ]),
    endDate: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.oneOf([null]),
    ]),
    dateFormat: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

DatePickerRange.defaultProps = {
    startDate: '',
    endDate: '',
    label: undefined,
    required: false,
    disabled: false,
    error: '',
    dateFormat: 'dd/MM/yyyy',
    onChange: undefined,
};

export default DatePickerRange;
