import Locale from 'locale';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MaskHelper } from 'helpers';
import { icoCheckDuotone } from 'assets/icons';
import { Image, KeyValueText, Line, Button } from 'components';
import './styles.scss';

export default function PaymentCompleted({ invoiceDetails }) {
    const navigate = useNavigate();
    return (
        <div className="flex--column flex-justify--between pix-payment-completed-wrapper">
            <div className="stack--lg flex--column flex-items--center">
                <Image src={icoCheckDuotone} size={56} alt="ico-check" />
                <p className="color--grayscale-dark font-size--xl fw-semibold">
                    {Locale.pages.invoicePayments.paymentCompleted}
                </p>
                <div className="proof-of-payment-card stack--md">
                    <p className="color--grayscale-dark font-size--md fw-semibold">
                        {Locale.pages.invoicePayments.proofOfPayment}
                    </p>
                    <div className="width--full stack--xs">
                        <p>{Locale.fields.CCRAirports}</p>
                        <KeyValueText
                            label={Locale.fields.document}
                            value={MaskHelper.documentWithLength(
                                invoiceDetails.clienteCPFCNPJ
                            )}
                            className="width--full"
                        />
                    </div>
                    <Line />
                    <div className="width--full stack--xs">
                        <KeyValueText
                            label={Locale.fields.paymentMethod.title}
                            value={Locale.fields.paymentMethod.pix}
                        />
                        <KeyValueText
                            label={Locale.pages.invoicePayments.invoiceNumber}
                            value={invoiceDetails.noDocFaturamento}
                        />
                        <KeyValueText
                            label={Locale.pages.invoicePayments.receiptNumber}
                            value={invoiceDetails.noNotaFiscal}
                        />
                        <KeyValueText
                            label={Locale.pages.invoicePayments.expirationDate}
                            value={moment(invoiceDetails.dtVencimento).format(
                                'DD/MM/YYYY'
                            )}
                        />
                        {/* TODO: Adicionar valor após realizar pagamento */}
                        {/* <KeyValueText
                            label={Locale.pages.invoicePayments.paymentDate}
                            value={moment(VAR_AQUI).format(
                                'DD/MM/YYYY'
                            )}
                        /> */}
                    </div>
                    <KeyValueText
                        className="width--full"
                        label={Locale.pages.invoicePayments.totalPaid}
                        value={new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(invoiceDetails.vlrTotal)}
                        bold
                    />
                </div>
            </div>
            <div className="footer-button-wrapper">
                <Button
                    type="button"
                    kind="outline"
                    onClick={() => {
                        navigate('/debt-hub');
                    }}
                >
                    {Locale.pages.invoicePayments.goBack}
                </Button>
                {/* TODO: Implementar download de comprovante */}
                {/* <Button type="button" kind="primary">
                    {Locale.pages.invoicePayments.downloadProofOfPayment}
                </Button> */}
            </div>
        </div>
    );
}

PaymentCompleted.propTypes = {
    invoiceDetails: PropTypes.object.isRequired,
};
