import { useEffect, useState } from 'react';
import { Image, Loading, ReservationCard } from 'components';
import Popover from 'components/Popover';
import Service from 'services';
import { Billing, Reservation } from 'models';
import { ErrorHelper } from 'helpers';
import { OrderingParams, PaginationParams } from 'utils/enums';
import Locale from 'locale';
import { useNavigate } from 'react-router-dom';
import notify from 'components/Toast';

import {
    icoChevronRightWhite,
    icoCopyDuotone,
    icoCloseDuotone,
} from 'assets/icons';

import './styles.scss';

export default function MyReservations() {
    const [reservations, setReservations] = useState([]);
    const [reservationSelected, setReservationSelected] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchReservationData();
    }, []);

    const handleOpenMyReservations = () => {
        navigate('/reservations');
    };

    const optionsMenu = [
        {
            title: Locale.pages.reservationDetail.reserveAgain,
            icon: icoCopyDuotone,
            onClick: () => {
                // TODO: rever quando não tiver mais a simplificação de correntista
                const reservation = new Reservation(reservationSelected);
                if (reservation.aircraft.isForeign) {
                    navigate('/newreservation/generalinfo', {
                        state: {
                            ...reservation.prepareForReplication(),
                            isInitReplication: true,
                        },
                    });
                } else {
                    Service.getBillingData(reservationSelected.id)
                        .then((response) => {
                            const billing =
                                Billing.createFromResponse(response);
                            navigate('/newreservation/generalinfo', {
                                state: {
                                    ...reservation.prepareForReplication(
                                        billing
                                    ),
                                    isInitReplication: true,
                                },
                            });
                        })
                        .catch(() => {
                            notify(Locale.errors.replicate);
                        });
                }
            },
        },
        ...(reservationSelected?.canCancel
            ? [
                  {
                      title: Locale.pages.reservationDetail.cancel,
                      icon: icoCloseDuotone,
                      onClick: () => {
                          Service.cancel(reservationSelected.id)
                              .then(() => {
                                  setShowMenu(false);
                                  notify(
                                      Locale.status.description.done.cancelled
                                  );
                                  fetchReservationData();
                              })
                              .catch((error) => {
                                  ErrorHelper.notifyError(error);
                              });
                      },
                  },
              ]
            : []),
    ];

    const fetchReservationData = async () => {
        Service.listUserLandingRequests({
            [PaginationParams.page]: 1,
            [PaginationParams.pageSize]: 3,
            [OrderingParams.ordering]: OrderingParams.lastUpdated,
            [Reservation.filterParams.structured]: 'aircraft',
        })
            .then((response) => {
                const reservationsResponse = Reservation.createListFromResponse(
                    response.results
                );
                setReservations(reservationsResponse);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className="container-my-reservations margin--b-xl">
                <div className="content">
                    <div className="header-my-reservations">
                        <h3>
                            {Locale.pages.homeLoggedIn.myReservations.title}
                        </h3>
                        <span onClick={handleOpenMyReservations}>
                            {Locale.pages.homeLoggedIn.myReservations.link}
                            <Image
                                src={icoChevronRightWhite}
                                alt="ícone acessar"
                            />
                        </span>
                    </div>
                    {!loading ? (
                        <div className="margin--b-lg wrapper-reservation">
                            {reservations.map((reservation) => (
                                <ReservationCard
                                    key={reservation.id.toString()}
                                    reservation={reservation}
                                    setReservationSelected={
                                        setReservationSelected
                                    }
                                    openMenu={() => setShowMenu(true)}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="reservations-loading">
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
            <Popover
                show={showMenu}
                options={optionsMenu}
                closeMenu={() => setShowMenu(false)}
            />
        </>
    );
}
