export const BR_PHONE_CODE = '55';

export const BILLING_INTEGRATION_ENABLED = process.env
    .REACT_APP_BILLING_INTEGRATION_ENABLED
    ? process.env.REACT_APP_BILLING_INTEGRATION_ENABLED.toLowerCase() === 'true'
    : false;

export const WEEK_DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const AIRCRAFT_SOLICITATION_PROCESS_ENABLED = process.env
    .REACT_APP_AIRCRAFT_SOLICITATION_PROCESS_ENABLED
    ? process.env.REACT_APP_AIRCRAFT_SOLICITATION_PROCESS_ENABLED.toLocaleLowerCase() ===
      'true'
    : false;

export const DEBT_POOLING_TIMER =
    process.env.REACT_APP_DEBT_POOLING_TIMER_MILLISECONDS;
