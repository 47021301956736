import PropTypes from 'prop-types';
import Locale from 'locale';

function getAirportCode(airport) {
    if (airport?.icaoCode) {
        return airport.icaoCode;
    }
    return Locale.general.noICAOCode;
}

export default function AirportCodeDisplay(props) {
    const { originAirport, targetAirport, destinationAirport, ...args } = props;
    return (
        <div {...args}>
            {getAirportCode(originAirport)} &gt;{' '}
            <span className="color--brand-primary">
                {getAirportCode(targetAirport)}
            </span>
            {destinationAirport && (
                <> &gt; {getAirportCode(destinationAirport)}</>
            )}
        </div>
    );
}

AirportCodeDisplay.propTypes = {
    originAirport: PropTypes.object.isRequired,
    targetAirport: PropTypes.object.isRequired,
    destinationAirport: PropTypes.object,
};

AirportCodeDisplay.defaultProps = {
    destinationAirport: undefined,
};
