import PropTypes from 'prop-types';

import { Line } from 'components';

import { icoClose } from 'assets/icons';

import './styles.scss';

function ConfirmDialog({ title, description, actionsButton, show, onClose }) {
    return (
        show && (
            <div className="modal-container">
                <div className="backdrop-dialog" onClick={onClose} />
                <div className="container-dialog">
                    <div className="dialog-header flex-justify--between flex-items--center">
                        <h1 className="headline-1">{title}</h1>
                        <div onClick={onClose}>
                            <img src={icoClose} alt="x" />
                        </div>
                    </div>
                    <div className="dialog-content margin--t-lg">
                        {description && <p className="body-1">{description}</p>}
                    </div>
                    {actionsButton && (
                        <>
                            <Line className="margin--t-lg" />
                            <div className="dialog-bottom flex-justify--end">
                                {actionsButton}
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    actionsButton: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
    description: '',
    actionsButton: undefined,
    show: false,
};

export default ConfirmDialog;
