import PropTypes from 'prop-types';
import { Image } from 'components';
import {
    icoExclamationSecondary,
    icoExclamationYellow,
    icoCloseError,
} from 'assets/icons';
import { Container } from './styles';

const getIcon = (kind, icon) => {
    if (icon) {
        return icon;
    }

    switch (kind) {
        case 'info':
            return icoExclamationSecondary;
        case 'alert':
            return icoExclamationYellow;
        case 'error':
            return icoCloseError;
        default:
            return icoExclamationSecondary;
    }
};

function InformationCard({ kind, icon, title, message }) {
    const selectedIcon = getIcon(kind, icon);
    return (
        <Container kind={kind}>
            <div className="flex--row flex-items--center flex-justify--start">
                <Image
                    className="margin--r-md"
                    src={selectedIcon}
                    size={24}
                    alt={selectedIcon}
                />
                <div className="flex--column flex-items--start stack--sm">
                    {title && (
                        <p className="color--neutral-95 font-size--sm fw-semibold">
                            {title}
                        </p>
                    )}
                    <p className="color--neutral-95 font-size--sm">{message}</p>
                </div>
            </div>
        </Container>
    );
}

InformationCard.propTypes = {
    kind: PropTypes.oneOf(['info', 'alert', 'error']),
    icon: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
};

InformationCard.defaultProps = {
    kind: 'info',
    icon: undefined,
    title: '',
};

export default InformationCard;
