import Locale from 'locale';
import * as Yup from 'yup';
import BaseModel from './BaseModel';

class BaseRegion extends BaseModel {
    constructor() {
        super();

        /* BaseRegion deve ser abstract, mas JS não suporta. */
        if (this.constructor === BaseRegion) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    baseConstructor(data) {
        this.geonameId = data.geonameId;
        this.name = data.name;
        this.alternateNames = data.alternateNames;

        // Helper props
        this.id = data.geonameId;
        this.ptName = this.getPtName(data);
    }

    getPtName(data) {
        if (!data.alternateNames) {
            return this.name;
        }

        return data.alternateNames.split(';')[0];
    }

    parseToRequest() {
        return {
            geonameId: this.geonameId,
        };
    }

    static get validationSchema() {
        return Yup.object().shape({
            id: Yup.string().required(Locale.errors.required),
        });
    }

    get label() {
        return this.nameByLanguage;
    }

    get value() {
        return this.id.toString();
    }

    get nameByLanguage() {
        return Locale.getLanguage() === 'en' ? this.name : this.ptName;
    }
}

export default BaseRegion;
