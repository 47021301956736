import * as Yup from 'yup';
import Locale from 'locale';
import AccessProfile from './AccessProfile';
import BaseModel from './BaseModel';

export default class User extends BaseModel {
    constructor(data) {
        super();

        this.id = data.id;
        this.email = data.email;
        this.isActive = data.isActive;
        this.airports = data.airports || [];
        this.accessProfile = AccessProfile.getValueOrNew(data.accessProfile);
    }

    static createFromResponse(response) {
        return new User({
            ...response,
            accessProfile:
                response.accessProfiles?.length > 0
                    ? response.accessProfiles[0]
                    : response.accessProfiles,
            airports: response.segregations?.map((segregation) => ({
                value: segregation?.value,
                label: segregation?.name,
            })),
        });
    }

    parseToRequest() {
        const data = { ...this };
        delete data.accessProfile;
        return {
            ...data,
            ...(typeof this.accessProfile === 'object'
                ? { accessProfiles: [this.accessProfile.id] }
                : { accessProfiles: [this.accessProfile] }),
        };
    }

    static createEmpty() {
        return new User({
            isActive: true,
            airports: [],
        });
    }

    static get filterParams() {
        return {
            hasAccessProfiles: 'has_access_profiles',
        };
    }

    static get orderingParams() {
        return {
            accessProfiles: 'access_profiles__name',
            isActive: 'is_active',
        };
    }

    static get validationSchema() {
        return Yup.object().shape({
            email: Yup.string()
                .email(Locale.errors.validEmail)
                .required(Locale.errors.required),
            accessProfile: Yup.object().shape({
                id: Yup.number().required(Locale.errors.required),
            }),
        });
    }
}
