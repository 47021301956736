import React from 'react';
import ReactSwitch from 'react-switch';
import PropTypes from 'prop-types';

import { Label } from '..';

import themes from '../../styles/themes/default';
import { Container } from './styles';

function Switch({
    id,
    name,
    label,
    disabled,
    error,
    checked,
    onChange,
    ...args
}) {
    const boxShadow = checked
        ? `0 0 0 8px ${themes.colors.brand.primary25}80`
        : `0 0 0 8px ${themes.colors.grayscale.lightGray}BF`;

    return (
        <Container error={error}>
            <ReactSwitch
                disabled={disabled}
                id={id.toString()}
                name={name}
                onChange={onChange}
                checked={checked}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={24}
                height={14}
                width={35}
                offColor={themes.colors.grayscale.light}
                offHandleColor={themes.colors.grayscale.blueGray}
                onColor={themes.colors.brand.primary25}
                onHandleColor={themes.colors.brand.primary}
                activeBoxShadow={boxShadow}
                {...args}
            />
            <Label htmlFor={id}>
                {label}
                <span>&nbsp;</span>
            </Label>
        </Container>
    );
}

Switch.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.string,
};

Switch.defaultProps = {
    label: '',
    checked: false,
    disabled: false,
    onChange: undefined,
    error: '',
};

export default Switch;
