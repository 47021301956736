import PropTypes from 'prop-types';

import { Label, Text } from 'components';

import './styles.scss';
import { isFalsyExceptZero } from 'utils/functions';

function TextInfo({ label, value, ...args }) {
    return (
        <div className="container-text-label" {...args}>
            {label && <Label>{label}</Label>}
            {!isFalsyExceptZero(value) && <Text kind="body-2">{value}</Text>}
        </div>
    );
}

TextInfo.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
};

TextInfo.defaultProps = {
    label: '',
    value: '',
};

export default TextInfo;
