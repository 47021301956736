import PropTypes from 'prop-types';

import theme from '../../styles/themes/default';

import { H1, H2, H3, H4, H5 } from './styles';

function Title({ tagName, color, children, ...args }) {
    switch (tagName) {
        case 'h2':
            return (
                <H2 color={color} {...args}>
                    {children}
                </H2>
            );
        case 'h3':
            return (
                <H3 color={color} {...args}>
                    {children}
                </H3>
            );
        case 'h4':
            return (
                <H4 color={color} {...args}>
                    {children}
                </H4>
            );
        case 'h5':
            return (
                <H5 color={color} {...args}>
                    {children}
                </H5>
            );
        default:
            return (
                <H1 color={color} {...args}>
                    {children}
                </H1>
            );
    }
}

Title.propTypes = {
    tagName: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Title.defaultProps = {
    tagName: 'h1',
    color: theme.colors.grayscale.blueGray,
};

export default Title;
