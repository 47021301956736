import {
    Button,
    StickyFooter,
    InformationCard,
    KeyValueText,
} from 'components';
import { MaskHelper } from 'helpers';
import { getDocumentLabel } from 'utils/functions';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Locale from 'locale';

export default function AircraftOperator({ aircraft, isSolicitation }) {
    const navigate = useNavigate();
    return (
        <div className="stack--md padding--y-md">
            <div className="stack--md">
                <InformationCard
                    kind="info"
                    message={
                        Locale.pages.aircraftDetails.operatorInfoCardMessage
                    }
                />
                <div className="stack--sm">
                    <KeyValueText
                        label={Locale.fields.operatorShort}
                        value={aircraft.operator?.name}
                    />
                    <KeyValueText
                        label={getDocumentLabel(
                            aircraft.operator?.isForeigner,
                            aircraft.operator?.isCompany
                        )}
                        value={MaskHelper.document(
                            aircraft.operator?.document,
                            aircraft.operator?.isForeigner,
                            aircraft.operator?.isCompany
                        )}
                    />
                    <KeyValueText
                        label={Locale.fields.zipCode}
                        value={aircraft.operator?.address?.postalCode}
                    />
                    <KeyValueText
                        label={Locale.fields.number}
                        value={aircraft.operator?.phoneNumber}
                    />
                    <KeyValueText
                        label={Locale.fields.email}
                        value={aircraft.operator?.email}
                    />
                    {aircraft.operator?.secondEmail && (
                        <KeyValueText
                            label={Locale.fields.email}
                            value={aircraft.operator?.secondEmail}
                        />
                    )}
                    {aircraft.operator?.thirdEmail && (
                        <KeyValueText
                            label={Locale.fields.email}
                            value={aircraft.operator?.thirdEmail}
                        />
                    )}
                    <KeyValueText
                        label={Locale.fields.country}
                        value={aircraft.operator?.address?.country?.name}
                    />
                    <KeyValueText
                        label={Locale.fields.state}
                        value={aircraft.operator?.address?.state?.name}
                    />
                    <KeyValueText
                        label={Locale.fields.streetAddress}
                        value={aircraft.operator?.address?.street}
                    />
                    <KeyValueText
                        label={Locale.fields.number}
                        value={aircraft.operator?.address?.number}
                    />
                    {aircraft.operator?.address?.complement && (
                        <KeyValueText
                            label={Locale.fields.complement}
                            value={aircraft.operator?.address?.complement}
                        />
                    )}
                </div>
            </div>
            {!isSolicitation && (
                <StickyFooter>
                    <div className="aircraft-list-footer">
                        <Button
                            kind="primary"
                            onClick={() =>
                                navigate(
                                    `/aircraft/${aircraft.registration}/edit/operator`
                                )
                            }
                        >
                            {Locale.pages.aircraftDetails.editOperator}
                        </Button>
                    </div>
                </StickyFooter>
            )}
        </div>
    );
}

AircraftOperator.propTypes = {
    aircraft: PropTypes.object.isRequired,
    isSolicitation: PropTypes.bool,
};

AircraftOperator.defaultProps = {
    isSolicitation: false,
};
