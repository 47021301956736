import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
    HeaderPage,
    Card,
    Input,
    TextSwitch,
    Line,
    Button,
    Dropdown,
    Textarea,
    Text,
    LoadingFullPage,
    InputFile,
    ItemFile,
    ConfirmDialog,
    TimePicker,
    Switch,
} from 'components';
import notify from 'components/Toast';

import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';

import './styles.scss';
import { Airport, Country, State } from 'models';
import moment from 'moment-timezone';
import { WEEK_DAYS } from 'utils/constants';

const DATE_PICKER_TIME_INTERVAL = 15;

function AirportDetail() {
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [documentListDelete, setDocumentListDelete] = useState([]);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [states, setStates] = useState(new Map());
    const [countries, setCountries] = useState(new Map());
    const [timezones, setTimezones] = useState([]);
    const navigate = useNavigate();
    const { airportId } = useParams();
    const [editId, setEditId] = useState(airportId);

    const goBack = () => {
        navigate('/backoffice/airports');
    };

    const initialValues = Airport.createEmptyNational();

    const icaoCodeMax = 4;

    const noTimezoneOption = {
        label: Locale.pages.backoffice.airports.noTimezone,
        value: '',
    };

    const validationNewAirportSchema = Yup.object().shape({
        name: Yup.string().required(Locale.errors.required),
        icaoCode: Yup.string()
            .max(icaoCodeMax, Locale.errors.maxLength(icaoCodeMax))
            .required(Locale.errors.required),
        status: Yup.bool().required(Locale.errors.required),
        city: Yup.string().required(Locale.errors.required),
        state: State.validationSchema,
        timezone: Yup.string()
            .nullable()
            .when('isManagedByCcr', {
                is: true,
                then: Yup.string().required(Locale.errors.required),
            }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationNewAirportSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            const airport = new Airport({
                ...values,
            });
            const requestData = airport.parseToRequest();
            if (!editId) {
                createAirport(requestData);
            } else {
                patchAirport(requestData);
            }
        },
    });

    const getCountries = () => {
        Service.getCountries()
            .then((response) => {
                const responseCountries =
                    Country.createListFromResponse(response);
                setCountries(
                    new Map(
                        responseCountries.map((responseCountry) => [
                            responseCountry.value,
                            responseCountry,
                        ])
                    )
                );
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const toggleDialog = () => {
        setShowDialog(!showDialog);
    };

    const handleDeleteAirport = () => {
        setIsLoadingDelete(true);
        Service.removeAirport(editId)
            .then(() => {
                notify(Locale.pages.backoffice.airports.delete);
                setIsLoadingDelete(false);
                goBack();
            })
            .catch((error) => {
                setIsLoadingDelete(false);
                ErrorHelper.notifyError(error);
            });
    };

    const updateDocumentFiles = async (_airportId) => {
        const files = formik.values.airportdocumentSet.filter(
            (item) => item.isUpdated === true
        );

        const updatingDocuments = files.map(async (file) =>
            Service.patchAirportDocument(_airportId, file.id, {
                name: file.name,
                isAcceptanceRequired: file.isAcceptanceRequired,
            })
        );
        return Promise.all(updatingDocuments);
    };

    const createDocumentFiles = async (_airportId) => {
        const files = formik.values.airportdocumentSet.filter(
            (item) => item.isNew === true
        );

        const creatingDocuments = files.map((file, index) =>
            Service.createAirportDocument(_airportId, {
                name: file.name || file.file.name,
                filepath: file.file,
                isAcceptanceRequired: file.isAcceptanceRequired,
            }).then((response) => {
                files[index].isNew = false;
                files[index].isUpdated = true;
                files[index].id = response.id;
            })
        );

        return Promise.all(creatingDocuments).then(() =>
            formik.setFieldValue('airportdocumentSet', files)
        );
    };

    const deleteDocumentFiles = async () => {
        await documentListDelete.forEach((idx) =>
            Service.removeAirportDocument(editId, idx).catch((error) => {
                ErrorHelper.notifyError(error);
                setIsLoading(false);
            })
        );
    };

    const createAirport = async (data) => {
        let airport;
        try {
            airport = await Service.createAirport(data);
            setEditId(airport.id);
        } catch (error) {
            ErrorHelper.notifyError(error);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoadingDocuments(true);
            await createDocumentFiles(airport.id);
            notify(Locale.pages.backoffice.airports.success);
            goBack();
        } catch (error) {
            ErrorHelper.notifyError(error);
            notify(Locale.errors.saveAirportDocuments);
        }
        setIsLoadingDocuments(false);
        setIsLoading(false);
    };

    const patchAirport = async (data) => {
        try {
            await Service.patchAirport(editId, data);
            setIsLoadingDocuments(true);
            await updateDocumentFiles(editId);
            await createDocumentFiles(editId);
            await deleteDocumentFiles();
            notify(Locale.pages.backoffice.airports.update);
            goBack();
        } catch (error) {
            ErrorHelper.notifyError(error);
        }
        setIsLoading(false);
        setIsLoadingDocuments(false);
    };

    const handleUpdateAirportDocumentAcceptance = (document, key) => {
        const files = formik.values.airportdocumentSet;
        const doc = files[key];
        doc.isAcceptanceRequired = !doc.isAcceptanceRequired;
        if (doc.id) {
            doc.isUpdated = true;
        } else {
            doc.isNew = true;
        }
        formik.setFieldValue('airportdocumentSet', files);
    };

    const handleChangeFile = async (e) => {
        const { files } = e.target;
        const updatedList = formik.values.airportdocumentSet || [];
        [...files].map((file) =>
            updatedList.push({
                name: file.name,
                filesize: file.size,
                isAcceptanceRequired: false,
                isNew: true,
                file,
            })
        );
        formik.setFieldValue('airportdocumentSet', updatedList);
    };

    const handleUpdateAirportDocumentName = (name, document, key) => {
        const files = formik.values.airportdocumentSet;
        const doc = files[key];
        doc.name = name;

        if (doc.id) {
            doc.isUpdated = true;
        } else {
            doc.isNew = true;
        }
        formik.setFieldValue('airportdocumentSet', files);
    };

    const handleDeleteAirportDocument = (document, position) => {
        const files = formik.values.airportdocumentSet;

        if (document.id) {
            setDocumentListDelete([...documentListDelete, document.id]);
        }
        files.splice(position, 1);
        formik.setFieldValue('airportdocumentSet', files);
    };

    useEffect(() => {
        getCountries();

        setTimezones(
            moment.tz.names().map((timezone) => ({
                label: timezone,
                value: timezone,
            }))
        );
    }, []);

    useEffect(() => {
        if (!formik.values.country?.id) {
            return;
        }
        Service.getStates({
            [State.filterParams.countryId]: formik.values.country.id,
        })
            .then((response) => {
                const responseStates = State.createListFromResponse(response);
                setStates(
                    new Map(
                        responseStates.map((responseState) => [
                            responseState.value,
                            responseState,
                        ])
                    )
                );
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    }, [formik.values.country?.id]);

    useEffect(() => {
        if (airportId) {
            setEditId(airportId);
            Service.getAirport(airportId)
                .then((response) => {
                    const airport = Airport.createFromResponse(response);
                    formik.setValues((values) => ({
                        ...values,
                        ...airport,
                        airportdocumentSet: response.airportdocumentSet,
                    }));
                    setIsLoadingInitial(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    goBack();
                });
        } else {
            setIsLoadingInitial(false);
        }
    }, [airportId]);

    return !isLoadingInitial ? (
        <div className="padding--lg">
            <HeaderPage
                labelBtnBack={Locale.general.airports}
                onClickBtnBack={goBack}
            />
            <Card kind="outlined">
                <div className="padding--md">
                    <form id="formAirport" onSubmit={formik.handleSubmit}>
                        <div className="margin--b-md">
                            <h4 className="headline-4">
                                {Locale.pages.backoffice.airports.airportData}
                            </h4>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Input
                                        label={
                                            Locale.pages.backoffice.airports
                                                .airportName
                                        }
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        error={
                                            formik.touched.name &&
                                            formik.errors.name
                                        }
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        label={
                                            Locale.pages.backoffice.airports
                                                .icao
                                        }
                                        name="icaoCode"
                                        value={formik.values.icaoCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        maxLength="4"
                                        error={
                                            formik.touched.icaoCode &&
                                            formik.errors.icaoCode
                                        }
                                    />
                                </div>
                            </div>
                            <div className="padding--y-md">
                                <h4 className="headline-4">
                                    {
                                        Locale.pages.backoffice.airports
                                            .openingHours
                                    }
                                </h4>
                                <TextSwitch
                                    id="hasWorkSchedule"
                                    name="hasWorkSchedule"
                                    label={
                                        Locale.pages.backoffice.airports
                                            .hasWorkSchedule
                                    }
                                    checked={formik.values?.hasWorkSchedule}
                                    onChange={() => {
                                        formik.setFieldValue(
                                            'hasWorkSchedule',
                                            !formik.values.hasWorkSchedule
                                        );
                                    }}
                                />
                                {formik.values.hasWorkSchedule && (
                                    <>
                                        <div className="row">
                                            <div className="col-3">
                                                {
                                                    Locale.pages.backoffice
                                                        .airports.days
                                                }
                                            </div>
                                            <div className="col-8">
                                                {
                                                    Locale.pages.backoffice
                                                        .airports.schedule
                                                }
                                            </div>
                                        </div>
                                        {WEEK_DAYS.map((day) => (
                                            <div className="row">
                                                <div className="col-3 flex-items--center">
                                                    <Switch
                                                        id={`weeklyWorkSchedule.${day}.hasDailySchedule`}
                                                        checked={
                                                            formik.values
                                                                ?.weeklyWorkSchedule?.[
                                                                day
                                                            ]?.hasDailySchedule
                                                        }
                                                        label={
                                                            Locale.general
                                                                .weekdays[day]
                                                        }
                                                        name={`weeklyWorkSchedule.${day}.hasDailySchedule`}
                                                        onChange={() => {
                                                            formik.setFieldValue(
                                                                `weeklyWorkSchedule.${day}.hasDailySchedule`,
                                                                !formik.values
                                                                    ?.weeklyWorkSchedule?.[
                                                                    day
                                                                ]
                                                                    ?.hasDailySchedule
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TimePicker
                                                        name={`weeklyWorkSchedule.${day}.openingTime`}
                                                        id={`weeklyWorkSchedule.${day}.openingTime`}
                                                        label={
                                                            Locale.pages
                                                                .backoffice
                                                                .airports
                                                                .opening
                                                        }
                                                        value={
                                                            formik.values
                                                                ?.weeklyWorkSchedule?.[
                                                                day
                                                            ]?.openingTime
                                                        }
                                                        timeIntervals={
                                                            DATE_PICKER_TIME_INTERVAL
                                                        }
                                                        timeCaption={
                                                            Locale.general.hour
                                                        }
                                                        onChange={(time) => {
                                                            formik.setFieldValue(
                                                                `weeklyWorkSchedule.${day}.openingTime`,
                                                                time
                                                            );
                                                        }}
                                                        error={undefined}
                                                        disabled={
                                                            !formik.values
                                                                ?.weeklyWorkSchedule?.[
                                                                day
                                                            ]?.hasDailySchedule
                                                        }
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TimePicker
                                                        name={`weeklyWorkSchedule.${day}.closingTime`}
                                                        id={`weeklyWorkSchedule.${day}.closingTime`}
                                                        label={
                                                            Locale.pages
                                                                .backoffice
                                                                .airports
                                                                .closing
                                                        }
                                                        value={
                                                            formik.values
                                                                ?.weeklyWorkSchedule?.[
                                                                day
                                                            ]?.closingTime
                                                        }
                                                        timeIntervals={
                                                            DATE_PICKER_TIME_INTERVAL
                                                        }
                                                        timeCaption={
                                                            Locale.general.hour
                                                        }
                                                        onChange={(time) => {
                                                            formik.setFieldValue(
                                                                `weeklyWorkSchedule.${day}.closingTime`,
                                                                time
                                                            );
                                                        }}
                                                        error={undefined}
                                                        disabled={
                                                            !formik.values
                                                                ?.weeklyWorkSchedule?.[
                                                                day
                                                            ]?.hasDailySchedule
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <TextSwitch
                                        id="status"
                                        name="status"
                                        label={
                                            Locale.pages.backoffice.airports
                                                .status
                                        }
                                        checked={formik.values.status}
                                        required
                                        error={formik.errors.status}
                                        onChange={(e) =>
                                            formik.setFieldValue('status', e)
                                        }
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <TextSwitch
                                        id="isManagedByCcr"
                                        name="isManagedByCcr"
                                        label={
                                            Locale.fields.airport
                                                .managedFullName
                                        }
                                        checked={formik.values.isManagedByCcr}
                                        required
                                        error={formik.errors.status}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'isManagedByCcr',
                                                e
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="margin--b-xs">
                            <h4 className="headline-4">
                                {Locale.fields.address}
                            </h4>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <Dropdown
                                        label={Locale.fields.country}
                                        name="country.id"
                                        id="country.id"
                                        options={Array.from(countries.values())}
                                        value={formik.values.country.id}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'country',
                                                countries.get(e.target.value)
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        required
                                        error={
                                            formik.touched.country?.id &&
                                            formik.errors.country?.id
                                        }
                                        autocomplete
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <Dropdown
                                        label={Locale.fields.state}
                                        name="state.id"
                                        id="state.id"
                                        options={Array.from(states.values())}
                                        value={formik.values.state.id}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'state',
                                                states.get(e.target.value)
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        required
                                        error={
                                            formik.touched.state?.id &&
                                            formik.errors.state?.id
                                        }
                                        autocomplete
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <Input
                                        label={Locale.fields.city}
                                        name="city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        error={
                                            formik.touched.city &&
                                            formik.errors.city
                                        }
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <Dropdown
                                        label={Locale.fields.airport.timezone}
                                        name="timezone"
                                        id="timezone"
                                        options={
                                            formik.values.isManagedByCcr
                                                ? timezones
                                                : [
                                                      noTimezoneOption,
                                                      ...timezones,
                                                  ]
                                        }
                                        value={formik.values.timezone}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'timezone',
                                                e.target.value || null
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        required
                                        error={
                                            formik.touched.timezone &&
                                            formik.errors.timezone
                                        }
                                        autocomplete
                                    />
                                </div>
                            </div>
                        </div>
                        <Line />
                        <div className="margin--b-lg">
                            <h4 className="headline-4 margin--b-xs">
                                {
                                    Locale.pages.backoffice.airports
                                        .titleDocumentation
                                }
                            </h4>
                            <Text kind="body-2" className="margin--b-sm">
                                {
                                    Locale.pages.backoffice.airports
                                        .textDocumentation
                                }
                            </Text>
                            <InputFile
                                name="upload"
                                label={
                                    Locale.pages.backoffice.airports.files.btn
                                }
                                accept="application/pdf, application/msword"
                                multiple
                                onChange={(e) => handleChangeFile(e)}
                            />
                            {formik.values.airportdocumentSet?.length > 0 && (
                                <div className="margin--t-lg">
                                    <h4 className="headline-4 margin--b-xs">
                                        {
                                            Locale.pages.backoffice.airports
                                                .nameDocument
                                        }
                                    </h4>
                                    <div className="margin--t-sm">
                                        {formik.values.airportdocumentSet?.map(
                                            (doc, key) => (
                                                <ItemFile
                                                    key={`airportdocumentSet-${Math.floor(
                                                        Math.random() * 1000
                                                    )}`}
                                                    title={doc.name}
                                                    size={doc.filesize || 0}
                                                    filepath={doc.filepath}
                                                    isNew={!!doc.isNew}
                                                    loading={isLoadingDocuments}
                                                    accept={
                                                        !!doc.isAcceptanceRequired
                                                    }
                                                    onChangeName={(name) => {
                                                        handleUpdateAirportDocumentName(
                                                            name,
                                                            doc,
                                                            key
                                                        );
                                                    }}
                                                    onAccept={() => {
                                                        handleUpdateAirportDocumentAcceptance(
                                                            doc,
                                                            key
                                                        );
                                                    }}
                                                    onDelete={() => {
                                                        handleDeleteAirportDocument(
                                                            doc,
                                                            key
                                                        );
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {formik.values.isManagedByCcr && (
                            <div className="margin--b-lg col-12 col-md-6">
                                <h4 className="headline-4 margin--b-xs">
                                    {
                                        Locale.pages.backoffice.airports
                                            .contactEmail
                                    }
                                </h4>
                                <Text kind="body-2" className="margin--b-sm">
                                    {
                                        Locale.pages.backoffice.airports
                                            .textContactEmail
                                    }
                                </Text>
                                <Input
                                    name="contactEmail"
                                    value={formik.values.contactEmail || ''}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.contactEmail &&
                                        formik.errors.contactEmail
                                    }
                                />
                            </div>
                        )}
                        <div className="margin--b-lg">
                            <h4 className="headline-4 margin--b-xs">
                                {
                                    Locale.pages.backoffice.airports
                                        .contactDetails
                                }
                            </h4>
                            <Text kind="body-2" className="margin--b-sm">
                                {
                                    Locale.pages.backoffice.airports
                                        .textContactDetails
                                }
                            </Text>
                            <Textarea
                                name="contactData"
                                value={formik.values.contactData || ''}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.contactData &&
                                    formik.errors.contactData
                                }
                            />
                        </div>
                        <Line />
                        <div
                            className={clsx('margin--t-lg', {
                                'flex-justify--between': editId,
                                'flex-justify--end': !editId,
                            })}
                        >
                            {editId && (
                                <div className="center-content">
                                    <Button
                                        kind="outline"
                                        type="button"
                                        onClick={() => toggleDialog()}
                                    >
                                        {Locale.actions.deleteAirport}
                                    </Button>
                                </div>
                            )}
                            <div className="center-content">
                                <Button
                                    kind="outline"
                                    type="button"
                                    className="margin--r-md"
                                    onClick={goBack}
                                >
                                    {Locale.actions.cancel}
                                </Button>
                                <Button
                                    type="submit"
                                    kind="primary"
                                    disabled={!(formik.isValid && formik.dirty)}
                                    isLoading={isLoading}
                                >
                                    <p>
                                        {!editId
                                            ? Locale.actions.send
                                            : Locale.actions.edit}
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Card>
            <ConfirmDialog
                title={Locale.actions.deleteAirport}
                description={Locale.pages.backoffice.airports.textDeleteConfirm}
                show={showDialog}
                onClose={() => toggleDialog()}
                actionsButton={
                    <>
                        <Button
                            kind="outline"
                            type="button"
                            className="margin--r-md"
                            onClick={() => toggleDialog()}
                        >
                            {Locale.actions.cancel}
                        </Button>
                        <Button
                            type="button"
                            kind="primary"
                            onClick={() => handleDeleteAirport()}
                            isLoading={isLoadingDelete}
                        >
                            <p>{Locale.actions.delete}</p>
                        </Button>
                    </>
                }
            />
        </div>
    ) : (
        <LoadingFullPage />
    );
}

export default AirportDetail;
