import { icoExpandLeft } from 'assets/icons';
import { LoadingFullPage, ButtonIcon } from 'components';
import Locale from 'locale';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { Aircraft, AircraftSolicitation } from 'models';
import {
    AircraftSolicitationStatus,
    AircraftSolicitationType,
    OrderingParams,
} from 'utils/enums';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';
import EmptyAircraftList from './EmptyAircraftList';
import FilledAircraftList from './FilledAircraftList';

export default function AircraftList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isFetchingAircraftData, setIsFetchingAircraftData] = useState(true);
    const [isFetchingSolicitationData, setIsFetchingSolicitationData] =
        useState(false);
    const [aircraftsFromSolicitations, setAircraftsFromSolicitations] =
        useState([]);
    const [aircrafts, setAircrafts] = useState([]);
    const [aircraftList, setAircraftList] = useState([]);
    const [totalAircraftCount, setTotalAircraftCount] = useState(0);
    const [totalSolicitationsCount, setTotalSolicitationsCount] = useState(0);
    const [filters, setFilters] = useState({});

    const getAircraft = async () => {
        setIsFetchingAircraftData(true);

        const apiFilters = {
            ...filters,
            [OrderingParams.ordering]: OrderingParams.lastUpdated,
        };

        Service.listAircraft(apiFilters)
            .then((response) => {
                setAircrafts(Aircraft.createListFromResponse(response));
                if (!totalAircraftCount) {
                    setTotalAircraftCount(response.length);
                }
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            })
            .finally(() => {
                setIsFetchingAircraftData(false);
            });
    };

    const getSolicitations = async () => {
        setIsFetchingSolicitationData(true);

        const apiFilters = {
            ...filters,
            [OrderingParams.ordering]: OrderingParams.lastUpdated,
            [AircraftSolicitation.filterParams.type]:
                AircraftSolicitationType.creation,
        };

        Service.listLastCreationSolicitations(apiFilters)
            .then((response) => {
                const solicitations =
                    AircraftSolicitation.createListFromResponse(response);
                setAircraftsFromSolicitations(
                    solicitations.map((solicitation) => {
                        const solicitationAircraft = new Aircraft({
                            ...solicitation.aircraft,
                            image: [solicitation.image],
                            document: [solicitation.document],
                        });
                        solicitationAircraft.solicitationStatus =
                            solicitation.status;
                        return solicitationAircraft;
                    })
                );
                if (!totalSolicitationsCount) {
                    setTotalSolicitationsCount(solicitations.length);
                }
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            })
            .finally(() => {
                setIsFetchingSolicitationData(false);
            });
    };

    const onClickRegisterAircraftButton = () => {
        navigate('/newaircraft/generalinfo');
    };

    const onClickAircraftCard = (aircraft) => {
        const allowedSolicitationStatus = [
            AircraftSolicitationStatus.incompleteRegistration,
            AircraftSolicitationStatus.pendingValidation,
            AircraftSolicitationStatus.disapprovedValidation,
        ];
        if (
            allowedSolicitationStatus.includes(aircraft.solicitationStatus) &&
            AIRCRAFT_SOLICITATION_PROCESS_ENABLED
        ) {
            navigate(`/aircraft/${aircraft.registration}`, {
                state: {
                    isSolicitation: true,
                },
            });
        } else {
            navigate(`/aircraft/${aircraft.registration}`);
        }
    };

    useEffect(() => {
        const fetchData = new Promise((resolve) => {
            getAircraft();
            if (AIRCRAFT_SOLICITATION_PROCESS_ENABLED) {
                getSolicitations();
            }
            resolve();
        });

        fetchData.finally(() => {
            setLoading(false);
        });
    }, [filters]);

    useEffect(() => {
        // Lista ordenada por -created_at
        const sortedList = [...aircrafts, ...aircraftsFromSolicitations].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setAircraftList(sortedList);
    }, [aircrafts, aircraftsFromSolicitations]);

    return !loading ? (
        <div className="padding--lg padding-bottom-sticky-footer">
            <div className="padding--b-sm flex-justify--between flex-items--center">
                <div
                    className="flex-items--center cursor--pointer"
                    onClick={() => navigate('/')}
                >
                    <ButtonIcon kind="no-border" icon={icoExpandLeft} />
                    <div className="headline-2 margin--l-xs">
                        {Locale.actions.myAircraft}
                    </div>
                </div>
            </div>
            {totalAircraftCount + totalSolicitationsCount < 1 ? (
                <EmptyAircraftList
                    title={Locale.pages.aircraftList.empty.nothingHere}
                    body={Locale.pages.aircraftList.empty.registerToUseServices}
                    onButtonClick={onClickRegisterAircraftButton}
                />
            ) : (
                <FilledAircraftList
                    searchFunc={setFilters}
                    initialFilters={filters}
                    aircraftList={aircraftList}
                    totalAircraftCount={
                        totalAircraftCount + totalSolicitationsCount
                    }
                    isFetchingData={
                        isFetchingAircraftData || isFetchingSolicitationData
                    }
                    onClickItem={onClickAircraftCard}
                    onButtonClick={onClickRegisterAircraftButton}
                />
            )}
        </div>
    ) : (
        <LoadingFullPage />
    );
}
