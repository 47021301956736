import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
    HeaderPage,
    Image,
    Button,
    LoadingFullPage,
    BottomSheet,
    Line,
} from 'components';
import { AirportCodeDisplay } from 'features/frontend';
import moment from 'moment';
import notify from 'components/Toast';
import { ErrorHelper, MaskHelper } from 'helpers';
import { useState, useEffect } from 'react';
import {
    iconChevronDownPrimary,
    icoExcalmationPrimary,
    iconChevronRightPrimary,
    icoBarCodeGray,
    icoPixGray,
} from 'assets/icons';
import Locale from 'locale';
import PropTypes from 'prop-types';
import Service from 'services';
import InvoiceDetail from 'models/InvoiceDetail';
import InvoiceOperation from 'models/InvoiceOperation';
import './styles.scss';

function BottomSheetPaymentOption({ icon, label, onClick, alt }) {
    return (
        <div
            className="flex--column stack--lg bottom-sheet-payment-option"
            onClick={onClick}
        >
            <div className="flex--row flex-justify--between">
                <div className="flex--row">
                    <Image
                        src={icon}
                        alt={alt}
                        size={24}
                        className="margin--r-sm"
                    />
                    <p className="color--grayscale-dark font-size--md">
                        {label}
                    </p>
                </div>
                <div>
                    <Image src={iconChevronRightPrimary} size={16} />
                </div>
            </div>
            <Line />
        </div>
    );
}

BottomSheetPaymentOption.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    alt: PropTypes.string,
};

BottomSheetPaymentOption.defaultProps = {
    alt: 'alt',
};

export default function InvoiceDetails() {
    const navigate = useNavigate();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [groupedInvoiceOperations, setGroupedInvoiceOperations] = useState(
        []
    );
    const [showPaymentBottomSheet, setShowPaymentBottomSheet] = useState(false);
    const location = useLocation();
    const isPaid = location.state?.isPaid;
    const { invoiceId } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Service.postExtrato({}, invoiceId)
            .then((response) => {
                setInvoiceDetails(InvoiceDetail.createFromResponse(response));
                setGroupedInvoiceOperations(response.operationsByAircraft);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    notify(Locale.pages.invoiceDetails.forbidden);
                    navigate('/debt-hub');
                } else {
                    ErrorHelper.notifyError(error);
                }
            });
    }, []);

    return (
        <div className="invoice-details-container">
            <HeaderPage
                className="padding--x-lg margin--t-md"
                labelBtnBack={Locale.pages.invoiceDetails.title}
                onClickBtnBack={() => {
                    navigate(-1);
                }}
            />
            {isLoading ? (
                <LoadingFullPage />
            ) : (
                <div className="content-wrapper">
                    <BottomSheet
                        show={showPaymentBottomSheet}
                        onClose={() => setShowPaymentBottomSheet(false)}
                    >
                        <div className="stack--lg">
                            <p className="padding--b-md color--grayscale-dark font-size--lg fw-semibold">
                                {
                                    Locale.pages.invoiceDetails
                                        .selectPaymentOptions
                                }
                            </p>
                            {invoiceDetails.dtVencimento &&
                                !invoiceDetails.isLate && (
                                    <BottomSheetPaymentOption
                                        icon={icoPixGray}
                                        label={
                                            Locale.pages.invoiceDetails
                                                .payWithPix
                                        }
                                        alt="pix"
                                        onClick={() => {
                                            navigate(
                                                `/invoice/pix-payment/${invoiceDetails.noDocFaturamento}`
                                            );
                                        }}
                                    />
                                )}
                            {invoiceDetails.boleto && (
                                <BottomSheetPaymentOption
                                    icon={icoBarCodeGray}
                                    label={
                                        Locale.pages.invoiceDetails
                                            .payWithBankSlip
                                    }
                                    alt="boleto"
                                    onClick={() => {
                                        Service.printSlip(
                                            invoiceId,
                                            invoiceDetails.boleto
                                        );
                                        setShowPaymentBottomSheet(false);
                                    }}
                                />
                            )}
                        </div>
                    </BottomSheet>
                    <div className="content-container">
                        <InvoiceCard invoiceDetails={invoiceDetails} />
                        {groupedInvoiceOperations?.map((groupData) => (
                            <InvoiceGroup data={groupData} />
                        ))}
                        <div className="invoice-summary padding--lg">
                            <h3>
                                {Locale.pages.invoiceDetails.invoiceSummary}
                            </h3>
                            <div className="stack--md padding--y-md">
                                {groupedInvoiceOperations?.map((groupData) => (
                                    <div className="flex--row flex-items--center flex-justify--between">
                                        <p className="color--grayscale-blue-gray">
                                            {`${groupData?.operations.length}x ${Locale.pages.invoiceDetails.operations} - ${groupData?.aircraftRegistration}`}
                                        </p>
                                        <p className="color--grayscale-dark fs-6">
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }).format(groupData?.vlrTotal)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <div className="invoice-summary-total">
                                <p>{Locale.pages.invoiceDetails.total}</p>
                                <p className="color--brand-primary">
                                    {`${new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(invoiceDetails?.vlrTotal)}`}
                                </p>
                            </div>
                        </div>
                        <div className="invoice-explanatory-card">
                            <Image
                                className="margin--r-sm"
                                src={icoExcalmationPrimary}
                                alt="ico-chevron-down"
                                size={16}
                            />
                            <p className="color--brand-primary">
                                {Locale.pages.invoiceDetails.explanatoryText}
                            </p>
                        </div>
                        {!invoiceDetails?.isMasked && (
                            <div className="invoice-footer-container">
                                <div className="invoice-footer-content">
                                    {!isPaid &&
                                        ((invoiceDetails.dtVencimento &&
                                            !invoiceDetails.isLate) ||
                                            invoiceDetails.boleto) && (
                                            <Button
                                                kind="primary"
                                                className="rounded-2"
                                                onClick={() => {
                                                    setShowPaymentBottomSheet(
                                                        true
                                                    );
                                                }}
                                            >
                                                {
                                                    Locale.pages.invoiceDetails
                                                        .pay
                                                }
                                            </Button>
                                        )}
                                    {invoiceDetails.notaFiscal && (
                                        <Button
                                            kind={
                                                isPaid ? 'primary' : 'outline'
                                            }
                                            className="rounded-2"
                                            onClick={() => {
                                                Service.downloadInvoice(
                                                    invoiceId,
                                                    invoiceDetails.notaFiscal
                                                );
                                            }}
                                        >
                                            {
                                                Locale.pages.invoiceDetails
                                                    .downloadInvoice
                                            }
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

function InvoiceCard(props) {
    const { invoiceDetails } = props;
    return (
        <div className="background-color--grayscale-light padding--lg">
            <div className="invoice-card stack--xs">
                <h3 className="color--grayscale-dark">
                    {`${invoiceDetails.aeroportoICAO} • ${invoiceDetails.noDocFaturamento}`}
                </h3>
                <div className="flex--row flex-items--center flex-justify--between">
                    <p className="color--grayscale-steel-gray">
                        {Locale.pages.invoiceDetails.totalValue}:
                    </p>
                    <p className="color--grayscale-dark fw-bold">
                        {`${new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(invoiceDetails.vlrTotal)}`}
                    </p>
                </div>
                <div className="flex--row flex-items--center flex-justify--between">
                    <p className="color--grayscale-steel-gray">
                        {Locale.pages.invoiceDetails.invoiceExpiration}:
                    </p>
                    <p className="color--grayscale-dark">
                        {moment(invoiceDetails.dtVencimento).format(
                            'DD/MM/YYYY'
                        )}
                    </p>
                </div>
                <div className="flex--row flex-items--center flex-justify--between">
                    <p className="color--grayscale-steel-gray">
                        {Locale.pages.invoiceDetails.invoice}
                    </p>
                    <p className="color--grayscale-dark">
                        {invoiceDetails.noNotaFiscal}
                    </p>
                </div>
                <div className="flex--row flex-items--center flex-justify--between">
                    <p className="color--grayscale-steel-gray">
                        {Locale.pages.invoiceDetails.operator}
                    </p>
                    <p className="color--grayscale-dark">
                        {invoiceDetails.clienteName}
                    </p>
                </div>
                <div className="flex--row flex-items--center flex-justify--between">
                    <p className="color--grayscale-steel-gray">CNPJ</p>
                    <p className="color--grayscale-dark">
                        {MaskHelper.documentWithLength(
                            invoiceDetails.clienteCPFCNPJ
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

InvoiceCard.propTypes = {
    invoiceDetails: PropTypes.object.isRequired,
};

function InvoiceGroup(props) {
    const { data } = props;
    return (
        <div className="invoice-group">
            <div className="invoice-group-header">
                <h3>{data?.aircraftRegistration}</h3>
                <p className="color--grayscale-carbono-gray">
                    {data?.operations.length}{' '}
                    {Locale.pages.invoiceDetails.operations?.toLowerCase()}
                </p>
            </div>
            <div className="invoice-group-items-container">
                {data?.operations?.map((operation) => (
                    <InvoiceGroupItem
                        operation={new InvoiceOperation(operation)}
                    />
                ))}
            </div>
        </div>
    );
}

InvoiceGroup.propTypes = {
    data: PropTypes.object.isRequired,
};

function InvoiceGroupItem(props) {
    const { operation } = props;
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="invoice-group-item padding--y-lg stack--xs">
            <div className="invoice-group-item-header">
                <AirportCodeDisplay
                    className="color--grayscale-steel-gray"
                    originAirport={{ icaoCode: operation.aeroportoOrigemICAO }}
                    targetAirport={{ icaoCode: operation.aeroportoICAO }}
                    destinationAirport={{
                        icaoCode: operation?.aeroportoDestinoICAO,
                    }}
                />
                <p>{`${new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(operation?.vlrTotal)}`}</p>
            </div>
            <div className="overline-2 color--neutral-85">
                {moment(operation.dtToqueChegada).format('DD/MM/YYYY - HH:mm')}
            </div>
            <div
                onClick={() => {
                    setShowMore(!showMore);
                }}
                className={`show-more-container cursor--pointer ${
                    showMore ? 'show-more-container--open' : ''
                }`}
            >
                <p className="color--brand-primary">
                    {showMore
                        ? Locale.pages.invoiceDetails.showLess
                        : Locale.pages.invoiceDetails.showMore}
                </p>
                <Image
                    className="margin--l-xs"
                    src={iconChevronDownPrimary}
                    alt="ico-chevron-down"
                    size={12}
                />
            </div>
            {showMore && (
                <div className="stack--xs">
                    <div className="border-bottom padding--t-sm padding--b-md stack--xs">
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.landingCost}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(operation.vlrPouso)}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.costOfPermanence}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(
                                operation.vlrManobra + operation.vlrEstadia
                            )}
                        />
                        {/* <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.discount}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(0)} // TODO: Pegar da API
                        /> */}
                    </div>
                    <div className="border-bottom padding--t-sm padding--b-md stack--xs">
                        <p className="color--black fw-bold">
                            {Locale.pages.invoiceDetails.operationDetails}
                        </p>
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.CCRAirport}
                            value={operation.aeroportoICAO}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.landingDate}
                            value={moment(operation.dtToqueChegada).format(
                                'DD/MM/YYYY - HH:mm'
                            )}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.takeoffDate}
                            value={moment(operation.dtToquePartida).format(
                                'DD/MM/YYYY - HH:mm'
                            )}
                        />
                        <InvoiceTitleValuePair
                            title={
                                Locale.pages.invoiceDetails
                                    .aircraftAverageWeight
                            }
                            value={`${operation.pmdAeronave} kg`}
                        />
                        {/* <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.landingTax}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(0)} // TODO: Pegar da API
                        /> */}
                    </div>
                    <div className="padding--t-sm padding--b-md stack--xs">
                        <p className="color--black fw-bold">
                            {Locale.pages.invoiceDetails.permanenceDetails}
                        </p>
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.maneuverTime}
                            value={`${operation.qtdManobra} h`}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.maneuverValue}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(operation.vlrManobra)}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.lengthOfStay}
                            value={`${operation.qtdEstadia} h`}
                        />
                        <InvoiceTitleValuePair
                            title={Locale.pages.invoiceDetails.costOfStay}
                            value={new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(operation.vlrEstadia)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

InvoiceGroupItem.propTypes = {
    operation: PropTypes.object.isRequired,
};

function InvoiceTitleValuePair(props) {
    const { title, value, className, titleClassName, valueClassName } = props;
    return (
        <div
            className={`flex--row flex-items--center flex-justify--between ${className}`}
        >
            <p className={`invoice-text-pair-title ${titleClassName}`}>
                {title}
            </p>
            <p className={`invoice-text-pair-value ${valueClassName}`}>
                {value}
            </p>
        </div>
    );
}

InvoiceTitleValuePair.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    valueClassName: PropTypes.string,
};

InvoiceTitleValuePair.defaultProps = {
    className: '',
    titleClassName: '',
    valueClassName: '',
};
