import { useState, useEffect, useMemo } from 'react';
import { HeaderPage, Table, Chip } from 'components';
import { getAircraftNature, scrollToTop } from 'utils/functions';
import { useNavigate } from 'react-router-dom';
import Service from 'services';
import Locale from 'locale';
import {
    OrderingParams,
    PaginationParams,
    getAircraftSolicitationStatus,
    AircraftSolicitationStatus,
    getAircraftSolicitationType,
    AircraftSolicitationType,
} from 'utils/enums';
import {
    parseOrderingToBackend,
    formatDateToTableOrDefault,
} from 'components/Table';
import { ErrorHelper, FilterHelper } from 'helpers';
import { AircraftSolicitation } from 'models';
import './styles.scss';
import HeaderFilters from './HeaderFilters';

const noFilters = {
    search: '',
    status: {
        pendingValidation: false,
        approvedValidation: false,
        disapprovedValidation: false,
        cancelled: false,
    },
    nature: null,
    type: {
        creation: false,
        edit: false,
    },
    dataFillingDate: {
        startDate: null,
        endDate: null,
    },
};

const solicitationNature = {
    national: 'national',
    international: 'international',
    military: 'military',
};

function AircraftSolicitations() {
    const navigate = useNavigate();
    const [solicitations, setSolicitations] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [shouldReload, setShouldReload] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [currentOrdering, setCurrentOrdering] = useState('-id');
    const [filters, setFilters] = useState(noFilters);

    const [loading, setLoading] = useState(true);

    const redirectToDetails = (solicitationId) => {
        navigate(`/backoffice/solicitations/${solicitationId}`);
    };

    const parseFilters = () => {
        const filterFillingDateAfter = FilterHelper.parseDateField(
            filters.dataFillingDate.startDate
        );
        const filterFillingDateBefore = FilterHelper.parseDateField(
            filters.dataFillingDate.endDate
        );

        const filterStatus = FilterHelper.stringifyObjectOfBooleans(
            filters.status,
            (key) => AircraftSolicitationStatus[key]
        );
        const filterType = FilterHelper.stringifyObjectOfBooleans(
            filters.type,
            (key) => AircraftSolicitationType[key]
        );

        const isNatureTouched = filters.nature !== noFilters.nature;
        const isForeign = filters.nature === solicitationNature.international;
        const isMilitary = filters.nature === solicitationNature.military;

        return {
            ...(filters.search && {
                [AircraftSolicitation.filterParams.search]: filters.search,
            }),
            ...(filterFillingDateAfter && {
                [AircraftSolicitation.filterParams.dataFillingStartDate]:
                    filterFillingDateAfter,
            }),
            ...(filterFillingDateBefore && {
                [AircraftSolicitation.filterParams.dataFillingEndDate]:
                    filterFillingDateBefore,
            }),
            ...(filterStatus && {
                [AircraftSolicitation.filterParams.status]: filterStatus,
            }),
            ...(filterType && {
                [AircraftSolicitation.filterParams.type]: filterType,
            }),
            ...(isNatureTouched &&
                !isMilitary && {
                    [AircraftSolicitation.filterParams.isForeign]: isForeign,
                }),
            ...(isNatureTouched && {
                [AircraftSolicitation.filterParams.isMilitary]: isMilitary,
            }),
        };
    };

    const showableFilters = () => {
        const statusValue = FilterHelper.stringifyObjectOfBooleans(
            filters.status,
            (key) => Locale.pages.backoffice.solicitations.status[key],
            ', '
        );

        const natureValue =
            Locale.pages.backoffice.solicitations.nature[filters.nature];

        const typeValue = FilterHelper.stringifyObjectOfBooleans(
            filters.type,
            (key) => Locale.pages.backoffice.solicitations.type[key],
            ', '
        );

        return [
            ...(statusValue
                ? [{ name: 'Status', value: statusValue, key: 'status' }]
                : []),
            ...(natureValue
                ? [{ name: '', value: natureValue, key: 'nature' }]
                : []),
            ...(typeValue
                ? [
                      {
                          name: Locale.pages.backoffice.solicitations
                              .solicitationType,
                          value: typeValue,
                          key: 'type',
                      },
                  ]
                : []),
        ];
    };

    const getSolicitations = () => {
        Service.listAllAircraftSolicitations({
            [PaginationParams.page]: currentPage,
            [PaginationParams.pageSize]: currentPageSize,
            [OrderingParams.ordering]: currentOrdering,
            [AircraftSolicitation.filterParams.notStatus]:
                AircraftSolicitationStatus.incompleteRegistration,
            ...parseFilters(),
        })
            .then((response) => {
                setTotalCount(response.count);
                setTotalPages(response.totalPages);
                const solicitationsResponse =
                    AircraftSolicitation.createListFromResponse(
                        response.results
                    );
                setSolicitations(solicitationsResponse);
                setLoading(false);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const onSort = (newOrdering) => {
        setCurrentOrdering(parseOrderingToBackend(newOrdering));
    };

    const onPageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setCurrentPageSize(newPageSize);
    };

    const formatNature = ({ data, row }) => {
        const rowData = data[row?.index];
        const title = getAircraftNature(
            rowData.aircraft.isForeign,
            rowData.aircraft.isMilitary
        );
        return <div>{title}</div>;
    };

    const formatType = ({ value }) => {
        const typeName = getAircraftSolicitationType(value);
        return Locale.fields.aircraft.solicitation.typeEnum[typeName];
    };

    const formatStatus = ({ value }) => {
        const statusName = getAircraftSolicitationStatus(value);
        return (
            <Chip className={`chip-status ${statusName}`}>
                {Locale.fields.aircraft.solicitation.statusEnum[statusName]}
            </Chip>
        );
    };

    const columns = useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id',
            className: 'column-id',
            sortBy: AircraftSolicitation.orderingParams.id,
        },
        {
            Header: Locale.fields.aircraft.solicitation.registration,
            accessor: 'aircraft.registration',
            className: 'column-registration',
            sortBy: AircraftSolicitation.orderingParams.registration,
        },
        {
            Header: Locale.fields.aircraft.solicitation.icaoModel,
            accessor: 'aircraft.icaoModel',
            className: 'column-model',
            sortBy: AircraftSolicitation.orderingParams.icaoModel,
        },
        {
            Header: Locale.fields.aircraft.solicitation.nature,
            className: 'column-nature',
            Cell: formatNature,
            sortBy: AircraftSolicitation.orderingParams.nature,
        },
        {
            Header: Locale.fields.aircraft.solicitation.type,
            accessor: 'type',
            className: 'column-type',
            Cell: formatType,
            sortBy: AircraftSolicitation.orderingParams.type,
        },
        {
            Header: Locale.fields.aircraft.solicitation.date,
            accessor: 'dataFillingDate',
            className: 'column-date',
            Cell: formatDateToTableOrDefault(''),
            sortBy: AircraftSolicitation.orderingParams.date,
        },
        {
            Header: Locale.fields.aircraft.solicitation.status,
            accessor: 'status',
            className: 'column-status',
            Cell: formatStatus,
            sortBy: AircraftSolicitation.orderingParams.status,
        },
    ]);

    useEffect(() => {
        if (shouldReload) {
            scrollToTop();
            getSolicitations();
            setShouldReload(false);
        }
    }, [shouldReload]);

    useEffect(() => {
        setShouldReload(true);
    }, [currentPage, currentPageSize]);

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            setShouldReload(true);
        }
    }, [currentOrdering, filters]);

    return (
        <div className="stack--lg width--full padding--lg">
            <div className="stack--sm">
                <HeaderPage
                    title={Locale.pages.backoffice.solicitations.title}
                />
            </div>
            <HeaderFilters filters={filters} setFilters={setFilters} />
            <div className="flex-justify--between">
                <div className="body-2 color--grayscale-steel-gray text-transform--lowercase flex-items--center">
                    {totalCount} {Locale.general.result}
                    {totalCount !== 1 && 's'}
                </div>
                <div className="flex-wrap inline--sm">
                    {showableFilters().map((filter) => (
                        <Chip
                            key={filter.name || filter.value}
                            onClickCloseButton={() =>
                                setFilters({
                                    ...filters,
                                    [filter.key]: noFilters[filter.key],
                                })
                            }
                        >
                            <div>
                                {filter.name && <b>{`${filter.name}: `}</b>}
                                {filter.value}
                            </div>
                        </Chip>
                    ))}
                </div>
            </div>
            <Table
                data={solicitations}
                columns={columns}
                onSort={onSort}
                onPageChange={onPageChange}
                totalPages={totalPages}
                currentPage={currentPage}
                loading={loading}
                rowClick={redirectToDetails}
            />
        </div>
    );
}

export default AircraftSolicitations;
