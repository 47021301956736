import { icoExpandRight, icoExpandLeft } from 'assets/icons';
import { Button, Image, List, LoadingFullPage, ButtonIcon } from 'components';
import { ErrorHelper } from 'helpers';
import Locale from 'locale';
import { Pilot } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Service from 'services';
import PilotDetailModal from './DetailModal';

export default function Pilots() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [editPilot, setEditPilot] = useState();
    const [pilots, setPilots] = useState([]);

    const listPilots = () => {
        setLoading(true);
        setPilots([]);
        Service.listPilots()
            .then((response) => {
                setPilots(Pilot.createListFromResponse(response));
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => listPilots(), []);

    return !loading ? (
        <>
            <PilotDetailModal
                show={showDetailModal}
                onClose={() => setShowDetailModal(false)}
                editPilot={editPilot}
                updateList={listPilots}
            />
            <div className="padding--lg padding-bottom-sticky-footer">
                <div className="padding--b-sm flex-justify--between flex-items--center">
                    <div
                        className="flex-items--center cursor--pointer"
                        onClick={() => navigate('/')}
                    >
                        <ButtonIcon kind="no-border" icon={icoExpandLeft} />
                        <div className="headline-2 margin--l-xs">
                            {Locale.pages.accountSettings.tabs.pilots}
                        </div>
                    </div>
                    <Button
                        kind="primary"
                        onClick={() => {
                            setEditPilot(undefined);
                            setShowDetailModal(true);
                        }}
                    >
                        + {Locale.general.new.m}
                    </Button>
                </div>
                {pilots.map((pilot) => (
                    <List
                        key={pilot.id}
                        text={pilot.name}
                        after={
                            <Image
                                src={icoExpandRight}
                                alt={Locale.pages.accountSettings.pilots.edit}
                            />
                        }
                        onClick={() => {
                            setEditPilot(pilot);
                            setShowDetailModal(true);
                        }}
                    />
                ))}
            </div>
        </>
    ) : (
        <LoadingFullPage />
    );
}
