import { useEffect } from 'react';
import Locale from 'locale';
import { MaskHelper } from 'helpers';

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const outsideClick = (ref, callback) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** i, 2)}${sizes[i]}`;
};

export const getDocumentLabel = (isForeigner, isCompany) => {
    if (isForeigner) {
        if (isCompany) {
            return Locale.fields.tin;
        }
        return Locale.fields.passport;
    }
    if (isCompany) {
        return Locale.fields.cnpj;
    }
    return Locale.fields.cpf;
};

export const getInitials = (name) => {
    const rgx = /(\p{L}{1})\p{L}+/gu;
    const initials = [...name.matchAll(rgx)] || [];

    return (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
};

export const joinDateTime = (date, time) => {
    const datetime = date;
    if (time) {
        datetime?.setHours(time.getHours());
        datetime?.setMinutes(time.getMinutes());
    }
    return datetime;
};

export const getPhoneWithCode = (data) =>
    `+${data.countryPhoneCode} ${MaskHelper.phone(data.phoneNumber)}`;

export const isFalsyExceptZero = (value) => !value && value !== 0;

export const getAircraftNature = (isForeign, isMilitary) => {
    if (isMilitary) {
        return Locale.fields.aircraft.militaryShort;
    }

    return isForeign
        ? Locale.fields.aircraft.foreignShort
        : Locale.fields.aircraft.nationalShort;
};
