export const enUS = {
    general: {
        CCRAirports: 'CCR Airports',
        aircraft: 'Aircraft',
        all: 'All',
        backoffice: 'Backoffice',
        and: 'and',
        yes: 'Yes',
        no: 'No',
        at: 'at',
        to: 'to',
        hour: 'Hour',
        result: 'Result',
        in: 'in',
        note: 'Note',
        new: {
            f: 'New',
            m: 'New',
        },
        airports: 'Airports',
        deleted: 'Deleted',
        noResults: 'No results',
        noICAOCode: 'No ICAO',
        weekdays: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        understood: 'Understood',
    },
    errors: {
        default: 'Something went wrong. Try again or contact support.',
        required: 'Required field',
        minLength: (number) =>
            `This field can't have less than ${number} digits`,
        maxLength: (number) =>
            `This field can't have more than ${number} digits`,
        validEmail: 'Insert a valid email address',
        emailsMatch: 'E-mails must match',
        connection: 'Connection error',
        passwordsMatch: 'The passwords must match',
        date: {
            format: 'Enter the date in dd/mm/yyyy format',
        },
        customerNotFound: 'Customer not found!',
        landingRequestInfoError:
            'We were unable to get your landing request info',
        billingInfoError: 'We were unable to get your billing info',
        replicate:
            'There was a problem replicating your reservation. Try again or contact support.',
        saveAirportDocuments:
            'The airport was created successfully, but something went wrong while creating the files. Please edit the airport to upload the documents again.',
        cep: 'Failed CEP search. Enter the address manually',
        qrCodeError:
            'There was a problem retrieving your QR Code. Try again or contact support.',
    },
    fields: {
        approvedBy: 'Reservation approved by',
        select: 'Select',
        landingPeriod: 'Landing period',
        filters: 'Filters',
        name: 'Name',
        fullName: 'Full name',
        corporateName: 'Corporate name',
        companyName: 'Company name',
        phone: 'Contact phone',
        operatorShort: 'Operator',
        document: 'CPF/CNPJ',
        email: 'E-mail',
        cpf: 'CPF',
        cnpj: 'CNPJ',
        city: 'City',
        country: 'Country',
        countryRegion: 'Country / Region',
        state: 'State',
        stateTerritory: 'State / Territory',
        cepZipcode: 'CEP / Zipcode',
        passport: 'Passport',
        tin: 'TIN',
        isForeigner: 'Foreigner',
        isCompany: 'Is a company',
        operatorIsForeigner: 'Foreign Operator',
        operatorIsCompany: 'Operator is a company',
        address: 'Address',
        streetAddress: 'Street address',
        numberComplement: 'No./Complement',
        typeOfFlight: 'Type of Flight',
        nationalizationFlight: 'Nationalization Flight?',
        handling: 'Handling',
        approved: 'Approved',
        returned: 'Returned',
        payday: 'Date of payment',
        dateUnavailable: 'Date unavailable',
        timeUnavailable: 'Unavailable Time',
        alternateDate: 'Alternative Date',
        alternateTime: 'Alternative Time',
        comment: 'Comment',
        commander: 'Commander',
        addressPlaceholder: 'Insert CEP',
        billing: 'Billing',
        cep: 'CEP',
        postalCode: 'Postal Code',
        zipCode: 'ZIP code',
        number: 'Number',
        complement: 'Complement',
        district: 'District',
        boarding: 'Boarding',
        deboarding: 'Deboarding',
        reservationData: 'Reservation data',
        showDeletedAirports: 'Show deleted airports',
        showDeletedAircraft: 'Show deleted aircraft',
        destination: 'Destination (CCR Airport)',
        CCRAirport: 'CCR Operating Airport',
        unknown: 'Unknown',
        profile: 'Profile',
        naturalPerson: 'Natural person',
        legalPerson: 'Legal person',
        billingOperator: 'Billing operator',
        userLabel: 'User',
        minValue: 'This field must be filled with a value greater or equal to',
        maxValue: 'This field must be filled with a value less or equal to',
        paymentMethod: {
            title: 'Payment method',
            pix: 'PIX',
        },
        file: {
            airworthnessCertification: 'Airworthness certification',
            attachCertification: 'Attach certification',
            attachImage: 'Attach image',
            documentAttached: 'Document attached',
            imageAttached: 'Image attached',
            aircraftImage: 'Aircraft image',
            successfullyDocumentAttach: 'Document successfully attached.',
            noAttachedFile: 'No file attached',
            sentIn: 'Sent in {1} - {2}',
            download: 'DOWNLOAD',
        },
        user: {
            password: 'Password',
            confirmPassword: 'Confirm the password',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            confirmEmail: 'Confirm e-mail',
            agree: 'I agree',
            confirmGuideline: 'I am aware of the guidelines',
            personalData: 'Personal data',
            linkedAirports: 'Linked airports',
            accessProfile: 'Access profile',
            isActive: 'Active?',
        },
        reservation: {
            title: 'Reservation',
            status: 'Reservation status',
            airport: 'Airport',
            origin: 'Origin',
            originDropdownTooltip:
                'CCR airport where landing or patio reservation is being requested',
            destination: 'Next Destination',
            useBillingDataFrom: 'Use billing data from',
            others: 'Others',
            requester: 'Requester',
            operator: 'Operator',
            destinationDropdownTooltip:
                'Destination airport after take-off from the landing airport of this booking',
            nextDestination: 'Next Destination',
            noICAO: 'Airport without ICAO',
            homeAerodrome: 'Home aerodrome',
            destinyAerodrome: 'Destiny aerodrome',
            city: 'City',
            state: 'State',
            country: 'Country',
            datetime: 'Date and time',
            peopleOnBoard: 'Passengers on board',
            patioReservation: 'Patio reservation?',
            hasPatioReservation: {
                yes: 'Yes, I will land and remain in the patio.',
                no: 'No',
            },
            takeOffForecast: 'Take off forecast?',
            landing: 'Landing',
            takeOff: 'Take off',
            newReservation: 'Make your reservation',
            startReservation: 'Start reservation',
            landingDate: 'Landing date',
            takeOffDate: 'Take off date',
            landingHour: 'Landing hour',
            takeOffHour: 'Take off hour',
            takeOffDatetime: 'Take off datetime',
            landingDatetime: 'Landing datetime',
            alternative: 'Alternative reservation?',
            trailerFork: 'Trailer fork?',
            flightInfo: 'Flight Information',
            flightType: 'Flight type',
            legalRepresentative: {
                title: 'Legal representative',
                name: 'Legal representative name',
                phone: 'Legal representative phone',
            },
            transportationSupport: 'Need for Transportation Support?',
            handlingCompany: 'Handling company',
            responsable: 'Reservation responsable',
            nature: 'Nature',
            domestic: 'Domestic',
            international: 'International',
        },
        aircraft: {
            active: 'Active',
            title: 'Aircraft',
            foreign: 'Foreign aircraft',
            national: 'National aircraft',
            foreignShort: 'Foreign',
            nationalShort: 'National',
            militaryShort: 'Military',
            aircraftRegistration: 'Aircraft registration',
            icao: 'Equipment model (ICAO)',
            mtow: 'Maximum take-off weight',
            mtowShort: 'MTOW',
            wingspan: 'Wingspan',
            planeLength: 'Length',
            registration: 'Registration',
            model: 'Aircraft model',
            agreedToDataSharing:
                'I agree to share my billing data with other operators sharing the same aircraft.',
            saveAndContinue: 'Save and continue',
            isMilitary: 'This is a military aircraft.',
            selectedAircraft: 'Aircraft selected:',
            editAircraft: 'Edit aircraft',
            editOperator: 'Edit operator',
            chooseAnotherAircraft: 'Choose another aircraft',
            incompleteRegistration: {
                title: 'The data of the operator of the aircraft are incomplete',
                description:
                    'Click the button to edit the operator and continue with the booking.',
            },
            companyInvoice: {
                title: 'Invoice for company',
                description: 'Enter NIF, phone number, email and zip code.',
            },
            individualInvoice: {
                title: 'Invoice natural persons',
                description:
                    'Enter full name, passport, phone number and an email address.',
            },
            document: {
                success: 'Your document has been successfully attached.',
                attach: 'Attach document',
                attached: 'Document attached',
                types: 'PDF, JPG or PNG',
            },
            image: {
                success: 'Your image has been successfully attached.',
                attach: 'Attach image',
                attached: 'Image attached',
                types: 'JPG or PNG',
            },
            solicitation: {
                registration: 'Registration',
                icaoModel: 'Model',
                nature: 'Nature',
                type: 'Type',
                date: 'Date',
                status: 'Status',
                foreign: 'Foreign',
                national: 'National',
                statusEnum: {
                    incompleteRegistration: 'Incomplete registration',
                    pendingValidation: 'Pending Validation',
                    approvedValidation: 'Validated',
                    disapprovedValidation: 'Disapproved',
                    cancelled: 'Cancelled',
                },
                typeEnum: {
                    creation: 'New registration',
                    edit: 'Edit',
                },
            },
        },
        operator: {
            title: 'Operator',
            useData: 'Use registration data',
            name: 'Name Prop. / Corporate Name',
            useForeignDocumentation: 'Use foreign documentation?',
        },
        pilot: {
            name: 'Pilot name',
        },
        payment: {
            title: 'Payment',
            description: {
                pix: 'Payment by PIX',
                creditCard: 'Payment by credit card',
                accountHolder:
                    'Payment simplified to account holder on behalf of the national aircraft',
            },
            accountHolderDisclaimer:
                'Payment value must be checked at billing service',
        },
        requester: 'Requester',
        checkout: {
            cardNumber: 'Card Number',
            cardholderName: 'Card Name',
            cardExpiringDate: 'Expiration Date',
            cvc: 'CVC',
        },
        justification: 'Justification',
        airport: {
            search: 'Enter the name of the airport',
            managedShortName: 'Managed?',
            managedFullName: 'Managed by CCR?',
            timezone: 'Timezone',
            documents: 'Documents',
            documentsSubtitle:
                'Orientation documents made available by the airport',
        },
        countryCode: 'Country',
        notInformed: 'Not informed',
    },
    pages: {
        forgotPassword: {
            subtitle:
                'Insert your email and we will send you instructions to reset your password.',
            email: 'Registered e-mail',
            success:
                'We sent you an e-mail with instructions to reset your password.',
        },
        accountSettings: {
            tabs: {
                personal: 'Personal',
                aircrafts: 'Aircraft',
                pilots: 'Pilots',
                reservations: 'Reservations',
            },
            personal: {
                data: 'Personal data',
                cardList: 'Card list',
                changePassword: {
                    success: 'Your password was updated.',
                },
                changeData: {
                    success: 'Your data has been updated',
                },
            },
            pilots: {
                new: 'New pilot',
                edit: 'Edit pilot',
                delete: 'Delete pilot',
                createSuccess: 'The pilot was successfully added.',
                editSuccess: 'The pilot was successfully updated.',
                deleteSuccess: 'The pilot was successfully deleted.',
                textDeleteConfirm:
                    "Are you sure you want to delete this pilot? It's possible it's being used in some of your reservations.",
            },
            aircraft: {
                new: 'New aircraft',
                edit: 'Edit aircraft',
                createSuccess: 'The aircraft was successfully added.',
                editSuccess: 'The aircraft was successfully updated.',
                deleteSuccess: 'The aircraft was successfully deleted.',
                registration: 'Registration',
                textDeleteConfirm:
                    "Are you sure you want to delete this aircraft? It's possible it's being used in some of your reservations.",
            },
        },
        aircraftDetails: {
            information: 'Information',
            registration: 'Registration',
            model: 'Model',
            mtow: 'MTOW',
            wingspan: 'Wingspan',
            planeLength: 'Length',
            operator: 'Operator',
            document: 'Document',
            zipCode: 'ZIP code',
            phoneNumber: 'Contact phone',
            email: 'E-mail',
            editAircraft: 'Edit aircraft',
            completeRegistration: 'Complete registration',
            editOperator: 'Edit operator',
            operatorInfoCardMessage:
                'Invoices for operations will be sent to this contact.',
            nature: 'Nature',
            statusInformationCard: {
                incompleteRegistration: {
                    title: 'Incomplete registration',
                    message:
                        'Complete the registration to enable this aircraft for reservations.',
                },
                pendingValidation: {
                    title: 'Registration under approval',
                    message:
                        'During validation it will not be possible to make reservations with this aircraft.',
                },
                disapprovedValidation: {
                    title: 'Registrarion rejected',
                    message:
                        'The registration request for this aircraft was rejected.',
                },
            },
            solicitationsHistory: {
                title: 'Solicitation history',
                type: {
                    creation: 'Register new aircraft',
                    edit: 'Aircraft edit',
                },
                status: {
                    incompleteRegistration: 'Incomplete Registration',
                    pendingValidation: 'Sent',
                    approvedValidation: 'Approved',
                    disapprovedValidation: 'Repproved',
                    cancelled: 'Cancelled',
                },
            },
        },
        aircraftEdit: {
            information: {
                title: 'Edit aircraft',
                saveChanges: 'Save changes',
                removeAircraft: 'Remove aircraft',
                confirmDeleteMessage:
                    'Are you sure you want to remove this aircraft?',
                confirmEditMessage:
                    'The changes will be analyzed by our team and compared to the current registration in the RAB (Brazilian Aeronautical Registry), after approval, the information will be replicated to all registrations of this aircraft.',
                wishToContinue: 'Do you wish to continue?',
                aircraftRemoved: 'Aircraft removed',
            },
            operator: {
                title: 'Edit operator',
                saveChanges: 'Save changes',
                description:
                    'Information used to send invoices for the selected aircraft.',
            },
        },
        register: {
            user: {
                title: 'Personal data',
                privacyTerms:
                    'I agree to share my information with CCR and I agree with the Privacy Policy, in accordance with the General Data Protection Law (Law No. 13.709/2018).',
                commonPassword: 'Avoid passwords that are too common', // TODO adicionar 'or similar to your email' nessa string MAG-495
                lengthPassword:
                    'The password must be at least 8 characters long',
                numericPassword: 'The password cannot have only numbers',
            },
            aircraft: {
                aircraftData: 'Aircraft Data',
                operatorData: 'Operator Data for Billing',
                operatorDataSubtitle:
                    'For Brazilian ACFT, inform active operator in RAB',
                description:
                    'Now include the data of the aircraft you want to link to your registration',
                saveAirplane: 'Save aircraft to my list',
                registerAircraft: 'Register aircraft',
                registerAircraftDescription:
                    'Enter the aircraft data for registration, you can edit it later.',
                mtowDescription:
                    'MTOW is the maximum takeoff weight that an aircraft can reach at its maximum capacity. This information must be filled in correctly, to prevent the calculation of landing and stay fees from being incorrect.',
                howToInvoiceTitle:
                    'How do you want to invoice the operations of this aircraft?',
                howToInvoiceDescription: 'You can change this data later.',
                validationDocument: 'Validation of the aircraft',
                validationDocumentDescription:
                    'For security, we ask that you send a document for validation of the aircraft.',
                airworthnessCertificate: 'Airworthiness Certification',
                airworthnessCertificateDescription:
                    "For the control of aircraft noise emissions, ANAC (National Civil Aviation Agency) provided the Brazilian Civil Aviation Regulation - RBAC 36 as certification, which fully adopts the US requirement US Title 14 CFR Part 36: Noise Standards: Aircraft Type and Airworthiness Certification. In addition to establishing maximum acceptable limits for the different categories, the certificate also guarantees the validation of your aircraft's registration for operations at CCR airports.",
                aircraftImage: 'Aircraft image',
                foreignAircraftAlert:
                    'Document required for foreign aircraft, without the attachment, your reservations may be blocked.',
                billingDataTitle: 'Billing data',
                billingDataDescription:
                    'Enter the data of the operator who will receive invoices for the selected aircraft.',
                NIFDetails: {
                    title: 'Tax Identification Number',
                    description:
                        'The TIN (Tax Identification Number) is a number used to identify individuals and entities. It is similar to the CNPJ in Brazil or the SSN in the United States. The TIN is used for tax and administrative purposes, allowing the government and organizations to monitor the financial and tax activities of citizens and companies.',
                },
                invalidFileFormat: 'Invalid file format',
                duplicatedRegistrationError: 'Aircraft already registered',
                registrationRequested: 'Registration requested',
                registrationRequestedDescription:
                    'Your data has been sent for analysis. Our team will soon validate and release the aircraft for reservations.',
                registrationCompleted: 'Registration completed',
                registrationCompletedDescription:
                    ' Very well, the data has been recorded and will be analyzed by our internal team.',
            },
        },
        login: {
            subtitle: 'Access your account to continue',
            errorBadRequest: 'Incorrect e-mail and/or password',
            activationEmail: {
                resend: 'Resend e-mail',
                sent: "We have sent an activation email to {}. If you don't see it, check your spam and junk folder or click the button to resend the email.",
                confirmEmail: 'Confirm your e-mail address',
            },
        },
        home: {
            help: {
                needHelp: 'Need help?',
                needHelpDescription:
                    'Use one of our service channels or consult the frequently asked questions.',
                contactUs: {
                    title: 'Contact us',
                    content: '0800-727-4720',
                },
                financial: {
                    title: 'Financial',
                    content: 'Send a message',
                },
                ombudsman: {
                    title: 'Ombudsman',
                    content: 'Send a message',
                },
            },
            whatsapp: {
                title: 'Contact Us via Whatsapp ',
                description:
                    'Exclusive channel for questions about billing, tickets and general aviation.',
                serviceTimeTitle: 'Service hours:',
                serviceTime:
                    'Monday to Thursday, from 08:00 to 18:00, and Friday, from 08:00 to 17:00.',
                card: {
                    title: 'Whatsapp',
                    content: '(11) 91595-4100',
                },
                warningMessage:
                    'This number is not enabled for phone calls, only for text messages.',
            },
            FAQ: {
                title: 'Frequently Asked Questions',
                seeAll: 'See all',
            },
            useOurServices: 'Use our services',
            useOurServicesDescription: "It's easy, fast and secure.",
            myReservations: 'My reservations',
            myAircrafts: 'My aircrafts',
            pilotRegistration: 'Pilot registration',
            financialServices: 'Financial services',
            debtsAndInvoices: 'Debts and Invoices',
            newFeaturesTitle: 'New services coming soon!',
            fareCalculator: {
                title: 'Fare Calculator',
                description:
                    'Know in advance the value of the reservation and the fare details.',
            },
            reservationStatus: {
                title: 'Booking status',
                description:
                    'Receive notifications of your booking status at every step.',
            },
            outstandingDebts: {
                title: 'Search for outstanding debts',
                description: 'Monitor and manage your billing.',
            },
        },
        homeLoggedIn: {
            banner: {
                title: 'Register your aircraft',
                subtitle: 'Register now',
            },
            outstandingDebts: {
                banner: {
                    title: 'News on AVG',
                    subtitle:
                        'Now you can view your payment history, check your debts download slips and invoices!',
                },
                title: 'Outstanding debt',
                tooltip:
                    'Outstanding values ​​referring to the registration of aircraft registered with their operators, after 04/01/2023 with operations made after 05/01/2023.',
                view: 'VIEW',
                observationMessage: 'Invoices available from 05/01/23',
            },
            lastUpdates: 'Last updates',
            replicate: 'Replicate',
            noReservations: {
                title: 'There are no reservations yet',
                subtitle: "You don't have a reservation history",
            },
            noResults: {
                title: 'No results',
                subtitle: "The filter parameters didn't found any results",
            },
            services: {
                title: 'Services',
                new: 'New Reservation',
            },
            myReservations: {
                title: 'My Reservations',
                link: 'Show All',
            },
        },
        homeNotLoggedIn: {
            doubt: 'Have a question about our website?',
            banner: {
                register: {
                    title: 'Our app has changed',
                    subtitle:
                        'With our completely renovated Home page, you will have easy and intuitive access to speed up your reservations at the airport apron',
                    button: 'Create new account',
                },
                aircraft: {
                    title: 'Register your aircraft',
                    subtitle: 'And simplify your airport apron reservations',
                    button: 'Register now',
                },
                reserve: {
                    title: 'Reserve the airport patio',
                    subtitle: 'And land safely and efficiently.',
                    button: 'Reserve now',
                },
                billing: {
                    title: 'Request your billing data',
                    subtitle: 'Track your flight status and other services.',
                    button: 'Financial',
                },
            },
        },
        sidemenu: {
            aboutCCR: 'About CCR',
            selectLanguage: 'Select language',
        },
        resetPassword: {
            success:
                'Your password was reset. Please login with your new password.',
        },
        activateAccount: {
            success:
                'Your account has been activated successfully. You can now login.',
            error: {
                title: 'Error activating account',
                message:
                    'Please try later or try resetting your password.\nIf the error persists, contact support.',
            },
        },
        reservationDetail: {
            title: 'Reservation detail',
            modal: 'Informations',
            createdAt: 'Reservation created at',
            reserveAgain: 'Reserve again',
            cancel: 'Cancel reserve',
            dateOptions: 'Date Options',
            adjustDate: 'Adjust the date',
            requestChangeSubtitle:
                'We sent an available alternative for your suggested date and time.',
            notSuitMe: "The option doesn't suit me",
            pixCard: {
                steps: [
                    "Choose the option to pay with Pix code in your bank's app.",
                    "Copy and paste the code or scan the QR code with your phone's camera.",
                    'Check all information and authorize payment.',
                ],
                totalLabel: 'Reservation total via PIX',
                copyPaste: 'PIX copy and paste',
                copied: 'QR Code copied to clipboard!',
            },
        },
        reservation: {
            generalInfo: 'General Information',
            additionalInfo: 'Additional Information',
            newAirportNoICAO: 'Create new airport without ICAO',
            noCommander: "Don't select commander",
            landingDateInLocalTime: 'Landing date in airport local time',
            localTime: 'Airport local time',
            localTimeDescription:
                'The landing and take-off date and time refer to the local time of the airport, therefore the local time at your CCR airport destination. Remember to check the time zone of the airports and if they have any specific opening hours to avoid your reservation being refused.',
            changeTime: 'Change time',
            confirm: 'Confirm',
            airportTimeWarningTitle: 'Airport working hours',
            airportTimeWarningMessage:
                'The selected time is not on the standard airport opening hours. If you continue, your reservation may be refused. Do you want to continue?',
        },
        summary: {
            summaryReservation: 'Booking Summary',
            takeoffLanding: 'Takeoff and Landing',
            requester: 'Requester',
            aircraftOperator: 'Aircraft Operator',
            operator: 'Operator',
            guidelines: 'Guidelines',
            guidelineA: 'Class A exit guidelines',
            guidelineB: 'Class B exit guidelines',
            messageSuccess: 'Landing request sent successfully.',
        },
        success: {
            title: 'Thank You!',
            description:
                'Your takeoff and landing reservation has been sent successfully!',
            emailMessage:
                'Soon we will send you an email with all the information on this reservation.',
            observation: 'Observation',
            backToHome: 'Back to home',
            AISWebMessage:
                'We suggest that you always consult the aeronautical information of the destination airport on the AISWeb.',
        },
        backoffice: {
            home: {
                title: 'Reservations',
                search: 'Search for ID, destination, origin or next destination',
                filters: {
                    landingAndTakeOff: 'With landing and take off',
                    onlyLanding: 'Only landing',
                    showFlights: 'Show flights',
                },
            },
            details: {
                status: 'Status',
                pricing: 'Pricing',
                payment: 'Payment',
                reservationCompleted: 'Reservation completed',
                billing: 'Billing',
                flightInformation: 'Flight information',
                reservationSentIn: 'Reservation sent in ',
                formPayment: 'Form of payment',
                paymentData: 'Payment Data',
                infoInternalControl: 'Information for internal control',
                negotiationHistory: 'Negotiation History',
                requesterObservation: 'Requester Observations',
                landingSuggestions: 'Landing Suggestions',
                customerResponse: 'Customer Response',
                acceptedOption: 'Accepted Option',
                text: {
                    approved:
                        "The date of the customer's reservation is available and you can proceed to the Pricing.",
                    pricing: 'Rate sent to customer by ',
                    changeRequested:
                        'The reservation was returned. Wait until the client accepts it or suggests another date or time.',
                },
                fareValues: 'Fare values',
                valueIn: 'Value in',
                reasonPending: 'Reason for Pending',
                dateDevolution: 'Date Devolution',
                placeholderComment: 'Write a customer review',
                noReservationLandingRequest:
                    'Landing request without patio reservation',
                arrival: 'Arrival',
                departure: 'Departure',
                noBilling: 'Reservation was not billed',
                commentsEditSuccess:
                    'The information for internal control were edited successfully.',
            },
            airports: {
                new: 'New airport',
                airportData: 'Airport Data',
                airportName: 'Airport Name',
                icao: 'ICAO',
                success: 'Airport created successfully',
                update: 'Airport updated successfully',
                delete: 'Airport deleted successfully',
                status: 'Active Airport',
                contactDetails: 'Contact Details',
                textContactDetails:
                    'Write the contact details for customer information and questions:',
                contactEmail: 'Contact E-mail',
                textContactEmail:
                    'Write the e-mail to receive new landing requests notifications:',
                textDeleteConfirm:
                    'Do you want to proceed with the Airport exclusion?',
                titleDocumentation: 'Documentation',
                textDocumentation:
                    'Enter the list of documents required to be sent to the customer during the booking request:',
                nameDocument: 'Document Name',
                noTimezone: "Don't select timezone",
                search: 'Search for ICAO, nome, state or country',
                files: {
                    btn: 'Send Document',
                    accept: 'Mandatory acceptance by the customer',
                    rename: 'Rename',
                    delete: 'Delete',
                    dialog: {
                        title: 'Delete Document',
                        description:
                            'Do you want to proceed with deleting the document?',
                    },
                },
                filters: {
                    states: 'States',
                    managed: 'management',
                    managedBy: {
                        ccr: 'Managed by CCR',
                        other: 'Not managed by CCR',
                    },
                },
                openingHours: 'Opening Hours',
                hasWorkSchedule: 'Does it have specific opening hours?',
                days: 'Days',
                schedule: 'Schedule',
                opening: 'OPENS',
                closing: 'CLOSES',
            },
            users: {
                title: 'Users',
                search: 'Search for e-mail or access profile',
                createSuccess: 'The user was successfully added.',
                editSuccess: 'The user was successfully updated.',
                new: 'New user',
            },
            solicitations: {
                title: 'Registration and editing requests',
                search: 'Search by registration or user name',
                status: {
                    incompleteRegistration: 'Incomplete registration',
                    pendingValidation: 'Pending validation',
                    approvedValidation: 'Approved',
                    disapprovedValidation: 'Disapproved',
                    cancelled: 'Cancelled',
                },
                aircraftNature: 'Aircraft nature',
                nature: {
                    national: 'National',
                    international: 'Foreigner',
                    military: 'Military',
                },
                solicitationType: 'Solicitation type',
                type: {
                    creation: 'New registration',
                    edit: 'Edition',
                },
                details: {
                    newSolicitation: 'New solicitation',
                    approved: 'Approved',
                    disapproved: 'Disapproved',
                    approvedDisapproved: 'Approved/Disapproved',
                    changeAircraftStatus: 'Change aircraft status',
                    observation: 'Observation:',
                    justification: 'Justification',
                    validateAircraft: 'Validate aircraft',
                    reproveAircraft: 'Disapprove aircraft',
                    validation: 'Validation',
                    lastUpdatedAt: 'Last updated at {1} - {2} ({3} UTC)',
                },
            },
        },
        checkout: {
            title: 'Payment',
            bookingTotal: 'Booking total',
            formPayment: 'Form of payment',
            reservationSentIn: 'Reservation sent in ',
            paymentMethods: 'Payment Methods',
            description:
                'Value subject to change after sending the request depending on the flight schedule.',
            paymentSuccess: 'Success',
            paymentFailure: 'Payment failure',
        },
        help: {
            title: 'Contact us',
            subtitle:
                'In case of questions or need of more information, please contact our operators',
            contact: 'Contact',
            noContact: 'No means of contact registered',
            ombudsman: 'CCR Airports Ombudsman',
            workingHours: 'Working hours',
            mondayToFriday: 'Monday to Friday',
            phone: 'Telephone',
            mail: 'E-mail',
            commercialService: 'General Aviation',
            helpCenter: 'Call Center',
            helpCenterLinkText:
                ' to register your request or call 0800 727 4720',
            clickHere: 'Click here',
        },
        debt: {
            tabs: {
                debt: 'Debt',
                history: 'History',
            },
            content: {
                openDebts: 'Open debts',
                invoicesInTotal: 'invoices in total',
                operations: 'operations',
                totalOpenInvoices: 'Total Open Invoices',
                selectOption: 'Select an option',
                printSlip: 'Print slip',
                downloadInvoice: 'Download Invoice',
                invoiceHistory: 'Invoice history',
                searchInvoices: 'Search invoices',
                lateDebt: 'Expired debt',
                dueDate: 'Due date',
                invoice: 'Invoice',
                slip: 'Slip',
                payWithPix: 'Pay with PIX',
                payWithBankSlip: 'Pay with Slip',
            },
            noAircraft: {
                nothingHere: 'There is nothing here yet',
                registerToConsult:
                    'Register an aircraft to consult debts in AVG',
                goToRegister: 'Register Aircraft',
            },
            noDebt: {
                nothingHere: 'No open debts',
                aircraftUpToDate: 'All your aircraft are up to date!',
                goToHome: 'Back to Home',
                filtered: {
                    nothingHere: 'No results',
                    noResults:
                        "We didn't find any valid results for this search, please try again.",
                },
            },
            noHistory: {
                nothingHere: 'There is nothing here yet',
                noRegisters: 'You have no recorded transactions.',
                goToHome: 'Back to Home',
                filtered: {
                    nothingHere: 'No results',
                    noResults:
                        "We didn't find any valid results for this search, please try again.",
                },
            },
            filter: {
                title: 'Filter debts',
                debtPeriodLabel: 'Select Period*',
                aircraftLabel: 'Aircraft Registration',
                operatorLabel: 'Operator',
                airportLabel: 'Operating Airport - CCR',
                filterResults: 'Filter Results',
                dropdownPlaceholder: 'Select',
            },
            tour: {
                list: {
                    steps: [
                        {
                            title: 'Debts',
                            description:
                                'View all your open invoices. Search or filter to sort the list according to your needs.',
                        },
                        {
                            title: 'Invoice details',
                            description:
                                'To view the details of the invoice, generate a payment slip or print the Invoice, just click on the desired card',
                        },
                    ],
                },
                history: {
                    steps: [
                        {
                            title: 'Invoice history ',
                            description:
                                'On this page you will be able to view the invoice history. By clicking on an invoice, you will be able to access the details of the operations and view the proof of payment.',
                        },
                        {
                            title: 'Invoice details',
                            description:
                                'Click on the card to view the invoice breakdown and access proof of payment.',
                        },
                    ],
                },
            },
        },
        invoiceDetails: {
            forbidden: 'You dont have permission to access this invoice',
            title: 'Invoice Details',
            invoiceSummary: 'Invoice details',
            operations: 'Operations',
            operationDetails: 'Operation details',
            permanenceDetails: 'Permanence details',
            CCRAirport: 'CCR airport',
            discount: 'Discount',
            total: 'Total',
            downloadBill: 'DOWNLOAD BILL',
            downloadInvoice: 'DOWNLOAD INVOICE',
            invoiceExpiration: 'Invoice Expiration',
            invoiceDate: 'Invoice date',
            totalValue: 'Total amount',
            invoice: 'Invoice',
            operator: 'Operator',
            aircraftAverageWeight: 'MTOW - Aircraft weight',
            landing: 'Landing',
            landingDate: 'Landing date',
            landingTax: 'Landing Fare',
            takeoff: 'Takeoff',
            takeoffDate: 'Take off date',
            showMore: 'Show details',
            showLess: 'Hide details',
            maneuver: 'Maneuver',
            maneuverTime: 'Maneuver time',
            maneuverValue: 'Maneuver fare',
            stay: 'Stay  ',
            landingCost: 'Landing cost',
            costOfStay: 'Cost of stay',
            lengthOfStay: 'Length of stay',
            costOfPermanence: 'Cost of permanence',
            explanatoryText:
                'If any of the charges presented does not correspond to your operations, contact the other aircraft operators for regularization.',
            selectPaymentOptions: 'Select one option',
            payWithPix: 'Pay with PIX',
            payWithBankSlip: 'Pay with Slip',
            pay: 'Pay',
            cannotPayExpiredDebt:
                'It is not possible to pay a late invoice using the PIX method',
        },
        invoicePayments: {
            invoiceTotal: 'Invoice total',
            pixInstructions:
                "Copy and paste or scan the QRcode into your bank's PIX clipboard to complete the payment.",
            copyPaste: 'COPY PASTE PIX',
            paymentCompleted:
                'Payment completed. This debt may take a few hours to disappear from the list of outstanding debts.',
            proofOfPayment: 'Proof of payment',
            invoiceNumber: 'Invoice number',
            receiptNumber: 'Receipt number',
            expirationDate: 'Expiration date',
            paymentDate: 'Payment date',
            totalPaid: 'Total paid',
            paymentNotConfirmed: 'Payment not confirmed',
            paymentNotConfirmedExplanation:
                'Unfortunately we were unable to identify your payment, please check your balance or contact your bank and try again later.',
            tryAgain: 'Try again',
            downloadBill: 'Download bill',
            downloadProofOfPayment: 'Download proof of payment',
            goBack: 'Go back',
            codeValidUntil: 'Code valid until {}',
            codeExpired: 'Code expired at {}',
        },
        aircraftList: {
            empty: {
                nothingHere: 'There is nothing here yet',
                registerToUseServices:
                    'Register an aircraft to use Aeroaease services.',
                noResults: 'No results',
                redoSearch:
                    "We didn't find any valid results for this search, please try again.",
                goToRegister: 'Register aircraft',
            },
            registerNew: 'Register new aircraft',
            searchRegistration: 'Search for registration',
            aircraftTotal: 'aircraft in total',
            incompleteRegistration: 'Incomplete registration',
        },
    },
    actions: {
        help: 'Help',
        login: 'Login',
        logout: 'Logout',
        search: 'Search',
        profile: 'My profile',
        save: 'Save',
        backToLogin: 'Back to login',
        changePassword: 'Change password',
        consultInvoice: 'Consult invoice',
        forgotPassword: 'Forgot my password',
        resetPassword: 'Reset password',
        createAccount: 'Create new account',
        newReservation: 'New reservation',
        myReservations: 'My reservations',
        termsAndConditions: 'Terms and conditions',
        proceed: 'Proceed',
        back: 'Back',
        completeRegistration: 'Complete registration',
        seeMore: 'See more',
        start: 'Start',
        continueReservation: 'Continue Booking',
        deleteAircraft: 'Delete aircraft',
        moreInformation: 'More information',
        backToTheTop: 'Back to the top',
        cancel: 'Cancel',
        send: 'Send',
        proceedToPayment: 'Proceed to payment',
        edit: 'Edit',
        deleteAirport: 'Delete Airport',
        delete: 'Delete',
        deletePilot: 'Delete pilot',
        filter: 'Filter',
        removeFilters: 'Remove filters',
        readMore: 'Read more',
        finishReservation: 'Finish reservation',
        myAircraft: 'My aircraft',
        myFinances: 'Financial service',
        saveAndContinue: 'Save and continue',
        sendLater: 'Send later',
        attention: 'Attention!',
    },
    status: {
        pendingReview: 'New',
        changeRequested: 'Returned',
        pendingBilling: 'Fee pending',
        pendingPayment: 'Pending Payment',
        processingPayment: 'Processing',
        paymentConfirmed: 'Payment confirmed',
        completed: 'Completed',
        cancelled: 'Cancelled',
        denied: 'Denied',
        title: {
            done: {
                new: 'Request sent',
                pendingReview: 'Request approved',
                changeRequested: 'Date adjustment',
                pendingBilling: 'Request approved',
                pendingPayment: 'Reservation confirmed',
                processingPayment: 'Reservation confirmed',
                paymentConfirmed: 'Reservation confirmed',
                completed: 'Reservation completed',
                cancelled: 'Reservation cancelled',
                denied: 'Reservation denied',
                cleared: 'Reservation completed',
            },
            current: {
                pendingReview: 'Waiting for review',
                changeRequested: 'Date adjustment',
                pendingBilling: 'Waiting for review',
                pendingPayment: 'Confirm the reservation',
                processingPayment: 'Processing payment',
                paymentConfirmed: 'Reservation confirmed',
                completed: 'Reservation completed',
                cancelled: 'Reservation cancelled',
                denied: 'Reservation denied',
            },
        },
        description: {
            done: {
                new: 'Your reservation was sent to analysis.',
                pendingReview:
                    'Your reservation request was analized and approved.',
                changeRequested:
                    'Your original reservation request had date and time that were unavailable.',
                pendingBilling:
                    'Your reservation request was analized and approved.',
                pendingPayment:
                    'Your reservation was confirmed and your payment was approved. Your payment receipt is available below.',
                processingPayment:
                    'Your reservation was confirmed and your payment was approved. Your payment receipt is available below.',
                paymentConfirmed:
                    'Your reservation was confirmed and your payment was approved. Your payment receipt is available below.',
                completed:
                    'Your reservation was finalized successfully. We hope your flight was good.',
                cancelled: 'Your reservation was cancelled.',
                denied: 'Your reservation was denied.',
                cleared:
                    'Your reservation is completed. Your flight is cleared.',
            },
            current: {
                pendingReview:
                    'Your reservation is in analysis. Wait for confirmation.',
                changeRequested:
                    'The date and time requested in your reservation are not available. Fill the next step to accept or refuse the new suggestion.',
                pendingBilling:
                    'Your reservation is in analysis. Wait for confirmation.',
                pendingPayment:
                    'So your reservation can be confirmed, fill the additional information and make the payment.',
                processingPayment: 'Your payment is being processed.',
                paymentConfirmed:
                    'Your reservation was confirmed and your payment was approved. Your payment receipt is available below.',
                completed:
                    'Your reservation was finalized successfully. We hope your flight was good.',
                cancelled: 'Your reservation was cancelled.',
                denied: 'Your reservation was denied.',
            },
        },
        noChangesRequested: 'No changes were requested for this reservation',
    },
    pagination: {
        perPage: 'Per page',
    },
    flightTypes: {
        none: 'General Aviation',
        instruction: 'Instruction',
        experience: 'Experience',
        maintenance: 'Maintenance',
        military: 'Military',
        federalPublicAdministration: 'Federal Public Administration',
        other: 'Other Exemption Types',
    },
    ordering: {
        orderBy: 'Sort by',
        aircraftRegistration: 'Aircraft registration',
        lastUpdated: 'Last updated',
        lastCreated: 'Recent booking',
        firstCreated: 'Older bookings',
    },
    paymentOptions: {
        accountHolder: 'Account Holder',
        pix: 'PIX',
        creditCard: 'Credit card',
    },
    tour: {
        nextStep: 'Next',
        lastStepMessage: 'Understood',
    },
};
