import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    Dropdown,
    Checkbox,
    Input,
    DatePicker,
    TextSwitch,
    Line,
    Title,
    Button,
    StickyFooter,
    TimePicker,
    BottomSheet,
    Radio,
} from 'components';
import Service from 'services';
import Locale from 'locale';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ErrorHelper, MaskHelper } from 'helpers';
import { Airport, Country, Flight, Reservation, State } from 'models';
import { setHours, setMinutes } from 'date-fns';
import moment from 'moment';
import { PaginationParams } from 'utils/enums';
import { useStateCallback } from 'utils/hooks';
import LocalTimeModal from '../LocalTimeModal';
import './styles.scss';

const DATE_PICKER_TIME_INTERVAL = 15;

export default function NewReservationForm({
    submitButtonIsFooter,
    redirectTo,
    formValues,
    isUserAuthenticated,
    ...args
}) {
    const navigate = useNavigate();
    const initialValues = Reservation.createEmpty(isUserAuthenticated);
    const [showLocalTimeModal, setShowLocalTimeModal] = useState(false);
    const [showAirportScheduleWarning, setShowAirportScheduleWarning] =
        useState(false);

    const reservation = formValues?.reservation && {
        ...Reservation.prepareGeneralInfo(
            formValues.reservation,
            formValues.isInitReplication
        ),
        selectedPatioReservationOption: formValues.reservation
            .hasPatioReservation
            ? 'withPatioReservation'
            : 'withoutPatioReservation',
    };

    const formik = useFormik({
        initialValues: reservation || initialValues,
        validationSchema: Reservation.validationSchemaNewReservation,
        onSubmit: (values) => {
            const reservationSubmit = new Reservation(values);
            navigate(redirectTo, {
                state: {
                    ...formValues,
                    reservation: reservationSubmit,
                    redirectTo: '/newreservation/aircraft/',
                },
            });
        },
    });

    const updatePatioReservationField = (checked) => {
        formik.setFieldValue('hasPatioReservation', checked);
        formik.setFieldValue(
            'selectedPatioReservationOption',
            checked ? 'withPatioReservation' : 'withoutPatioReservation'
        );

        if (checked) {
            formik.setFieldValue('hasTakeOffPrevision', checked);
        }
    };

    useEffect(() => {
        if (formik.values.hasTakeOffPrevision) {
            if (!formik.values.destinationFlight) {
                formik.setFieldValue(
                    'destinationFlight',
                    Flight.createEmpty(!isUserAuthenticated)
                );
            }
        } else {
            formik.setFieldValue('destinationFlight', null);
        }
    }, [formik.values.hasTakeOffPrevision]);

    useEffect(() => {
        if (
            !formik.values.destinationFlight ||
            !formik.values.originFlight.datetime ||
            !formik.values.originFlight.hour
        ) {
            return;
        }
        const isTakeOffDayBeforeLandingDay = moment(
            formik.values.destinationFlight.datetime
        ).isBefore(formik.values.originFlight.datetime, 'days');
        const isSameDay = moment(
            formik.values.destinationFlight.datetime
        ).isSame(formik.values.originFlight.datetime, 'day');
        const isTakeOffHourBeforeOrEqualLandingHour = moment(
            formik.values.destinationFlight.hour
        ).isSameOrBefore(formik.values.originFlight.hour, 'minutes');

        if (isTakeOffDayBeforeLandingDay) {
            formik.setFieldValue('destinationFlight.datetime', '');
            formik.setFieldValue('destinationFlight.hour', '');
        } else if (isSameDay && isTakeOffHourBeforeOrEqualLandingHour) {
            formik.setFieldValue('destinationFlight.hour', '');
        }
    }, [
        formik.values.originFlight.datetime,
        formik.values.originFlight.hour,
        formik.values.destinationFlight?.datetime,
    ]);

    return (
        <div {...args}>
            <LocalTimeModal
                show={showLocalTimeModal}
                onClose={() => setShowLocalTimeModal(false)}
            />
            <BottomSheet
                show={showAirportScheduleWarning}
                title={Locale.pages.reservation.airportTimeWarningTitle}
                onClose={() => setShowAirportScheduleWarning(false)}
            >
                <div className="stack--md">
                    <div className="local-time-description">
                        {Locale.pages.reservation.airportTimeWarningMessage}
                    </div>
                    <Button
                        kind="alert"
                        type="button"
                        large
                        onClick={() => {
                            setShowAirportScheduleWarning(false);
                        }}
                    >
                        {Locale.pages.reservation.confirm}
                    </Button>
                    <Button
                        kind="outline"
                        type="button"
                        large
                        onClick={() => {
                            setShowAirportScheduleWarning(false);
                        }}
                    >
                        {Locale.pages.reservation.changeTime}
                    </Button>
                </div>
            </BottomSheet>
            <form onSubmit={formik.handleSubmit} className="stack--lg">
                <FlightForm
                    flightType="originFlight"
                    formik={formik}
                    isUserAuthenticated={isUserAuthenticated}
                    openLocalTimeModal={() => setShowLocalTimeModal(true)}
                    openAirportScheduleWarning={() => {
                        setShowAirportScheduleWarning(true);
                    }}
                />
                <div className="stack--md margin--b-lg">
                    <div className="flex--column">
                        <p className="color--grayscale-blue-gray font-size--md fw-semibold">
                            {Locale.fields.reservation.patioReservation} *
                        </p>
                        {formik.errors.selectedPatioReservationOption &&
                            formik.touched.hasPatioReservation && (
                                <p className="font-size--xs color--support-error">
                                    {
                                        formik.errors
                                            .selectedPatioReservationOption
                                    }
                                </p>
                            )}
                    </div>
                    <Radio
                        id="withPatioReservation"
                        name="withPatioReservation"
                        label={
                            Locale.fields.reservation.hasPatioReservation.yes
                        }
                        checked={
                            formik.values.selectedPatioReservationOption ===
                            'withPatioReservation'
                        }
                        onChange={(e) =>
                            updatePatioReservationField(e.target.checked)
                        }
                        onBlur={formik.handleBlur}
                        error={
                            formik.errors.selectedPatioReservationOption &&
                            formik.touched.hasPatioReservation
                        }
                    />
                    <Radio
                        id="withoutPatioReservation"
                        name="withoutPatioReservation"
                        label={Locale.fields.reservation.hasPatioReservation.no}
                        checked={
                            formik.values.selectedPatioReservationOption ===
                            'withoutPatioReservation'
                        }
                        onChange={(e) =>
                            updatePatioReservationField(!e.target.checked)
                        }
                        onBlur={formik.handleBlur}
                        error={
                            formik.errors.selectedPatioReservationOption &&
                            formik.touched.hasPatioReservation
                        }
                    />
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TextSwitch
                            id="hasTakeOffPrevision"
                            name="hasTakeOffPrevision"
                            label={Locale.fields.reservation.takeOffForecast}
                            checked={formik.values.hasTakeOffPrevision}
                            onChange={(e) =>
                                formik.setFieldValue('hasTakeOffPrevision', e)
                            }
                            disabled={formik.values.hasPatioReservation}
                        />
                    </div>
                </div>

                {formik.values.hasTakeOffPrevision &&
                    formik.values.destinationFlight && (
                        <div className="stack--lg">
                            <Line color="#C9CFDC" />
                            <FlightForm
                                flightType="destinationFlight"
                                formik={formik}
                                isUserAuthenticated={isUserAuthenticated}
                                openLocalTimeModal={() =>
                                    setShowLocalTimeModal(true)
                                }
                            />
                        </div>
                    )}
                {submitButtonIsFooter ? (
                    <StickyFooter>
                        <Button type="submit">{Locale.actions.proceed}</Button>
                    </StickyFooter>
                ) : (
                    <Button type="submit">
                        {Locale.fields.reservation.startReservation}
                    </Button>
                )}
            </form>
        </div>
    );
}

NewReservationForm.propTypes = {
    submitButtonIsFooter: PropTypes.bool,
    redirectTo: PropTypes.string.isRequired,
    reservation: PropTypes.instanceOf(Reservation),
    formValues: PropTypes.object,
    isUserAuthenticated: PropTypes.bool,
};

NewReservationForm.defaultProps = {
    submitButtonIsFooter: true,
    reservation: undefined,
    formValues: undefined,
    isUserAuthenticated: false,
};

function FlightForm({
    flightType,
    formik,
    isUserAuthenticated,
    openLocalTimeModal,
    openAirportScheduleWarning,
}) {
    const landingOrTakeOff =
        flightType === 'originFlight' ? 'landing' : 'takeOff';
    const originOrDestination = flightType.replace('Flight', '');
    const header = Locale.fields.reservation[landingOrTakeOff];
    const values = formik.values[flightType];
    const errors = formik.errors[flightType];
    const touched = formik.touched[flightType];
    const minSearchLength = 4;

    const [airports, setAirports] = useState([]);
    const [airportsOptions, setAirportsOptions] = useStateCallback(
        Airport.parseDropdownOptions([values?.airport])
    );
    const [airportsNoICAO, setAirportsNoICAO] = useState([]);
    const [airportsNoICAOOptions, setAirportsNoICAOOptions] = useState([]);
    const [countries, setCountries] = useState(new Map());
    const [states, setStates] = useState(new Map());
    const [loadingICAOAirports, setLoadingICAOAirports] = useState(false);
    const [initLoadingICAOAirports, setInitLoadingICAOAirports] =
        useState(true);
    const [loadingNoICAOAirports, setLoadingNoICAOAirports] = useState(true);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingStates, setLoadingStates] = useState(false);

    const maxTime =
        formik.values.destinationFlight?.datetime &&
        formik.values.originFlight.datetime
            ? setHours(setMinutes(new Date(), 45), 23)
            : undefined;
    const calculatedMinTime = moment(
        formik.values.destinationFlight?.datetime
    ).isAfter(formik.values.originFlight.datetime, 'days')
        ? setHours(setMinutes(new Date(), 0), 0)
        : moment(formik.values.originFlight.hour)
              .add(DATE_PICKER_TIME_INTERVAL, 'minutes')
              .toDate();
    const minTime =
        formik.values.destinationFlight?.datetime &&
        formik.values.originFlight.datetime
            ? calculatedMinTime
            : undefined;

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [showInfiniteScroll, setShowInfiniteScroll] = useState(false);

    const nextICAOAirports = () => {
        if (!loadingICAOAirports) {
            setPage((currentPage) => currentPage + 1);
        }
    };

    useEffect(() => {
        if (showInfiniteScroll) {
            getICAOAirports();
        }
    }, [page]);

    useEffect(() => {
        if (search.length >= minSearchLength) {
            setPage(1);
            setShowInfiniteScroll(false);
            getICAOAirports(true);
        }
    }, [search]);

    const getICAOAirports = (reset) => {
        setLoadingICAOAirports(true);

        Service.listAirports({
            [PaginationParams.page]: reset ? 1 : page,
            [PaginationParams.pageSize]: 10,
            [Airport.filterParams.search]: search || values.airport.name,
            [Airport.filterParams.hasICAO]: true,
            [Airport.filterParams.isActive]: true,
        })
            .then((response) => {
                let airportsResponse = Airport.createListFromResponse(
                    response.results
                );
                setShowInfiniteScroll(!!response.next);
                if (!reset) {
                    airportsResponse = airports.concat(airportsResponse);
                }
                setAirports(airportsResponse);
                setAirportsOptions(
                    Airport.parseDropdownOptions(airportsResponse),
                    () => {
                        setInitLoadingICAOAirports(false);
                        setLoadingICAOAirports(false);
                    }
                );
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setInitLoadingICAOAirports(false);
                setLoadingICAOAirports(false);
            });
    };

    const getNoICAOAirports = () => {
        setLoadingNoICAOAirports(true);
        Service.listAirports({ [Airport.filterParams.hasICAO]: false })
            .then((response) => {
                const airportsResponse =
                    Airport.createListFromResponse(response);
                setAirportsNoICAO(airportsResponse);
                setAirportsNoICAOOptions(
                    Airport.parseDropdownOptions(airportsResponse)
                );
                setLoadingNoICAOAirports(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoadingNoICAOAirports(false);
            });
    };

    const getCountries = () => {
        setLoadingCountries(true);
        Service.getCountries()
            .then((response) => {
                const responseCountries =
                    Country.createListFromResponse(response);
                setCountries(
                    new Map(
                        responseCountries.map((responseCountry) => [
                            responseCountry.value,
                            responseCountry,
                        ])
                    )
                );
                setLoadingCountries(false);
            })
            .catch((error) => {
                setLoadingCountries(false);
                ErrorHelper.notifyError(error);
            });
    };

    useEffect(() => {
        if (countries) {
            const orderedCountries = new Map(
                [...countries].sort((a, b) =>
                    a[1].nameByLanguage.localeCompare(b[1].nameByLanguage)
                )
            );
            setCountries(orderedCountries);
        }
    }, [Locale.getLanguage()]);

    useEffect(() => {
        getCountries();
        if (isUserAuthenticated) {
            getNoICAOAirports();
        }
    }, []);

    useEffect(() => {
        if (!values.airport?.country?.id) {
            return;
        }
        setLoadingStates(true);
        Service.getStates({
            [State.filterParams.countryId]: values.airport.country.id,
        })
            .then((response) => {
                const responseStates = State.createListFromResponse(response);
                setStates(
                    new Map(
                        responseStates.map((responseState) => [
                            responseState.value,
                            responseState,
                        ])
                    )
                );
                setLoadingStates(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoadingStates(false);
            });
    }, [values.airport?.country?.id]);

    const setAirportFields = (idSelected, field, options) => {
        const selectedAirport = options.find(
            (data) => data.id.toString() === idSelected
        );

        if (!selectedAirport) {
            return;
        }

        formik.setFieldValue(`${field}.id`, selectedAirport.id.toString());
        formik.setFieldValue(`${field}.name`, selectedAirport.name);
        formik.setFieldValue(`${field}.timezone`, selectedAirport.timezone);
        formik
            .setFieldValue(`${field}.icaoCode`, selectedAirport.icaoCode)
            .then(() => {
                formik.validateForm();
            });
        formik.setFieldValue(
            `${field}.weeklyWorkSchedule`,
            selectedAirport.weeklyWorkSchedule
        );
    };

    const resetAirportValues = (flight) => {
        formik.setFieldValue(`${flight}.airport.id`, undefined);
        formik.setFieldValue(`${flight}.airport.name`, undefined);
        formik.setFieldValue(`${flight}.airport.icaoCode`, undefined);
        formik.setFieldValue(`${flight}.airport.iataCode`, undefined);
        formik.setFieldValue(`${flight}.airport.city`, undefined);
        formik.setFieldValue(`${flight}.airport.state`, State.createEmpty());
        formik.setFieldValue(
            `${flight}.airport.country`,
            Country.createEmpty()
        );
    };

    const hasTouchedAirport = Object.values(touched?.airport || {}).some(
        (value) => value
    );

    useEffect(() => {
        if (
            formik.values.airportTarget.id ===
            formik.values.originFlight.airport?.id
        ) {
            resetAirportValues('originFlight');
        }

        if (
            formik.values.airportTarget.id ===
            formik.values.destinationFlight?.airport?.id
        ) {
            resetAirportValues('destinationFlight');
        }
    }, [formik.values.airportTarget]);

    return (
        <div className="stack--lg">
            {formik.values.hasTakeOffPrevision &&
                landingOrTakeOff === 'takeOff' && (
                    <Title tagName="h4">{header}</Title>
                )}
            {flightType === 'destinationFlight' && (
                <FlightAmountInputFields
                    flightType={flightType}
                    values={values}
                    touched={touched}
                    errors={errors}
                    formik={formik}
                    minTime={minTime}
                    maxTime={maxTime}
                    openLocalTimeModal={openLocalTimeModal}
                />
            )}
            <Dropdown
                id={`${flightType}.airport.id`}
                name={`${flightType}.airport.id`}
                label={Locale.fields.reservation[originOrDestination]}
                options={airportsOptions.filter(
                    (option) =>
                        option?.value?.toString() !==
                        formik.values.airportTarget?.id?.toString()
                )}
                onChange={(e) => {
                    setAirportFields(
                        e.target.value,
                        `${flightType}.airport`,
                        airports
                    );
                }}
                value={values.airport.id}
                error={
                    !values.airport.noICAO &&
                    hasTouchedAirport &&
                    !!errors?.airport?.id
                        ? errors.airport.id
                        : undefined
                }
                onBlur={formik.handleBlur}
                disabled={values.airport?.noICAO}
                required
                autocomplete
                requestAutocomplete={setSearch}
                loading={loadingICAOAirports}
                resetLoading={initLoadingICAOAirports}
                setResetLoading={setInitLoadingICAOAirports}
                loadCallback={nextICAOAirports}
                showInfiniteScroll={showInfiniteScroll}
                minSearchLength={minSearchLength}
                placeholder={Locale.fields.airport.search}
                tooltipContent={
                    originOrDestination === 'destination'
                        ? Locale.fields.reservation.destinationDropdownTooltip
                        : null
                }
                isSearchDropdown
            />
            <Checkbox
                id={`${flightType}.airport.noICAO`}
                name={`${flightType}.airport.noICAO`}
                label={Locale.fields.reservation.noICAO}
                onChange={(e) => {
                    formik.setFieldValue(
                        `${flightType}.airport.noICAO`,
                        e.target.checked
                    );

                    resetAirportValues(flightType);
                }}
                checked={values.airport.noICAO}
            />
            {values.airport.noICAO && (
                <div>
                    <div className="row">
                        <div className="col-md-3">
                            {isUserAuthenticated && (
                                <TextSwitch
                                    id="createAirport"
                                    name="createAirport"
                                    label={
                                        Locale.pages.reservation
                                            .newAirportNoICAO
                                    }
                                    checked={!!values.airport.isCreating}
                                    onChange={(value) => {
                                        formik.setFieldValue(
                                            `${flightType}.airport.isCreating`,
                                            value
                                        );

                                        if (value) {
                                            resetAirportValues(flightType);
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {!values.airport.isCreating ? (
                        <Dropdown
                            id={`${flightType}.airport.name`}
                            name={`${flightType}.airport.name`}
                            label={
                                flightType === 'originFlight'
                                    ? Locale.fields.reservation.homeAerodrome
                                    : Locale.fields.reservation.destinyAerodrome
                            }
                            options={airportsNoICAOOptions}
                            onChange={(e) => {
                                setAirportFields(
                                    e.target.value,
                                    `${flightType}.airport`,
                                    airportsNoICAO
                                );
                            }}
                            value={values.airport.id}
                            error={
                                values.airport.noICAO &&
                                !values.airport.isCreating &&
                                hasTouchedAirport &&
                                errors?.airport?.id
                                    ? errors.airport.id
                                    : undefined
                            }
                            onBlur={formik.handleBlur}
                            required
                            autocomplete
                            loading={loadingNoICAOAirports}
                        />
                    ) : (
                        <div className="stack--md">
                            <Input
                                id={`${flightType}.airport.name`}
                                name={`${flightType}.airport.name`}
                                label={
                                    flightType === 'originFlight'
                                        ? Locale.fields.reservation
                                              .homeAerodrome
                                        : Locale.fields.reservation
                                              .destinyAerodrome
                                }
                                value={values.airport.name}
                                onChange={formik.handleChange}
                                error={
                                    touched?.airport?.name &&
                                    errors?.airport?.name
                                }
                                required
                                onBlur={formik.handleBlur}
                                disabled={!!values.airport.id}
                            />
                            <div className="row">
                                <div className="col-md-4">
                                    <Dropdown
                                        id={`${flightType}.airport.country.id`}
                                        name={`${flightType}.airport.country.id`}
                                        label={Locale.fields.country}
                                        options={Array.from(countries.values())}
                                        value={values.airport.country.id}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                `${flightType}.airport.country`,
                                                countries.get(e.target.value)
                                            );
                                        }}
                                        error={
                                            touched?.airport?.country?.id &&
                                            errors?.airport?.country?.id
                                        }
                                        required
                                        onBlur={formik.handleBlur}
                                        disabled={!!values.airport.id}
                                        autocomplete
                                        loading={loadingCountries}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Dropdown
                                        id={`${flightType}.airport.state.id`}
                                        name={`${flightType}.airport.state.id`}
                                        label={Locale.fields.state}
                                        options={Array.from(states.values())}
                                        value={values.airport.state.id}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                `${flightType}.airport.state`,
                                                states.get(e.target.value)
                                            );
                                        }}
                                        error={
                                            touched?.airport?.state?.id &&
                                            errors?.airport?.state?.id
                                        }
                                        required
                                        onBlur={formik.handleBlur}
                                        disabled={
                                            !!values.airport.id ||
                                            !values.airport.country?.id
                                        }
                                        autocomplete
                                        loading={loadingStates}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        id={`${flightType}.airport.city`}
                                        name={`${flightType}.airport.city`}
                                        label={Locale.fields.city}
                                        value={values.airport.city}
                                        onChange={formik.handleChange}
                                        error={
                                            touched?.airport?.city &&
                                            errors?.airport?.city
                                        }
                                        required
                                        onBlur={formik.handleBlur}
                                        disabled={!!values.airport.id}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {flightType === 'originFlight' && (
                <>
                    <Line color="#C9CFDC" />
                    <Title tagName="h4">{header}</Title>
                    <OriginDropdown
                        formik={formik}
                        setAirportFields={setAirportFields}
                    />
                    <FlightAmountInputFields
                        flightType={flightType}
                        values={values}
                        touched={touched}
                        errors={errors}
                        formik={formik}
                        minTime={minTime}
                        maxTime={maxTime}
                        openLocalTimeModal={openLocalTimeModal}
                        openAirportScheduleWarning={openAirportScheduleWarning}
                    />
                </>
            )}
        </div>
    );
}

FlightForm.propTypes = {
    flightType: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    openLocalTimeModal: PropTypes.func.isRequired,
    openAirportScheduleWarning: PropTypes.func,
};

FlightForm.defaultProps = {
    openAirportScheduleWarning: () => {},
};

function FlightAmountInputFields({
    flightType,
    values,
    touched,
    errors,
    formik,
    minTime,
    maxTime,
    openLocalTimeModal,
    openAirportScheduleWarning,
}) {
    useEffect(() => {
        if (flightType === 'originFlight') {
            const selectedDay = formik.values[flightType]?.datetime;
            const selectedHour = formik.values[flightType]?.hour;
            if (selectedDay && selectedHour) {
                const weekdayString = selectedDay
                    ?.toLocaleDateString('en-us', { weekday: 'long' })
                    ?.toLowerCase();

                const weekdayWorkSchedule =
                    formik.values.airportTarget?.weeklyWorkSchedule?.[
                        weekdayString
                    ];

                if (
                    weekdayWorkSchedule?.openingTime &&
                    selectedHour.getHours() * 60 + selectedHour.getMinutes() <
                        weekdayWorkSchedule.openingTime.getHours() * 60 +
                            weekdayWorkSchedule.openingTime.getMinutes()
                ) {
                    openAirportScheduleWarning();
                }
                if (
                    weekdayWorkSchedule?.closingTime &&
                    selectedHour.getHours() * 60 + selectedHour.getMinutes() >
                        weekdayWorkSchedule.closingTime.getHours() * 60 +
                            weekdayWorkSchedule.closingTime.getMinutes()
                ) {
                    openAirportScheduleWarning();
                }
            }
        }
    }, [
        formik.values[flightType]?.datetime,
        formik.values[flightType]?.hour,
        formik.values.airportTarget,
    ]);

    return (
        <div className="row flight-amount-input-wrapper">
            <div className="col-lg-6">
                <Input
                    id={`${flightType}.peopleOnBoard`}
                    name={`${flightType}.peopleOnBoard`}
                    label={Locale.fields.reservation.peopleOnBoard}
                    type="number"
                    value={values.peopleOnBoard}
                    error={touched?.peopleOnBoard && errors?.peopleOnBoard}
                    onChange={(e) =>
                        formik.setFieldValue(
                            `${flightType}.peopleOnBoard`,
                            MaskHelper.numeric(e.target.value, 0)
                        )
                    }
                    onBlur={formik.handleBlur}
                    isInt
                    required
                />
            </div>
            <div className="col-lg-3 col-md-6">
                <DatePicker
                    name={`${flightType}.datetime`}
                    id={`${flightType}.datetime`}
                    label={
                        flightType === 'originFlight'
                            ? Locale.fields.reservation.landingDate
                            : Locale.fields.reservation.takeOffDate
                    }
                    isRequired
                    value={values.datetime}
                    onChange={(datetime) =>
                        formik.setFieldValue(`${flightType}.datetime`, datetime)
                    }
                    required
                    error={touched?.datetime && errors?.datetime}
                    minDate={
                        flightType === 'destinationFlight'
                            ? formik.values.originFlight.datetime
                            : undefined
                    }
                    moreInputSpace
                />
                <span className="overline-2 color--grayscale-steel-gray">
                    {Locale.pages.reservation.landingDateInLocalTime}.{' '}
                </span>
            </div>
            <div className="col-lg-3 col-md-6">
                <TimePicker
                    name={`${flightType}.hour`}
                    id={`${flightType}.hour`}
                    label={
                        flightType === 'originFlight'
                            ? Locale.fields.reservation.landingHour
                            : Locale.fields.reservation.takeOffHour
                    }
                    value={values.hour}
                    timeIntervals={DATE_PICKER_TIME_INTERVAL}
                    timeCaption={Locale.general.hour}
                    onChange={(hour) =>
                        formik.setFieldValue(`${flightType}.hour`, hour)
                    }
                    error={touched?.hour && errors?.hour}
                    minTime={
                        flightType === 'destinationFlight' ? minTime : null
                    }
                    maxTime={
                        flightType === 'destinationFlight' ? maxTime : null
                    }
                    moreInputSpace
                />
                <span className="overline-2 color--grayscale-steel-gray">
                    <span>{Locale.pages.reservation.localTime}. </span>
                    <span
                        onClick={openLocalTimeModal}
                        className="color--brand-primary cursor--pointer"
                    >
                        {Locale.actions.readMore}
                    </span>
                </span>
            </div>
        </div>
    );
}

FlightAmountInputFields.propTypes = {
    flightType: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
    minTime: PropTypes.object.isRequired,
    maxTime: PropTypes.object.isRequired,
    openLocalTimeModal: PropTypes.func.isRequired,
    openAirportScheduleWarning: PropTypes.func,
};

FlightAmountInputFields.defaultProps = {
    openAirportScheduleWarning: () => {},
};

function OriginDropdown({ formik, setAirportFields }) {
    const [airports, setAirports] = useState([]);
    const [airportsOptions, setAirportsOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCCRAirports = () => {
        setLoading(true);
        Service.listAirports({
            [Airport.filterParams.isActive]: true,
            [Airport.filterParams.isManagedByCcr]: true,
        })
            .then((response) => {
                const airportsResponse =
                    Airport.createListFromResponse(response);
                setAirports(airportsResponse);
                setAirportsOptions(
                    Airport.parseDropdownOptions(airportsResponse)
                );
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getCCRAirports();
    }, []);

    return (
        <Dropdown
            id="airportTarget.id"
            name="airportTarget.id"
            label={Locale.fields.CCRAirport}
            options={airportsOptions}
            onChange={(e) => {
                setAirportFields(e.target.value, 'airportTarget', airports);
            }}
            value={formik.values.airportTarget.id}
            error={
                formik.touched.airportTarget?.id &&
                formik.errors.airportTarget?.id
            }
            onBlur={formik.handleBlur}
            tooltipContent={Locale.fields.reservation.originDropdownTooltip}
            required
            autocomplete
            loading={loading}
        />
    );
}

OriginDropdown.propTypes = {
    formik: PropTypes.object.isRequired,
    setAirportFields: PropTypes.func.isRequired,
};
