import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Image } from 'components';

import { icoUploadDuotone } from 'assets/icons';

import './styles.scss';

function InputFile({
    name,
    label,
    accept,
    multiple,
    error,
    required,
    disabled,
    onChange,
    onBlur,
}) {
    return (
        <>
            <div
                className={clsx('container-input-file', {
                    error,
                    disabled,
                })}
            >
                <input
                    type="file"
                    id={name}
                    name={name}
                    accept={accept}
                    multiple={multiple}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required}
                    disabled={disabled}
                />
                <label htmlFor={name}>
                    <Image src={icoUploadDuotone} alt={label} />
                    {label && <span>{label}</span>}
                </label>
            </div>
            {error && <p className="overline-2 txt-error">{error}</p>}
        </>
    );
}

InputFile.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

InputFile.defaultProps = {
    label: undefined,
    error: '',
    accept: 'application/pdf',
    multiple: false,
    required: false,
    disabled: false,
    onChange: undefined,
    onBlur: undefined,
};

export default InputFile;
