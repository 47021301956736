import * as Yup from 'yup';
import Locale from 'locale';
import { BR_PHONE_CODE } from 'utils/constants';
import { getDocumentLabel } from 'utils/functions';
import BaseModel from './BaseModel';
import Address from './Address';

export default class Operator extends BaseModel {
    constructor(data) {
        super();

        this.email = data.email;
        this.secondEmail = data.secondEmail;
        this.thirdEmail = data.thirdEmail;
        this.name = data.name;
        this.document = data.document;
        this.countryPhoneCode = data.countryPhoneCode || BR_PHONE_CODE;
        this.phoneNumber = data.phoneNumber;
        this.isForeigner = !!data.isForeigner;
        this.isCompany = !!data.isCompany;
        this.address = Address.getValueOrNew(data.address);
    }

    static createEmpty() {
        return new Operator({
            isForeigner: false,
            isCompany: false,
            address: Address.createEmpty(),
        });
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new Operator({
            ...response,
        });
    }

    static get maxLength() {
        return { phoneNumber: 15 };
    }

    static get validationSchema() {
        return Yup.object({
            email: Yup.string()
                .email(Locale.errors.validEmail)
                .required(Locale.errors.required),
            name: Yup.string().required(Locale.errors.required),
            document: Yup.string().required(Locale.errors.required),
            countryPhoneCode: Yup.string().required(Locale.errors.required),
            phoneNumber: Yup.string()
                .max(
                    this.maxLength.phoneNumber,
                    Locale.errors.maxLength(this.maxLength.phoneNumber)
                )
                .required(Locale.errors.required),
            isForeigner: Yup.boolean(),
            isCompany: Yup.boolean(),
            address: Address.validationSchema,
            secondEmail: Yup.string()
                .email(Locale.errors.validEmail)
                .nullable(),
            thirdEmail: Yup.string().email(Locale.errors.validEmail).nullable(),
        });
    }

    static get aircraftOperatorValidationSchema() {
        return Yup.object({
            operator: this.validationSchema,
        });
    }

    static parseDropdownOptions(list) {
        return list.map((item) => ({
            label: `${item.name} - ${item.document}`,
            value: item.document,
        }));
    }

    parseToRequest() {
        const { ...operatorData } = this;
        const payload = {
            ...operatorData,
        };

        if (operatorData.address) {
            payload.address = operatorData.address.parseToRequest();
        } else {
            delete payload.address;
        }

        return payload;
    }

    get documentLabel() {
        return getDocumentLabel(this.isForeigner, this.isCompany);
    }
}
