import { useFormik } from 'formik';
import { HeaderPage, Button, Radio, SelectedAircraftCard } from 'components';
import Locale from 'locale';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles.scss';
import { Aircraft } from 'models';

function OperatorTypeOption({ title, name, description, checked, onClick }) {
    return (
        <div className="operator-type-option" onClick={onClick}>
            <div>
                <p className="fw-semibold font-size--md">{title}</p>
                <p className="font-size--sm">{description}</p>
            </div>
            <Radio id={name} name={name} checked={checked} readOnly />
        </div>
    );
}

OperatorTypeOption.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
};

OperatorTypeOption.defaultProps = {
    checked: false,
    onClick: undefined,
};

function OperatorType() {
    const navigate = useNavigate();
    const location = useLocation();
    const { aircraft } = location.state;
    const initialValues = new Aircraft(aircraft);

    const formik = useFormik({
        initialValues,
        onSubmit: (data) => {
            navigate('/newaircraft/operatordata', {
                state: {
                    ...location.state,
                    aircraft: {
                        ...location.state?.aircraft,
                        operator: data.operator,
                    },
                },
            });
        },
    });

    return (
        <div className="padding--lg height--full stack--lg operator-type-wrapper">
            <form
                onSubmit={formik.handleSubmit}
                className="height--full stack--lg"
            >
                <div className="height--full stack--lg ">
                    <HeaderPage
                        title={Locale.pages.register.aircraft.howToInvoiceTitle}
                        description={
                            Locale.pages.register.aircraft
                                .howToInvoiceDescription
                        }
                        labelBtnBack={Locale.actions.back}
                        onClickBtnBack={() =>
                            navigate('/newaircraft/documents', {
                                state: {
                                    ...location.state,
                                    aircraft: {
                                        ...location.state?.aircraft,
                                        operator: {
                                            ...location.state?.aircraft
                                                ?.operator,
                                            isCompany:
                                                formik.values.operator
                                                    ?.isCompany,
                                        },
                                    },
                                },
                            })
                        }
                    />
                    <SelectedAircraftCard aircraft={aircraft} />
                    <OperatorTypeOption
                        title={Locale.fields.aircraft.companyInvoice.title}
                        name="companyInvoice"
                        description={
                            Locale.fields.aircraft.companyInvoice.description
                        }
                        checked={formik.values.operator?.isCompany}
                        onClick={() => {
                            formik.setFieldValue('operator.isCompany', true);
                        }}
                    />
                    <OperatorTypeOption
                        title={Locale.fields.aircraft.individualInvoice.title}
                        name="individualInvoice"
                        description={
                            Locale.fields.aircraft.individualInvoice.description
                        }
                        checked={!formik.values.operator?.isCompany}
                        onClick={() => {
                            formik.setFieldValue('operator.isCompany', false);
                        }}
                    />
                </div>
                <div className="width-full">
                    <Button type="submit" className="width--full">
                        {Locale.actions.saveAndContinue}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default OperatorType;
