import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Card({ children, isRounded, kind, ...args }) {
    return (
        <Container isRounded={isRounded} kind={kind} {...args}>
            {children}
        </Container>
    );
}

Card.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    kind: PropTypes.oneOf(['filled', 'outlined', 'secondary', '']),
    isRounded: PropTypes.bool,
};

Card.defaultProps = {
    kind: 'filled',
    isRounded: true,
};

export default Card;
