import { get, post, patch, remove } from './axios';

const AirportService = {
    listAirports(params) {
        return get('airport/', { params });
    },

    createAirport(data) {
        return post('airport/create', data);
    },

    getAirport(pk) {
        return get(`airport/${pk}`);
    },

    patchAirport(pk, data) {
        return patch(`airport/${pk}`, data);
    },

    removeAirport(pk) {
        return remove(`airport/${pk}`);
    },
};

const AirportDocumentService = {
    listAirportDocuments(airportId, params) {
        return get(`airport/${airportId}/document/`, { params });
    },

    createAirportDocument(airportId, data) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('filepath', data.filepath);
        formData.append('isAcceptanceRequired', !!data.isAcceptanceRequired);
        return post(`airport/${airportId}/document/`, formData, config);
    },

    getAirportDocument(airportId, pk) {
        return get(`airport/${airportId}/document/${pk}`);
    },

    patchAirportDocument(airportId, pk, data) {
        return patch(`airport/${airportId}/document/${pk}`, data);
    },

    removeAirportDocument(airportId, pk) {
        return remove(`airport/${airportId}/document/${pk}`);
    },
};

export default { ...AirportService, ...AirportDocumentService };
