import Locale from 'locale';
import {
    icoInfoRed,
    icoKebabMenu,
    iconChevronRightPrimary,
} from 'assets/icons';
import PropTypes from 'prop-types';
import './styles.scss';
import { ButtonIcon, Image, Card } from 'components';
import Debt from 'models/Debt';
import { format } from 'date-fns';

export default function DebtCard({
    debt,
    kind,
    showMenu,
    openMenu,
    onClick,
    isDebtActive,
    isPaid,
    className,
}) {
    return (
        <div className={`debt-card ${className}`}>
            <Card
                className={`${isDebtActive && 'active-debt-card'}`}
                kind={kind}
                isRounded
                onClick={onClick}
            >
                <div className="inline--sm flex-justify--between align-items-center">
                    <span className="debt-title flex-items--center gap-1">
                        <p
                            className={`airport ${
                                isDebtActive ? 'active' : 'disabled'
                            }`}
                        >
                            {debt.aeroportoICAO}
                        </p>
                        {' - '}
                        <p className="invoice">{debt.noDocFaturamento}</p>
                    </span>
                    {isDebtActive &&
                        (isPaid ? (
                            <Image src={iconChevronRightPrimary} size="16" />
                        ) : (
                            showMenu && (
                                <ButtonIcon
                                    className="kebab"
                                    kind="no-border"
                                    icon={icoKebabMenu}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (openMenu) {
                                            openMenu();
                                        }
                                    }}
                                    size="medium"
                                />
                            )
                        ))}
                </div>
                <p>
                    {debt.qtdOperacoes} {Locale.pages.debt.content.operations}
                </p>
                <p>{debt.clienteName}</p>
                {debt.isLate ? (
                    <>
                        <p>
                            {Locale.pages.debt.content.dueDate}{' '}
                            {format(debt.dtVencimento, 'dd/MM/yyyy')}
                        </p>
                        <div className="late-debt-warning" />
                        <div className="flex-justify--between">
                            <div className="inline--xs flex-items--center">
                                <Image src={icoInfoRed} alt="" size={16} />
                                <div className="color--support-error overline-2">
                                    {Locale.pages.debt.content.lateDebt}
                                </div>
                            </div>
                            <p className="value">
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                }).format(debt.vlrTotal)}
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="flex-justify--between">
                        <p>
                            {Locale.pages.debt.content.dueDate}{' '}
                            {format(debt.dtVencimento, 'dd/MM/yyyy')}
                        </p>
                        <p className="value">
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(debt.vlrTotal)}
                        </p>
                    </div>
                )}
            </Card>
        </div>
    );
}

DebtCard.propTypes = {
    debt: PropTypes.instanceOf(Debt).isRequired,
    kind: PropTypes.oneOf(['filled', 'outlined', 'secondary', '']),
    showMenu: PropTypes.bool,
    openMenu: PropTypes.func,
    onClick: PropTypes.func,
    isDebtActive: PropTypes.bool,
    isPaid: PropTypes.bool,
    className: PropTypes.string,
};

DebtCard.defaultProps = {
    openMenu: undefined,
    className: '',
    kind: '',
    onClick: undefined,
    isDebtActive: false,
    isPaid: false,
    showMenu: true,
};
