import { FullPageModal, Input, Button, Text } from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Service from 'services';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';
import { useState, useEffect } from 'react';

export default function ChangePasswordModal({ show, onClose }) {
    const [serverErrors, setServerErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const passwordMin = 8;

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(Locale.errors.required),
        newPassword: Yup.string()
            .min(passwordMin, Locale.errors.minLength(passwordMin))
            .matches(/[\D]/, Locale.pages.register.user.numericPassword)
            .required(Locale.errors.required),
        confirmPassword: Yup.string()
            .required(Locale.errors.required)
            .oneOf(
                [Yup.ref('newPassword'), null],
                Locale.errors.passwordsMatch
            ),
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            Service.patchUser({
                currentPassword: values.currentPassword,
                password: values.newPassword,
                passwordConfirm: values.confirmPassword,
            })
                .then(() => {
                    notify(
                        Locale.pages.accountSettings.personal.changePassword
                            .success
                    );
                    resetForm();
                    onClose();
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response?.status === 400) {
                        setServerErrors(error.response?.data);
                    } else {
                        ErrorHelper.notifyError(error);
                    }
                    setLoading(false);
                });
        },
    });

    useEffect(() => {
        formik.setErrors({
            ...formik.errors,
            ...serverErrors,
            newPassword: serverErrors?.password,
        });
    }, [serverErrors]);

    return (
        <FullPageModal
            show={show}
            title={Locale.actions.changePassword}
            onClose={onClose}
            endPageButton={
                <Button
                    type="submit"
                    onClick={formik.handleSubmit}
                    isLoading={loading}
                >
                    {Locale.actions.changePassword}
                </Button>
            }
        >
            <div className="row">
                <div className="col-12 col-md-4">
                    <Input
                        type="password"
                        label={Locale.fields.user.currentPassword}
                        id="currentPassword"
                        name="currentPassword"
                        onChange={formik.handleChange}
                        value={formik.values.currentPassword}
                        error={
                            formik.touched.currentPassword &&
                            formik.errors.currentPassword
                        }
                        onBlur={formik.handleBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <Input
                        type="password"
                        label={Locale.fields.user.newPassword}
                        id="newPassword"
                        name="newPassword"
                        onChange={formik.handleChange}
                        value={formik.values.newPassword}
                        error={
                            formik.touched.newPassword &&
                            formik.errors.newPassword
                        }
                        onBlur={formik.handleBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <Input
                        type="password"
                        label={Locale.fields.user.confirmNewPassword}
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        error={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                        }
                        onBlur={formik.handleBlur}
                        required
                    />
                    <Text
                        kind="overline-2"
                        className="margin--t-sm margin--x-md"
                    >
                        {/* TODO MAG-495 adicionar na string abaixo */}
                        <p>{Locale.pages.register.user.commonPassword}</p>
                        <p>{Locale.pages.register.user.lengthPassword}</p>
                        <p>{Locale.pages.register.user.numericPassword}</p>
                    </Text>
                </div>
            </div>
        </FullPageModal>
    );
}

ChangePasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
