import Slider from 'react-slick';

import Locale from 'locale';

import img01 from 'assets/images/carousel/img-desktop-1.jpg';
import imgMobile01 from 'assets/images/carousel/img-mobile-1.jpg';
import img02 from 'assets/images/carousel/img-desktop-2.jpg';
import imgMobile02 from 'assets/images/carousel/img-mobile-2.jpg';
import img03 from 'assets/images/carousel/img-desktop-3.jpg';
import imgMobile03 from 'assets/images/carousel/img-mobile-3.jpg';
import img04 from 'assets/images/carousel/img-desktop-4.jpg';
import imgMobile04 from 'assets/images/carousel/img-mobile-4.jpg';

import {
    icoMobilePhone,
    icoAeroplane,
    icoLanding,
    icoSearchWhite,
} from 'assets/icons';

import ItemCarrousel from './modules';

import './styles.scss';

export default function Carousel() {
    const items = [
        {
            icon: icoMobilePhone,
            title: Locale.pages.homeNotLoggedIn.banner.register.title,
            subtitle: Locale.pages.homeNotLoggedIn.banner.register.subtitle,
            button: Locale.pages.homeNotLoggedIn.banner.register.button,
            link: '/',
            linkExternal: false,
            tab: '',
            imgDesktop: img04,
            imgMobile: imgMobile04,
        },
        {
            icon: icoAeroplane,
            title: Locale.pages.homeNotLoggedIn.banner.aircraft.title,
            subtitle: Locale.pages.homeNotLoggedIn.banner.aircraft.subtitle,
            button: Locale.pages.homeNotLoggedIn.banner.aircraft.button,
            link: '/aircraft',
            linkExternal: false,
            tab: 'aircrafts',
            imgDesktop: img01,
            imgMobile: imgMobile01,
        },
        {
            icon: icoLanding,
            title: Locale.pages.homeNotLoggedIn.banner.reserve.title,
            subtitle: Locale.pages.homeNotLoggedIn.banner.reserve.subtitle,
            button: Locale.pages.homeNotLoggedIn.banner.reserve.button,
            link: '/newreservation/generalinfo',
            linkExternal: false,
            tab: '',
            imgDesktop: img02,
            imgMobile: imgMobile02,
        },
        {
            icon: icoSearchWhite,
            title: Locale.pages.homeNotLoggedIn.banner.billing.title,
            subtitle: Locale.pages.homeNotLoggedIn.banner.billing.subtitle,
            button: Locale.pages.homeNotLoggedIn.banner.billing.button,
            link: 'https://ccraeroportos.omd.com.br/ccrfinanceiro/externo/cadastro.do',
            linkExternal: true,
            tab: '',
            imgDesktop: img03,
            imgMobile: imgMobile03,
        },
    ];
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className="container-carrousel">
            <Slider {...settings}>
                {items.map((item) => (
                    <ItemCarrousel
                        {...item}
                        key={`carousel-item-${Math.random()}`}
                    />
                ))}
            </Slider>
        </div>
    );
}
