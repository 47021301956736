import Locale from 'locale';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Service from 'services';
import { Line } from 'components';
import PropTypes from 'prop-types';
import { AircraftSolicitation } from 'models';
import { ErrorHelper } from 'helpers';
import {
    AircraftSolicitationType,
    OrderingParams,
    getAircraftSolicitationStatus,
} from 'utils/enums';
import './styles.scss';

const getSolicitationTypeLabel = (solicitationType) =>
    solicitationType === AircraftSolicitationType.creation
        ? Locale.pages.aircraftDetails.solicitationsHistory.type.creation
        : Locale.pages.aircraftDetails.solicitationsHistory.type.edit;

function SolicitationHistoryStatus({ status }) {
    return (
        <p
            className={`solicitation-history-status fw-semibold ${getAircraftSolicitationStatus(
                status
            )}`}
        >
            &#x25CF;{' '}
            {
                Locale.pages.aircraftDetails.solicitationsHistory.status[
                    getAircraftSolicitationStatus(status)
                ]
            }
        </p>
    );
}

SolicitationHistoryStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

function SolicitationHistoryItem({ solicitation }) {
    return (
        <div className="stack--md">
            <div className="flex--row flex-justify--between flex-items--center">
                <div className="flex--column">
                    <p className="font-size--sm solicitation-history-item-title">
                        {getSolicitationTypeLabel(solicitation.type)}
                    </p>
                    {solicitation.updatedAt && (
                        <p className="font-size--xs solicitation-history-item-date">
                            {moment(solicitation.updatedAt).format(
                                'DD/MM/yyyy'
                            )}{' '}
                            {moment(solicitation.updatedAt).format('HH:mm')}
                        </p>
                    )}
                </div>
                <SolicitationHistoryStatus status={solicitation.status} />
            </div>
            <Line />
        </div>
    );
}

SolicitationHistoryItem.propTypes = {
    solicitation: PropTypes.object.isRequired,
};

function SolicitationsHistory({ aircraft }) {
    const [solicitations, setSolicitations] = useState([]);

    useEffect(() => {
        Service.listAllAircraftSolicitations({
            [OrderingParams.ordering]: `-${AircraftSolicitation.orderingParams.createdAt}`,
            registration: aircraft.registration,
        })
            .then((response) => {
                const fetchedSolicitations =
                    AircraftSolicitation.createListFromResponse(response);
                setSolicitations(fetchedSolicitations);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    }, [aircraft]);

    return (
        solicitations.length > 0 && (
            <div className="stack--lg">
                <p className="font-size--md fw-semibold">
                    {Locale.pages.aircraftDetails.solicitationsHistory.title}
                </p>
                <div className="stack--md">
                    {solicitations.map((item) => (
                        <SolicitationHistoryItem solicitation={item} />
                    ))}
                </div>
            </div>
        )
    );
}

SolicitationsHistory.propTypes = {
    aircraft: PropTypes.object.isRequired,
};

export default SolicitationsHistory;
