import PropTypes from 'prop-types';
import { Image } from 'components';
import {
    icoCheckGreen,
    icoAdd,
    icoAttachment,
    icoThrash,
    icoDownloadGray,
} from 'assets/icons/';
import './styles.scss';

function ThrashIcon({ disabled, clearFiles }) {
    return (
        !disabled && (
            <Image
                src={icoThrash}
                size={24}
                alt="ico-thrash"
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled) {
                        clearFiles();
                    }
                }}
            />
        )
    );
}

ThrashIcon.propTypes = {
    disabled: PropTypes.bool,
    clearFiles: PropTypes.func,
};

ThrashIcon.defaultProps = {
    disabled: false,
    clearFiles: undefined,
};

function FileInputCard({
    title,
    description,
    addIcon,
    file,
    id,
    accept,
    onChange,
    clearFiles,
    onBlur,
    required,
    disabled,
    successMessage,
    readOnly,
    disableClear,
    download,
}) {
    return (
        <label htmlFor={id}>
            <input
                type="file"
                id={id}
                name={id}
                accept={accept}
                onChange={!readOnly ? onChange : undefined}
                onBlur={!readOnly ? onBlur : undefined}
                required={required}
                disabled={disabled || readOnly}
                hidden
            />
            <div
                className={`file-card-container stack--sm ${
                    !readOnly && 'cursor--pointer'
                }`}
            >
                <div className="flex--row flex-justify--between flex-items--center">
                    <div className="flex--row flex-items--center">
                        <Image
                            src={file ? icoCheckGreen : icoAttachment}
                            size={24}
                            alt="ico-yes"
                            className="margin--r-md"
                        />
                        <div className="flex--column">
                            <p className="font-size--sm fw-semibold">{title}</p>
                            <p className="font-size--xs">{description}</p>
                        </div>
                    </div>
                    <div>
                        {download && (
                            <a
                                href={download}
                                download
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={icoDownloadGray} size={24} />
                            </a>
                        )}
                        {!readOnly &&
                            (file ? (
                                <ThrashIcon
                                    disabled={disableClear}
                                    clearFiles={clearFiles}
                                />
                            ) : (
                                <Image src={addIcon} size={24} alt="ico-add" />
                            ))}
                    </div>
                </div>
                {file && !readOnly && (
                    <>
                        <div>
                            <hr className="bar" />
                        </div>
                        <p className="font-size--xs">{successMessage}</p>
                    </>
                )}
            </div>
        </label>
    );
}

FileInputCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    addIcon: PropTypes.string,
    id: PropTypes.string.isRequired,
    accept: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    clearFiles: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    file: PropTypes.object,
    successMessage: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    disableClear: PropTypes.bool,
    download: PropTypes.string,
};

FileInputCard.defaultProps = {
    addIcon: icoAdd,
    accept: 'application/pdf',
    onBlur: undefined,
    required: false,
    disabled: false,
    file: undefined,
    readOnly: false,
    disableClear: false,
    download: '',
};

export default FileInputCard;
