import notify from 'components/Toast';
import Locale from 'locale';
import { StatusCodes } from 'http-status-codes';

const ErrorHelper = {
    getObjMessageAsArray(obj) {
        return Object.keys(obj).map((key) => {
            let message = obj[key];
            if (typeof message === 'object') {
                message =
                    message instanceof Array
                        ? message.join(', ')
                        : ErrorHelper.getObjMessageAsArray(message);
            }
            return `${key}: ${message}`;
        });
    },

    getErrorMessage(error) {
        const { response } = error;
        if (response?.status === 0) {
            return Locale.errors.connection;
        }
        if (response?.data) {
            if (typeof response.data === 'string' && response.data !== 'None') {
                return response.data;
            }

            if (typeof response.data === 'object') {
                if (typeof response.data.detail === 'string') {
                    return response.data.detail;
                }

                const dataToParse = response.data?.detail || response.data;
                const parsed =
                    dataToParse instanceof Array
                        ? dataToParse
                        : ErrorHelper.getObjMessageAsArray(dataToParse);
                return parsed.join('\n');
            }
        }
        return Locale.errors.default;
    },

    notifyError(error, timeClose) {
        const errorMessage = ErrorHelper.getErrorMessage(error);
        notify(errorMessage, null, null, timeClose);
    },

    notifyCustomerError(error) {
        if (error.response?.status === StatusCodes.NOT_FOUND) {
            notify(Locale.errors.customerNotFound);
        } else {
            ErrorHelper.notifyError(error);
        }
    },
};

export default ErrorHelper;
