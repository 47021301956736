import { Banner } from 'components';
import {
    OurServices,
    FutureServices,
    HelpSection,
    MyReservations,
    OutstandingDebts,
    WhatsappSection,
    // FAQ,
} from 'features/frontend';
import Locale from 'locale';

import imgDesktop from 'assets/images/img-home-banner-desktop.jpg';
import imgMobile from 'assets/images/img-home-banner-mobile.jpg';

import './styles.scss';
import { useState, useEffect } from 'react';
import Service from 'services';
import { BILLING_INTEGRATION_ENABLED } from 'utils/constants';

export default function LoggedIn() {
    const [isLoadingBillingData, setIsLoadingBillingData] = useState(true);
    const [showOutstandingDebts, setShowOutstandingDebts] = useState(false);
    const [outstandingDebtsValue, setOutstandingDebtsValue] = useState(0);
    const bannerProps = {
        imgDesktop,
        imgMobile,
        title: showOutstandingDebts
            ? Locale.pages.homeLoggedIn.outstandingDebts.banner.title
            : Locale.pages.homeLoggedIn.banner.title,
        description: showOutstandingDebts
            ? Locale.pages.homeLoggedIn.outstandingDebts.banner.subtitle
            : Locale.pages.homeLoggedIn.banner.subtitle,
        link: showOutstandingDebts ? '/debt-hub' : '/aircraft',
        rightSideComponent: showOutstandingDebts ? (
            <OutstandingDebts
                value={outstandingDebtsValue}
                className="outstanding-debts-desktop-only"
            />
        ) : null,
    };

    useEffect(() => {
        if (!BILLING_INTEGRATION_ENABLED) {
            setShowOutstandingDebts(false);
            setIsLoadingBillingData(false);
            return;
        }
        Service.postSaldoPendente().then((response) => {
            if (response.dados) {
                setOutstandingDebtsValue(response.dados?.vlrSaldo);
                setShowOutstandingDebts(response.dados?.vlrSaldo !== 0);
                setIsLoadingBillingData(false);
            }
        });
    }, []);

    return (
        <>
            <Banner
                {...bannerProps}
                loading={isLoadingBillingData}
                className="outstanding-debts-desktop-only"
            />
            <div className="logged-in-home-container">
                {showOutstandingDebts && (
                    <OutstandingDebts
                        value={outstandingDebtsValue}
                        className="outstanding-debts-mobile-only"
                    />
                )}
                <OurServices isLoggedIn />
                <MyReservations />
                <FutureServices />
                <HelpSection />
                <WhatsappSection />
                {/* <FAQ /> */}
            </div>
        </>
    );
}
