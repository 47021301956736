import { Button, FullPageModal } from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';

function LocalTimeModal({ show, onClose }) {
    return (
        <FullPageModal
            show={show}
            title={Locale.pages.reservation.localTime}
            onClose={onClose}
            endPageButton={
                <Button kind="outline" large onClick={onClose}>
                    {Locale.actions.back}
                </Button>
            }
        >
            <div className="local-time-description">
                {Locale.pages.reservation.localTimeDescription}
            </div>
        </FullPageModal>
    );
}

LocalTimeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default LocalTimeModal;
