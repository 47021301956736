import { Carousel } from 'components';
import {
    OurServices,
    FutureServices,
    HelpSection,
    WhatsappSection,
    // FAQ,
} from 'features/frontend';

import './styles.scss';

export default function NotLoggedIn() {
    return (
        <>
            <Carousel isLoggedIn={false} />
            <div className="not-logged-home-container">
                <OurServices isLoggedIn={false} />
                <FutureServices />
                <HelpSection />
                <WhatsappSection />
                {/* <FAQ /> */}
            </div>
        </>
    );
}
