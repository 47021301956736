import { useState } from 'react';
import PropTypes from 'prop-types';

import { Image, Title } from 'components';

import { icoExpandDown } from 'assets/icons';

import './styles.scss';

function Accordion({ title, children, isOpen, className }) {
    const [open, setOpen] = useState(isOpen);
    return (
        <div
            className={`accordion-container ${
                open ? 'accordion--open' : ''
            } ${className}`}
        >
            <header onClick={() => setOpen(!open)}>
                <Title tagName="h3" className="color--grayscale-blue-gray">
                    {title}
                </Title>
                <Image src={icoExpandDown} alt="ico-down" />
            </header>
            {open && (
                <div className="content">
                    <div className="inner">{children}</div>
                </div>
            )}
        </div>
    );
}

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
};

Accordion.defaultProps = {
    className: '',
    isOpen: false,
};

export default Accordion;
