import PropTypes from 'prop-types';
import { Input } from 'components';
import { MaskHelper } from 'helpers';

function PhoneNumberInput({ value, onChange, ...args }) {
    return (
        <Input
            type="phoneNumber"
            value={MaskHelper.phone(value)}
            onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 11);
                onChange(e);
            }}
            {...args}
        />
    );
}

PhoneNumberInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
    value: '',
    onChange: undefined,
};

export default PhoneNumberInput;
