import { useState } from 'react';
import { useFormik } from 'formik';
import Locale from 'locale';
import {
    Button,
    HeaderPage,
    Switch,
    Input,
    PhoneNumberInput,
    SelectedAircraftCard,
} from 'components';
import { AddressForm } from 'features/frontend';
import { useNavigate, useLocation } from 'react-router-dom';
import { MaskHelper, ErrorHelper } from 'helpers';
import { getDocumentLabel } from 'utils/functions';
import { Aircraft, AircraftSolicitation, Operator } from 'models';
import { AircraftSolicitationType } from 'utils/enums';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';
import PropTypes from 'prop-types';
import './styles.scss';
import Service from 'services';

function NIFBottomSheetContent({ setShowVisible }) {
    return (
        <div className="stack--md">
            <p>{Locale.pages.register.aircraft.NIFDetails.description}</p>
            <Button
                kind="primary"
                type="button"
                onClick={() => {
                    setShowVisible(false);
                }}
            >
                {Locale.general.understood}
            </Button>
        </div>
    );
}

NIFBottomSheetContent.propTypes = {
    setShowVisible: PropTypes.func.isRequired,
};

function OperatorData() {
    const navigate = useNavigate();
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showNIFBottomSheet, setShowNIFBottomSheet] = useState(false);

    const { aircraft } = location.state;
    const initialValues = new Aircraft(aircraft);

    const createAircraft = async (aircraftData) => {
        setIsSubmitting(true);
        let createdAircraft;
        try {
            createdAircraft = await Service.postAircraft(aircraftData);
        } catch (error) {
            setIsSubmitting(false);
            ErrorHelper.notifyError(error);
            return;
        }

        if (aircraftData.document) {
            try {
                await Service.createAircraftDocument(createdAircraft.id, {
                    filepath: aircraftData.document.filepath,
                    name: aircraftData.document.name,
                });
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }
        if (aircraftData.image) {
            try {
                await Service.createAircraftImage(createdAircraft.id, {
                    filepath: aircraftData.image.filepath,
                    name: aircraftData.image.name,
                });
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        setIsSubmitting(false);
        navigate('/newaircraft/success', {
            state: {
                aircraft: createdAircraft,
            },
        });
    };

    const createAircraftSolicitation = async (aircraftData) => {
        setIsSubmitting(true);
        let solicitation;
        try {
            const solicitationData = new AircraftSolicitation({
                type: AircraftSolicitationType.creation,
                aircraft: aircraftData,
            });
            solicitation = await Service.postAircraftSolicitation(
                solicitationData
            );
        } catch (error) {
            setIsSubmitting(false);
            ErrorHelper.notifyError(error);
            return;
        }

        if (aircraftData.document) {
            try {
                await Service.createAircraftSolicitationDocument(
                    solicitation.id,
                    {
                        filepath: aircraftData.document.filepath,
                        name: aircraftData.document.name,
                    }
                );
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }
        if (aircraftData.image) {
            try {
                await Service.createAircraftSolicitationImage(solicitation.id, {
                    filepath: aircraftData.image.filepath,
                    name: aircraftData.image.name,
                });
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        setIsSubmitting(false);
        navigate('/newaircraft/success', {
            state: {
                aircraft: solicitation.aircraft,
            },
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Operator.aircraftOperatorValidationSchema,
        onSubmit: (data) => {
            const aircraftData = new Aircraft(data);
            if (AIRCRAFT_SOLICITATION_PROCESS_ENABLED) {
                createAircraftSolicitation(aircraftData);
            } else {
                createAircraft(aircraftData);
            }
        },
    });

    const setDocument = (e) => {
        if (formik.values.operator?.isForeigner) {
            if (formik.values.operator?.isCompany) {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 15)
                );
            } else {
                formik.setFieldValue(
                    'operator.document',
                    e.target.value.replace(/\D/g, '').slice(0, 9)
                );
            }
        } else if (formik.values.operator?.isCompany) {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 14)
            );
        } else {
            formik.setFieldValue(
                'operator.document',
                e.target.value.replace(/\D/g, '').slice(0, 11)
            );
        }
    };

    const getNameLabel = () => {
        if (formik.values.operator?.isCompany) {
            return formik.values.operator?.isForeigner
                ? Locale.fields.companyName
                : Locale.fields.corporateName;
        }
        return Locale.fields.fullName;
    };

    return (
        <div className="padding--lg height--full stack--lg operator-data-wrapper">
            <form
                onSubmit={formik.handleSubmit}
                className="height--full stack--lg"
            >
                <HeaderPage
                    title={Locale.pages.register.aircraft.billingDataTitle}
                    description={
                        Locale.pages.register.aircraft.billingDataDescription
                    }
                    labelBtnBack={Locale.actions.back}
                    onClickBtnBack={() =>
                        navigate('/newaircraft/operatortype', {
                            state: {
                                ...location.state,
                                aircraft: formik.values,
                            },
                        })
                    }
                />
                <SelectedAircraftCard aircraft={aircraft} />
                <div>
                    <div className="margin--b-lg">
                        <Switch
                            label={
                                Locale.fields.operator.useForeignDocumentation
                            }
                            id="isForeigner"
                            name="isForeigner"
                            onChange={(e) => {
                                formik.setFieldValue('operator.isForeigner', e);
                            }}
                            checked={!!formik.values.operator?.isForeigner}
                            error={
                                formik.touched.aircraft?.operator
                                    ?.isForeigner &&
                                formik.errors.aircraft?.operator?.isForeigner
                            }
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="stack--sm">
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    type="text"
                                    label={getNameLabel()}
                                    id="name"
                                    name="name"
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'operator.name',
                                            e.target.value
                                        )
                                    }
                                    value={formik.values.operator?.name}
                                    error={
                                        (formik.touched.name ||
                                            formik.touched.operator?.name) &&
                                        formik.errors.operator?.name
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md 6">
                                <Input
                                    type="text"
                                    label={getDocumentLabel(
                                        formik.values.operator?.isForeigner,
                                        formik.values.operator?.isCompany
                                    )}
                                    name="document"
                                    id="document"
                                    required
                                    onChange={(e) => {
                                        setDocument(e);
                                    }}
                                    value={MaskHelper.document(
                                        formik.values.operator?.document,
                                        formik.values.operator?.isForeigner,
                                        formik.values.operator?.isCompany
                                    )}
                                    error={
                                        (formik.touched.document ||
                                            formik.touched.operator
                                                ?.document) &&
                                        formik.errors.operator?.document
                                    }
                                    onBlur={formik.handleBlur}
                                    bottomSheetContent={
                                        formik.values.operator?.isForeigner &&
                                        formik.values.operator?.isCompany
                                            ? {
                                                  title: Locale.pages.register
                                                      .aircraft.NIFDetails
                                                      .title,
                                                  content: (
                                                      <NIFBottomSheetContent
                                                          setShowVisible={
                                                              setShowNIFBottomSheet
                                                          }
                                                      />
                                                  ),
                                                  visible: showNIFBottomSheet,
                                                  setVisible:
                                                      setShowNIFBottomSheet,
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="col-md 6">
                                <PhoneNumberInput
                                    label={Locale.fields.phone}
                                    name="operator.phoneNumber"
                                    id="operator.phoneNumber"
                                    value={formik.values.operator?.phoneNumber}
                                    onChange={formik.handleChange}
                                    error={
                                        (formik.touched.phoneNumber ||
                                            formik.touched.operator
                                                ?.phoneNumber) &&
                                        formik.errors.operator?.phoneNumber
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                    countryPhoneCode={
                                        formik.values.operator?.countryPhoneCode
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <Input
                                    type="email"
                                    label={Locale.fields.email}
                                    name="email"
                                    id="email"
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'operator.email',
                                            e.target.value
                                        )
                                    }
                                    value={formik.values.operator?.email}
                                    error={
                                        (formik.touched.email ||
                                            formik.touched.operator?.email) &&
                                        formik.errors.operator?.email
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <Input
                                    type="email"
                                    label={Locale.fields.email}
                                    name="secondEmail"
                                    id="secondEmail"
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'operator.secondEmail',
                                            e.target.value
                                        )
                                    }
                                    value={formik.values.operator?.secondEmail}
                                    error={
                                        (formik.touched.secondEmail ||
                                            formik.touched.operator
                                                ?.secondEmail) &&
                                        formik.errors.operator?.secondEmail
                                    }
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <Input
                                    type="email"
                                    label={Locale.fields.email}
                                    name="thirdEmail"
                                    id="thirdEmail"
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'operator.thirdEmail',
                                            e.target.value
                                        )
                                    }
                                    value={formik.values.operator?.thirdEmail}
                                    error={
                                        (formik.touched.thirdEmail ||
                                            formik.touched.operator
                                                ?.thirdEmail) &&
                                        formik.errors.operator?.thirdEmail
                                    }
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <AddressForm
                            title={Locale.fields.address}
                            formik={formik}
                        />
                    </div>
                </div>

                <div className="width-full padding--b-md">
                    <Button
                        isLoading={isSubmitting}
                        type="submit"
                        className="width--full"
                    >
                        {Locale.actions.saveAndContinue}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default OperatorData;
