import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Header, HeaderBO } from 'components';
import { Sidemenu } from 'features/frontend';
import Service from 'services';

import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidemenuBO } from 'features/backoffice';

function Main({
    children,
    verifyLogin,
    isAuthenticated,
    setLoggedOut,
    handleSwitchLanguage,
}) {
    const [showSidemenu, setShowSidemenu] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => verifyLogin(navigate), [location]);

    const toggle = () => {
        setShowSidemenu(!showSidemenu);
    };

    const logout = (_callback) => {
        Service.logout().then(() => {
            setShowSidemenu(false);
            setLoggedOut();

            if (typeof _callback === 'function') {
                _callback();
            }
        });
    };

    const frontend = (
        <div className="wrapper-main">
            <Header
                isUserAuthenticated={isAuthenticated}
                toggle={() => toggle()}
                logout={logout}
            />
            <Sidemenu
                show={showSidemenu}
                toggle={() => toggle()}
                isUserAuthenticated={isAuthenticated}
                logout={logout}
                handleSwitchLanguage={handleSwitchLanguage}
            />
            <div className="container-main">{children}</div>
        </div>
    );

    const backoffice = (
        <div className="wrapper-main">
            <HeaderBO isAuthenticated={isAuthenticated} logout={logout} />
            <div className="bo-container">
                <SidemenuBO />
                <div className="container-main-bo">{children}</div>
            </div>
        </div>
    );

    return location.pathname.includes('backoffice') ? backoffice : frontend;
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    verifyLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    setLoggedOut: PropTypes.func.isRequired,
    handleSwitchLanguage: PropTypes.func.isRequired,
};

export default Main;
