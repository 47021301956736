import React from 'react';
import { Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FlightInfo } from 'pages/frontend/Reservation';
import {
    BILLING_INTEGRATION_ENABLED,
    AIRCRAFT_SOLICITATION_PROCESS_ENABLED,
} from 'utils/constants';
import AuthGuard from './AuthGuard';

import {
    Home,
    Login,
    ResetPassword,
    AccountSettings,
    UserRegister,
    RegisterAircraft,
    DetailReservation,
    GeneralInfo,
    ReservationAircraft,
    Summary,
    AdditionalInfo,
    Checkout,
    RequestChanges,
    Success,
    ActivateAccount,
    Help,
    DebtHub,
    InvoiceDetails,
    InvoicePayment,
    NewAircraft,
    OperatorType,
    AircraftDocuments,
    CreatedAircraft,
    ReservationsList,
    AircraftList,
    Pilots,
    OperatorData,
    AircraftDetails,
    EditAircraft,
    EditOperator,
} from '../pages/frontend';

import {
    Home as BackHome,
    ReservationDetails,
    Airports,
    AirportDetail,
    Users,
    UserDetail,
    AircraftSolicitations,
    AircraftSolicitationDetails,
} from '../pages/backoffice';

export const resetPasswordPath = 'reset-password';

function Routers({ isUserAuthenticated, setIsAuthenticated }) {
    return (
        <Routes>
            {/* FRONTEND */}
            {AuthGuard({
                path: '/',
                element: <Home isAuthenticated={isUserAuthenticated} />,
            })}
            {AuthGuard({
                path: '/login',
                element: <Login setIsAuthenticated={setIsAuthenticated} />,
            })}
            {AuthGuard({
                path: `/${resetPasswordPath}/:uidb64/:token`,
                element: <ResetPassword />,
            })}
            {AuthGuard({
                path: `/activate-account/:uidb64/:token`,
                element: <ActivateAccount />,
            })}
            {AuthGuard({
                path: '/register/user',
                element: <UserRegister />,
            })}
            {AuthGuard({
                path: '/register/aircraft',
                element: <RegisterAircraft />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/accountsettings',
                element: <AccountSettings />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newaircraft/generalinfo',
                element: <NewAircraft />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newaircraft/documents',
                element: <AircraftDocuments />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newaircraft/operatortype',
                element: <OperatorType />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newaircraft/operatordata',
                element: <OperatorData />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newaircraft/success',
                element: <CreatedAircraft />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/aircraft/:aircraftRegistration',
                element: <AircraftDetails />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/aircraft/:aircraftRegistration/edit/operator',
                element: <EditOperator />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/aircraft/:aircraftRegistration/edit/information',
                element: <EditAircraft />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/reservation/:reservationId',
                element: <DetailReservation />,
            })}
            {AuthGuard({
                path: '/newreservation/generalinfo',
                element: <GeneralInfo />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newreservation/aircraft',
                element: <ReservationAircraft />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newreservation/flightinfo',
                element: <FlightInfo />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newreservation/additionalinfo',
                element: <AdditionalInfo />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newreservation/summary',
                element: <Summary />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/newreservation/success',
                element: <Success />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: 'reservation/:reservationId/checkout',
                element: <Checkout />,
            })}
            {AuthGuard({
                path: '/reservation/:reservationId/requestchanges',
                element: <RequestChanges />,
            })}
            {AuthGuard({
                path: '/reservation/:reservationId/additionalinfo',
                element: <AdditionalInfo />,
            })}
            {AuthGuard({
                path: '/help',
                element: <Help />,
            })}
            {AuthGuard({
                path: '/reservations',
                element: <ReservationsList />,
            })}
            {AuthGuard({
                path: '/aircraft',
                element: <AircraftList />,
            })}
            {AuthGuard({
                path: '/pilots',
                element: <Pilots />,
            })}
            {BILLING_INTEGRATION_ENABLED &&
                AuthGuard({
                    path: '/debt-hub',
                    element: <DebtHub />,
                    isUserAuthenticated,
                })}
            {BILLING_INTEGRATION_ENABLED &&
                AuthGuard({
                    path: '/invoice/:invoiceId',
                    element: <InvoiceDetails />,
                    isUserAuthenticated,
                })}
            {BILLING_INTEGRATION_ENABLED &&
                AuthGuard({
                    path: '/invoice/pix-payment/:invoiceId',
                    element: <InvoicePayment />,
                    isUserAuthenticated,
                })}

            {/* BACKOFFICE */}
            {AuthGuard({
                path: '/backoffice',
                element: <BackHome />,
                navigateTo: '/backoffice/login',
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/login',
                element: <Login setIsAuthenticated={setIsAuthenticated} />,
            })}
            {AuthGuard({
                path: '/backoffice/reservation/:reservationId',
                element: <ReservationDetails />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/airports',
                element: <Airports />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/airports/:airportId',
                element: <AirportDetail />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/airports/new',
                element: <AirportDetail />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/users',
                element: <Users />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/users/:userId',
                element: <UserDetail />,
                isUserAuthenticated,
            })}
            {AuthGuard({
                path: '/backoffice/users/new',
                element: <UserDetail />,
                isUserAuthenticated,
            })}
            {AIRCRAFT_SOLICITATION_PROCESS_ENABLED &&
                AuthGuard({
                    path: '/backoffice/solicitations',
                    element: <AircraftSolicitations />,
                    isUserAuthenticated,
                })}
            {AIRCRAFT_SOLICITATION_PROCESS_ENABLED &&
                AuthGuard({
                    path: '/backoffice/solicitations/:solicitationId',
                    element: <AircraftSolicitationDetails />,
                    isUserAuthenticated,
                })}
        </Routes>
    );
}

Routers.propTypes = {
    isUserAuthenticated: PropTypes.bool.isRequired,
    setIsAuthenticated: PropTypes.func.isRequired,
};

export default Routers;
