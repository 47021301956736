const MaskHelper = {
    document(document, isForeigner, isCompany) {
        if (!document) {
            return document;
        }
        let value = document;

        if (!isForeigner) {
            if (isCompany) {
                // CPNJ
                value = value.replace(/^(\d{2})(\d)/, '$1.$2');
                value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
                value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
                value = value.replace(/(\d{4})(\d)/, '$1-$2');
            } else {
                // CPF
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            }
        }
        return value;
    },

    documentWithLength(document) {
        const isForeigner = document.length !== 11 && document.length !== 14;
        const isCompany =
            (isForeigner && document.length === 9) ||
            (!isForeigner && document.length === 14);

        return this.document(document, isForeigner, isCompany);
    },

    cep(cep) {
        if (!cep) {
            return cep;
        }
        return cep.replace(/(\d{5})(\d)/, '$1-$2');
    },

    upperAlphanumeric(value) {
        if (!value) {
            return value;
        }
        return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    },

    numeric(value, decimalDigits = 2) {
        if (!value) {
            return undefined;
        }

        let regEx = /[^0-9.,]/;
        const decimalRegEx = new RegExp(
            `(\\d+[.,]?\\d{0,${decimalDigits}})\\d*`
        );

        if (decimalDigits === 0) regEx = /[^0-9]/g;

        return Number(
            value.toString().replace(regEx, '').replace(decimalRegEx, '$1')
        );
    },

    phone(value, country = '55') {
        if (!value) {
            return undefined;
        }

        const COUNTRY_BRAZIL = '55';
        const numbers = value.toString().replace(/\D/g, '');
        if (country.toString() === COUNTRY_BRAZIL) {
            const matches = numbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
            const ddd = matches[1];
            const firstHalf = matches[2];
            const secondHalf = matches[3];

            if (numbers.length > 7) {
                return `(${ddd}) ${firstHalf}-${secondHalf}`;
            }

            if (numbers.length > 2) {
                return `(${ddd}) ${firstHalf}`;
            }

            return `(${ddd}`;
        }
        return numbers;
    },
};

export default MaskHelper;
