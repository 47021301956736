export const ptBR = {
    general: {
        CCRAirports: 'CCR Aeroportos',
        aircraft: 'Aeronave',
        all: 'Todos',
        backoffice: 'Backoffice',
        and: 'e',
        yes: 'Sim',
        no: 'Não',
        at: 'às',
        to: 'às',
        hour: 'Hora',
        result: 'Resultado',
        in: 'em',
        note: 'Observação',
        new: {
            f: 'Nova',
            m: 'Novo',
        },
        airports: 'Aeroportos',
        deleted: 'Deletado',
        noResults: 'Sem resultados',
        noICAOCode: 'S/ ICAO',
        weekdays: {
            monday: 'Segunda-feira',
            tuesday: 'Terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira',
            friday: 'Sexta-feira',
            saturday: 'Sábado',
            sunday: 'Domingo',
        },
        understood: 'Entendi',
    },
    errors: {
        default: 'Algo deu errado. Tente novamente ou contate o suporte.',
        validEmail: 'Insira um e-mail válido',
        emailsMatch: 'Os emails devem corresponder',
        required: 'Campo obrigatório',
        minLength: (number) =>
            `Este campo não pode ter menos ${number} digitos`,
        maxLength: (number) =>
            `Este campo não pode ter mais de ${number} digitos`,
        connection: 'Erro de conexão',
        passwordsMatch: 'As senhas devem ser iguais',
        date: {
            format: 'Insira a data no formato dd/mm/aaaa',
        },
        customerNotFound: 'Usuário não encontrado!',
        landingRequestInfoError:
            'Não foi possível conseguir os dados da sua solicitação de pouso',
        billingInfoError:
            'Não foi possível conseguir os dados da sua tarifação',
        replicate:
            'Houve um problema ao replicar sua reserva. Tente novamente ou contate o suporte.',
        saveAirportDocuments:
            'O aeroporto foi criado com sucesso, mas algo deu errado ao criar os arquivos. Por favor edite o aeroporto para inserir os documentos novamente.',
        cep: 'Falha ao buscar CEP. Insira o endereço manualmente',
        qrCodeError:
            'Houve um problema ao obter seu QR Code. Tente novamente ou contate o suporte',
    },
    fields: {
        approvedBy: 'Reserva aprovada por',
        select: 'Selecione',
        landingPeriod: 'Período de pouso',
        filters: 'Filtros',
        name: 'Nome',
        fullName: 'Nome completo',
        corporateName: 'Razão Social',
        companyName: 'Nome da empresa',
        phone: 'Telefone de contato',
        operatorShort: 'Operador',
        document: 'CPF/CNPJ',
        email: 'E-mail',
        cpf: 'CPF',
        cnpj: 'CNPJ',
        country: 'País',
        countryRegion: 'País / Região',
        state: 'UF',
        stateTerritory: 'Estado / Território',
        cepZipcode: 'CEP / Zipcode',
        city: 'Cidade',
        passport: 'Passaporte',
        tin: 'NIF',
        isForeigner: 'Estrangeiro',
        isCompany: 'É uma empresa',
        operatorIsForeigner: 'Operador estrangeiro',
        operatorIsCompany: 'Operador é uma empresa',
        numberComplement: 'Nº/Complemento',
        typeOfFlight: 'Tipo de voo',
        nationalizationFlight: 'Voo de nacionalização?',
        handling: 'Handling',
        approved: 'Aprovada',
        returned: 'Devolvida',
        payday: 'Data de pagamento',
        dateUnavailable: 'Data indisponível',
        timeUnavailable: 'Horário indisponível',
        alternateDate: 'Data Alternativa',
        alternateTime: 'Horário Alternativo',
        comment: 'Comentário',
        commander: 'Comandante',
        billing: 'Faturamento',
        addressPlaceholder: 'Preencha o CEP',
        cep: 'CEP',
        postalCode: 'Código Postal',
        zipCode: 'CEP',
        address: 'Endereço',
        streetAddress: 'Endereço',
        number: 'Número',
        complement: 'Complemento',
        district: 'Bairro',
        boarding: 'Embarque',
        deboarding: 'Desembarque',
        reservationData: 'Dados de reserva',
        showDeletedAirports: 'Exibir aeroportos deletados',
        showDeletedAircraft: 'Exibir aeronaves deletadas',
        destination: 'Destino (Aeroporto CCR)',
        CCRAirport: 'Aeroporto de Operação CCR',
        unknown: 'Desconhecido',
        profile: 'Perfil',
        naturalPerson: 'Pessoa física',
        legalPerson: 'Pessoa jurídica',
        billingOperator: 'Operador para faturamento',
        userLabel: 'Usuário',
        minValue:
            'Esse campo deve ser preenchido com um valor maior ou igual a',
        maxValue:
            'Esse campo deve ser preenchido com um valor menor ou igual a',
        paymentMethod: {
            title: 'Forma de pagamento',
            pix: 'PIX',
        },
        file: {
            airworthnessCertification: 'Certificado de ruído',
            attachCertification: 'Anexar certificado',
            attachImage: 'Anexar imagem',
            documentAttached: 'Documento anexado',
            imageAttached: 'Imagem anexada',
            aircraftImage: 'Imagem da aeronave',
            successfullyDocumentAttach: 'Documento anexado com sucesso',
            noAttachedFile: 'Não há arquivos anexados',
            sentIn: 'Enviado em {1} - {2}',
            download: 'BAIXAR',
        },
        user: {
            agree: 'Estou de acordo',
            confirmEmail: 'Confirme o e-mail',
            password: 'Senha',
            confirmPassword: 'Confirme a senha',
            currentPassword: 'Senha atual',
            newPassword: 'Nova senha',
            confirmNewPassword: 'Confirme a nova senha',
            confirmGuideline: 'Estou ciente das orientações',
            personalData: 'Dados pessoais',
            accessProfile: 'Perfil de acesso',
            linkedAirports: 'Aeroportos Vinculados',
            isActive: 'Ativo?',
        },
        reservation: {
            title: 'Reserva',
            status: 'Status da reserva',
            airport: 'Aeroporto',
            origin: 'Origem',
            originDropdownTooltip:
                'Aeroporto da CCR em que está sendo solicitado pouso ou reserva de pátio',
            destination: 'Próximo Destino',
            destinationDropdownTooltip:
                'Aeroporto de destino após a decolagem do aeroporto de pouso desta reserva',
            nextDestination: 'Próximo Destino',
            noICAO: 'Aeroporto sem ICAO',
            homeAerodrome: 'Aeródromo de origem',
            destinyAerodrome: 'Aeródromo de destino',
            datetime: 'Data e horário',
            useBillingDataFrom: 'Usar dados de faturamento de',
            others: 'Outros',
            requester: 'Solicitante',
            operator: 'Operador',
            peopleOnBoard: 'Passageiros a bordo',
            patioReservation: 'Reserva de pátio?',
            hasPatioReservation: {
                yes: 'Sim, vou pousar e permanecer no pátio.',
                no: 'Não',
            },
            takeOffForecast: 'Previsão de decolagem?',
            landing: 'Pouso',
            takeOff: 'Decolagem',
            newReservation: 'Faça sua reserva',
            startReservation: 'Iniciar reserva',
            landingDate: 'Data de pouso',
            takeOffDate: 'Data de decolagem',
            takeOffDatetime: 'Data e horário de decolagem',
            landingDatetime: 'Data e horário de pouso',
            landingHour: 'Hora de pouso',
            takeOffHour: 'Hora da decolagem',
            alternative: 'Reserva alternativa?',
            trailerFork: 'Garfo para reboque?',
            flightInfo: 'Informações de Voo',
            flightType: 'Tipo de voo',
            legalRepresentative: {
                title: 'Representante legal',
                name: 'Nome do representante legal',
                phone: 'Telefone do representante legal',
            },
            transportationSupport: 'Necessidade de Transporte de Apoio?',
            handlingCompany: 'Empresa de handling',
            responsable: 'Responsável pela reserva',
            nature: 'Natureza',
            domestic: 'Doméstico',
            international: 'Internacional',
            city: 'Cidade',
            state: 'UF',
            country: 'País',
        },
        aircraft: {
            active: 'Ativo',
            title: 'Aeronave',
            foreign: 'Aeronave estrangeira',
            national: 'Aeronave nacional',
            foreignShort: 'Estrangeira',
            nationalShort: 'Nacional',
            militaryShort: 'Militar',
            aircraftRegistration: 'Matrícula da aeronave',
            icao: 'Modelo do equipamento (ICAO)',
            mtow: 'Peso máximo de decolagem',
            mtowShort: 'PMD',
            wingspan: 'Envergadura',
            planeLength: 'Comprimento',
            registration: 'Matrícula',
            model: 'Modelo da aeronave',
            agreedToDataSharing:
                'Eu concordo em compartilhar meus dados de faturamento com os demais operadores que compartilharem essa mesma aeronave.',
            saveAndContinue: 'Salvar e continuar',
            isMilitary: 'Essa é uma aeronave militar.',
            selectedAircraft: 'Aeronave selecionada',
            editAircraft: 'Editar aeronave',
            editOperator: 'Editar operador',
            chooseAnotherAircraft: 'Escolher outra aeronave',
            incompleteRegistration: {
                title: 'Os dados do operador da aeronave estão incompletos',
                description:
                    'Clique no botão para editar o operador e continuar com a reserva.',
            },
            companyInvoice: {
                title: 'Faturar para empresa',
                description:
                    'Informe nome da empresa, documento, telefone e um endereço atualizado.',
            },
            individualInvoice: {
                title: 'Faturar para pessoa física',
                description:
                    'Informe documento, telefone, e-mail e um endereço atualizado.',
            },
            document: {
                success: 'Seu documento foi anexado com sucesso.',
                attach: 'Anexar documento',
                attached: 'Documento anexado',
                types: 'PDF, JPG ou PNG',
            },
            image: {
                success: 'Sua imagem foi anexada com sucesso.',
                attach: 'Anexar imagem',
                attached: 'Imagem anexada',
                types: 'JPG ou PNG',
            },
            solicitation: {
                registration: 'Matrícula',
                icaoModel: 'Modelo',
                nature: 'Natureza',
                type: 'Tipo',
                date: 'Data',
                status: 'Status',
                foreign: 'Estrangeira',
                national: 'Nacional',
                statusEnum: {
                    incompleteRegistration: 'Cadastro incompleto',
                    pendingValidation: 'Aguardando validação',
                    approvedValidation: 'Validado',
                    disapprovedValidation: 'Reprovado',
                    cancelled: 'Cancelado',
                },
                typeEnum: {
                    creation: 'Novo cadastro',
                    edit: 'Edição',
                },
            },
        },
        operator: {
            title: 'Operador',
            useData: 'Utilizar dados do cadastro',
            name: 'Nome Prop. / Razão Social',
            useForeignDocumentation: 'Usar documentação estrangeira?',
        },
        pilot: {
            name: 'Nome do piloto',
        },
        payment: {
            title: 'Pagamento',
            description: {
                pix: 'Pagamento por PIX',
                creditCard: 'Pagamento por cartão de crédito',
                accountHolder:
                    'Pagamento simplificado para correntista por conta da aeronave nacional',
            },
            accountHolderDisclaimer:
                'O valor deve ser consultado no serviço de tarifação',
        },
        requester: 'Solicitante',
        checkout: {
            cardNumber: 'Número do Cartão',
            cardholderName: 'Nome do Cartão',
            cardExpiringDate: 'Validade',
            cvc: 'CVC',
        },
        justification: 'Justificativa',
        airport: {
            search: 'Digite o nome do aeroporto',
            managedShortName: 'Gerenciado?',
            managedFullName: 'Gerenciado pela CCR?',
            timezone: 'Fuso Horário',
            documents: 'Documentos',
            documentsSubtitle:
                'Documentos de orientações disponibilizados pelo aeroporto',
        },
        countryCode: 'DDI',
        notInformed: 'Não informado',
    },
    pages: {
        forgotPassword: {
            subtitle:
                'Confirme seu email e enviaremos as instruções para mudar sua senha.',
            email: 'E-mail cadastrado',
            success:
                'Nós enviamos um e-mail com instruções para você mudar sua senha.',
        },
        accountSettings: {
            tabs: {
                personal: 'Pessoal',
                aircrafts: 'Aeronaves',
                pilots: 'Pilotos',
                reservations: 'Reservas',
            },
            personal: {
                data: 'Dados pessoais',
                cardList: 'Lista de cartões',
                changePassword: {
                    success: 'Sua senha foi atualizada.',
                },
                changeData: {
                    success: 'Seus dados foram atualizados',
                },
            },
            pilots: {
                new: 'Novo piloto',
                edit: 'Editar piloto',
                delete: 'Excluir piloto',
                createSuccess: 'O piloto foi adicionado com sucesso.',
                editSuccess: 'O piloto foi editado com sucesso.',
                deleteSuccess: 'O piloto foi excluído com sucesso.',
                textDeleteConfirm:
                    'Você tem certeza que quer deletar este piloto? É possível que ela esteja sendo usada em algumas das suas reservas.',
            },
            aircraft: {
                new: 'Nova aeronave',
                edit: 'Editar aeronave',
                createSuccess: 'A aeronave foi adicionada com sucesso.',
                editSuccess: 'A aeronave foi editada com sucesso.',
                deleteSuccess: 'A aeronave foi excluída com sucesso.',
                registration: 'Matrícula',
                textDeleteConfirm:
                    'Você tem certeza que quer deletar esta aeronave? É possível que ela esteja sendo usada em algumas das suas reservas.',
            },
        },
        aircraftDetails: {
            information: 'Informações',
            registration: 'Matrícula',
            model: 'Modelo',
            mtow: 'PMD',
            wingspan: 'Envergadura',
            planeLength: 'Comprimento',
            operator: 'Operador',
            document: 'Documento',
            zipCode: 'CEP',
            phoneNumber: 'Telefone',
            email: 'E-mail',
            editAircraft: 'Editar aeronave',
            completeRegistration: 'Finalizar cadastro',
            editOperator: 'Editar operador',
            operatorInfoCardMessage:
                'As faturas das operações serão enviadas para este contato.',
            nature: 'Natureza',
            statusInformationCard: {
                incompleteRegistration: {
                    title: 'Cadastro incompleto',
                    message:
                        'Finalize o cadastro para liberar essa aeronave para realizar reservas.',
                },
                pendingValidation: {
                    title: 'Cadastro em análise',
                    message:
                        'Durante a validação não será possível realizar reservas para esta aeronave.',
                },
                disapprovedValidation: {
                    title: 'Cadastro reprovado',
                    message:
                        'A solicitação de cadastro para esta aeronave foi reprovada.',
                },
            },
            solicitationsHistory: {
                title: 'Histórico de solicitações',
                type: {
                    creation: 'Registrar nova aeronave',
                    edit: 'Edição de aeronave',
                },
                status: {
                    incompleteRegistration: 'Cadastro incompleto',
                    pendingValidation: 'Enviado',
                    approvedValidation: 'Aprovado',
                    disapprovedValidation: 'Reprovado',
                    cancelled: 'Cancelado',
                },
            },
        },
        aircraftEdit: {
            information: {
                title: 'Editar aeronave',
                saveChanges: 'Salvar alterações',
                removeAircraft: 'Remover aeronave',
                confirmDeleteMessage:
                    'Tem certeza que deseja remover esta aeronave?',
                confirmEditMessage:
                    'As alterações serão analisadas por nossa equipe e comparadas ao registro atual no RAB (Registro Aeronáutico Brasileiro), após aprovação, as informações serão replicadas para todos os cadastros desta aeronave.',
                wishToContinue: 'Deseja continuar?',
                aircraftRemoved: 'Aeronave removida',
            },
            operator: {
                title: 'Editar operador',
                saveChanges: 'Salvar alterações',
                description:
                    'Informações utilizadas para envio das faturas para a aeronave selecionada.',
            },
        },
        register: {
            user: {
                title: 'Dados pessoais',
                privacyTerms:
                    'Aceito compartilhar minhas informações com a CCR e estou de acordo com a Política de Privacidade, de acordo Lei Geral de Proteção de Dados (Lei nº 13.709/2018).',
                commonPassword: 'Evite senhas muito comuns', // TODO adicionar 'ou parecidas com seu email' nessa string MAG-495
                lengthPassword: 'A senha deve ter pelo menos de 8 caracteres',
                numericPassword: 'A senha não pode ter apenas números',
            },
            aircraft: {
                aircraftData: 'Dados da aeronave',
                operatorData: 'Dados do Operador para Faturamento',
                operatorDataSubtitle:
                    'Para ACFT brasileiras, informar operador ativo no RAB',
                description:
                    'Agora inclua os dados das aeronaves que você quer vincular ao seu cadastro',
                saveAirplane: 'Salvar aeronave na minha lista',
                registerAircraft: 'Cadastrar aeronave',
                registerAircraftDescription:
                    'Informe os dados da aeronave para registro, você pode editá-los mais tarde.',
                mtowDescription:
                    'O PMD é o peso máximo de decolagem que uma aeronave pode atingir em sua lotação máxima. Essa informação deve ser preenchida corretamente, para evitar que o cálculo das tarifas de pouso e permanência seja incorreto.',
                howToInvoiceTitle:
                    'Como deseja faturar as operações desta aeronave?',
                howToInvoiceDescription:
                    'Você pode alterar esses dados mais tarde.',
                validationDocument: 'Documento de validação',
                validationDocumentDescription:
                    'Por segurança, solicitamos que você envie um documento para validação da aeronave.',
                airworthnessCertificate: 'Certificado de ruído',
                airworthnessCertificateDescription:
                    'Para o controle da emissão sonora das aeronaves, a ANAC (Agência Nacional de Aviação Civil) estabeleceu como certificação o Regulamento Brasileiro de Aviação Civil – RBAC 36, que adota integralmente o requisito estadunidense US Title 14 CFR Part 36: Noise Standards: Aircraft Type and Airworthiness Certification. Além de estabelecer os limites máximos aceitáveis para as diversas categorias, o certificado também garante a validação do cadastro de sua aeronave para operaçòes nos aeroportos da CCR.',
                aircraftImage: 'Imagem da aeronave',
                foreignAircraftAlert:
                    'Documento necessário para aeronaves estrangeiras, sem o anexo suas reservas poderão ser bloqueadas.',
                billingDataTitle: 'Dados de faturamento',
                billingDataDescription:
                    'Informe os dados do operador que irá receber as faturas da aeronave selecionada.',
                NIFDetails: {
                    title: 'Número de Identificação Fiscal',
                    description:
                        'O NIF (Número de Identificação Fiscal) é um número usado para identificar indivíduos e entidades. É semelhante ao CNPJ no Brasil ou ao SSN nos Estados Unidos. O NIF é utilizado para fins fiscais e administrativos, permitindo que o governo e as organizações acompanhem as atividades financeiras e tributárias dos cidadãos e empresas.',
                },
                invalidFileFormat: 'Formato do arquivo inválido',
                duplicatedRegistrationError: 'Aeronave já cadastrada',
                registrationRequested: 'Cadastro solicitado',
                registrationRequestedDescription:
                    'Seus dados foram enviados para análise. Em breve nossa equipe irá validar e liberar a aeronave para reservas.',
                registrationCompleted: 'Cadastro realizado',
                registrationCompletedDescription:
                    'Muito bem, os dados foram registrados e serão analisados por nossa equipe interna.',
            },
        },
        login: {
            subtitle: 'Acesse sua conta para continuar',
            errorBadRequest: 'E-mail e/ou senha incorretos',
            activationEmail: {
                resend: 'Reenviar e-mail',
                sent: 'Enviamos o email de ativação para {}. Se não o vir, verifique a sua pasta de spam e lixeira ou clique no botão para reenviarmos o e-mail.',
                confirmEmail: 'Confirme seu endereço de e-mail',
            },
        },
        home: {
            help: {
                needHelp: 'Precisa de ajuda?',
                needHelpDescription:
                    'Utilize um dos nosso canais de atendimento ou verifique as dúvidas frequentes.',
                contactUs: {
                    title: 'Fale conosco',
                    content: '0800-727-4720',
                },
                financial: {
                    title: 'Financeiro',
                    content: 'Enviar mensagem',
                },
                ombudsman: {
                    title: 'Ouvidoria',
                    content: 'Enviar mensagem',
                },
            },
            whatsapp: {
                title: 'Atendimento por WhatsApp',
                description:
                    'Canal exclusivo para dúvidas sobre faturamento, boletos e aviação geral.',
                serviceTimeTitle: 'Horário de atendimento:',
                serviceTime:
                    'Segunda a Quinta, das 08:00 às 18:00, e Sexta, das 08:00 às 17:00.',
                card: {
                    title: 'Whatsapp',
                    content: '(11) 91595-4100',
                },
                warningMessage:
                    'Este número não está habilitado para chamadas telefônicas, apenas para mensagens de texto.',
            },
            FAQ: {
                title: 'Perguntas Frequentes',
                seeAll: 'Ver todas',
            },
            useOurServices: 'Use nossos serviços',
            useOurServicesDescription: 'É fácil, rápido e seguro.',
            myReservations: 'Minhas reservas',
            myAircrafts: 'Minhas aeronaves',
            pilotRegistration: 'Cadastro de pilotos',
            financialServices: 'Serviço Financeiro',
            debtsAndInvoices: 'Débitos e Faturas',
            newFeaturesTitle: 'Em breve, novos serviços!',
            fareCalculator: {
                title: 'Calculadora de Tarifas',
                description:
                    'Saiba antes o valor da reserva e o detalhamento das tarifas.',
            },
            reservationStatus: {
                title: 'Status da reserva',
                description:
                    'Receba notificações dos status de sua reserva a cada etapa.',
            },
            outstandingDebts: {
                title: 'Buscar débitos em aberto',
                description: 'Monitore e gerencie seu faturamento.',
            },
        },
        homeLoggedIn: {
            banner: {
                title: 'Cadastre suas aeronaves',
                subtitle: 'Cadastre agora',
            },
            outstandingDebts: {
                banner: {
                    title: 'Novidades no Portal AVG',
                    subtitle:
                        'Agora você pode acompanhar seu histórico de pagamentos, consultar seus débitos em aberto e baixar boletos e notas fiscais!',
                },
                title: 'Débitos em aberto',
                tooltip:
                    'Valores em aberto referente às matrículas das aeronaves cadastradas e seus operadores, a partir de 01/04/2023 de operações realizadas a partir de 01/05/2023.',
                view: 'EXIBIR',
                observationMessage:
                    'Faturas disponibilizadas a partir de 01/05/23',
            },
            lastUpdates: 'Últimas atualizações',
            replicate: 'Replicar',
            noReservations: {
                title: 'Ainda não há reservas',
                subtitle: 'Você não possui um histórico de reservas',
            },
            noResults: {
                title: 'Sem resultados',
                subtitle:
                    'Os parâmetros de filtragem não encontraram nenhum resultado',
            },
            services: {
                title: 'Serviços',
                new: 'Nova Reserva',
            },
            myReservations: {
                title: 'Minhas Reservas',
                link: 'Mostrar todas',
            },
        },
        homeNotLoggedIn: {
            doubt: 'Tem alguma dúvida sobre o nosso site?',
            banner: {
                register: {
                    title: 'Nosso aplicativo mudou',
                    subtitle:
                        'Com nossa Home totalmente renovada, você terá acesso fácil e intuitivo para agilizar suas reservas de pátio no aeroporto',
                    button: 'Criar conta',
                },
                aircraft: {
                    title: 'Cadastre sua aeronave',
                    subtitle: 'E agilize suas reservas de pátio no aeroporto',
                    button: 'Cadastrar agora',
                },
                reserve: {
                    title: 'Reserve o pátio do aeroporto',
                    subtitle: 'E pouse de maneira segura e eficiente.',
                    button: 'Reservar agora',
                },
                billing: {
                    title: 'Solicite seus dados de faturamento',
                    subtitle: 'Acompanhe seus status de voo e outros serviços.',
                    button: 'Financeiro',
                },
            },
        },
        sidemenu: {
            aboutCCR: 'Sobre a CCR',
            selectLanguage: 'Selecione o idioma',
        },
        resetPassword: {
            success:
                'Sua senha foi alterada. Por favor entre com sua nova senha.',
        },
        activateAccount: {
            success:
                'Sua conta foi ativada com sucesso. Já pode iniciar sessão.',
            error: {
                title: 'Erro ao ativar conta',
                message:
                    'Por favor, tente mais tarde ou tente redefinir sua senha.\nSe o erro persistir, entre em contato com o suporte.',
            },
        },
        reservationDetail: {
            title: 'Detalhe da reserva',
            modal: 'Informações',
            createdAt: 'Reserva criada em',
            reserveAgain: 'Reservar novamente',
            cancel: 'Cancelar reserva',
            dateOptions: 'Opções de data',
            adjustDate: 'Ajustar a data',
            requestChangeSubtitle:
                'Enviamos uma alternativa disponível para sua data e horário sugerido.',
            notSuitMe: 'A opção não me atende',
            pixCard: {
                steps: [
                    'Escolha a opção pagar com código Pix no app do seu banco.',
                    'Copie e cole o código ou escaneie o código QR com a câmera de seu celular.',
                    'Confira todas as informações e autorize o pagamento.',
                ],
                totalLabel: 'Total da reserva via PIX',
                copyPaste: 'PIX copia e cola',
                copied: 'Código QR copiado!',
            },
        },
        reservation: {
            generalInfo: 'Informações Gerais',
            additionalInfo: 'Informações Adicionais',
            newAirportNoICAO: 'Criar novo aeroporto sem ICAO',
            noCommander: 'Não selecionar comandante',
            landingDateInLocalTime: 'Data de pouso na hora local do aeroporto',
            localTime: 'Horário local do aeroporto',
            localTimeDescription:
                'A data e hora, de pouso e decolagem, são referentes à hora local do aeroporto, ou seja, o seu destino na CCR. Lembre-se de conferir o fuso horário dos aeroportos e se possuem algum horário específico de funcionamento para evitar que sua reserva seja recusada.',
            changeTime: 'Alterar horário',
            confirm: 'Confirmar',
            airportTimeWarningTitle: 'Horário do aeroporto',
            airportTimeWarningMessage:
                'O horário selecionado está fora do horário de funcionamento padrão do aeroporto, se continuar, sua reserva pode ser recusada. Deseja continuar?',
        },
        summary: {
            summaryReservation: 'Resumo da reserva',
            takeoffLanding: 'Pouso e Decolagem',
            requester: 'Solicitante',
            aircraftOperator: 'Operador da Aeronave',
            operator: 'Operador',
            guidelines: 'Orientações',
            guidelineA: 'Orientações para saída por meios próprios Classe A',
            guidelineB: 'Orientações para saída por meios próprios Classe B',
            messageSuccess: 'Solicitação de pouso enviada com sucesso.',
        },
        success: {
            title: 'Obrigado!',
            description:
                'Sua reserva de pouso e decolagem foi enviada com sucesso!',
            emailMessage:
                'Em breve enviaremos um e-mail com todas as informações desta reserva.',
            observation: 'Observações',
            backToHome: 'Voltar para o início',
            AISWebMessage:
                'Sugerimos que sempre consulte as informações aeronáuticas do aeroporto de destino no AISWeb.',
        },
        backoffice: {
            home: {
                title: 'Reservas',
                search: 'Pesquise por matrícula, modelo da aeronave ou aeroporto',
                filters: {
                    landingAndTakeOff: 'Com pouso e decolagem',
                    onlyLanding: 'Apenas pouso',
                    showFlights: 'Listar voos',
                },
            },
            details: {
                status: 'Status',
                commander: 'Comandante',
                pricing: 'Tarifação',
                payment: 'Pagamento',
                reservationCompleted: 'Reserva concluída',
                billing: 'Faturamento',
                flightInformation: 'Informações do voo',
                reservationSentIn: 'Reserva enviada em ',
                formPayment: 'Forma de Pagamento',
                paymentData: 'Dados do Pagamento',
                infoInternalControl: 'Informações para controle interno',
                negotiationHistory: 'Histórico de Negociação',
                requesterObservation: 'Observações do Solicitante',
                landingSuggestions: 'Sugestões de Pouso',
                customerResponse: 'Resposta do Cliente',
                acceptedOption: 'Opção Aceita',
                text: {
                    approved:
                        'A data da reserva do cliente está disponível e pode seguir para a Tarifação.',
                    pricing: 'Tarifa enviada ao cliente pelo ',
                    changeRequested:
                        'A reserva foi devolvida. Aguarde o cliente aceitar ou sugerir nova data ou hora.',
                },
                fareValues: 'Valores da tarifa',
                valueIn: 'Valor em',
                reasonPending: 'Motivo da Pendência',
                dateDevolution: 'Devolução de data',
                placeholderComment: 'Escreva um comentário para o cliente',
                noReservationLandingRequest:
                    'Solicitação de pouso sem reserva de pátio',
                arrival: 'Chegada',
                departure: 'Partida',
                noBilling: 'Reserva não foi tarifada',
                commentsEditSuccess:
                    'As informações de controle interno foram editadas com sucesso',
            },
            airports: {
                new: 'Novo aeroporto',
                airportData: 'Dados do aeroporto',
                airportName: 'Nome do aeroporto',
                icao: 'ICAO',
                success: 'Aeroporto criado com sucesso',
                update: 'Aeroporto atualizado com sucesso',
                delete: 'Aeroporto deletado com sucesso',
                status: 'Aeroporto Ativo',
                contactDetails: 'Dados de Contato',
                textContactDetails:
                    'Escreva os dados de contato para informações e dúvidas dos clientes:',
                contactEmail: 'Email de contato',
                textContactEmail:
                    'Escreva o email para receber notificações de novas solicitações de pouso:',
                textDeleteConfirm:
                    'Deseja prosseguir com a exclusão do Aeroporto?',
                titleDocumentation: 'Documentações',
                textDocumentation:
                    'Insira a lista de documentos necessários para envio ao cliente durante a solicitação de pouso:',
                nameDocument: 'Nome do Documento',
                noTimezone: 'Não selecionar fuso horário',
                search: 'Pesquise por ICAO, nome, estado ou país',
                files: {
                    btn: 'Enviar documento',
                    accept: 'Aceite obrigatório pelo cliente',
                    rename: 'Renomear',
                    delete: 'Excluir',
                    dialog: {
                        title: 'Excluir Documento',
                        description:
                            'Deseja prosseguir com a exclusão do documento?',
                    },
                },
                filters: {
                    states: 'Estados',
                    managed: 'Gerência',
                    managedBy: {
                        ccr: 'Gerenciado pela CCR',
                        other: 'Não gerenciado pela CCR',
                    },
                },
                openingHours: 'Horário de funcionamento',
                hasWorkSchedule: 'Possui horário de funcionamento específico?',
                days: 'Dias',
                schedule: 'Horários',
                opening: 'ABRE',
                closing: 'FECHA',
            },
            users: {
                title: 'Usuários',
                search: 'Pesquise por e-mail ou perfil de acesso',
                createSuccess: 'O usuário foi adicionado com sucesso.',
                editSuccess: 'O usuário foi editado com sucesso.',
                new: 'Novo usuário',
            },
            solicitations: {
                title: 'Solicitações de cadastro e edição',
                search: 'Pesquise por matrícula ou nome de usuário',
                status: {
                    incompleteRegistration: 'Cadastro incompleto',
                    pendingValidation: 'Aguardando Validação',
                    approvedValidation: 'Validada',
                    disapprovedValidation: 'Reprovada',
                    cancelled: 'Cancelada',
                },
                aircraftNature: 'Natureza da Aeronave',
                nature: {
                    national: 'Nacional',
                    international: 'Estrangeira',
                    military: 'Militar',
                },
                solicitationType: 'Tipo de solicitação',
                type: {
                    creation: 'Novo cadastro',
                    edit: 'Edição',
                },
                details: {
                    newSolicitation: 'Nova solicitação',
                    approved: 'Aprovada',
                    disapproved: 'Reprovada',
                    approvedDisapproved: 'Aprovada/Reprovada',
                    changeAircraftStatus: 'Alterar status da aeronave',
                    observation: 'Observação',
                    justification: 'Justificativa',
                    validateAircraft: 'Validar aeronave',
                    reproveAircraft: 'Reprovar aeronave',
                    validation: 'Validação',
                    lastUpdatedAt:
                        'Última atualização realizada em {1} - {2} ({3} UTC)',
                },
            },
        },
        checkout: {
            title: 'Pagamento',
            bookingTotal: 'Total da reserva',
            formPayment: 'Forma de pagamento',
            reservationSentIn: 'Reserva enviada em ',
            paymentMethods: 'Formas de Pagamento',
            description:
                'Valor sujeito a alteração após envio da solicitação em função da programação de voo.',
            paymentSuccess: 'Sucesso',
            paymentFailure: 'Falha no pagamento',
        },
        help: {
            title: 'Fale Conosco',
            subtitle:
                'Em caso de dúvidas ou mais informações, por favor entre em contato com nossos operadores',
            contact: 'Contato',
            noContact: 'Nenhum meio de contato cadastrado',
            ombudsman: 'Ouvidoria CCR Aeroportos',
            workingHours: 'Horário de atendimento',
            mondayToFriday: 'Segunda à Sexta',
            phone: 'Telefone',
            mail: 'E-mail',
            commercialService: 'Aviação Geral',
            helpCenter: 'Central de Atendimento',
            helpCenterLinkText:
                ' para registar sua solicitação ou ligue para  0800 727 4720',
            clickHere: 'Clique aqui',
        },
        debt: {
            tabs: {
                debt: 'Débitos',
                history: 'Histórico',
            },
            content: {
                openDebts: 'Débitos em aberto',
                invoicesInTotal: 'faturas no total',
                operations: 'operações',
                totalOpenInvoices: 'Total de faturas abertas',
                selectOption: 'Selecione uma opção',
                printSlip: 'Imprimir boleto',
                downloadInvoice: 'Baixar Nota Fiscal',
                invoiceHistory: 'Histórico de faturas',
                searchInvoices: 'Buscar faturas',
                lateDebt: 'Fatura vencida',
                dueDate: 'Dt. Venc.',
                invoice: 'Nota Fiscal',
                slip: 'Boleto',
                payWithPix: 'Pagar com PIX',
                payWithBankSlip: 'Pagar com Boleto Bancário',
            },
            noAircraft: {
                nothingHere: 'Ainda não há nada por aqui',
                registerToConsult:
                    'Cadastre uma aeronave para consultar débitos no AVG',
                goToRegister: 'Cadastrar Aeronave',
            },
            noDebt: {
                nothingHere: 'Nenhum débito em aberto',
                aircraftUpToDate: 'Todas as suas aeronaves estão em dia!',
                goToHome: 'Voltar ao início',
                filtered: {
                    nothingHere: 'Nenhum resultado encontrado',
                    noResults:
                        'Não encontramos resultados válidos referente a sua busca, por favor tente novamente.',
                },
            },
            noHistory: {
                nothingHere: 'Ainda não há nada por aqui',
                noRegisters: 'Você não possui nenhuma movimentação registrada.',
                goToHome: 'Voltar ao início',
                filtered: {
                    nothingHere: 'Nenhum resultado encontrado',
                    noResults:
                        'Não encontramos resultados válidos referente a sua busca, por favor tente novamente.',
                },
            },
            filter: {
                title: 'Filtrar Faturas',
                debtPeriodLabel: 'Selecionar Período*',
                aircraftLabel: 'Matrícula da Aeronave',
                operatorLabel: 'Operador',
                airportLabel: 'Aeroporto de Operação - CCR',
                filterResults: 'Filtrar Resultados',
                dropdownPlaceholder: 'Selecionar',
            },
            tour: {
                list: {
                    steps: [
                        {
                            title: 'Débitos',
                            description:
                                'Visualize todas as suas faturas em aberto. Busque ou filtre para ordenar a listagem conforme sua necessidade.',
                        },
                        {
                            title: 'Detalhe da fatura',
                            description:
                                'Para exibir o detalhamento da fatura, gerar boleto ou imprimir a Nota Fiscal, basta clicar no card desejado.',
                        },
                    ],
                },
                history: {
                    steps: [
                        {
                            title: 'Histórico de faturamento',
                            description:
                                'Nessa página você consegue visualizar o histórico das faturas. Ao clicar em alguma fatura você pode ver detalhamento das operações e visualizar o comprovante de pagamento.',
                        },
                        {
                            title: 'Detalhe da fatura',
                            description:
                                'Clique no card para exibir o detalhamento da fatura e acessar o comprovante de pagamento.',
                        },
                    ],
                },
            },
        },
        invoiceDetails: {
            forbidden: 'Você não possui permissão para acessar esta fatura',
            title: 'Detalhes da Fatura',
            invoiceSummary: 'Detalhes da fatura',
            operations: 'Operações',
            operationDetails: 'Detalhes da operação',
            permanenceDetails: 'Detalhes da permanência',
            CCRAirport: 'Aeroporto CCR',
            discount: 'Desconto',
            total: 'Total',
            downloadBill: 'BAIXAR BOLETO',
            downloadInvoice: 'BAIXAR NOTA FISCAL',
            invoiceExpiration: 'Vencimento da Fatura',
            invoiceDate: 'Data da fatura',
            totalValue: 'Valor total',
            invoice: 'Nota Fiscal',
            operator: 'Operador',
            aircraftAverageWeight: 'PMD - Peso da aeronave',
            landing: 'Pouso',
            landingDate: 'Data de pouso',
            landingTax: 'Tarifa de pouso',
            takeoff: 'Decolagem',
            takeoffDate: 'Data de decolagem',
            showMore: 'Exibir detalhes',
            showLess: 'Ocultar detalhes',
            maneuver: 'Manobra',
            maneuverTime: 'Tempo de manobra',
            maneuverValue: 'Valor da manobra',
            stay: 'Estadia',
            landingCost: 'Valor do pouso',
            costOfStay: 'Valor da estadia',
            lengthOfStay: 'Tempo de estadia',
            costOfPermanence: 'Valor da permanência',
            explanatoryText:
                'Se algum dos débitos apresentados não corresponder às suas operações, entre em contato com os demais operadores da aeronave para regularização.',
            selectPaymentOptions: 'Selecione uma opção',
            payWithPix: 'Pagar com PIX',
            payWithBankSlip: 'Pagar com Boleto Bancário',
            pay: 'Pagar',
            cannotPayExpiredDebt:
                'Não é possível realizar o pagamento de uma fatura atrasada pelo método PIX',
        },
        invoicePayments: {
            invoiceTotal: 'Total da Fatura',
            pixInstructions:
                'Copie e cole ou escaneie o código QRcode na área de transferência PIX do seu banco para finalizar o pagamento.',
            copyPaste: 'PIX COPIA E COLA',
            paymentCompleted:
                'Pagamento Completo. Este título pode levar algumas horas para desaparecer da lista de débitos em aberto.',
            proofOfPayment: 'Comprovante de pagamento',
            invoiceNumber: 'Número da fatura',
            receiptNumber: 'Número da Nota Fiscal',
            expirationDate: 'Data do vencimento',
            paymentDate: 'Data do pagamento',
            totalPaid: 'Total pago',
            paymentNotConfirmed: 'Pagamento não confirmado',
            paymentNotConfirmedExplanation:
                'Infelizmente não foi possível identificar seu pagamento. Por favor, verifique seu saldo ou entre em contato com seu banco e tente novamente mais tarde.',
            tryAgain: 'Tentar novamente',
            downloadBill: 'Baixar boleto',
            downloadProofOfPayment: 'Baixar comprovante',
            goBack: 'Voltar ao início',
            codeValidUntil: 'Código válido até às {}',
            codeExpired: 'Código expirado às {}',
        },
        aircraftList: {
            empty: {
                nothingHere: 'Ainda não há nada por aqui',
                registerToUseServices:
                    'Cadastre uma aeronave para usar os serviços do Aeroease',
                noResults: 'Nenhum resultado encontrado',
                redoSearch:
                    'Não encontramos resultados válidos referente a sua busca, por favor tente novamente.',
                goToRegister: 'Cadastrar aeronave',
            },
            registerNew: 'Cadastrar nova aeronave',
            searchRegistration: 'Pesquisar por matrícula',
            aircraftTotal: 'aeronave(s) no total',
            incompleteRegistration: 'Cadastro incompleto',
        },
    },
    actions: {
        help: 'Ajuda',
        login: 'Entrar',
        logout: 'Sair',
        search: 'Procurar',
        profile: 'Meu cadastro',
        save: 'Salvar',
        backToLogin: 'Voltar ao login',
        changePassword: 'Alterar a senha',
        consultInvoice: 'Consultar fatura',
        forgotPassword: 'Esqueci a senha',
        resetPassword: 'Recuperar a senha',
        createAccount: 'Criar conta',
        newReservation: 'Nova reserva',
        myReservations: 'Minhas reservas',
        termsAndConditions: 'Termos e condições',
        proceed: 'Prosseguir',
        back: 'Voltar',
        completeRegistration: 'Concluir cadastro',
        seeMore: 'Ver mais',
        start: 'Início',
        continueReservation: 'Continuar a reserva',
        deleteAircraft: 'Excluir aeronave',
        moreInformation: 'Mais informações',
        backToTheTop: 'Voltar ao Topo',
        cancel: 'Cancelar',
        send: 'Enviar',
        proceedToPayment: 'Prosseguir para pagamento',
        edit: 'Editar',
        deleteAirport: 'Excluir Aeroporto',
        delete: 'Excluir',
        deletePilot: 'Excluir piloto',
        filter: 'Filtrar',
        removeFilters: 'Remover filtros',
        readMore: 'Saiba mais',
        finishReservation: 'Finalizar reserva',
        myAircraft: 'Minhas aeronaves',
        myFinances: 'Atendimento financeiro',
        saveAndContinue: 'Salvar e continuar',
        sendLater: 'Pular etapa',
        attention: 'Atenção!',
    },
    status: {
        pendingReview: 'Nova',
        changeRequested: 'Devolvida',
        pendingBilling: 'Tarifa pendente',
        pendingPayment: 'Pgto. pendente',
        processingPayment: 'Processando',
        paymentConfirmed: 'Pgto. confirmado',
        completed: 'Concluída',
        cancelled: 'Cancelada',
        denied: 'Negada',
        title: {
            done: {
                new: 'Pedido enviado',
                pendingReview: 'Pedido aprovado',
                changeRequested: 'Ajuste de data',
                pendingBilling: 'Pedido aprovado',
                pendingPayment: 'Reserva confirmada',
                processingPayment: 'Reserva confirmada',
                paymentConfirmed: 'Reserva confirmada',
                completed: 'Reserva finalizada',
                cancelled: 'Reserva cancelada',
                denied: 'Reserva negada',
                cleared: 'Reserva concluída',
            },
            current: {
                pendingReview: 'Em aprovação',
                changeRequested: 'Ajuste de data',
                pendingBilling: 'Em aprovação',
                pendingPayment: 'Confirmar reserva',
                processingPayment: 'Processando pagamento',
                paymentConfirmed: 'Reserva confirmada',
                completed: 'Reserva finalizada',
                cancelled: 'Reserva cancelada',
                denied: 'Reserva negada',
            },
        },
        description: {
            done: {
                new: 'Sua reserva foi enviada para análise.',
                pendingReview:
                    'Seu pedido de reserva foi analisado e aprovado.',
                changeRequested:
                    'Seu pedido de reserva original estava com datas e horários indisponíveis.',
                pendingBilling:
                    'Seu pedido de reserva foi analisado e aprovado.',
                pendingPayment:
                    'Sua reserva foi confirmada e seu pagamento foi aprovado. Segue abaixo o comprovante fiscal do pagamento.',
                processingPayment:
                    'Sua reserva foi confirmada e seu pagamento foi aprovado. Segue abaixo o comprovante fiscal do pagamento.',
                paymentConfirmed:
                    'Sua reserva foi confirmada e seu pagamento foi aprovado. Segue abaixo o comprovante fiscal do pagamento.',
                completed:
                    'Sua reserva foi finalizada com sucesso. Esperamos que seu voo tenha sido bom.',
                cancelled: 'Sua reserva foi cancelada.',
                denied: 'Sua reserva foi recusada.',
                cleared: 'Sua reserva está concluída. Seu voo está liberado.',
            },
            current: {
                pendingReview:
                    'Seu pedido de reserva está em análise. Aguarde confirmação.',
                changeRequested:
                    'Datas e horários requisitados na reserva não estão disponíveis. Preencha o próximo passo para aceitar ou recusar a nova sugestão.',
                pendingBilling:
                    'Seu pedido de reserva está em análise. Aguarde confirmação.',
                pendingPayment:
                    'Para que sua reserva seja confirmada, preencha as informações adicionais e efetue o pagamento.',
                processingPayment: 'Seu pagamento está sendo processado.',
                paymentConfirmed:
                    'Sua reserva foi confirmada e seu pagamento foi aprovado. Segue abaixo o comprovante fiscal do pagamento.',
                completed:
                    'Sua reserva foi finalizada com sucesso. Esperamos que seu voo tenha sido bom.',
                cancelled: 'Sua reserva foi cancelada.',
                denied: 'Sua reserva foi recusada.',
            },
        },
        noChangesRequested: 'Não foram requisitadas mudanças para esta reserva',
    },
    pagination: {
        perPage: 'Por página',
    },
    flightTypes: {
        none: 'Aviação Geral',
        instruction: 'Instrução',
        experience: 'Experiência',
        maintenance: 'Manutenção',
        military: 'Militar',
        federalPublicAdministration: 'Administração Pública Federal Direta',
        other: 'Outros tipos de isenções',
    },
    ordering: {
        orderBy: 'Ordernar por',
        aircraftRegistration: 'Matrícula da aeronave',
        lastUpdated: 'Atualizado por último',
        lastCreated: 'Reserva mais recente',
        firstCreated: 'Reserva mais antiga',
    },
    paymentOptions: {
        accountHolder: 'Correntista',
        pix: 'PIX',
        creditCard: 'Cartão de Crédito',
    },
    tour: {
        nextStep: 'Próximo',
        lastStepMessage: 'Entendi',
    },
};
