import PropTypes from 'prop-types';

import { icoPending } from 'assets/icons';
import { Button, Image } from 'components';
import './styles.scss';

export default function EmptyDebtList({
    title,
    body,
    buttonText,
    onClickButton,
    icon,
}) {
    return (
        <div className="empty-debt-list d-flex flex-column align-items-center justify-content-center padding--md">
            <Image
                src={icon}
                alt="Pending"
                className="empty-debt-icon"
                size={96}
            />
            <div className="d-flex flex-column align-items-center">
                <span className="empty-debt-title">{title}</span>
                <span className="empty-debt-text">{body}</span>
            </div>
            {buttonText && (
                <Button type="primary" onClick={onClickButton}>
                    {buttonText}
                </Button>
            )}
        </div>
    );
}

EmptyDebtList.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClickButton: PropTypes.func.isRequired,
    icon: PropTypes.string,
};

EmptyDebtList.defaultProps = {
    icon: icoPending,
};
