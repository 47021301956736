import styled from 'styled-components';

export const Container = styled.p`
    color: ${(props) => props.color || props.theme.colors.grayscale.dark};
    font-weight: ${(props) => props.theme.font_weights.regular};

    ${(props) =>
        props.kind === 'body-1' &&
        `
      font-size: ${props.theme.fonts.lg};
      line-height: ${props.theme.line_height.lg};
  `}

    ${(props) =>
        props.kind === 'body-2' &&
        `
      font-size: ${props.theme.fonts.md};
      line-height: ${props.theme.line_height.md};
  `}

  ${(props) =>
        props.kind === 'subtitle-1' &&
        `
      font-size: ${props.theme.fonts.sm};
      line-height: ${props.theme.line_height.sm};
  `}

${(props) =>
        props.kind === 'overline-1' &&
        `
      font-size: ${props.theme.fonts.xs};
      line-height: ${props.theme.line_height.xs};
      font-weight: ${props.theme.font_weights.medium};
`}

  ${(props) =>
        props.kind === 'overline-2' &&
        `
        font-size: ${props.theme.fonts.xs};
        line-height: ${props.theme.line_height.xs};
  `}
`;
