import { PropTypes } from 'prop-types';

import { TextInfo, Text, Title, Loading } from 'components';
import Locale from 'locale';
import theme from 'styles/themes/default';
import { useEffect, useState } from 'react';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import moment from 'moment';

function FormCompleted({ data, statusTransitionResponsibles }) {
    const [isLoading, setIsLoading] = useState(true);
    const [billingData, setBillingData] = useState();
    const [paymentMethod, setPaymentMethod] = useState();

    const getPayment = () => {
        if (data.aircraft.isForeign) {
            Service.getLandingPayment(data.id)
                .then((response) => {
                    setPaymentMethod(response.paymentMethod);
                    setIsLoading(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    setIsLoading(false);
                });
        } else {
            setPaymentMethod('accountHolder');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (data?.aircraft?.isForeign || data.hasPatioReservation) {
            Service.getBillingData(data.id)
                .then((response) => {
                    setBillingData(response);
                    getPayment();
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    setIsLoading(false);
                });
        } else setIsLoading(false);
    }, []);

    const paymentData = {
        formPayment: Locale.paymentOptions[paymentMethod],
        date: new Date(data.updatedAt),
        description: Locale.fields.payment.description[paymentMethod],
    };

    return (
        <div className="padding--t-xxl padding--x-md">
            {data?.hasPatioReservation ? (
                <div>
                    {!isLoading ? (
                        <div>
                            <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                                {Locale.pages.backoffice.details.payment}
                            </h4>

                            <div className="row margin--b-sm">
                                <div className="col-12 col-md-3">
                                    {billingData?.pricing?.priceReal === 0 ? (
                                        <TextInfo
                                            label={`${Locale.pages.backoffice.details.valueIn} R$`}
                                            // TODO: criar máscara de moeda
                                            value={`${Locale.fields.payment.accountHolderDisclaimer}`}
                                        />
                                    ) : (
                                        <TextInfo
                                            label={`${Locale.pages.backoffice.details.valueIn} R$`}
                                            // TODO: criar máscara de moeda
                                            value={`R$${billingData?.pricing?.priceReal}`}
                                        />
                                    )}
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextInfo
                                        label={
                                            Locale.pages.backoffice.details
                                                .formPayment
                                        }
                                        value={paymentData.formPayment}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextInfo
                                        label={Locale.fields.payday}
                                        value={moment(paymentData.date).format(
                                            'DD/MM/YYYY'
                                        )}
                                    />
                                </div>
                                <div className="col-12 col-md-3 break-word">
                                    <TextInfo
                                        label={Locale.fields.approvedBy}
                                        value={
                                            statusTransitionResponsibles
                                                ?.approval?.email ||
                                            Locale.fields.unknown
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                                        {
                                            Locale.pages.backoffice.details
                                                .paymentData
                                        }
                                    </h4>
                                    <Text
                                        kind="body-2"
                                        color={
                                            theme.colors.grayscale.carbonoGray
                                        }
                                    >
                                        {paymentData.description}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex-items--center flex-justify--center">
                            <Loading />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <Title tagName="h3">
                        {Locale.pages.backoffice.details.reservationCompleted}
                    </Title>
                    {
                        Locale.pages.backoffice.details
                            .noReservationLandingRequest
                    }
                </div>
            )}
        </div>
    );
}

FormCompleted.propTypes = {
    data: PropTypes.object.isRequired,
    statusTransitionResponsibles: PropTypes.object,
};

FormCompleted.defaultProps = {
    statusTransitionResponsibles: {},
};

export default FormCompleted;
