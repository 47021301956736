import { Image } from 'components';
import PropTypes from 'prop-types';
import { Backdrop, Container, PopoverTitle } from './styles';

export default function Popover({ show, options, closeMenu, title }) {
    return (
        <>
            <Backdrop show={show} onClick={closeMenu} />
            <Container show={show}>
                {title && <PopoverTitle>{title}</PopoverTitle>}
                <div className="stack--xs gap-1">
                    {options.reduce((optionsShown, option) => {
                        if (!option.hidden) {
                            optionsShown.push(
                                <div
                                    key={`menu-option-${option.title}`}
                                    className={`inline--sm flex-items--center cursor--pointer padding--x-md padding--y-sm ${
                                        option.selected
                                            ? 'menu-option-selected'
                                            : ''
                                    }`}
                                    onClick={option.onClick}
                                >
                                    {option.icon && (
                                        <Image
                                            src={option.icon}
                                            alt={option.title}
                                            size={16}
                                        />
                                    )}
                                    <div className="body-2">{option.title}</div>
                                </div>
                            );
                        }
                        return optionsShown;
                    }, [])}
                </div>
            </Container>
        </>
    );
}

Popover.propTypes = {
    show: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            selected: PropTypes.bool,
            onClick: PropTypes.func.isRequired,
            hide: PropTypes.bool,
        })
    ).isRequired,
    title: PropTypes.string,
};

Popover.defaultProps = {
    title: '',
};
