import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Button,
    Input,
    Text,
    Line,
    TextInfo,
    Loading,
    Title,
} from 'components';
import Locale from 'locale';
import theme from 'styles/themes/default';
import Service from 'services';
import { useNavigate } from 'react-router-dom';
import { ErrorHelper } from 'helpers';
import { ReservationStatus } from 'utils/enums';

function FormPricing({ data }) {
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);

    const initialValues = {
        priceReal: '',
        priceDollar: '',
        priceEuro: '',
    };

    const validationPricingFormSchema = Yup.object().shape({
        priceReal: Yup.number()
            .positive(Locale.errors.required)
            .required(Locale.errors.required),
        priceDollar: Yup.number()
            .positive(Locale.errors.required)
            .required(Locale.errors.required),
        priceEuro: Yup.number()
            .positive(Locale.errors.required)
            .required(Locale.errors.required),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationPricingFormSchema,
        onSubmit: () => {
            Service.fulfillBilling(data.id, formik.values)
                .then(() => {
                    navigate(0);
                })
                .catch((error) => ErrorHelper.notifyError(error));
        },
    });

    useEffect(() => {
        if (
            (data?.aircraft?.isForeign || data?.hasPatioReservation) &&
            data?.status > ReservationStatus.pendingBilling
        ) {
            setLoading(true);
            Service.getBillingData(data.id)
                .then((response) => {
                    setValues(response.pricing);
                    setLoading(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    setLoading(false);
                });
        }
    }, []);

    const billingContent = () => {
        if (!data?.aircraft?.isForeign) {
            return (
                <div>
                    <Title tagName="h3">
                        {Locale.pages.backoffice.details.noBilling}
                    </Title>
                    {data?.hasPatioReservation
                        ? `${Locale.fields.payment.description.accountHolder}. ${Locale.fields.payment.accountHolderDisclaimer}.`
                        : Locale.pages.backoffice.details
                              .noReservationLandingRequest}
                </div>
            );
        }

        if (data?.status > ReservationStatus.pendingBilling) {
            return (
                <>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.pages.backoffice.details.fareValues}
                    </h4>
                    <div className="row margin--b-sm">
                        <div className="col-12 col-md-4">
                            <TextInfo
                                label={`${Locale.pages.backoffice.details.valueIn} R$`}
                                value={new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                }).format(values.priceReal)}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextInfo
                                label={`${Locale.pages.backoffice.details.valueIn} US$`}
                                value={new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(values.priceDollar)}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextInfo
                                label={`${Locale.pages.backoffice.details.valueIn} €$`}
                                value={new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                }).format(values.priceEuro)}
                            />
                        </div>
                    </div>
                </>
            );
        }

        return (
            <form onSubmit={formik.handleSubmit}>
                <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                    {Locale.pages.backoffice.details.fareValues}
                </h4>
                <div className="row margin--b-sm">
                    <div className="col-12 col-md-4">
                        <Input
                            id="priceReal"
                            name="priceReal"
                            type="number"
                            onBlur={formik.handleBlur}
                            label={`${Locale.pages.backoffice.details.valueIn} R$`}
                            value={formik.values.priceReal}
                            onChange={formik.handleChange}
                            before={
                                <Text
                                    kind="body-2"
                                    color={theme.colors.grayscale.blueGray}
                                >
                                    R$
                                </Text>
                            }
                            error={
                                formik.touched.priceReal &&
                                formik.errors.priceReal
                            }
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <Input
                            id="priceDollar"
                            name="priceDollar"
                            type="number"
                            onBlur={formik.handleBlur}
                            label={`${Locale.pages.backoffice.details.valueIn} US$`}
                            value={formik.values.priceDollar}
                            onChange={formik.handleChange}
                            before={
                                <Text
                                    kind="body-2"
                                    color={theme.colors.grayscale.blueGray}
                                >
                                    US$
                                </Text>
                            }
                            error={
                                formik.touched.priceDollar &&
                                formik.errors.priceDollar
                            }
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <Input
                            id="priceEuro"
                            name="priceEuro"
                            type="number"
                            onBlur={formik.handleBlur}
                            label={`${Locale.pages.backoffice.details.valueIn} €$`}
                            value={formik.values.priceEuro}
                            onChange={formik.handleChange}
                            before={
                                <Text
                                    kind="body-2"
                                    color={theme.colors.grayscale.blueGray}
                                >
                                    €$
                                </Text>
                            }
                            error={
                                formik.touched.priceEuro &&
                                formik.errors.priceEuro
                            }
                        />
                    </div>
                </div>
                <Line />
                <div className="flex-justify--end margin--t-lg margin--b-sm">
                    <Button
                        kind="outline"
                        type="button"
                        className="margin--r-md"
                    >
                        {Locale.actions.cancel}
                    </Button>
                    <Button
                        type="submit"
                        kind="primary"
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        {Locale.actions.send}
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <div className="padding--t-xxl padding--x-md">
            <div>{!loading ? billingContent() : <Loading />}</div>
        </div>
    );
}

FormPricing.propTypes = {
    data: PropTypes.object.isRequired,
};

export default FormPricing;
