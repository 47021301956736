import {
    icoCalendarDuotone,
    icoFlightDuotone,
    icoPerson,
    icoAirportDuotone,
} from 'assets/icons';
import { Image } from 'components';
import Locale from 'locale';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';

export default function SidemenuBO() {
    const navigate = useNavigate();
    const location = useLocation();
    const routerSelected = location.pathname;

    return (
        <div className="stack--md padding--t-lg sidemenu-border sidemenu-bo">
            <div
                className={`sidemenu-item-bo ${
                    routerSelected === '/backoffice' ||
                    routerSelected.includes('reservation')
                        ? 'item-selected'
                        : ''
                }`}
                onClick={() => navigate('/backoffice')}
            >
                <Image
                    className="large-icon margin--x-md"
                    src={icoCalendarDuotone}
                    alt={Locale.pages.backoffice.home.title}
                />
            </div>
            <div
                className={`sidemenu-item-bo ${
                    routerSelected.includes('airports') ? 'item-selected' : ''
                }`}
                onClick={() => navigate('/backoffice/airports')}
            >
                <Image
                    className="large-icon margin--x-md"
                    src={icoAirportDuotone}
                    alt={Locale.general.airports}
                />
            </div>
            <div
                className={`sidemenu-item-bo ${
                    routerSelected.includes('users') ? 'item-selected' : ''
                }`}
                onClick={() => navigate('/backoffice/users')}
            >
                <Image
                    className="large-icon margin--x-md"
                    src={icoPerson}
                    alt={Locale.pages.backoffice.users.title}
                />
            </div>
            {AIRCRAFT_SOLICITATION_PROCESS_ENABLED && (
                <div
                    className={`sidemenu-item-bo ${
                        routerSelected.includes('solicitations')
                            ? 'item-selected'
                            : ''
                    }`}
                    onClick={() => navigate('/backoffice/solicitations')}
                >
                    <Image
                        className="large-icon margin--x-md"
                        src={icoFlightDuotone}
                        alt={Locale.pages.backoffice.solicitations.title}
                    />
                </div>
            )}
        </div>
    );
}
