import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    * {
        opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    }

    label {
        cursor: pointer;
    }

    ${(props) =>
        props.error &&
        `
        p{
            color: ${props.theme.colors.support.error};
        }
    `}
`;

export const Text = styled.p`
    font-size: ${(props) => props.theme.fonts.sm};
    line-height: ${(props) => props.theme.line_height.sm};
    color: ${(props) => props.theme.colors.grayscale.carbonoGray};
    font-weight: normal;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    height: 0;
    margin: -1px;
    padding: 0;
    opacity: 0;
    border: none;
`;

export const StyledCheckbox = styled.label`
    width: ${(props) => props.theme.spacing.lg};
    height: ${(props) => props.theme.spacing.lg};
    border-radius: ${(props) => props.theme.spacing.xs};
    background-color: ${(props) => {
        if (!props.checked) return props.theme.colors.grayscale.white;
        if (props.disabled) return props.theme.colors.grayscale.lightGray;
        return props.theme.colors.brand.secondary;
    }};
    border: 2px solid
        ${(props) =>
            !props.checked || props.disabled
                ? props.theme.colors.grayscale.paleShaleGray
                : props.theme.colors.brand.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${(props) => props.theme.spacing.sm};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    img {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
        height: 0.75rem;
        width: 0.75rem;
    }
    :hover {
        box-shadow: 0px 0px 0px 8px
            ${(props) =>
                props.checked
                    ? props.theme.colors.brand.secondary
                    : props.theme.colors.grayscale.lightGray}${(props) =>
                props.checked ? 40 : 80};
    }
    :focus,
    :active {
        box-shadow: 0px 0px 0px 8px
            ${(props) =>
                props.checked
                    ? props.theme.colors.brand.secondary
                    : props.theme.colors.grayscale.lightGray}${(props) =>
                props.checked ? 80 : 'BF'};
    }
`;
