/* eslint-disable no-console */
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Locale from 'locale';
import Service from 'services';
import notify from 'components/Toast';
import { ErrorHelper, PixItauHelper } from 'helpers';
import { HeaderPage, LoadingFullPage } from 'components';
import InvoiceDetail from 'models/InvoiceDetail';
import { DEBT_POOLING_TIMER } from 'utils/constants';
import PaymentDetails from './PaymentDetails';
import PaymentCompleted from './PaymentCompleted';
import PaymentError from './PaymentError';

export default function InvoicePayment() {
    const navigate = useNavigate();
    const { invoiceId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceDetails, setInvoiceDetails] = useState();
    const [displayedComponent, setDisplayedComponent] = useState('open');
    const [QRCode, setQRCode] = useState('');
    const [expiration, setExpiration] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [expired, setExpired] = useState(false);
    let pollingTimer;

    const setPixDataFromResponse = (
        response,
        shouldRefreshQrCodeIfExpired = false
    ) => {
        const pixExpirationDate = new Date(response.pixExpirationDateTime);
        const expirationTime =
            pixExpirationDate.getTime() - new Date().getTime();
        const isExpired = expirationTime < 0;
        if (isExpired && shouldRefreshQrCodeIfExpired) {
            createPayment();
        } else {
            setQRCode(response.pixQrCode);
            setExpirationDate(pixExpirationDate);
            setExpiration(expirationTime);
            setExpired(isExpired);
            startPolling();
        }
    };

    const downloadSlip = () => {
        Service.printSlip(invoiceId, invoiceDetails.boleto);
    };

    const createPayment = () => {
        if (invoiceDetails) {
            Service.createInvoicePayment({
                airportIcaoCode: invoiceDetails?.aeroportoICAO,
                debtId: invoiceDetails?.noDocFaturamento,
                amount: invoiceDetails.vlrTotal,
            }).then((response) => {
                setPixDataFromResponse(response);
                startPolling();
            });
        }
    };

    const startPolling = () => {
        if (!pollingTimer) {
            pollingTimer = window.setInterval(
                checkPixTransaction,
                DEBT_POOLING_TIMER
            );
        }
    };

    const checkPixTransaction = (shouldRefreshQrCodeIfExpired = false) => {
        if (invoiceId) {
            Service.checkPixTransaction(invoiceId)
                .then((response) => {
                    if (
                        PixItauHelper.isCompletedStatus(response?.data?.status)
                    ) {
                        // Pagamento Completo
                        clearInterval(pollingTimer);
                        setDisplayedComponent('completed');
                    } else if (PixItauHelper.isActive(response?.data?.status)) {
                        // Pagamento ativo
                        setPixDataFromResponse(
                            response.data,
                            shouldRefreshQrCodeIfExpired
                        );
                        setDisplayedComponent('open');
                    } else if (
                        PixItauHelper.isFailureStatus(response?.data?.status)
                    ) {
                        // Falha no pagamento
                        clearInterval(pollingTimer);
                        setDisplayedComponent('error');
                    }
                })
                .catch((error) => {
                    const { response } = error;
                    const data = response?.data
                        ? JSON.stringify(response.data)
                        : null;
                    if (
                        response?.status === 404 ||
                        (response?.status === 400 &&
                            data?.toLowerCase().search('not found') >= 0)
                    ) {
                        // Pagamento para este débito não existe
                        createPayment();
                    }
                });
        }
    };

    const refreshQrCode = () => {
        clearInterval(pollingTimer);
        setQRCode('');
        setExpirationDate('');
        setExpiration('');
        setExpired(false);
        const shouldRefreshQrCodeIfExpired = true;
        checkPixTransaction(shouldRefreshQrCodeIfExpired);
    };

    const paymentDetailComponent = {
        open: (
            <PaymentDetails
                invoiceDetails={invoiceDetails}
                expired={expired}
                expirationDate={expirationDate}
                QRCode={QRCode}
                refreshQrCode={refreshQrCode}
            />
        ),
        completed: <PaymentCompleted invoiceDetails={invoiceDetails} />,
        error: (
            <PaymentError
                invoiceDetails={invoiceDetails}
                onClickDownloadBill={downloadSlip}
            />
        ),
    };

    useEffect(() => {
        Service.postExtrato({}, invoiceId)
            .then((response) => {
                const invoice = InvoiceDetail.createFromResponse(response);
                if (invoice.isMasked) {
                    notify(Locale.pages.invoiceDetails.forbidden);
                    navigate('/debt-hub');
                    return;
                }
                if (invoice?.isLate) {
                    notify(Locale.pages.invoiceDetails.cannotPayExpiredDebt);
                    navigate('/debt-hub');
                    return;
                }
                setInvoiceDetails(invoice);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    notify(Locale.pages.invoiceDetails.forbidden);
                    navigate('/debt-hub');
                } else {
                    ErrorHelper.notifyError(error);
                }
            });
    }, []);

    useEffect(() => {
        if (invoiceDetails) {
            const shouldRefreshQrCodeIfExpired = true;
            checkPixTransaction(shouldRefreshQrCodeIfExpired);
        }

        return () => {
            window.clearInterval(pollingTimer);
        };
    }, [invoiceDetails]);

    useEffect(() => {
        if (expiration) {
            setTimeout(() => {
                setExpired(true);
            }, Math.max(expiration, 0));
        }
    }, [expiration]);

    return (
        <div className="invoice-details-container">
            <HeaderPage
                className="padding--x-lg margin--t-md"
                labelBtnBack={Locale.pages.invoiceDetails.title}
                onClickBtnBack={() => {
                    navigate(-1);
                }}
            />
            {isLoading ? (
                <LoadingFullPage />
            ) : (
                paymentDetailComponent[displayedComponent]
            )}
        </div>
    );
}
