import styled from 'styled-components';

import { Text } from '..';

export const Container = styled.div`
    position: relative;

    label {
        text-transform: uppercase;
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: ${(props) =>
        `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
    background-color: ${(props) => props.theme.colors.grayscale.light};
    border: 1px solid ${(props) => props.theme.colors.grayscale.light};
    border-radius: ${(props) => props.theme.spacing.sm};
    box-shadow: 0px 0px 4px
        ${(props) =>
            props.error
                ? props.theme.colors.support.error
                : props.theme.colors.opacity.transparent};

    &:focus-within {
        box-shadow: 0px 0px 4px
            ${(props) => props.theme.colors.brand.primaryLight};
    }

    > button {
        width: ${(props) => props.theme.spacing.lg};
        height: ${(props) => props.theme.spacing.lg};
        border: none;
        background-color: ${(props) => props.theme.colors.opacity.transparent};
        cursor: pointer;
    }

    ${(props) =>
        props.disabled === true &&
        `
        * {
            opacity: 0.5;
            cursor: not-allowed;
        }
    `}
`;

export const Span = styled.span`
    display: flex;
    align-items: center;
`;

export const Before = styled(Span)`
    margin-right: ${(props) => props.theme.spacing.sm};
`;

export const After = styled(Span)`
    color: ${(props) => props.theme.colors.grayscale.blueGray};
    margin-left: ${(props) => props.theme.spacing.sm};
`;

export const CTextarea = styled.textarea`
    width: 100%;
    min-height: 80px;
    flex: 1;
    border: none;
    background-color: ${(props) => props.theme.colors.opacity.transparent};
    outline: none;
    font-size: ${(props) => props.theme.fonts.md};
    color: ${(props) => props.theme.colors.grayscale.dark};

    ::placeholder {
        color: ${(props) => props.theme.colors.grayscale.steelGray};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.grayscale.light}
            inset !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.colors.grayscale.dark} !important;
    }
`;

export const TextError = styled(Text)`
    color: ${(props) => props.theme.colors.support.error};
    margin-top: ${(props) => props.theme.spacing.xs};
    margin-left: ${(props) => props.theme.spacing.md};
`;
