import PropTypes from 'prop-types';
import DtPicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { icoClockDuotone } from 'assets/icons';
import { Input, Image } from '..';

import { Container } from './styles';

registerLocale('ptBR', ptBR);

function TimePicker({
    id,
    label,
    name,
    value,
    dateFormat,
    error,
    required,
    disabled,
    onChange,
    onBlur,
    moreInputSpace,
    ...args
}) {
    let calendar = {};

    return (
        <Container>
            <DtPicker
                id={id}
                name={name}
                selected={value}
                onChange={onChange}
                dateFormat={dateFormat}
                disabled={disabled}
                calendarClassName="timepicker-custom"
                locale={ptBR}
                showTimeSelect
                showTimeSelectOnly
                customInput={
                    <Input
                        name="dtName"
                        error={error}
                        mask="99:99"
                        label={label}
                        required={required}
                        before={
                            <Image
                                className={
                                    moreInputSpace
                                        ? 'negative-margin-before'
                                        : ''
                                }
                                src={icoClockDuotone}
                                onClick={() => calendar.setOpen(true)}
                                alt="clock"
                            />
                        }
                    />
                }
                ref={(ref) => {
                    calendar = ref;
                }}
                {...args}
            />
        </Container>
    );
}

TimePicker.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.oneOf([null]),
    ]),
    dateFormat: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    moreInputSpace: PropTypes.bool,
};

TimePicker.defaultProps = {
    value: '',
    label: undefined,
    required: false,
    disabled: false,
    error: '',
    onChange: undefined,
    onBlur: undefined,
    dateFormat: 'HH:mm',
    moreInputSpace: false,
};

export default TimePicker;
