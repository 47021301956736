import styled, { css } from 'styled-components';

const styleTitle = css`
    color: ${(props) => props.color || props.theme.colors.grayscale.blueGray};
    font-weight: ${(props) => props.theme.font_weights.semibold};
`;

export const H1 = styled.h1`
    ${styleTitle};
    font-size: ${(props) => props.theme.fonts.xxl};
    line-height: ${(props) => props.theme.line_height.xxl};
`;

export const H2 = styled.h2`
    ${styleTitle};
    font-size: ${(props) => props.theme.fonts.xl};
    line-height: ${(props) => props.theme.line_height.xl};
`;

export const H3 = styled.h3`
    ${styleTitle};
    font-size: ${(props) => props.theme.fonts.lg};
    line-height: ${(props) => props.theme.line_height.lg};
`;

export const H4 = styled.h4`
    ${styleTitle};
    font-size: ${(props) => props.theme.fonts.md};
    line-height: ${(props) => props.theme.line_height.md};
`;

export const H5 = styled.h5`
    ${styleTitle};
    font-size: ${(props) => props.theme.fonts.sm};
    line-height: ${(props) => props.theme.line_height.sm};
`;
