/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import * as TooltipComponent from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Tooltip({ content, children }) {
    return (
        <TooltipComponent.Provider delayDuration={0}>
            <TooltipComponent.Root>
                <TooltipComponent.Trigger asChild className="icon-margin">
                    <button
                        type="button"
                        className="trigger-wrapper flex-items--center"
                    >
                        {children}
                    </button>
                </TooltipComponent.Trigger>
                <TooltipComponent.Portal>
                    <TooltipComponent.Content className="tooltip-content">
                        {content}
                        <TooltipComponent.Arrow className="tooltip-arrow" />
                    </TooltipComponent.Content>
                </TooltipComponent.Portal>
            </TooltipComponent.Root>
        </TooltipComponent.Provider>
    );
}

Tooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
