import styled from 'styled-components';

const size10 = '0.625rem';

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${(props) =>
        props.disabled &&
        `
        > * {
            opacity: 0.5;
        }
    `}
`;

export const Text = styled.p`
    font-size: ${(props) => props.theme.fonts.sm};
    line-height: ${(props) => props.theme.line_height.sm};
    color: ${(props) => props.theme.colors.grayscale.carbonoGray};
    font-weight: normal;
`;

export const CRadio = styled.input`
    position: absolute;
    left: -9999px;

    &[type='radio']:checked + label,
    &[type='radio']:not(:checked) + label {
        min-height: ${(props) => props.theme.spacing.lg};
        display: flex;
        align-items: center;
        position: relative;
        padding-left: ${(props) => props.theme.spacing.xl};
        cursor: pointer;

        p {
            text-transform: none;
            ${(props) =>
                props.error &&
                `
                color: ${props.theme.colors.support.error};
            `}
        }
    }

    &[type='radio']:checked + label:before,
    &[type='radio']:not(:checked) + label:before {
        content: '';
        width: ${(props) => props.theme.spacing.lg};
        height: ${(props) => props.theme.spacing.lg};
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: ${(props) => props.theme.colors.grayscale.white};
        border: 2px solid
            ${(props) => props.theme.colors.grayscale.paleShaleGray};
        border-radius: 100%;
    }

    &[type='radio']:checked + label:before {
        background-color: ${(props) => props.theme.colors.brand.primary25};
        border-color: ${(props) => props.theme.colors.brand.primary25};
    }

    &[type='radio']:checked + label:after,
    &[type='radio']:not(:checked) + label:after {
        content: '';
        width: ${size10};
        height: ${size10};
        position: absolute;
        z-index: 1;
        top: ${(props) => props.theme.spacing.sm};
        left: ${(props) => props.theme.spacing.sm};
        background: ${(props) => props.theme.colors.brand.primary};
        border-radius: 100%;
        transition: all 0.2s ease;
    }

    &[type='radio']:not(:checked) + label:after {
        opacity: 0;
    }

    &[type='radio']:checked + label:after {
        opacity: 1;
    }

    &[type='radio']:hover + label > span {
        width: ${(props) => props.theme.spacing.lg};
        height: ${(props) => props.theme.spacing.lg};
        position: absolute;
        top: 0.04rem;
        left: 0.04rem;
        z-index: 0;
        border-radius: 50%;
        ${(props) =>
            props.disabled === false &&
            `
            box-shadow: 0px 0px 0px 8px rgba(242, 244, 248, 0.5);
        `};
    }

    &[type='radio']:active + label > span {
        ${(props) =>
            props.disabled === false &&
            `
            box-shadow: 0px 0px 0px 8px rgba(205, 218, 243, 0.5);
        `};
    }
`;
