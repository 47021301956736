import { HeaderPage } from 'components';
import Locale from 'locale';
import { NewReservationForm, RequesterAccordion } from 'features/frontend';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { scrollToTop } from 'utils/functions';

export default function GeneralInfo() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        scrollToTop();
    }, [location]);

    return (
        <div className="padding--lg padding-bottom-sticky-footer">
            <HeaderPage
                title={Locale.pages.reservation.generalInfo}
                labelBtnBack={Locale.actions.start}
                onClickBtnBack={() => navigate('/')}
            />
            <RequesterAccordion />
            <NewReservationForm
                submitButtonIsFooter
                redirectTo="/newreservation/aircraft"
                formValues={location.state}
                isUserAuthenticated
            />
        </div>
    );
}
