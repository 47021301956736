import { get, patch } from './axios';

const BillingService = {
    insertAdditionalData(landingRequestId, data) {
        return patch(`billing/${landingRequestId}`, data);
    },

    getBillingData(pk) {
        return get(`billing/${pk}`);
    },
};

export default BillingService;
