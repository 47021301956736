import BaseRegion from './BaseRegion';

export default class Country extends BaseRegion {
    constructor(data) {
        super();

        this.baseConstructor(data);
    }

    static get filterIds() {
        return {
            brazil: 3469034,
        };
    }

    static getCountriesPhonesOptions(phoneList) {
        const options = [];
        phoneList.forEach((phone) =>
            options.push({ label: phone, value: phone })
        );
        return options;
    }

    static createListFromResponse(response) {
        const countries = response.map((responseItem) =>
            this.createFromResponse(responseItem)
        );

        return countries.sort((a, b) =>
            a.nameByLanguage.localeCompare(b.nameByLanguage)
        );
    }
}
