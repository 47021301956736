import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Input,
    PhoneNumberInput,
    Button,
    HeaderPage,
    ContainerForm,
    StickyFooter,
    Dropdown,
    LoadingFullPage,
} from 'components';
import Service from 'services';
import Locale from 'locale';

import './styles.scss';
import notify from 'components/Toast';
import { ErrorHelper, MaskHelper } from 'helpers';
import { getDocumentLabel } from 'utils/functions';
import { useCountryPhoneOptions } from 'states';
import ChangePasswordModal from '../ChangePasswordModal';

function PersonalSettings() {
    const [loading, setLoading] = useState(true);
    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required(Locale.errors.required),
        document: Yup.string().required(Locale.errors.required),
        phone: Yup.string().required(Locale.errors.required),
        email: Yup.string()
            .email(Locale.errors.validEmail)
            .required(Locale.errors.required),
    });

    const formik = useFormik({
        initialValues: {
            isCompany: false,
            isForeigner: false,
        },
        validationSchema: RegisterSchema,
        onSubmit: (data) => {
            const changeableData = {
                email: data.email,
                countryPhoneCode: data.countryPhoneCode,
                phoneNumber: data.phone,
                name: data.name,
            };
            Service.patchProfileData(changeableData)
                .then(() => {
                    notify(
                        Locale.pages.accountSettings.personal.changeData.success
                    );
                    window.location.reload();
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                });
        },
    });

    useEffect(() => {
        setLoading(true);
        Service.getProfileData()
            .then((response) => {
                formik.setValues((values) => ({
                    ...values,
                    name: response.name,
                    document: response.document,
                    countryPhoneCode: response.countryPhoneCode,
                    phone: response.phoneNumber,
                    email: response.email,
                    isForeigner: response.isForeigner,
                    isCompany: response.isCompany,
                }));
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    }, []);

    const [showChangePassword, setShowChangePassword] = useState(false);

    return !loading ? (
        <div className="padding--lg">
            <HeaderPage title={Locale.pages.accountSettings.personal.data} />
            <form onSubmit={formik.handleSubmit}>
                <ContainerForm>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                label={
                                    formik.values.isCompany
                                        ? Locale.fields.corporateName
                                        : Locale.fields.fullName
                                }
                                error={formik.errors.name}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                id="document"
                                name="document"
                                label={getDocumentLabel(
                                    formik.values.isForeigner,
                                    formik.values.isCompany
                                )}
                                onChange={formik.handleChange}
                                value={MaskHelper.document(
                                    formik.values.document,
                                    formik.values.isForeigner,
                                    formik.values.isCompany
                                )}
                                disabled
                            />
                        </div>
                        <div className="col-md-2 col-4">
                            <Dropdown
                                id="countryPhoneCode"
                                name="countryPhoneCode"
                                options={countriesPhones}
                                label={Locale.fields.countryCode}
                                onChange={formik.handleChange}
                                value={formik.values.countryPhoneCode}
                                error={
                                    formik.touched?.countryPhoneCode &&
                                    formik.errors?.countryPhoneCode
                                }
                                onBlur={formik.handleBlur}
                                required
                                autocomplete
                                loading={loadingCountriesPhones}
                            />
                        </div>
                        <div className="col-md-4 col-8">
                            <PhoneNumberInput
                                id="phone"
                                name="phone"
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                label={Locale.fields.phone}
                                error={formik.errors.phone}
                                required
                                countryPhoneCode={
                                    formik.values.countryPhoneCode
                                }
                            />
                        </div>
                    </div>
                    <div className="col-md-12 margin--b-xs">
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            label={Locale.fields.email}
                            error={formik.errors.email}
                            required
                            disabled
                        />
                    </div>
                    <div className="row">
                        <Button
                            type="button"
                            kind="text"
                            className="change-password"
                            onClick={() => setShowChangePassword(true)}
                        >
                            {Locale.actions.changePassword}
                        </Button>
                    </div>
                    {/* TODO: Implementar listagem de cartões */}
                    {/*
                    <Line />
                    <div className="cards-list">
                        <Title tagName="h2">
                            {Locale.pages.accountSettings.personal.cardList}
                        </Title>
                        <Button kind="text">
                            {Locale.pages.accountSettings.personal.add}
                        </Button>
                    </div>
                    <List
                        text="MasterCard 1234"
                        secondaryText="crédito"
                        after={<Image src={icoArrowRight} />}
                    />
                     */}
                </ContainerForm>
                <StickyFooter>
                    <Button
                        kind="primary"
                        type="submit"
                        disabled={Object.keys(formik.errors).length !== 0}
                    >
                        {Locale.actions.save}
                    </Button>
                </StickyFooter>
            </form>
            <ChangePasswordModal
                show={showChangePassword}
                onClose={() => setShowChangePassword(false)}
            />
        </div>
    ) : (
        <LoadingFullPage />
    );
}

export default PersonalSettings;
