import styled from 'styled-components';

export const Container = styled.div`
    padding: ${(props) => `${props.theme.spacing.md}`};

    ${(props) =>
        props.kind === 'filled' &&
        `
        background-color: ${props.theme.colors.grayscale.lightGray};
    `}
    ${(props) =>
        props.kind === 'outlined' &&
        `
        background-color: ${props.theme.colors.grayscale.white};
        border: solid 1px ${props.theme.colors.grayscale.paleShaleGray};
    `}
    ${(props) =>
        props.kind === 'secondary' &&
        `
        background-color: ${props.theme.colors.grayscale.whiteBg};
        border: 1px solid ${props.theme.colors.grayscale.paleShaleGray};
    `}
        ${(props) =>
        props.isRounded &&
        `
        border-radius: ${props.theme.spacing.sm};
    `};
`;
