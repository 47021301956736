import Locale from 'locale';
import { icoWalletCircle, icoBellCircle, icoSearchCircle } from 'assets/icons';
import { FutureServiceItem } from 'components';
import './styles.scss';

export default function FutureServices() {
    return (
        <div className="future-services-container">
            <p className="color--brand-primary fw-bold margin--b-md">
                {Locale.pages.home.newFeaturesTitle}
            </p>
            <div className="row">
                <FutureServiceItem
                    icon={icoWalletCircle}
                    title={Locale.pages.home.fareCalculator.title}
                    description={Locale.pages.home.fareCalculator.description}
                />
                <FutureServiceItem
                    icon={icoBellCircle}
                    title={Locale.pages.home.reservationStatus.title}
                    description={
                        Locale.pages.home.reservationStatus.description
                    }
                />
                <FutureServiceItem
                    icon={icoSearchCircle}
                    title={Locale.pages.home.outstandingDebts.title}
                    description={Locale.pages.home.outstandingDebts.description}
                />
            </div>
        </div>
    );
}
