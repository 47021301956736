import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';
import { StickyFooter } from 'components';

export default function DebtListFooter({ amount }) {
    return (
        <StickyFooter kind="primary">
            <div className="flex-justify--between debt-list-footer">
                <span className="debt-footer-text">
                    {Locale.pages.debt.content.totalOpenInvoices}
                </span>
                <span className="debt-footer-amount">
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(amount)}
                </span>
            </div>
        </StickyFooter>
    );
}

DebtListFooter.propTypes = {
    amount: PropTypes.number.isRequired,
};
