import { useTable, useSortBy, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';
import { Image, Pagination } from 'components';
import { icoArrowDown, icoArrowUp, icoNo, icoYes } from 'assets/icons';
import { useEffect } from 'react';
import Locale from 'locale';

export default function Table({
    columns,
    data,
    onSort,
    onPageChange,
    totalPages,
    currentPage,
    loading,
    rowClick,
    rowHeight,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state: { sortBy, pageIndex, pageSize },
    } = useTable(
        { columns, data, manualSortBy: true },
        useSortBy,
        usePagination
    );

    const getSortByColumn = () => {
        let returnSort = [...sortBy];
        if (sortBy.length > 0) {
            const sorted = { ...sortBy[0] };
            const columnSort = columns.find(
                (column) => column.accessor === sorted.id
            );
            sorted.id = columnSort.sortBy || sorted.id;
            returnSort = [sorted];
        }
        return returnSort;
    };

    useEffect(() => {
        onPageChange(pageIndex + 1, pageSize);
    }, [pageSize]);

    useEffect(() => {
        onSort(getSortByColumn());
    }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="custom-table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className={`custom-header ${
                                        column.className || ''
                                    }`}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    title={column.title || column.Header}
                                >
                                    <div className="flex-items--center inline--sm">
                                        {column.render('Header')}
                                        {column.isSorted && (
                                            <Image
                                                src={
                                                    column.isSortedDesc
                                                        ? icoArrowDown
                                                        : icoArrowUp
                                                }
                                                alt="Order"
                                                className="sort-icon"
                                            />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {!loading && (
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={`custom-row ${
                                        rowClick ? 'cursor--pointer' : ''
                                    }`}
                                    onClick={() => {
                                        if (rowClick) rowClick(row.original.id);
                                    }}
                                >
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps()}
                                            className={`custom-cell height-${rowHeight}`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageSize={pageSize}
                setPageSize={setPageSize}
                loading={loading}
            />
        </>
    );
}

Table.propTypes = {
    columns: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    onSort: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    rowClick: PropTypes.func,
    rowHeight: PropTypes.oneOf(['small', 'medium', 'large']),
};

Table.defaultProps = {
    loading: false,
    rowClick: undefined,
    rowHeight: 'large',
};

export const formatDateToTable = ({ value }) => (
    <div>{moment(value).format('DD/MM/YY')}</div>
);

export const valueOrDefault = (valueDefault) =>
    function valueOrDefaultCell({ value }) {
        return value || valueDefault;
    };

export const formatDateToTableOrDefault = (defaultValue) =>
    function formatDateToTableOrDefaultCell({ value }) {
        return value ? formatDateToTable({ value }) : defaultValue;
    };

export const boolCell = ({ value }) => {
    const text = value ? Locale.general.yes : Locale.general.no;
    return <Image src={value ? icoYes : icoNo} alt={text} title={text} />;
};

export const parseOrderingToBackend = (sortBy) => {
    if (sortBy.length === 0) {
        return '-id';
    }
    const fieldName = sortBy[0].id.replace(/\./g, '__');
    const desc = sortBy[0].desc ? '-' : '';
    return `${desc}${fieldName}`;
};
