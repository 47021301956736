import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    padding: 16px;

    ${(props) =>
        props.kind === 'info' &&
        `
            background-color: #E8EDF8
        `};

    ${(props) =>
        props.kind === 'alert' &&
        `
            background-color: #FFFAEA
        `};

    ${(props) =>
        props.kind === 'error' &&
        `
            background-color: #FEEBEC
        `};
`;
