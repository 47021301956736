import { useEffect, useState } from 'react';
import Locale from 'locale';
import PropTypes from 'prop-types';
import {
    Button,
    DatePickerRange,
    Dropdown,
    FullPageModal,
    Title,
} from 'components';
import { ErrorHelper } from 'helpers';
import { PaginationParams } from 'utils/enums';
import { useFormik } from 'formik';
import Service from 'services';
import { Airport } from 'models';

const FILTER_ALL = '0';
const dropdownAllOption = {
    label: Locale.general.all,
    value: FILTER_ALL,
};

export default function DebtListFilter({
    showFilterModal,
    onCloseClick,
    onApplyClick,
    filters,
    airportList,
}) {
    const [editingFilters, setEditingFilters] = useState({});
    const [airportsOptions, setAirportsOptions] = useState([dropdownAllOption]);

    return (
        <FullPageModal
            show={showFilterModal}
            onClose={() => {
                setEditingFilters({});
                onCloseClick(filters);
            }}
            title={Locale.fields.filters}
            endPageButton={
                <Button
                    large
                    onClick={() => {
                        onApplyClick(editingFilters);
                    }}
                >
                    {Locale.actions.filter}
                </Button>
            }
        >
            <Filters
                initialFilters={filters}
                onChange={setEditingFilters}
                isOpen={showFilterModal}
                airportList={airportList}
                airportsOptions={airportsOptions}
                setAirportsOptions={setAirportsOptions}
            />
        </FullPageModal>
    );
}

DebtListFilter.propTypes = {
    showFilterModal: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onApplyClick: PropTypes.func.isRequired,
    airportList: PropTypes.array.isRequired,
    filters: PropTypes.shape({
        startDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        endDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        airportIcao: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};

DebtListFilter.defaultProps = {
    filters: {},
};

function Filters({
    initialFilters,
    onChange,
    isOpen,
    airportList,
    airportsOptions,
    setAirportsOptions,
}) {
    const initialFormikValues = {
        ...initialFilters,
        airportIcao: initialFilters.airportIcao || dropdownAllOption?.value,
    };
    const formik = useFormik({
        initialValues: initialFormikValues,
    });

    const clearFilters = () => {
        formik.setValues({
            airportIcao: dropdownAllOption?.value,
            startDate: undefined,
            endDate: undefined,
        });
    };

    const optionsValueToArray = (array) => {
        if (array.length > 1) {
            array.shift(); // remove valor de FILTER_ALL
            return array.map((object) => object.value);
        }
        return undefined;
    };

    const parseDebtOptionsValues = (filterList) => {
        const correctedFilter = { ...filterList };

        Object.keys(filterList).forEach((filter) => {
            if (filterList[filter] === FILTER_ALL) {
                correctedFilter[filter] = undefined;
            }
        });

        if (filterList.airportIcao === FILTER_ALL) {
            correctedFilter.airportIcao = optionsValueToArray(
                filterList.airportIcao
            );
        }

        return correctedFilter;
    };

    const searchAirport = (search) => {
        Service.listAirports({
            [PaginationParams.page]: 1,
            [PaginationParams.pageSize]: 50,
            [Airport.filterParams.search]: search,
            [Airport.filterParams.hasICAO]: true,
            [Airport.filterParams.isManagedByCcr]: true,
        })
            .then((response) => {
                createAirportOptions(response.results);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const createAirportOptions = (searchedList) => {
        const airportsResponse = Airport.createListFromResponse(
            searchedList || airportList
        );
        setAirportsOptions([
            dropdownAllOption,
            ...Airport.parseDropdownOptions(airportsResponse, false),
        ]);
    };

    useEffect(() => {
        onChange(parseDebtOptionsValues(formik.values));
    }, [formik.values]);

    useEffect(() => {
        searchAirport('');
    }, [airportList]);

    useEffect(() => {
        formik.setValues({
            airportIcao: initialFilters.airportIcao || FILTER_ALL,
            startDate: initialFilters.startDate,
            endDate: initialFilters.endDate,
        });
    }, [isOpen]);

    return (
        <div className="stack--lg padding-bottom-sticky-footer">
            <Title tagName="h2">{Locale.pages.debt.filter.title}</Title>
            <div className="stack--sm">
                <DatePickerRange
                    name="range"
                    id="tange"
                    label={Locale.pages.debt.filter.debtPeriodLabel}
                    startDate={formik.values.startDate}
                    endDate={formik.values.endDate}
                    onChange={(dates) => {
                        formik.setFieldValue('startDate', dates[0]);
                        formik.setFieldValue('endDate', dates[1]);
                    }}
                    popperPlacement="top-end"
                />
            </div>
            <div className="stack--md">
                <Dropdown
                    id="airportIcao"
                    label={Locale.pages.debt.filter.airportLabel}
                    name="airportIcao"
                    options={airportsOptions}
                    value={formik.values.airportIcao}
                    onChange={formik.handleChange}
                    placeholder={Locale.pages.debt.filter.dropdownPlaceholder}
                    autocomplete
                    requestAutocomplete={searchAirport}
                />
            </div>
            <Button kind="text" onClick={clearFilters}>
                {Locale.actions.removeFilters}
            </Button>
        </div>
    );
}

Filters.propTypes = {
    initialFilters: PropTypes.shape({
        aircraftRegistration: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        airportIcao: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        operatorDocument: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        startDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        endDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
    }),
    onChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    airportList: PropTypes.array.isRequired,
    airportsOptions: PropTypes.array.isRequired,
    setAirportsOptions: PropTypes.func.isRequired,
};

Filters.defaultProps = {
    initialFilters: {},
};
