import { PixItauStatus } from 'utils/enums';

const PixItauHelper = {
    isValidStatus(status) {
        return !!PixItauStatus[status];
    },

    isCompletedStatus(status) {
        return (
            status === PixItauStatus.CONCLUIDA &&
            PixItauHelper.isValidStatus(status)
        );
    },

    isActive(status) {
        return status === PixItauStatus.ATIVA;
    },

    isFailureStatus(status) {
        const FAILURE_STATUS = [
            PixItauStatus.REMOVIDA_PELO_USUARIO_RECEBEDOR,
            PixItauStatus.REMOVIDA_PELO_PSP,
            PixItauStatus.DEVOLVIDO,
            PixItauStatus.DEVOLUCAO_EM_PROCESSAMENTO,
            PixItauStatus.DEVOLUCAO_NAO_REALIZADA,
        ];

        return (
            PixItauHelper.isValidStatus(status) &&
            FAILURE_STATUS.includes(status)
        );
    },
};

export default PixItauHelper;
