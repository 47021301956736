import { Image, Title } from 'components';
import PropTypes from 'prop-types';
import { icoClose } from 'assets/icons';
import { useEffect } from 'react';
import theme from 'styles/themes/default';
import {
    Container,
    ModalHeader,
    CloseButton,
    ModalBody,
    Content,
    Backdrop,
} from './styles';

export default function BottomSheet({
    show,
    title,
    children,
    onClose,
    endPageButton,
    ...args
}) {
    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => document.body.classList.remove('modal-open');
    }, [show]);

    return (
        <>
            <Backdrop show={show} onClick={onClose} />
            <Container show={show} {...args}>
                <ModalHeader showBorder={!!title}>
                    <Content className="container-main">
                        <>
                            <Title
                                color={theme.colors.grayscale.dark}
                                tagName="h2"
                            >
                                {title}
                            </Title>
                            <CloseButton onClick={onClose}>
                                <Image src={icoClose} alt={`Close ${title}`} />
                            </CloseButton>
                        </>
                    </Content>
                </ModalHeader>
                <ModalBody className="overflow-y--auto">
                    <Content className="container-main">{children}</Content>
                </ModalBody>
            </Container>
        </>
    );
}

BottomSheet.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    endPageButton: PropTypes.node,
};

BottomSheet.defaultProps = {
    endPageButton: undefined,
};
