import moment from 'moment';
import BaseModel from './BaseModel';

class DailyWorkSchedule extends BaseModel {
    constructor(data) {
        super();

        this.openingTime =
            data.openingTime && moment(data.openingTime, 'HH:mm:ss').toDate();
        this.closingTime =
            data.closingTime && moment(data.closingTime, 'HH:mm:ss').toDate();
        this.hasDailySchedule = !!data.hasDailySchedule;
    }

    static createEmpty() {
        return new DailyWorkSchedule({
            openingTime: null,
            closingTime: null,
        });
    }

    parseToRequest() {
        return this.hasDailySchedule
            ? {
                  openingTime:
                      this.openingTime &&
                      moment(this.openingTime).format('HH:mm:ss'),
                  closingTime:
                      this.closingTime &&
                      moment(this.closingTime).format('HH:mm:ss'),
              }
            : { openingTime: null, closingTime: null };
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new DailyWorkSchedule({
            ...response,
            hasDailySchedule: !!(response.openingTime || response.closingTime),
        });
    }
}

export default DailyWorkSchedule;
