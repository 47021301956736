import styled from 'styled-components';
import ReactSwitch from 'react-switch';

import { Text, Label } from '..';

export const Container = styled.div`
    ${(props) =>
        props.disabled &&
        `
        label {
            opacity: 0.5;
        }
    `}

    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 575px) {
        justify-content: space-between;
        align-items: center;
    }
`;

export const Switch = styled(ReactSwitch)`
    width: 5.25rem;
    display: flex;
    margin-left: ${(props) => props.theme.spacing.sm};

    .react-switch-bg {
        width: ${(props) => props.theme.spacing.full} !important;

        > div {
            width: 50% !important;
            height: ${(props) => props.theme.spacing.full} !important;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                padding: ${(props) =>
                    props.checked ? '0px 0px 0px 7px' : '0px 8px 0px 0px'};
                text-transform: uppercase;
                font-weight: 500;
                color: ${(props) =>
                    props.checked
                        ? props.theme.colors.grayscale.white
                        : props.theme.colors.grayscale.dark};
            }
        }
    }

    .react-switch-handle {
        width: 32px !important;
        height: 32px !important;
        border-radius: 50% !important;
        margin: ${(props) => (props.checked ? '3px 3px 3px 11px' : '3px')};
        padding-left: 5px;
        background-color: ${(props) =>
            props.theme.colors.grayscale.white} !important;
        transform: ${(props) =>
            props.checked
                ? `translateX(calc(100% + 4px))`
                : `translateX(1px)`} !important;
        box-shadow: none !important;

        > div {
            width: ${(props) => props.theme.spacing.full} !important;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
                width: ${(props) => props.theme.spacing.full} !important;
            }

            > p {
                width: ${(props) => props.theme.spacing.full};
                text-transform: uppercase;
                text-align: center;
                color: ${(props) => props.theme.colors.grayscale.dark};
            }
        }
    }
`;

export const CLabel = styled(Label)`
    margin-bottom: ${(props) => props.theme.spacing.xs};
    text-transform: uppercase;
    ${(props) => props.error && `color: ${props.theme.colors.support.error}`};
`;

export const TitleText = styled(Text)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => props.theme.spacing.full};
`;
