import PropTypes from 'prop-types';
import './styles.scss';

function KeyValueText({ label, value, bold, className }) {
    return (
        <div className={`key-value-text-wrapper ${className}`}>
            <p className={`label ${bold && 'fw-semibold'}`}>{label}</p>
            <p className={`value ${bold && 'fw-semibold'}`}>{value}</p>
        </div>
    );
}

KeyValueText.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    bold: PropTypes.bool,
    className: PropTypes.string,
};

KeyValueText.defaultProps = {
    bold: false,
    className: '',
};

export default KeyValueText;
