import PropTypes from 'prop-types';

import { Label } from '..';

import { Container, FormGroup, CTextarea, TextError } from './styles';

function Textarea({
    label,
    placeholder,
    name,
    value,
    required,
    disabled,
    error,
    onChange,
    onBlur,
    className,
    ...args
}) {
    return (
        <Container label={label} className={className}>
            {label && <Label isRequired={required}>{label}</Label>}
            <FormGroup error={error} disabled={disabled}>
                <CTextarea
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    onBlur={(e) => {
                        if (onBlur) {
                            onBlur(e);
                        }
                    }}
                    {...args}
                />
            </FormGroup>
            {error && <TextError kind="overline-2">{error}</TextError>}
        </Container>
    );
}

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
};

Textarea.defaultProps = {
    value: '',
    label: undefined,
    placeholder: undefined,
    required: false,
    disabled: false,
    error: '',
    onChange: undefined,
    onBlur: undefined,
    className: undefined,
};

export default Textarea;
