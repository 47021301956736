import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '..';

import { Container, CRadio, Text } from './styles';

function Radio({
    id,
    name,
    value,
    label,
    checked,
    error,
    onChange,
    onBlur,
    disabled,
    className,
    ...args
}) {
    return (
        <Container disabled={disabled} className={className}>
            <CRadio
                type="radio"
                id={id}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                checked={checked}
                error={error}
                disabled={disabled}
                {...args}
            />
            <Label htmlFor={id}>
                {label && <Text>{label}</Text>}
                <span>&nbsp;</span>
            </Label>
        </Container>
    );
}

Radio.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checked: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
};

Radio.defaultProps = {
    value: undefined,
    checked: false,
    error: '',
    onChange: undefined,
    onBlur: undefined,
    disabled: false,
    label: '',
    className: '',
};

export default Radio;
