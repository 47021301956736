import { DownloadFileHelper, ErrorHelper } from 'helpers';
import Locale from 'locale';
import { post, get } from './axios';

const FARMService = {
    postSaldoPendente() {
        return post('farm-integration/saldopendente');
    },

    postFaturas(data) {
        return post('farm-integration/fatura', data);
    },

    postExtrato(data, invoiceId) {
        return post(`farm-integration/extrato/${invoiceId}`, data);
    },

    downloadInvoice(invoiceId, documentAddress) {
        return get(
            `farm-integration/nota-fiscal/${invoiceId}/${documentAddress}`
        )
            .then((response) => {
                const filename = `${Locale.pages.debt.content.invoice} - ${
                    response.filename || invoiceId
                }.pdf`;
                DownloadFileHelper.downloadB64asPDF(response.file, filename);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    },

    printSlip(invoiceId, documentAddress) {
        return get(`farm-integration/boleto/${invoiceId}/${documentAddress}`)
            .then((response) => {
                const filename = `${Locale.pages.debt.content.slip} - ${
                    response.filename || invoiceId
                }.pdf`;
                DownloadFileHelper.downloadB64asPDF(response.file, filename);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    },
};

export default FARMService;
