import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Service from 'services';
import { Input, Button, Text, Title, Modal, Line, Image } from 'components';
import Locale from 'locale';
import './styles.scss';
import { ErrorHelper } from 'helpers';
import PropTypes from 'prop-types';
import notify from 'components/Toast';
import { icoMailBlack } from 'assets/icons';
import ForgotPassword from './ForgotPassword';

function Login({ setIsAuthenticated }) {
    const [isLoading, setIsLoading] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showActivationEmailModal, setShowActivationEmailModal] =
        useState(false);
    const [activationEmail, setActivationEmail] = useState('');
    const [isSendingActivationEmail, setIsSendingActivationEmail] =
        useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const values = {
        email: '',
        password: '',
    };

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(Locale.errors.validEmail)
            .required(Locale.errors.required),
        password: Yup.string().required(Locale.errors.required),
    });

    const functionSendActivationEmail = (email) => {
        setIsSendingActivationEmail(true);
        Service.sendActivationEmail({ email })
            .then(() => {
                setIsSendingActivationEmail(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const formik = useFormik({
        initialValues: values,
        validationSchema: LoginSchema,
        onSubmit: (data) => {
            setIsLoading(true);
            Service.login(data)
                .then(() => {
                    setIsAuthenticated(true);
                    setIsLoading(false);
                    navigate(
                        location.pathname.includes('backoffice')
                            ? '/backoffice'
                            : location.state?.redirectTo || '/',
                        {
                            state: location.state,
                        }
                    );
                })
                .catch((e) => {
                    const errorMessage = ErrorHelper.getErrorMessage(e);
                    const showActivationEmailButton =
                        e.response?.data?.canSendActivationEmail === 'True';
                    if (showActivationEmailButton) {
                        functionSendActivationEmail(data.email);
                        setActivationEmail(data.email);
                        setShowActivationEmailModal(true);
                    } else {
                        notify(errorMessage, null, null, 8000);
                    }
                    setIsLoading(false);
                });
        },
    });

    const handleEnterSubmit = (event) => {
        if (event.keyCode === 13) {
            formik.handleSubmit(event);
        }
    };

    return (
        <>
            <Modal
                show={showActivationEmailModal}
                onBackdropPress={() => setShowActivationEmailModal(false)}
            >
                <div className="flex--columns stack--lg">
                    <Image src={icoMailBlack} size={32} />
                    <p className="font-size--lg fw-semibold">
                        {Locale.pages.login.activationEmail.confirmEmail}
                    </p>
                    <p className="font-size--md">
                        {Locale.pages.login.activationEmail.sent.replace(
                            '{}',
                            activationEmail
                        )}
                    </p>
                    <Line />
                    <div className="flex--row flex-justify--end">
                        <Button
                            type="button"
                            isLoading={isSendingActivationEmail}
                            onClick={() => {
                                functionSendActivationEmail(activationEmail);
                            }}
                        >
                            {Locale.pages.login.activationEmail.resend}
                        </Button>
                    </div>
                </div>
            </Modal>
            <div className="padding--x-lg padding--b-lg container-form">
                <div className="margin--y-md">
                    <Title tagName="h2">{Locale.actions.login}</Title>
                    <Text kind="subtitle-1">{Locale.pages.login.subtitle}</Text>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="padding--y-sm">
                        <Input
                            label={Locale.fields.email}
                            name="email"
                            type="email"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={formik.errors.email}
                            onKeyDown={handleEnterSubmit}
                        />
                    </div>
                    <div className="padding--y-sm">
                        <Input
                            label={Locale.fields.user.password}
                            id="password"
                            name="password"
                            type="password"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={formik.errors.password}
                            onKeyDown={handleEnterSubmit}
                        />
                    </div>
                    <Button
                        onClick={() => setShowForgotPassword(true)}
                        className="margin--y-sm"
                        kind="text"
                        type="button"
                        block
                    >
                        {Locale.actions.forgotPassword}
                    </Button>
                    <Button
                        className={`margin--t-lg margin--b-md ${
                            isLoading && `loading-button`
                        }`}
                        type="submit"
                        block
                        isLoading={isLoading}
                    >
                        {Locale.actions.login}
                    </Button>
                    {!location.pathname.includes('backoffice') && (
                        <Link
                            to="/register/user"
                            className="text-decoration--none"
                        >
                            <Button kind="outline" block>
                                {Locale.actions.createAccount}
                            </Button>
                        </Link>
                    )}
                </form>
            </div>

            <ForgotPassword
                show={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
            />
        </>
    );
}

Login.propTypes = {
    setIsAuthenticated: PropTypes.func.isRequired,
};

export default Login;
