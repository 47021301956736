import PropTypes from 'prop-types';
import DtPicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { icoCalendarDuotone } from 'assets/icons';
import { Image, Input } from '..';

import { Container } from './styles';

registerLocale('ptBR', ptBR);

function DatePicker({
    id,
    label,
    name,
    value,
    placeholder,
    dateFormat,
    error,
    required,
    disabled,
    showTimeSelect,
    onChange,
    onBlur,
    moreInputSpace,
    ...args
}) {
    const mask = showTimeSelect ? '99/99/9999 99:99' : '99/99/9999';
    let calendar = {};

    return (
        <Container>
            <DtPicker
                id={id}
                name={name}
                selected={value}
                onChange={onChange}
                dateFormat={dateFormat}
                disabled={disabled}
                calendarClassName="datepicker-custom"
                locale={ptBR}
                showTimeSelect={showTimeSelect}
                formatWeekDay={(d) => d.substring(0, 1)}
                customInput={
                    <Input
                        name="dtName"
                        placeholder={placeholder}
                        error={error}
                        mask={mask}
                        label={label}
                        required={required}
                        before={
                            <Image
                                className={
                                    moreInputSpace
                                        ? 'negative-margin-before'
                                        : ''
                                }
                                src={icoCalendarDuotone}
                                onClick={() => calendar.setOpen(true)}
                                alt=""
                            />
                        }
                    />
                }
                ref={(ref) => {
                    calendar = ref;
                }}
                {...args}
            />
        </Container>
    );
}

DatePicker.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.oneOf([null]),
    ]),
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    moreInputSpace: PropTypes.bool,
};

DatePicker.defaultProps = {
    value: '',
    placeholder: '',
    label: undefined,
    required: false,
    disabled: false,
    showTimeSelect: false,
    error: '',
    onBlur: undefined,
    onChange: undefined,
    dateFormat: 'dd/MM/yyyy',
    moreInputSpace: false,
};

export default DatePicker;
