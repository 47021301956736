import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Locale from 'locale';
import './styles.scss';
import {
    Button,
    BottomSheet,
    Image,
    Tour,
    Loading,
    Chip,
    Line,
} from 'components';
import {
    icoFilterDuotone,
    icoDownload,
    icoFilterNoResult,
    iconChevronRightPrimary,
    icoBarCodeGray,
    icoPixGray,
} from 'assets/icons';
import Debt from 'models/Debt';
import { setItem, getItem } from 'localStorage';
import { ErrorHelper, FilterHelper } from 'helpers';
import { statusFARM } from 'utils/enums';
import Service from 'services';
import moment from 'moment';
import { format } from 'date-fns';
import DebtCard from '../DebtCard';
import DebtListFooter from '../DebtListFooter';
import DebtListFilter from '../DebtListFilter';
import EmptyDebtList from '../EmptyDebtList';

function BottomSheetPaymentOption({ icon, label, onClick, alt }) {
    return (
        <div
            className="flex--column stack--lg bottom-sheet-payment-option"
            onClick={onClick}
        >
            <div className="flex--row flex-justify--between">
                <div className="flex--row">
                    <Image
                        src={icon}
                        alt={alt}
                        size={24}
                        className="margin--r-sm"
                    />
                    <p className="color--grayscale-dark font-size--md">
                        {label}
                    </p>
                </div>
                <div>
                    <Image src={iconChevronRightPrimary} size={16} />
                </div>
            </div>
            <Line />
        </div>
    );
}

BottomSheetPaymentOption.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    alt: PropTypes.string,
};

BottomSheetPaymentOption.defaultProps = {
    alt: 'alt',
};

export default function DebtList({ aircraftList, airportList }) {
    const DEBTS_TOUR_COMPLETED = 'DEBTS_TOUR_COMPLETED';

    const [debtList, setDebtList] = useState([]);
    const [totalDebts, setTotalDebts] = useState();
    const [totalAmount, setTotalAmout] = useState();
    const [haveOpenDebts, setHaveOpenDebts] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tourControl, setTourControl] = useState({
        run: false,
        steps: [
            {
                target: '.tab__debt',
                content: {
                    title: Locale.pages.debt.tour.list.steps[0].title,
                    description:
                        Locale.pages.debt.tour.list.steps[0].description,
                },
                disableBeacon: true,
            },
            {
                target: '.debt-card',
                content: {
                    title: Locale.pages.debt.tour.list.steps[1].title,
                    description:
                        Locale.pages.debt.tour.list.steps[1].description,
                },
            },
        ],
    });

    const [showCardMenu, setShowCardMenu] = useState(false);
    const [debtSelected, setDebtSelected] = useState(Debt.createEmpty());

    const [showFilterModal, setShowFilterModal] = useState(false);
    const getPreviousSearchFilters = () => {
        const airportIcao = searchParams.get('airportIcao') || undefined;
        const startDate = moment(searchParams.get('startDate')).isValid()
            ? moment(searchParams.get('startDate')).toDate()
            : undefined;
        const endDate = moment(searchParams.get('endDate')).isValid()
            ? moment(searchParams.get('endDate')).toDate()
            : undefined;
        const previousFilters = {
            airportIcao,
            startDate,
            endDate,
        };
        return previousFilters;
    };

    const noFilters = {
        startDate: undefined,
        endDate: undefined,
        aircraftRegistration: undefined,
        airportIcao: undefined,
    };
    const [filters, setFilters] = useState(
        getPreviousSearchFilters() || noFilters
    );
    const showableFilters = () => {
        const airportsValue = filters.airportIcao;
        const period =
            filters.startDate &&
            `${format(filters.startDate, 'dd/MM/yyyy')} - ${
                filters.endDate ? format(filters.endDate, 'dd/MM/yyyy') : ''
            }`;

        return [
            ...(airportsValue
                ? [
                      {
                          name: Locale.fields.reservation.airport,
                          value: airportsValue,
                          keys: ['airportIcao'],
                      },
                  ]
                : []),
            ...(period
                ? [
                      {
                          name: Locale.pages.invoiceDetails.invoiceDate,
                          value: period,
                          keys: ['startDate', 'endDate'],
                      },
                  ]
                : []),
        ];
    };

    const removeFilter = (keys) => {
        const newFilters = { ...filters };
        keys.forEach((key) => {
            newFilters[key] = noFilters[key];
        });
        setFilters(newFilters);
    };

    const isFilterEmpty = Object.keys(filters).every(
        (key) => filters[key] === undefined
    );

    const closeFilterModal = (currentFilters) => {
        setFilters(currentFilters);
        setShowFilterModal(false);
    };

    const handleTourFinished = () => {
        setItem(DEBTS_TOUR_COMPLETED, true);
    };

    const controlTour = () => {
        const isTourCompleted = getItem(DEBTS_TOUR_COMPLETED);
        if (haveOpenDebts && !isTourCompleted) {
            setTourControl({
                ...tourControl,
                run: true,
            });
        }
    };

    useEffect(() => {
        setFilters(getPreviousSearchFilters());
    }, [location.pathname]);

    useEffect(() => {
        setIsLoading(true);
        setSearchParams({
            airportIcao: filters.airportIcao || '',
            startDate: FilterHelper.parseDateField(filters.startDate),
            endDate: FilterHelper.parseDateField(filters.endDate),
        });

        const correctedFilter = { ...filters };
        correctedFilter.startDate = FilterHelper.parseDateField(
            correctedFilter.startDate
        );
        correctedFilter.endDate = FilterHelper.parseDateField(
            correctedFilter.endDate
        );

        Service.postFaturas({ status: statusFARM.aberto, ...correctedFilter }) // TODO: tem que filtrar por status Atrasado também
            .then((response) => {
                if (response) {
                    setDebtList(response);
                }
            })
            .catch((error) => ErrorHelper.notifyError(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [filters]);

    useEffect(() => {
        controlTour();
    }, [debtList]);

    useEffect(() => {
        setTotalDebts(debtList.length);
        if (debtList.length >= 0) {
            setHaveOpenDebts(true);
        } else {
            setHaveOpenDebts(false);
        }

        let sum = 0;
        debtList.forEach((debt) => {
            sum += debt.vlrTotal;
        });
        setTotalAmout(sum);
    }, [debtList]);

    if (isLoading) {
        return (
            <div className="height--full width--full flex-justify--center flex-items--center">
                <Loading className="margin--l-sm" size={50} />
            </div>
        );
    }

    if (!aircraftList || aircraftList.length === 0) {
        return (
            <EmptyDebtList
                title={Locale.pages.debt.noAircraft.nothingHere}
                body={Locale.pages.debt.noAircraft.registerToConsult}
                buttonText={Locale.pages.debt.noAircraft.goToRegister}
                onClickButton={() =>
                    navigate('/aircraft', {
                        state: {
                            showModal: true,
                        },
                    })
                }
            />
        );
    }

    return (
        <>
            <DebtListFilter
                filters={filters}
                onApplyClick={closeFilterModal}
                onCloseClick={closeFilterModal}
                showFilterModal={showFilterModal}
                airportList={airportList}
            />
            <Tour
                run={tourControl.run}
                steps={tourControl.steps}
                onTourFinish={handleTourFinished}
            />
            <div className="debt-header">
                <div>
                    <p className="title">
                        {Locale.pages.debt.content.openDebts}
                    </p>
                    <p className="subtitle">
                        {`${totalDebts} ${Locale.pages.debt.content.invoicesInTotal}`}
                    </p>
                </div>
                <Button
                    kind="outline"
                    className="debt-list-filter-btn"
                    icon={icoFilterDuotone}
                    onClick={() => setShowFilterModal(true)}
                >
                    {Locale.actions.filter}
                </Button>
            </div>
            <div className="filter-chips-group">
                {showableFilters().map((filter) => (
                    <div className="padding--t-sm padding--l-sm">
                        <Chip
                            key={filter.name || filter.value}
                            onClickCloseButton={() => removeFilter(filter.keys)}
                        >
                            <div>
                                {filter.name && <b>{`${filter.name}: `}</b>}
                                {filter.value}
                            </div>
                        </Chip>
                    </div>
                ))}
            </div>
            {!debtList.length ? (
                <EmptyDebtList
                    title={
                        isFilterEmpty
                            ? Locale.pages.debt.noDebt.nothingHere
                            : Locale.pages.debt.noDebt.filtered.nothingHere
                    }
                    body={
                        isFilterEmpty
                            ? Locale.pages.debt.noDebt.aircraftUpToDate
                            : Locale.pages.debt.noDebt.filtered.noResults
                    }
                    buttonText={
                        isFilterEmpty ? Locale.pages.debt.noDebt.goToHome : ''
                    }
                    onClickButton={() => navigate('/')}
                    icon={isFilterEmpty ? undefined : icoFilterNoResult}
                />
            ) : (
                <>
                    <div className="debt-list">
                        {debtList.map((debt) => (
                            <DebtCard
                                debt={new Debt(debt)}
                                key={`fatura-${debt?.noDocFaturamento}`}
                                kind="outlined"
                                showMenu={
                                    debt.boleto ||
                                    debt.notaFiscal ||
                                    (debt.dtVencimento && !debt.isLate)
                                }
                                openMenu={() => {
                                    setShowCardMenu(true);
                                    setDebtSelected(new Debt(debt));
                                }}
                                onClick={() => {
                                    searchParams.set('activeTab', 'debt');
                                    setSearchParams(searchParams);
                                    navigate(
                                        `/invoice/${debt.noDocFaturamento}`
                                    );
                                }}
                                isDebtActive={!debt.isMasked}
                            />
                        ))}
                    </div>
                    <DebtListFooter amount={totalAmount} />
                    <BottomSheet
                        show={showCardMenu}
                        onClose={() => setShowCardMenu(false)}
                    >
                        <div className="stack--lg">
                            <p className="padding--b-md color--grayscale-dark font-size--lg fw-semibold">
                                {Locale.pages.debt.content.selectOption}
                            </p>
                            {debtSelected.dtVencimento &&
                                !debtSelected?.isLate && (
                                    <BottomSheetPaymentOption
                                        icon={icoPixGray}
                                        label={
                                            Locale.pages.debt.content.payWithPix
                                        }
                                        alt="pix"
                                        onClick={() => {
                                            navigate(
                                                `/invoice/pix-payment/${debtSelected.noDocFaturamento}`
                                            );
                                        }}
                                    />
                                )}
                            {debtSelected.boleto && (
                                <BottomSheetPaymentOption
                                    icon={icoBarCodeGray}
                                    label={
                                        Locale.pages.debt.content
                                            .payWithBankSlip
                                    }
                                    alt="boleto"
                                    onClick={() => {
                                        Service.printSlip(
                                            debtSelected.noDocFaturamento,
                                            debtSelected.boleto
                                        );
                                        setShowCardMenu(false);
                                    }}
                                />
                            )}
                            {debtSelected.notaFiscal && (
                                <BottomSheetPaymentOption
                                    icon={icoDownload}
                                    label={
                                        Locale.pages.debt.content
                                            .downloadInvoice
                                    }
                                    alt="nota-fiscal"
                                    onClick={() => {
                                        Service.downloadInvoice(
                                            debtSelected.noDocFaturamento,
                                            debtSelected.notaFiscal
                                        );
                                        setShowCardMenu(false);
                                    }}
                                />
                            )}
                        </div>
                    </BottomSheet>
                </>
            )}
        </>
    );
}

DebtList.propTypes = {
    aircraftList: PropTypes.array.isRequired,
    airportList: PropTypes.array.isRequired,
};

DebtList.defaultProps = {};
