import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Reservation } from 'models';
import { ButtonIcon, Card, Image } from 'components';
import Locale from 'locale';

import { icoArrivalPrimary, icoKebabMenu } from 'assets/icons';

import {
    getReservationStatus,
    ReservationStatus as ReservationStatusEnum,
} from 'utils/enums';

import './styles.scss';

export default function ReservationCard({
    reservation,
    openMenu,
    setReservationSelected,
    orderingParam,
    showButtonIcon,
}) {
    const navigate = useNavigate();
    const navigateToDetail = () =>
        navigate(`/reservation/${reservation.id}`, {
            state: { orderReservationParam: orderingParam },
        });

    return (
        <Card
            kind="outlined"
            className="reservation"
            onClick={navigateToDetail}
        >
            <div className="stack--sm padding--t-sm">
                <div className="card-padding flex-justify--between">
                    <div className="inline--sm flex-items--center">
                        <div className="headline-3 color--grayscale-blue-gray">
                            {reservation.originFlight.airport.icaoCode} &gt;{' '}
                            <span className="color--brand-primary">
                                {reservation.airportTarget.icaoCode}
                            </span>
                            {reservation.destinationFlight && (
                                <>
                                    {' '}
                                    &gt;{' '}
                                    {
                                        reservation.destinationFlight.airport
                                            .icaoCode
                                    }
                                </>
                            )}
                        </div>
                    </div>
                    {showButtonIcon && (
                        <ButtonIcon
                            kind="no-border"
                            icon={icoKebabMenu}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (openMenu) {
                                    openMenu();
                                }
                                if (setReservationSelected) {
                                    setReservationSelected(reservation);
                                }
                            }}
                        />
                    )}
                </div>
                <div className="card-padding stack--xs">
                    <div className="overline-1 color--brand-primary flex-items--center">
                        <Image
                            className="flight-icons margin--r-sm"
                            src={icoArrivalPrimary}
                            alt="flight-icon"
                        />
                        {moment(reservation.originFlight.datetime).format(
                            'DD/MM/YYYY - HH:mm'
                        )}
                    </div>
                    <div className="subtitle-1 aircraft-name">
                        {reservation.aircraft.icaoModel} -{' '}
                        {reservation.aircraft.registration}
                    </div>
                </div>
                <ReservationStatus
                    status={reservation.status}
                    reservationId={reservation.id}
                />
            </div>
        </Card>
    );
}

ReservationCard.propTypes = {
    reservation: PropTypes.oneOfType([
        PropTypes.instanceOf(Reservation),
        PropTypes.objectOf(Reservation),
    ]).isRequired,
    openMenu: PropTypes.func,
    setReservationSelected: PropTypes.func,
    orderingParam: PropTypes.string,
    showButtonIcon: PropTypes.bool,
};

ReservationCard.defaultProps = {
    orderingParam: undefined,
    showButtonIcon: true,
    setReservationSelected: undefined,
    openMenu: undefined,
};

function ReservationStatus({ status, reservationId }) {
    const hasAction = status === ReservationStatusEnum.changeRequested;
    const title = Locale.status.title.current[getReservationStatus(status)];

    return (
        <div className="flex-items--center flex-justify--between background-color--grayscale-light-gray status-card padding--y-sm padding--x-md">
            <div className="inline--sm flex-items--center">
                <Image
                    className="flight-icons"
                    src={Reservation.getIconByStatus(status)}
                    alt={title}
                />
                <div
                    className={`text-transform--capitalize overline-2 ${
                        hasAction ? 'color--support-error' : ''
                    }`}
                >
                    {title}
                </div>
            </div>
            <div className="inline--sm color--grayscale-blue-gray fw-semibold">
                {reservationId && `Reserva #${reservationId}`}
            </div>
        </div>
    );
}

ReservationStatus.propTypes = {
    status: PropTypes.number.isRequired,
    reservationId: PropTypes.number,
};

ReservationStatus.defaultProps = {
    reservationId: undefined,
};
