import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    Card,
    HeaderPage,
    Button,
    HeaderInfo,
    Steps,
    Loading,
} from 'components';
import Locale from 'locale';
import Service from 'services';
import { MoreInfo } from 'features/backoffice';
import momentTZ from 'moment-timezone';
import { ReservationStatus, BackofficeSteps } from 'utils/enums';
import { icoPlane } from 'assets/icons';
import { ErrorHelper } from 'helpers';
import { Reservation } from 'models';
import { FormApprove, FormPricing, FormPayment, FormCompleted } from './Forms';

import './styles.scss';
import NegotiationHistory from './NegotiationHistory';

function Details() {
    const [reservation, setReservation] = useState({});
    const [loading, setLoading] = useState(true);
    const [aircraft, setAircraft] = useState();
    const [stepIndex, setStepIndex] = useState(1);
    const [stepStatus, setStepStatus] = useState('pending');
    const [statusList, setStatusList] = useState();
    const [initialStatus, setInitialStatus] = useState();
    const [statusTransitionResponsibles, setStatusTransitionResponsibles] =
        useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { reservationId } = useParams();

    const getLandingRequest = () => {
        setLoading(true);
        Service.getLandingRequest(reservationId)
            .then((response) => {
                const reservationResponse =
                    Reservation.createFromResponse(response);
                setReservation(reservationResponse);
                setAircraft(reservationResponse.aircraft);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    const getStatusTransitionResponsibles = (statusHistory) => {
        const approvalTransition = statusHistory.find(
            (statusTransition) =>
                statusTransition.statusId === ReservationStatus.pendingBilling
        );

        if (approvalTransition?.transitionedByUserId) {
            Service.getUser(approvalTransition.transitionedByUserId).then(
                (response) => {
                    setStatusTransitionResponsibles({
                        ...statusTransitionResponsibles,
                        approval: {
                            email: response.email,
                        },
                    });
                }
            );
        }
    };

    const getStatusHistory = () => {
        Service.getStatusHistory(reservationId)
            .then((response) => {
                const negotiationStatus = response.statusHistory.filter(
                    (item) =>
                        item.statusId === ReservationStatus.pendingReview ||
                        item.statusId === ReservationStatus.changeRequested
                );
                setInitialStatus(negotiationStatus.shift());
                setStatusList(negotiationStatus);
                getStatusTransitionResponsibles(response.statusHistory);
            })
            .catch((error) => ErrorHelper.notifyError(error));
    };

    const loadData = () => {
        getStatusHistory();
        getLandingRequest();
    };
    useEffect(() => {
        loadData();
    }, []);

    const steps = [
        {
            label: Locale.general.new.f,
            content: (
                <>
                    <FormApprove data={reservation} loadData={loadData} />

                    <NegotiationHistory
                        data={reservation}
                        statusList={statusList}
                    />
                </>
            ),
        },
        {
            label: Locale.pages.backoffice.details.pricing,
            content: <FormPricing data={reservation} />,
        },
        {
            label: Locale.pages.backoffice.details.payment,
            content: (
                <FormPayment data={reservation} updateData={setReservation} />
            ),
        },
        {
            label: Locale.pages.backoffice.details.reservationCompleted,
            content: (
                <FormCompleted
                    data={reservation}
                    statusTransitionResponsibles={statusTransitionResponsibles}
                />
            ),
        },
    ];

    const getDateTimeDescription = () => {
        const timeZone = reservation.airportTarget.timezone;
        const datetimeFormat = 'DD/MM/YY - HH:mm';
        const originDateTime = momentTZ(reservation.originFlight.datetime).tz(
            timeZone
        );

        const dateTimes = [];

        dateTimes.push({
            key: 'arrival',
            label: Locale.pages.backoffice.details.arrival,
            text: `${originDateTime.format(datetimeFormat)} | ${originDateTime
                .utc()
                .format(datetimeFormat)} UTC 0`,
        });

        if (reservation.destinationFlight?.datetime) {
            const destinationDateTime = momentTZ(
                reservation.destinationFlight.datetime
            ).tz(timeZone);
            dateTimes.push({
                key: 'departure',
                label: Locale.pages.backoffice.details.departure,
                text: `${destinationDateTime.format(
                    datetimeFormat
                )} | ${destinationDateTime.utc().format(datetimeFormat)} UTC 0`,
            });
        }
        return dateTimes;
    };

    useEffect(() => {
        if (!loading) {
            switch (reservation.status) {
                case ReservationStatus.pendingReview:
                case ReservationStatus.changeRequested:
                    setStepIndex(BackofficeSteps.new);
                    setStepStatus('success');
                    break;
                case ReservationStatus.pendingBilling:
                    setStepIndex(BackofficeSteps.pricing);
                    break;
                case ReservationStatus.pendingPayment:
                case ReservationStatus.processingPayment:
                    setStepIndex(BackofficeSteps.payment);
                    break;
                case ReservationStatus.paymentConfirmed:
                    setStepIndex(BackofficeSteps.payment);
                    setStepStatus('success');
                    break;
                case ReservationStatus.completed:
                    setStepStatus('success');
                    setStepIndex(BackofficeSteps.completed);
                    break;
                case ReservationStatus.cancelled:
                case ReservationStatus.denied:
                    setStepStatus('error');
                    setStepIndex(BackofficeSteps.new);
                    break;
                default:
                    setStepIndex(BackofficeSteps.new);
            }
        }
    });

    const handleGoTo = (target) => {
        document.getElementById(target)?.scrollIntoView({ behavior: 'smooth' });
    };

    return !loading ? (
        <div className="padding--lg scroll-margin-top" id="wrapperPage">
            <HeaderPage
                onClickBtnBack={() =>
                    navigate('/backoffice', { state: location.state })
                }
                labelBtnBack={`#${reservationId} ${aircraft.registration} ${aircraft.icaoModel}`}
                contentInfoFirst={
                    <Button
                        kind="outline"
                        onClick={() => handleGoTo('wrapperInfo')}
                        className="btn-more-info"
                    >
                        {Locale.actions.moreInformation}
                    </Button>
                }
            />
            <div className="wrapper-info-flight flex-justify--between width--full">
                {reservation.originFlight && (
                    <HeaderInfo
                        title={reservation.originFlight.airport.icaoName}
                        description={`(${reservation.originFlight.peopleOnBoard} ${Locale.fields.reservation.peopleOnBoard})`}
                        textIco={Locale.fields.reservation.origin}
                    />
                )}
                {reservation.airportTarget && (
                    <HeaderInfo
                        title={reservation.airportTarget.icaoName}
                        description={getDateTimeDescription()}
                        ico={icoPlane}
                        textIco={Locale.fields.destination}
                    />
                )}
                {reservation.destinationFlight && (
                    <HeaderInfo
                        title={reservation.destinationFlight.airport.icaoName}
                        description={`(${reservation.destinationFlight.peopleOnBoard} ${Locale.fields.reservation.peopleOnBoard})`}
                        textIco={Locale.fields.reservation.nextDestination}
                    />
                )}
            </div>
            <Card kind="outlined" className="margin--b-xl">
                <Steps
                    items={steps}
                    stepActive={stepIndex}
                    stepStatus={stepStatus}
                />
            </Card>
            <div id="wrapperInfo" className="scroll-margin-top wrapper-info">
                <MoreInfo
                    reservation={reservation}
                    aircraft={aircraft}
                    initialStatus={initialStatus}
                />
                <div className="row padding--t-md">
                    <div className="col-12 flex-justify--end">
                        <Button
                            kind="outline"
                            float
                            onClick={() => handleGoTo('wrapperPage')}
                        >
                            {Locale.actions.backToTheTop}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="full-screen flex-items--center flex-justify--center fixed-top">
            <Loading />
        </div>
    );
}

export default Details;
