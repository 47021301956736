import { HeaderPage, Button, StickyFooter } from 'components';
import { AircraftForm } from 'features/frontend';
import Locale from 'locale';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { scrollToTop } from 'utils/functions';
import { Aircraft, Reservation } from 'models';

export default function ReservationAircraft() {
    const navigate = useNavigate();
    const location = useLocation();
    const [formikValues, setFormikValues] = useState();

    useEffect(() => {
        scrollToTop();
    }, [location]);

    const onSubmit = (values) => {
        const aircraft = new Aircraft(values);
        const { reservation } = location.state;
        reservation.aircraft = aircraft;
        navigate('/newreservation/flightinfo', {
            state: {
                ...location.state,
                reservation,
                isInitReplication: false,
            },
        });
    };

    return (
        <div className="padding--lg stack--lg">
            <HeaderPage
                title={Locale.general.aircraft}
                labelBtnBack={Locale.pages.reservation.generalInfo}
                onClickBtnBack={() => {
                    const reservation = new Reservation({
                        ...location.state.reservation,
                        aircraft: formikValues,
                    });
                    navigate('/newreservation/generalinfo', {
                        state: {
                            ...location.state,
                            reservation,
                            isInitReplication: false,
                        },
                    });
                }}
            />
            <AircraftForm
                onSubmit={onSubmit}
                setFormikValues={setFormikValues}
                previousValues={
                    new Aircraft(location.state?.reservation?.aircraft)
                }
            >
                <StickyFooter>
                    <Button type="submit" kind="primary">
                        {Locale.actions.proceed}
                    </Button>
                </StickyFooter>
            </AircraftForm>
        </div>
    );
}
