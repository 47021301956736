import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { icoSearch, icoExpandDown, icoQuestion } from 'assets/icons';
import { outsideClick } from 'utils/functions';
import Locale from 'locale';

import { Label, Loading, Tooltip, Image } from '..';

import {
    Container,
    FormGroup,
    TextDisplay,
    TextError,
    TextInput,
    Before,
    ExpandIcon,
    ScrollableList,
    ListItem,
} from './styles';

function Item({ item, id, onClick }) {
    return (
        <ListItem
            type="button"
            id={id}
            onClick={onClick}
            value={item.value}
            disabled={item.disabled}
        >
            {item.label}
        </ListItem>
    );
}

function ScrollableOptionsList({
    options,
    id,
    onItemClick,
    showListOnTop,
    loadCallback,
    showInfiniteScroll,
}) {
    const scrollLoaderRef = useRef(null);
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entities) => {
            const entity = entities[0];
            if (entity.isIntersecting && showInfiniteScroll) {
                setTimeout(() => {
                    loadCallback();
                }, 500);
            }
        }, observerOptions);

        if (scrollLoaderRef.current) {
            observer.observe(scrollLoaderRef.current);
        }
    }, [showInfiniteScroll]);

    return (
        <ScrollableList showListOnTop={showListOnTop}>
            {options.map((item) => (
                <Item
                    key={`${id}-option-${item.value}`}
                    id={id}
                    onClick={onItemClick}
                    item={item}
                />
            ))}
            <div className="center-content" ref={scrollLoaderRef}>
                {showInfiniteScroll && (
                    <Loading size={20} className="margin--y-sm" />
                )}
            </div>
        </ScrollableList>
    );
}

function Dropdown({
    autocomplete,
    requestAutocomplete,
    id,
    label,
    name,
    value,
    options,
    required,
    disabled,
    error,
    before,
    onChange,
    onBlur,
    placeholder,
    showListOnTop,
    loading,
    tooltipContent,
    resetLoading,
    setResetLoading,
    loadCallback,
    showInfiniteScroll,
    minSearchLength,
    isSearchDropdown,
    ...args
}) {
    const [filled, setFilled] = useState(
        value !== '' && value.length >= minSearchLength
    );

    const [showOptions, setShowOptions] = useState(false);
    const [filteredValues, setFilteredValues] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState('');
    const [timeoutFilter, setTimeoutFilter] = useState();

    const inputItemRef = useRef(null);
    const containerRef = useRef(null);

    const getOptionById = (itemId) =>
        options.find((item) => item?.value?.toString() === itemId?.toString());

    const getOptionLabelById = (itemId) => getOptionById(itemId)?.label || '';

    useEffect(() => {
        setFilteredValues(options);
    }, [options]);

    const filterWithoutRequest = (textValue) => {
        setFilteredValues(
            options.filter((optionItem) =>
                optionItem.label
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(
                        textValue
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                    )
            )
        );
    };

    const filterWithRequest = (textValue) => {
        clearTimeout(timeoutFilter);
        setTimeoutFilter(setTimeout(() => requestAutocomplete(textValue), 500));
    };

    const handleFilterChange = (textValue) => {
        if (!textValue && textValue !== '') return;

        if (
            setResetLoading &&
            !(editText.includes(textValue) || textValue.includes(editText))
        ) {
            setResetLoading(true);
        }
        setEditText(textValue);

        if (textValue.length < minSearchLength) {
            setShowOptions(false);
            return;
        }

        if (requestAutocomplete) {
            filterWithRequest(textValue);
            setTimeout(() => setShowOptions(true), 600);
        } else {
            filterWithoutRequest(textValue);
        }
    };

    const handleFilterEventChange = (event) => {
        handleFilterChange(event.target.value);
    };

    const handleItemSelect = (e) => {
        const newSelectedValue = options.find(
            (item) => item?.value?.toString() === e.target.value
        );
        if (autocomplete) {
            inputItemRef.current.value = newSelectedValue?.label || '';
            if (!requestAutocomplete) {
                handleFilterChange(inputItemRef.current.value);
            }
        }
        setShowOptions(!showOptions);
        setIsEditing(false);
        onChange(e);
        setFilled(e.currentTarget.value !== '');
    };

    useEffect(() => {
        setFilled(value !== '');
    }, []);

    outsideClick(containerRef, () => {
        setShowOptions(false);
        setIsEditing(false);
    });

    const isEmptyList = !loading && (!options || options.length === 0);

    return (
        <Container ref={containerRef}>
            <div className="inline--sm">
                {label && (
                    <Label htmlFor={id} isRequired={required}>
                        {label}
                    </Label>
                )}
                {tooltipContent && (
                    <Tooltip content={tooltipContent}>
                        <Image src={icoQuestion} size={15} />
                    </Tooltip>
                )}
            </div>
            <FormGroup
                error={error}
                filled={filled}
                before={before}
                disabled={disabled}
                onClick={() => {
                    if (!disabled) {
                        setShowOptions(!showOptions);
                    }
                }}
            >
                {before && <Before>{before}</Before>}
                {autocomplete ? (
                    <TextInput
                        ref={inputItemRef}
                        name={name}
                        onFocus={() => {
                            if (!disabled) {
                                setIsEditing(true);
                                handleFilterChange('');
                            }
                        }}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={handleFilterEventChange}
                        value={isEditing ? editText : getOptionLabelById(value)}
                        type="text"
                        autoComplete="off"
                        {...args}
                    />
                ) : (
                    <TextDisplay name={name}>
                        {getOptionById(value)?.label === '' && !!placeholder ? (
                            <span className="color--grayscale-carbono-gray">
                                {placeholder}
                            </span>
                        ) : (
                            getOptionById(value)?.label
                        )}
                    </TextDisplay>
                )}
                {!loading ? (
                    <ExpandIcon
                        src={isSearchDropdown ? icoSearch : icoExpandDown}
                        alt=""
                    />
                ) : (
                    <Loading size={16} className="margin--x-md" />
                )}
            </FormGroup>
            {!resetLoading &&
                showOptions &&
                editText.length >= minSearchLength && (
                    <ScrollableOptionsList
                        id={id}
                        options={
                            !isEmptyList
                                ? filteredValues
                                : [
                                      {
                                          label: Locale.general.noResults,
                                          disabled: true,
                                      },
                                  ]
                        }
                        onItemClick={handleItemSelect}
                        showListOnTop={showListOnTop}
                        loadCallback={loadCallback}
                        showInfiniteScroll={showInfiniteScroll}
                    />
                )}
            {error && <TextError kind="overline-2">{error}</TextError>}
        </Container>
    );
}

Dropdown.propTypes = {
    autocomplete: PropTypes.bool,
    requestAutocomplete: PropTypes.func,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        })
    ).isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    before: PropTypes.node || PropTypes.element,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    showListOnTop: PropTypes.bool,
    loading: PropTypes.bool,
    tooltipContent: PropTypes.string,
    resetLoading: PropTypes.bool,
    setResetLoading: PropTypes.func,
    loadCallback: PropTypes.func,
    showInfiniteScroll: PropTypes.bool,
    minSearchLength: PropTypes.number,
    isSearchDropdown: PropTypes.bool,
};

Dropdown.defaultProps = {
    autocomplete: false,
    requestAutocomplete: undefined,
    value: '',
    label: undefined,
    required: false,
    disabled: false,
    error: '',
    before: undefined,
    onBlur: undefined,
    placeholder: Locale.fields.select,
    showListOnTop: false,
    loading: false,
    tooltipContent: '',
    resetLoading: false,
    setResetLoading: undefined,
    loadCallback: undefined,
    showInfiniteScroll: false,
    minSearchLength: 0,
    isSearchDropdown: false,
};

ScrollableOptionsList.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    id: PropTypes.string,
    onItemClick: PropTypes.func,
    showListOnTop: PropTypes.bool,
    loadCallback: PropTypes.func,
    showInfiniteScroll: PropTypes.bool,
};

ScrollableOptionsList.defaultProps = {
    id: '',
    onItemClick: undefined,
    showListOnTop: false,
    loadCallback: undefined,
    showInfiniteScroll: false,
};

Item.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
    }).isRequired,
    id: PropTypes.string,
    onClick: PropTypes.func,
};

Item.defaultProps = {
    id: '',
    onClick: undefined,
};

export default Dropdown;
