import styled, { keyframes } from 'styled-components';
import theme from '../../styles/themes/default';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinningCircle = styled.div`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border: 3px solid ${(props) => props.color};
    border-top: 3px solid ${theme.colors.grayscale.lightGray};
    border-radius: ${(props) => props.size * 2}px;
    animation: ${rotate} 1s linear infinite;
`;
