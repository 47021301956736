import PropTypes from 'prop-types';
import { Image } from 'components';
import './styles.scss';

export default function FutureServiceItem(props) {
    const { title, description, icon } = props;
    return (
        <div className="col-lg-4 col-md-4 col-sm-12 flex-items--stretch">
            <div className="future-service-item flex--row flex-items--center width--full">
                <Image src={icon} size={50} alt={icon} />
                <div>
                    <h4 className="color--grayscale-blue-gray fw-bold">
                        {title}
                    </h4>
                    <p className="color--grayscale-blue-gray">{description}</p>
                </div>
            </div>
        </div>
    );
}

FutureServiceItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
};

FutureServiceItem.defaultProps = {
    title: undefined,
    description: undefined,
    icon: undefined,
};
