import PropTypes from 'prop-types';
import './styles.scss';

function ColoredTag({ text, type, ...args }) {
    return (
        <div
            {...args}
            className={`colored-tag--${type} ${args.className ?? ''}`}
        >
            <p>{text}</p>
        </div>
    );
}

ColoredTag.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
};

ColoredTag.defaultProps = {
    type: 'default',
};

export default ColoredTag;
