import { Navigate, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AuthGuard({
    path,
    element,
    isUserAuthenticated,
    navigateTo,
    ...args
}) {
    const navigateDefault = path.includes('backoffice')
        ? '/backoffice/login'
        : '/login';

    return isUserAuthenticated === false ? (
        <Route
            path={path}
            {...args}
            element={<Navigate to={navigateTo || navigateDefault} />}
        />
    ) : (
        <Route path={path} {...args} element={element} />
    );
}

AuthGuard.propTypes = {
    path: PropTypes.string.isRequired,
    element: PropTypes.node.isRequired,
    isUserAuthenticated: PropTypes.bool,
    navigateTo: PropTypes.string,
};

AuthGuard.defaultProps = {
    isUserAuthenticated: true,
    navigateTo: '/',
};
