import Locale from 'locale';
import moment from 'moment';
import { TextInfo, Line, Image } from 'components';
import { useEffect, useState } from 'react';
import { MaskHelper } from 'helpers';
import Service from 'services';
import { PropTypes } from 'prop-types';
import { icoFile, icoImageFile, icoDownloadPrimary } from 'assets/icons';
import {
    getDocumentLabel,
    getPhoneWithCode,
    getAircraftNature,
} from 'utils/functions';
import './styles.scss';

function FileDisplay({ file, label, fileIcon }) {
    return (
        <div className="solicitation-file-wrapper">
            <div className="stack--md margin--b-md">
                <div className="stack--sm">
                    <p className="font-size--sm color--grayscale-steel-gray section-title">
                        {label}
                    </p>
                    {file ? (
                        <div className="inline--xxl flex-items--center">
                            <div className="inline--md flex-items--center">
                                <Image
                                    src={fileIcon}
                                    alt="alt-file"
                                    size={24}
                                />
                                <div className="flex--columns">
                                    <p className="font-size--md color--grayscale-dark">
                                        {file.name}
                                    </p>
                                    <p className="font-size--sm color--grayscale-dark">
                                        {file.filesize &&
                                            Math.ceil(
                                                file.filesize / 1024
                                            )}{' '}
                                        MB
                                    </p>
                                </div>
                            </div>
                            {file?.updatedAt && (
                                <p className="font-size--md color--grayscale-carbono-gray">
                                    {Locale.fields.file.sentIn
                                        .replace(
                                            '{1}',
                                            moment(file.updatedAt).format(
                                                'DD/MM/yyyy'
                                            )
                                        )
                                        .replace(
                                            '{2}',
                                            moment(file.updatedAt).format(
                                                'HH:mm'
                                            )
                                        )}
                                </p>
                            )}
                            <a
                                href={file.filepath}
                                target="_blank"
                                rel="noreferrer"
                                className="download-button"
                            >
                                <Image
                                    src={icoDownloadPrimary}
                                    alt="alt-download"
                                    size={24}
                                    className="margin--r-sm"
                                />
                                <p className="font-size--sm color--brand-primary">
                                    {Locale.fields.file.download}
                                </p>
                            </a>
                        </div>
                    ) : (
                        <p className="font-size--sm color--grayscale-dark">
                            {Locale.fields.file.noAttachedFile}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

FileDisplay.propTypes = {
    file: PropTypes.object,
    label: PropTypes.string.isRequired,
    fileIcon: PropTypes.string,
};

FileDisplay.defaultProps = {
    file: undefined,
    fileIcon: icoFile,
};

function SolicitationGeneralInfo({ aircraftSolicitation }) {
    const [requester, setRequester] = useState();
    const [aircraft] = useState(aircraftSolicitation?.aircraft);
    const getCustomerByUserId = () => {
        Service.getCustomerByUserId(
            aircraftSolicitation?.aircraft?.userId
        ).then((response) => setRequester(response));
    };

    useEffect(() => {
        if (aircraftSolicitation?.aircraft?.userId) {
            getCustomerByUserId();
        }
    }, [aircraftSolicitation]);

    const getLastUpdatedLabel = () => {
        const date = aircraftSolicitation?.updatedAt;
        const utcDate = moment.utc(date);

        const dayText = moment(date).format('DD/MM/yyyy');
        const hoursText = moment(date).format('HH:mm');
        const hoursTextUTC = moment(utcDate).format('HH:mm');

        return Locale.pages.backoffice.solicitations.details.lastUpdatedAt
            .replace('{1}', dayText)
            .replace('{2}', hoursText)
            .replace('{3}', hoursTextUTC);
    };

    return (
        <div className="stack--lg">
            <h4 className="headline-4 color--grayscale-steel-gray text-transform--uppercase margin--b-xl">
                {Locale.actions.moreInformation}
            </h4>
            {requester && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark">
                        {Locale.fields.userLabel}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.fullName}
                                value={requester.name}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={getDocumentLabel(
                                    requester.isForeigner,
                                    requester.isCompany
                                )}
                                value={requester.document}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.phone}
                                value={getPhoneWithCode(requester)}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.email}
                                value={requester.email}
                            />
                        </div>
                    </div>
                    <Line />
                </div>
            )}
            {aircraft && (
                <div className="stack--md">
                    <div>
                        <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                            {Locale.general.aircraft}
                        </h4>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={Locale.fields.aircraft.registration}
                                    value={aircraft.registration}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={Locale.fields.aircraft.icao}
                                    value={aircraft.icaoModel}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={Locale.fields.aircraft.mtow}
                                    value={`${aircraft.mtow} kg`}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={
                                        Locale.fields.aircraft.solicitation
                                            .nature
                                    }
                                    value={getAircraftNature(
                                        aircraft?.isForeign,
                                        aircraft?.isMilitary
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={Locale.fields.aircraft.wingspan}
                                    value={`${aircraft.wingspan} m`}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextInfo
                                    label={Locale.fields.aircraft.planeLength}
                                    value={`${aircraft.length} m`}
                                />
                            </div>
                        </div>
                    </div>
                    <FileDisplay
                        file={aircraftSolicitation?.document}
                        label={Locale.fields.file.airworthnessCertification}
                    />
                    <FileDisplay
                        file={aircraftSolicitation?.image}
                        label={Locale.fields.file.aircraftImage}
                        fileIcon={icoImageFile}
                    />
                    <Line />
                </div>
            )}
            {aircraft?.operator && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.fields.billingOperator}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.profile}
                                value={
                                    aircraft.operator.isCompany
                                        ? Locale.fields.legalPerson
                                        : Locale.fields.naturalPerson
                                }
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.fullName}
                                value={
                                    aircraft.operator.name ||
                                    aircraft.operator.corporateName
                                }
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={getDocumentLabel(
                                    aircraft.operator.isForeigner,
                                    aircraft.operator.isCompany
                                )}
                                value={MaskHelper.document(
                                    aircraft.operator.document,
                                    aircraft.operator.isForeign,
                                    aircraft.operator.isCompany
                                )}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.phone}
                                value={getPhoneWithCode(aircraft.operator)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.email}
                                value={aircraft.operator.email}
                            />
                        </div>
                        {aircraft.operator.secondEmail && (
                            <div className="col-12 col-md-3 break-word">
                                <TextInfo
                                    label={Locale.fields.email}
                                    value={aircraft.operator.secondEmail}
                                />
                            </div>
                        )}
                        {aircraft.operator.thirdEmail && (
                            <div className="col-12 col-md-3 break-word">
                                <TextInfo
                                    label={Locale.fields.email}
                                    value={aircraft.operator.thirdEmail}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.countryRegion}
                                value={aircraft.operator.address?.country?.name}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.stateTerritory}
                                value={aircraft.operator.address?.state?.name}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.city}
                                value={aircraft.operator?.address?.city}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.cepZipcode}
                                value={aircraft.operator?.address?.postalCode}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.streetAddress}
                                value={aircraft.operator?.address?.street}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.number}
                                value={aircraft.operator?.address?.number}
                            />
                        </div>
                        {aircraft.operator.address?.complement && (
                            <div className="col-12 col-md-3 break-word">
                                <TextInfo
                                    label={Locale.fields.complement}
                                    value={
                                        aircraft.operator?.address?.complement
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {aircraftSolicitation?.updatedAt && (
                <p className="color--grayscale-blue-gray font-size--md">
                    {getLastUpdatedLabel()}
                </p>
            )}
        </div>
    );
}

SolicitationGeneralInfo.propTypes = {
    aircraftSolicitation: PropTypes.object.isRequired,
};

export default SolicitationGeneralInfo;
