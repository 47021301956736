import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { TextInfo } from 'components';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import { Reservation, Billing } from 'models';
import { getDocumentLabel, getPhoneWithCode } from 'utils/functions';
import { getFlightType, ReservationStatus } from 'utils/enums';
import moment from 'moment';

function MoreInfo({ reservation, aircraft, initialStatus }) {
    const [requester, setRequester] = useState();
    const [commander, setCommander] = useState();
    const [billingData, setBillingData] = useState();

    const getBillingData = () => {
        Service.getBillingData(reservation.id)
            .then((response) => {
                const billingDataResponse =
                    Billing.createFromResponse(response);
                setBillingData(billingDataResponse);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const getCustomerByUserId = () => {
        Service.getCustomerByUserId(reservation.idRequester)
            .then((response) => setRequester(response))
            .catch((error) => ErrorHelper.notifyError(error));
    };

    useEffect(() => {
        if (reservation.idRequester) {
            getCustomerByUserId();
        }

        if (reservation.commander) {
            setCommander(reservation.commander);
        }
        // TODO: tirar condicional isForeign após simplificação de correntista
        if (
            !aircraft.isForeign ||
            reservation.status > ReservationStatus.pendingBilling
        ) {
            getBillingData();
        }
    }, []);

    const getTransportSupport = (boarding, deboarding) => {
        if (boarding && deboarding) {
            return `${Locale.fields.boarding}/${Locale.fields.deboarding}`;
        }
        if (boarding) {
            return Locale.fields.boarding;
        }
        if (deboarding) {
            return Locale.fields.deboarding;
        }
        return Locale.general.no;
    };

    const getFlightTypeLabel = () => {
        const flightTypeKey = getFlightType(reservation.flightType);
        return Locale.flightTypes[flightTypeKey];
    };

    return (
        <div className="stack--lg">
            <h4 className="headline-4 color--grayscale-steel-gray text-transform--uppercase margin--b-xl">
                {Locale.actions.moreInformation}
            </h4>
            {requester && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark">
                        {Locale.pages.summary.requester}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.fullName}
                                value={requester.name}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={getDocumentLabel(
                                    requester.isForeigner,
                                    requester.isCompany
                                )}
                                value={requester.document}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.phone}
                                value={getPhoneWithCode(requester)}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.email}
                                value={requester.email}
                            />
                        </div>
                    </div>
                </div>
            )}
            {initialStatus?.details && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.pages.backoffice.details.requesterObservation}
                    </h4>
                    <div className="row white-space--pre-line">
                        <TextInfo value={initialStatus?.details?.observation} />
                    </div>
                </div>
            )}
            {aircraft && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.general.aircraft}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.title}
                                value={
                                    aircraft.isForeign
                                        ? Locale.fields.aircraft.foreign
                                        : Locale.fields.aircraft.national
                                }
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.registration}
                                value={aircraft.registration}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.icao}
                                value={aircraft.icaoModel}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.mtow}
                                value={`${aircraft.mtow} kg`}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.wingspan}
                                value={`${aircraft.wingspan} m`}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.aircraft.planeLength}
                                value={`${aircraft.length} m`}
                            />
                        </div>
                    </div>
                </div>
            )}
            {aircraft?.operator && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.pages.summary.operator}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.fullName}
                                value={
                                    aircraft.operator.name ||
                                    aircraft.operator.corporateName
                                }
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={getDocumentLabel(
                                    aircraft.operator.isForeigner,
                                    aircraft.operator.isCompany
                                )}
                                value={aircraft.operator.document}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.phone}
                                value={getPhoneWithCode(aircraft.operator)}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.email}
                                value={aircraft.operator.email}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div>
                <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                    {Locale.fields.commander}
                </h4>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextInfo
                            label={Locale.fields.pilot.name}
                            value={commander?.name || Locale.fields.notInformed}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <TextInfo
                            label={Locale.fields.phone}
                            value={
                                commander
                                    ? getPhoneWithCode(commander)
                                    : Locale.fields.notInformed
                            }
                        />
                    </div>
                </div>
            </div>
            {billingData?.name && (
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.pages.backoffice.details.billing}
                    </h4>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.operator.name}
                                value={billingData.name}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={getDocumentLabel(
                                    billingData.isForeigner,
                                    billingData.isCompany
                                )}
                                value={billingData.document}
                            />
                        </div>

                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.phone}
                                value={getPhoneWithCode(billingData)}
                            />
                        </div>
                        <div className="col-12 col-md-3 break-word">
                            <TextInfo
                                label={Locale.fields.email}
                                value={billingData.email}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.cep}
                                value={billingData.postalCode}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.address}
                                value={billingData.address}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.numberComplement}
                                value={billingData.complement || '-'}
                            />
                        </div>
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={`${Locale.fields.city}/${Locale.fields.state}`}
                                value={`${billingData.city} - ${billingData.state.name}`}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div>
                <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                    {Locale.pages.backoffice.details.flightInformation}
                </h4>
                <div className="row">
                    {reservation.flightType !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.typeOfFlight}
                                value={getFlightTypeLabel()}
                            />
                        </div>
                    )}
                    {reservation.isNationalizationFlight !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.nationalizationFlight}
                                value={
                                    reservation.isNationalizationFlight
                                        ? Locale.general.yes
                                        : Locale.general.no
                                }
                            />
                        </div>
                    )}
                    {reservation.isAlternativeReserve !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.reservation.alternative}
                                value={
                                    reservation.isAlternativeReserve
                                        ? Locale.general.yes
                                        : Locale.general.no
                                }
                            />
                        </div>
                    )}
                    {reservation.hasTrailerFork !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.reservation.trailerFork}
                                value={
                                    reservation.hasTrailerFork
                                        ? Locale.general.yes
                                        : Locale.general.no
                                }
                            />
                        </div>
                    )}
                    {(reservation.hasEmbarkationTransport !== null ||
                        reservation.hasDisembarkationTransport !== null) && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={
                                    Locale.fields.reservation
                                        .transportationSupport
                                }
                                value={getTransportSupport(
                                    reservation.hasEmbarkationTransport,
                                    reservation.hasDisembarkationTransport
                                )}
                            />
                        </div>
                    )}
                    {reservation.handlingCompany !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={Locale.fields.handling}
                                value={reservation.handlingCompany}
                            />
                        </div>
                    )}
                    {reservation.hasPatioReservation !== null && (
                        <div className="col-12 col-md-3">
                            <TextInfo
                                label={
                                    Locale.fields.reservation.patioReservation
                                }
                                value={
                                    reservation.hasPatioReservation
                                        ? Locale.general.yes
                                        : Locale.general.no
                                }
                            />
                        </div>
                    )}
                </div>
                {reservation.createdAt !== null && (
                    <div className="row padding--t-md">
                        <div className="col-12">
                            <TextInfo
                                value={`${
                                    Locale.pages.backoffice.details
                                        .reservationSentIn
                                } ${moment(reservation.createdAt).format(
                                    `DD MMM, YYYY [${Locale.general.at}] H:mm`
                                )}.`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

MoreInfo.propTypes = {
    reservation: PropTypes.instanceOf(Reservation).isRequired,
    aircraft: PropTypes.object.isRequired,
    initialStatus: PropTypes.object,
};

MoreInfo.defaultProps = {
    initialStatus: undefined,
};

export default MoreInfo;
