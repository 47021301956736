import styled from 'styled-components';

export const Container = styled.img`
    ${(props) =>
        props.size &&
        `
        width: ${props.size}px;
        height: ${props.size}px;
    `}
`;
