import styled from 'styled-components';

export const Container = styled.section`
    padding: 0.5rem 1.375rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    * {
        opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    }

    p {
        ${(props) =>
            props.selected &&
            `
            color: ${props.theme.colors.grayscale.white}
        `};
    }

    label {
        ${(props) =>
            props.selected &&
            `
            color: ${props.theme.colors.brand.primary25}
        `};
    }
    .left-content {
        display: flex;
    }

    :hover {
        ${(props) =>
            !props.disabled &&
            !props.selected &&
            `
            background-color: ${props.theme.colors.grayscale.light}
        `};
    }

    ${(props) =>
        props.selected &&
        `
        background-color: ${props.theme.colors.brand.primaryLight}
    `};

    span {
        color: ${(props) => props.theme.colors.brand.primary};
    }
`;

export const Before = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-right: ${(props) => props.theme.spacing.sm};
    ${(props) =>
        props.avatar &&
        `
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid ${props.theme.colors.grayscale.light}
    `};
    ${(props) =>
        props.avatar &&
        props.selected &&
        `
        background-color: ${props.theme.colors.grayscale.white}
    `};
    ${(props) =>
        !props.avatar &&
        props.selected &&
        `
        filter: brightness(50)
    `};
`;

export const After = styled.div`
    width: fit-content;
    margin-left: ${(props) => props.theme.spacing.sm};
    display: flex;
    align-items: center;
    ${(props) =>
        props.selected &&
        `
        filter: brightness(50)
    `};
`;
