import { icoSearch, icoFilterNoResult } from 'assets/icons';
import {
    Button,
    Image,
    Input,
    LoadingFullPage,
    StickyFooter,
} from 'components';
import Locale from 'locale';
import { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import EmptyAircraftList from '../EmptyAircraftList';
import AircraftCard from '../AircraftCard';

export default function FilledAircraftList({
    searchFunc,
    initialFilters,
    aircraftList,
    isFetchingData,
    totalAircraftCount,
    onClickItem,
    onButtonClick,
}) {
    const [searchRegistration, setSearchRegistration] = useState(
        initialFilters.registration ?? ''
    );
    const [shouldFetchData, setShouldFetchData] = useState(true);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchFunc({ registration: searchRegistration });
            setShouldFetchData(false);
        } else {
            setShouldFetchData(true);
        }
    };

    return (
        <>
            <div>
                <Input
                    name="search-aircraft"
                    placeholder={Locale.pages.aircraftList.searchRegistration}
                    value={searchRegistration}
                    onChange={(e) =>
                        setSearchRegistration(e.currentTarget.value)
                    }
                    onKeyDown={handleKeyDown}
                    onBlur={() =>
                        shouldFetchData &&
                        searchFunc({ registration: searchRegistration })
                    }
                    after={
                        <Image
                            src={icoSearch}
                            alt={Locale.pages.aircraftList.searchRegistration}
                        />
                    }
                    className="my-3"
                />
                <span className="total-aircraft">
                    {totalAircraftCount < 10
                        ? `0${totalAircraftCount}`
                        : totalAircraftCount}{' '}
                    {Locale.pages.aircraftList.aircraftTotal}
                </span>
            </div>
            {isFetchingData ? (
                <LoadingFullPage />
            ) : aircraftList.length < 1 ? (
                <EmptyAircraftList
                    icon={icoFilterNoResult}
                    title={Locale.pages.aircraftList.empty.noResults}
                    body={Locale.pages.aircraftList.empty.redoSearch}
                    onButtonClick={onButtonClick}
                />
            ) : (
                <>
                    <div className="aircraft-list">
                        {aircraftList.map((aircraft) => (
                            <AircraftCard
                                aircraft={aircraft}
                                key={`aircraft-${aircraft.id}`}
                                onClick={() => onClickItem(aircraft)}
                            />
                        ))}
                    </div>
                    <StickyFooter>
                        <div className="aircraft-list-footer">
                            <Button kind="primary" onClick={onButtonClick}>
                                {Locale.pages.aircraftList.registerNew}
                            </Button>
                        </div>
                    </StickyFooter>
                </>
            )}
        </>
    );
}

FilledAircraftList.propTypes = {
    searchFunc: PropTypes.func,
    initialFilters: PropTypes.object,
    aircraftList: PropTypes.array.isRequired,
    isFetchingData: PropTypes.bool,
    totalAircraftCount: PropTypes.number.isRequired,
    onClickItem: PropTypes.func,
    onButtonClick: PropTypes.func,
};

FilledAircraftList.defaultProps = {
    searchFunc: () => {},
    initialFilters: {},
    isFetchingData: true,
    onClickItem: () => {},
    onButtonClick: () => {},
};
