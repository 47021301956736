import { Checkbox } from 'components';
import { default as ReactSelect, components } from 'react-select';
import PropTypes from 'prop-types';
import Locale from 'locale';
import './styles.scss';

function CustomOption({ isSelected, label, ...args }) {
    return (
        <div>
            <components.Option {...args}>
                <Checkbox
                    id={label}
                    name={label}
                    checked={isSelected}
                    label={label}
                    onChange={() => {}}
                />
            </components.Option>
        </div>
    );
}

CustomOption.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

export default function Multiselect({
    options,
    onChange,
    value,
    onInputChange,
    ...args
}) {
    return (
        <ReactSelect
            isMulti
            options={options}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: CustomOption }}
            onChange={onChange}
            allowSelectAll
            value={value}
            placeholder={Locale.fields.select}
            onInputChange={onInputChange}
            className="custom-multiselect"
            classNamePrefix="custom-multiselect"
            {...args}
        />
    );
}

Multiselect.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    onInputChange: PropTypes.func,
};

Multiselect.defaultProps = {
    onInputChange: undefined,
};
