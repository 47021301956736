import * as Yup from 'yup';
import Locale from 'locale';
import { joinDateTime } from 'utils/functions';
import Airport from './Airport';
import BaseModel from './BaseModel';

class Flight extends BaseModel {
    constructor(data) {
        super();

        this.airport = Airport.getValueOrNew(data.airport);
        this.datetime = Flight.getValueOrNew(
            joinDateTime(data.datetime, data.hour),
            Date
        );
        this.hour = Flight.getValueOrNew(data.hour || data.datetime, Date);
        this.peopleOnBoard = data.peopleOnBoard || 0;
    }

    static createEmpty(isCreatingAirport) {
        return new Flight({
            airport: Airport.createEmpty(!!isCreatingAirport),
            datetime: '',
            hour: '',
        });
    }

    static get validationSchema() {
        return Yup.object().shape({
            airport: Airport.validationSchema,
            peopleOnBoard: Yup.number()
                .min(0, `${Locale.fields.minValue} 0`)
                .max(99, `${Locale.fields.maxValue} 99`)
                .required(Locale.errors.required),
            datetime: Yup.string().required(Locale.errors.required),
            hour: Yup.string().required(Locale.errors.required),
        });
    }

    parseToRequest() {
        return {
            ...this,
            airport: {
                ...(this.airport.id
                    ? { id: this.airport.id }
                    : {
                          ...this.airport,
                          state: this.airport.state.parseToRequest(),
                          country: this.airport.country.parseToRequest(),
                      }),
            },
        };
    }
}

export default Flight;
