import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorHelper, MaskHelper } from 'helpers';
import Service from 'services';
import Locale from 'locale';
import './styles.scss';
import {
    Input,
    PhoneNumberInput,
    Button,
    Card,
    Text,
    Checkbox,
    Dropdown,
    ContainerForm,
    HeaderPage,
    StickyFooter,
    TextSwitch,
} from 'components';
import { getDocumentLabel } from 'utils/functions';
import { BR_PHONE_CODE } from 'utils/constants';
import { useCountryPhoneOptions } from 'states';

function UserRegister() {
    const [isLoading, setIsLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState({});
    const navigate = useNavigate();

    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const values = {
        name: '',
        isCompany: false,
        isForeigner: false,
        document: '',
        countryPhoneCode: BR_PHONE_CODE,
        phone: '',
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        agreed: false,
    };

    const nameMax = 100;
    const passwordMin = 8;

    const RegisterSchema = Yup.object().shape({
        name: Yup.string()
            .max(nameMax, Locale.errors.maxLength(nameMax))
            .required(Locale.errors.required),
        isCompany: Yup.bool(),
        isForeigner: Yup.bool(),
        document: Yup.string().required(Locale.errors.required),
        countryPhoneCode: Yup.string().required(Locale.errors.required),
        phone: Yup.string().required(Locale.errors.required),
        email: Yup.string()
            .email(Locale.errors.validEmail)
            .required(Locale.errors.required),
        emailConfirmation: Yup.string()
            .oneOf([Yup.ref('email'), null], Locale.errors.emailsMatch)
            .required(Locale.errors.required),
        password: Yup.string()
            .min(passwordMin, Locale.errors.minLength(passwordMin))
            .matches(/[\D]/, Locale.pages.register.user.numericPassword)
            .required(Locale.errors.required),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], Locale.errors.passwordsMatch)
            .required(Locale.errors.required),
        agreed: Yup.bool().required(Locale.errors.required),
    });

    const handleGoBack = () => {
        navigate('/login');
    };

    const handleSubmit = (data) => {
        setIsLoading(true);
        Service.registerUser(data)
            .then(() => {
                navigate('/');
                setIsLoading(false);
            })
            .catch((error) => {
                setServerErrors(error.response?.data);
                setIsLoading(false);
                if (error.response?.status === 400) {
                    setServerErrors(error.response?.data);
                } else {
                    ErrorHelper.notifyError(error);
                }
            });
    };

    const formik = useFormik({
        initialValues: values,
        validationSchema: RegisterSchema,
        onSubmit: (data) => {
            handleSubmit(data);
        },
    });

    useEffect(() => {
        formik.setErrors({ ...formik.errors, ...serverErrors });
    }, [serverErrors]);

    const setDocument = (e) => {
        if (formik.values.isForeigner) {
            if (formik.values.isCompany) {
                formik.setFieldValue(
                    'document',
                    e.target.value.replace(/\D/g, '').slice(0, 15)
                );
            } else {
                formik.setFieldValue(
                    'document',
                    e.target.value.replace(/\D/g, '').slice(0, 9)
                );
            }
        } else if (formik.values.isCompany) {
            formik.setFieldValue(
                'document',
                e.target.value.replace(/\D/g, '').slice(0, 14)
            );
        } else {
            formik.setFieldValue(
                'document',
                e.target.value.replace(/\D/g, '').slice(0, 11)
            );
        }
    };

    return (
        <div className="padding--lg">
            <HeaderPage
                title={Locale.pages.register.user.title}
                labelBtnBack={Locale.general.back}
                onClickBtnBack={handleGoBack}
            />
            <form onSubmit={formik.handleSubmit} className="padding--b-xxl">
                <ContainerForm>
                    <div className="row">
                        <div className="col-md-3">
                            <TextSwitch
                                label={Locale.fields.isCompany}
                                id="isCompany"
                                name="isCompany"
                                onChange={(e) => {
                                    formik.setFieldValue('isCompany', e);
                                    formik.setFieldValue('document', '');
                                }}
                                checked={formik.values.isCompany}
                                error={
                                    formik.touched.isCompany &&
                                    formik.errors.isCompany
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData}
                            />
                        </div>
                        <div className="col-md-3">
                            <TextSwitch
                                label={Locale.fields.isForeigner}
                                id="isForeigner"
                                name="isForeigner"
                                onChange={(e) => {
                                    formik.setFieldValue('isForeigner', e);
                                    formik.setFieldValue('document', '');
                                }}
                                checked={formik.values.isForeigner}
                                error={
                                    formik.touched.isForeigner &&
                                    formik.errors.isForeigner
                                }
                                onBlur={formik.handleBlur}
                                disabled={formik.values.useData}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                label={
                                    formik.values.isCompany
                                        ? Locale.fields.corporateName
                                        : Locale.fields.fullName
                                }
                                error={
                                    formik.touched.name && formik.errors.name
                                }
                                required
                            />
                        </div>
                        <div className="col-md-4">
                            <Input
                                id="document"
                                name="document"
                                type="text"
                                onChange={(e) => setDocument(e)}
                                onBlur={formik.handleBlur}
                                value={MaskHelper.document(
                                    formik.values.document,
                                    formik.values.isForeigner,
                                    formik.values.isCompany
                                )}
                                label={getDocumentLabel(
                                    formik.values.isForeigner,
                                    formik.values.isCompany
                                )}
                                error={
                                    formik.touched.document &&
                                    formik.errors.document
                                }
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <Dropdown
                                id="countryPhoneCode"
                                name="countryPhoneCode"
                                options={countriesPhones}
                                label={Locale.fields.countryCode}
                                onChange={formik.handleChange}
                                value={formik.values.countryPhoneCode}
                                error={
                                    formik.touched?.countryPhoneCode &&
                                    formik.errors?.countryPhoneCode
                                }
                                onBlur={formik.handleBlur}
                                required
                                autocomplete
                                loading={loadingCountriesPhones}
                            />
                        </div>
                        <div className="col-8 col-md-4">
                            <PhoneNumberInput
                                id="phone"
                                name="phone"
                                countryPhoneCode={
                                    formik.values.countryPhoneCode
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                label={Locale.fields.phone}
                                error={
                                    formik.touched.phone &&
                                    (formik.errors.phone ||
                                        formik.errors.phoneNumber)
                                }
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                label={Locale.fields.email}
                                error={
                                    formik.touched.email && formik.errors.email
                                }
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                id="emailConfirmation"
                                name="emailConfirmation"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.emailConfirmation}
                                label={Locale.fields.user.confirmEmail}
                                error={
                                    formik.touched.emailConfirmation &&
                                    formik.errors.emailConfirmation
                                }
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                label={Locale.fields.user.password}
                                error={
                                    formik.touched.password &&
                                    formik.errors.password
                                }
                                required
                            />
                            <Text
                                kind="overline-2"
                                className="margin--t-sm margin--x-md"
                            >
                                {/* TODO MAG-495 adicionar na string abaixo */}
                                <p>
                                    {Locale.pages.register.user.commonPassword}
                                </p>
                                <p>
                                    {Locale.pages.register.user.lengthPassword}
                                </p>
                                <p>
                                    {Locale.pages.register.user.numericPassword}
                                </p>
                            </Text>
                        </div>
                        <div className="col-md-6">
                            <Input
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordConfirmation}
                                label={Locale.fields.user.confirmPassword}
                                error={
                                    formik.touched.passwordConfirmation &&
                                    formik.errors.passwordConfirmation
                                }
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Card
                                isRounded
                                className="padding--b-md margin--y-xl"
                                kind="secondary"
                            >
                                <div>
                                    <Text kind="overline-2">
                                        {
                                            Locale.pages.register.user
                                                .privacyTerms
                                        }
                                    </Text>
                                    <br />
                                    <Checkbox
                                        id="agreed"
                                        name="agreed"
                                        label={Locale.fields.user.agree}
                                        checked={formik.values.agreed}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContainerForm>
                <StickyFooter>
                    <Button
                        type="button"
                        kind="outline"
                        className="d-none d-sm-block"
                        onClick={() => handleGoBack()}
                    >
                        {Locale.actions.back}
                    </Button>
                    <Button
                        className={isLoading && 'register-submit-loading'}
                        kind="primary"
                        type="submit"
                        disabled={
                            !formik.values.agreed ||
                            Object.keys(formik.errors).length !== 0
                        }
                        isLoading={isLoading}
                    >
                        <p>{Locale.actions.proceed}</p>
                    </Button>
                </StickyFooter>
            </form>
        </div>
    );
}

export default UserRegister;
