import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../styles/themes/default';
import { SpinningCircle } from './styles';

function Loading({ color, size, ...args }) {
    return <SpinningCircle color={color} size={size} {...args} />;
}

Loading.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

Loading.defaultProps = {
    color: theme.colors.brand.primaryLight,
    size: 32,
};

export default Loading;
