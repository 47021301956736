import PropTypes from 'prop-types';

import { ButtonIcon } from 'components';

import { icoArrowLeft, icoKebabMenu } from 'assets/icons';

import Popover from 'components/Popover';
import { useState } from 'react';
import styles from './styles.module.scss';

function HeaderPage({
    title,
    description,
    contentInfo,
    labelBtnBack,
    onClickBtnBack,
    contentInfoFirst,
    optionsMenu,
    ...args
}) {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            {optionsMenu && (
                <Popover
                    show={showMenu}
                    options={optionsMenu}
                    closeMenu={() => setShowMenu(false)}
                />
            )}
            <div className={styles.container} {...args}>
                <div className="flex-justify--between">
                    {labelBtnBack && (
                        <div
                            className="flex-items--center margin--b-md cursor--pointer"
                            onClick={() => onClickBtnBack()}
                        >
                            <ButtonIcon icon={icoArrowLeft} kind="no-border" />
                            <h4 className="color--grayscale-steel-gray margin--l-xs">
                                {labelBtnBack}
                            </h4>
                        </div>
                    )}
                    {contentInfoFirst}
                </div>
                <div className="flex-justify--between flex-items--center margin--b-md">
                    <h1 className="headline-1">{title}</h1>
                    {contentInfo}
                    {optionsMenu && (
                        <ButtonIcon
                            kind="no-border"
                            icon={icoKebabMenu}
                            onClick={() => setShowMenu(true)}
                        />
                    )}
                </div>
                <p
                    className={`subtitle-1 color--grayscale-carbono-gray ${styles.description}`}
                >
                    {description}
                </p>
            </div>
        </>
    );
}

HeaderPage.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    contentInfo: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    contentInfoFirst: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    labelBtnBack: PropTypes.string,
    onClickBtnBack: PropTypes.func,
    optionsMenu: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            onClick: PropTypes.func.isRequired,
        })
    ),
};

HeaderPage.defaultProps = {
    title: '',
    description: '',
    contentInfo: undefined,
    contentInfoFirst: undefined,
    labelBtnBack: '',
    onClickBtnBack: undefined,
    optionsMenu: undefined,
};

export default HeaderPage;
