import { CardDirect } from 'components';
import {
    icoPhoneDuotoneSecondary,
    icoWalletDuotoneSecondary,
    icoChatDuotoneSecondary,
} from 'assets/icons';
import './styles.scss';
import Locale from 'locale';

export default function HelpSection() {
    return (
        <div className="help-section-container">
            <div className="text-container">
                <h3 className="title">{Locale.pages.home.help.needHelp}</h3>
                <p>{Locale.pages.home.help.needHelpDescription}</p>
            </div>
            <div className="row">
                <CardDirect
                    icon={icoChatDuotoneSecondary}
                    title={Locale.pages.home.help.ombudsman.title}
                    description={Locale.pages.home.help.ombudsman.content}
                    href="https://ccraeroportos.omd.com.br/ccraeroportos/externo/cadastro.do"
                    target="_blank"
                />
                <CardDirect
                    icon={icoWalletDuotoneSecondary}
                    title={Locale.pages.home.help.financial.title}
                    description={Locale.pages.home.help.financial.content}
                    href="https://ccraeroportos.omd.com.br/ccrfinanceiro/externo/cadastro.do"
                    target="_blank"
                />
                <CardDirect
                    icon={icoPhoneDuotoneSecondary}
                    title={Locale.pages.home.help.contactUs.title}
                    description={Locale.pages.home.help.contactUs.content}
                    href="tel:08007274720"
                />
            </div>
            {/* <Line className="line color--grayscale-light-gray" /> */}
        </div>
    );
}
