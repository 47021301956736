import { get } from './axios';

const RegionsService = {
    getCountries(params) {
        return get(`country/`, { params });
    },

    getStates(params) {
        return get(`state/`, { params });
    },

    getCountriesPhones() {
        return get(`countries_phones/`);
    },
};

export default RegionsService;
