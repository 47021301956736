import moment from 'moment';

const FilterHelper = {
    parseDateField(originalValue) {
        if (originalValue) {
            return moment(originalValue).format('YYYY-MM-DD');
        }
        return null;
    },

    stringifyArray(array, field, separator = ',') {
        const values = array.map((object) => object[field]);
        return values.join(separator);
    },

    /**
     * Reduces an object to a string based on the value of each key.
     * @param  {Object} object Object with values as booleans.
     * @param  {Function} parseKeyValue Function to parse the value that's inserted in the stringified result.
     * @param  {String} separator Whats separating the string.
     * @return {String} Stringified value, as in "1<separator>2<separator>3", for example "1,2,3".
     */
    stringifyObjectOfBooleans(object, parseValue, separator = ',') {
        const values = Object.entries(object).reduce((final, [key, value]) => {
            if (value) {
                final.push(parseValue(key, value));
            }
            return final;
        }, []);
        return values.join(separator);
    },
};

export default FilterHelper;
