import React from 'react';
import './styles.scss';
import { Button } from 'components';
import PropTypes from 'prop-types';
import Locale from 'locale';

export default function TourTooltip(props) {
    const { tooltipProps, step, index, size, primaryProps, isLastStep } = props;
    return (
        <div className="tour-tooltip-wrapper stack--sm" {...tooltipProps}>
            <p className="fw-semibold">{step.content?.title}</p>
            <p>{step.content?.description}</p>
            <Button type="primary" onClick={primaryProps.onClick}>
                {isLastStep
                    ? step.content?.lastStepMessage ||
                      Locale.tour.lastStepMessage
                    : `${Locale.tour.nextStep} ${index + 1}/${size}`}
            </Button>
        </div>
    );
}

TourTooltip.propTypes = {
    tooltipProps: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    primaryProps: PropTypes.object.isRequired,
    isLastStep: PropTypes.bool.isRequired,
};
