import { FlightType } from 'utils/enums';
import { useFormik } from 'formik';
import Locale from 'locale';
import {
    Input,
    PhoneNumberInput,
    Title,
    Dropdown,
    TextSwitch,
    Checkbox,
    HeaderPage,
    StickyFooter,
    Button,
    Line,
} from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Reservation, Pilot } from 'models';
import { useState, useEffect } from 'react';
import Service from 'services';
import { useCountryPhoneOptions } from 'states';
import { BR_PHONE_CODE } from 'utils/constants';
import PilotDetailModal from 'pages/frontend/Pilots/DetailModal';
import { ErrorHelper } from 'helpers';

export default function FlightInfo() {
    const navigate = useNavigate();
    const location = useLocation();

    const noPilotOption = {
        label: Locale.pages.reservation.noCommander,
        value: 0,
    };

    const [pilots, setPilots] = useState([]);
    const [pilotsOptions, setPilotsOptions] = useState([]);
    const [showNewPilotModal, setShowNewPilotModal] = useState(false);
    const [createdPilot, setCreatedPilot] = useState();
    const [loading, setLoading] = useState(true);

    const [countriesPhones, loadingCountriesPhones] = useCountryPhoneOptions();

    const parseDropdownFlightType = () =>
        Object.keys(FlightType).map((key) => ({
            label: Locale.flightTypes[key],
            value: FlightType[key],
        }));

    const reservation = new Reservation(location.state.reservation);

    const initialValues = {
        ...reservation.getFlightInfo(),
        pilot: new Pilot(location.state.pilot || {}),
    };

    const validationSchema = Reservation.validationSchemaFlightInfo;

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form) => {
            reservation.setFlightInfo(form);
            navigate('/newreservation/additionalinfo', {
                state: {
                    ...location.state,
                    reservation,
                    pilot: form.pilot,
                },
            });
        },
    });

    const setValuesFromPreviousId = (pilotList) => {
        const values = pilotList.find(
            (pilotFromList) => pilotFromList.id === location.state.pilot.id
        );
        formik.setFieldValue('pilot', values || {});
    };

    const listPilots = () => {
        setLoading(true);
        Service.listPilots()
            .then((response) => {
                const responsePilots = Pilot.createListFromResponse(response);
                setPilotsOptions(Pilot.parseDropdownOptions(responsePilots));
                setPilots(responsePilots);
                if (location.state.pilot?.id) {
                    setValuesFromPreviousId(response);
                }
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        listPilots();
    }, []);

    useEffect(() => {
        if (formik.values.pilot?.id) {
            setPilotsOptions([noPilotOption, ...pilotsOptions]);
        } else {
            setPilotsOptions(Pilot.parseDropdownOptions(pilots));
        }
    }, [formik.values.pilot]);

    useEffect(() => {
        if (createdPilot) {
            formik.setFieldValue('pilot', createdPilot);
        }
    }, [pilots]);

    const resetPilotData = () => {
        formik.setFieldValue('pilot.id', '');
        formik.setFieldValue('pilot.name', '');
        formik.setFieldValue('pilot.phoneNumber', '');
    };

    const handlePilotSelect = (e) => {
        if (e.target.value === noPilotOption.value) {
            resetPilotData();
        } else {
            const selectedValue = pilots.find(
                (pilot) => e.target.value === pilot.id.toString()
            );
            formik.setFieldValue('pilot.id', selectedValue?.id);
            formik.setFieldValue('pilot.name', selectedValue?.name);
            formik.setFieldValue(
                'pilot.countryPhoneCode',
                selectedValue?.countryPhoneCode
            );
            formik.setFieldValue(
                'pilot.phoneNumber',
                selectedValue?.phoneNumber
            );
        }
    };

    useEffect(() => {
        if (!formik.values.isNationalizationFlight) {
            formik.setFieldValue('legalRepresentativeName', '');
            formik.setFieldValue('legalRepresentativePhoneNumber', '');
            formik.setFieldValue('legalRepresentativeCountryPhoneCode', '');
        }
    }, [formik.values.isNationalizationFlight]);

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="padding--lg stack--lg padding-bottom-sticky-footer"
        >
            <HeaderPage
                title={Locale.fields.reservation.flightInfo}
                labelBtnBack={Locale.pages.register.aircraft.aircraftData}
                onClickBtnBack={() => {
                    reservation.setFlightInfo(formik.values);
                    navigate('/newreservation/aircraft', {
                        state: {
                            ...location.state,
                            reservation,
                            pilot: formik.values.pilot,
                        },
                    });
                }}
            />
            <PilotDetailModal
                show={showNewPilotModal}
                onClose={() => setShowNewPilotModal(false)}
                updateList={listPilots}
                setCreatedPilot={setCreatedPilot}
            />

            <div className="row">
                <div className="col-12 col-md-6">
                    <Dropdown
                        id="flightType"
                        name="flightType"
                        autocomplete
                        label={Locale.fields.reservation.flightType}
                        options={parseDropdownFlightType()}
                        required
                        onChange={formik.handleChange}
                        value={formik.values.flightType}
                        error={
                            formik.touched?.flightType &&
                            formik.errors?.flightType
                        }
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <TextSwitch
                    id="isNationalizationFlight"
                    name="isNationalizationFlight"
                    label={Locale.fields.nationalizationFlight}
                    onChange={(e) => {
                        formik.setFieldValue('isNationalizationFlight', e);
                        if (e)
                            formik.setFieldValue(
                                'legalRepresentativeCountryPhoneCode',
                                BR_PHONE_CODE
                            );
                    }}
                    checked={formik.values.isNationalizationFlight}
                />
            </div>
            {formik.values.isNationalizationFlight && (
                <>
                    <Line />
                    <Title tagName="h4">
                        {Locale.fields.reservation.legalRepresentative.title}
                    </Title>
                    <div className="col-12 col-md-8">
                        <Input
                            id="legalRepresentativeName"
                            name="legalRepresentativeName"
                            value={formik.values.legalRepresentativeName}
                            label={Locale.fields.fullName}
                            error={
                                formik.touched?.legalRepresentativeName &&
                                formik.errors?.legalRepresentativeName
                            }
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <Dropdown
                                id="legalRepresentativeCountryPhoneCode"
                                name="legalRepresentativeCountryPhoneCode"
                                options={countriesPhones}
                                label={Locale.fields.countryCode}
                                onChange={formik.handleChange}
                                value={
                                    formik.values
                                        .legalRepresentativeCountryPhoneCode
                                }
                                error={
                                    formik.touched
                                        ?.legalRepresentativeCountryPhoneCode &&
                                    formik.errors
                                        ?.legalRepresentativeCountryPhoneCode
                                }
                                onBlur={formik.handleBlur}
                                required
                                autocomplete
                                loading={loadingCountriesPhones}
                            />
                        </div>
                        <div className="col-8 col-md-4">
                            <PhoneNumberInput
                                id="legalRepresentativePhoneNumber"
                                name="legalRepresentativePhoneNumber"
                                value={
                                    formik.values.legalRepresentativePhoneNumber
                                }
                                label={Locale.fields.phone}
                                error={
                                    formik.touched
                                        ?.legalRepresentativePhoneNumber &&
                                    formik.errors
                                        ?.legalRepresentativePhoneNumber
                                }
                                required
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                countryPhoneCode={
                                    formik.values
                                        ?.legalRepresentativeCountryPhoneCode
                                }
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="row">
                <div className="col-12 col-md-6">
                    <TextSwitch
                        id="isAlternativeReserve"
                        name="isAlternativeReserve"
                        label={Locale.fields.reservation.alternative}
                        onChange={(e) => {
                            formik.setFieldValue('isAlternativeReserve', e);
                        }}
                        checked={formik.values.isAlternativeReserve}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextSwitch
                        id="hasTrailerFork"
                        name="hasTrailerFork"
                        label={Locale.fields.reservation.trailerFork}
                        onChange={(e) => {
                            formik.setFieldValue('hasTrailerFork', e);
                        }}
                        checked={formik.values.hasTrailerFork}
                    />
                </div>
            </div>
            <Title tagName="h4">
                {Locale.fields.reservation.transportationSupport}
            </Title>
            <div className="row">
                <div className="col-12">
                    <Checkbox
                        id="hasEmbarkationTransport"
                        name="hasEmbarkationTransport"
                        label={Locale.fields.boarding}
                        onChange={formik.handleChange}
                        checked={formik.values.hasEmbarkationTransport}
                    />
                </div>
                <div className="col-12">
                    <Checkbox
                        id="hasDisembarkationTransport"
                        name="hasDisembarkationTransport"
                        label={Locale.fields.deboarding}
                        onChange={formik.handleChange}
                        checked={formik.values.hasDisembarkationTransport}
                    />
                </div>
            </div>
            {(formik.values.hasDisembarkationTransport ||
                formik.values.hasEmbarkationTransport) && (
                <div className="row">
                    <div className="col-12">
                        <Input
                            id="handlingCompany"
                            name="handlingCompany"
                            value={formik.values.handlingCompany}
                            label={Locale.fields.reservation.handlingCompany}
                            error={
                                formik.touched?.handlingCompany &&
                                formik.errors?.handlingCompany
                            }
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
            )}
            <Line />
            <div className="flex-justify--between flex-items--center">
                <Title tagName="h4">{Locale.fields.commander}</Title>
                <Button
                    kind="primary"
                    type="button"
                    onClick={() => {
                        setShowNewPilotModal(true);
                    }}
                >
                    + {Locale.general.new.m}
                </Button>
            </div>
            <div className="col-12 col-md-8">
                <Dropdown
                    id="pilot.name"
                    name="pilot.name"
                    value={formik.values.pilot.id}
                    label={Locale.fields.pilot.name}
                    options={pilotsOptions}
                    error={
                        formik.touched?.pilot?.name &&
                        formik.errors?.pilot?.name
                    }
                    onBlur={formik.handleBlur}
                    onChange={handlePilotSelect}
                    autocomplete
                    showListOnTop
                    loading={loading}
                />
            </div>
            <div className="row">
                <div className="col-4 col-md-2">
                    <Dropdown
                        id="pilot.countryPhoneCode"
                        name="pilot.countryPhoneCode"
                        options={countriesPhones}
                        label={Locale.fields.countryCode}
                        onChange={formik.handleChange}
                        disabled
                        value={formik.values.pilot?.countryPhoneCode}
                        error={
                            formik.touched?.pilot?.countryPhoneCode &&
                            formik.errors?.pilot?.countryPhoneCode
                        }
                        showListOnTop
                    />
                </div>
                <div className="col-8 col-md-4">
                    <PhoneNumberInput
                        id="pilot.phoneNumber"
                        name="pilot.phoneNumber"
                        value={formik.values.pilot.phoneNumber}
                        label={Locale.fields.phone}
                        disabled
                        error={
                            formik.touched?.pilot?.phoneNumber &&
                            formik.errors?.pilot?.phoneNumber
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        countryPhoneCode={formik.values.pilot?.countryPhoneCode}
                    />
                </div>
            </div>
            <StickyFooter>
                <Button type="submit">{Locale.actions.proceed}</Button>
            </StickyFooter>
        </form>
    );
}
