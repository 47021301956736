import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Locale from 'locale';
import { Aircraft, AircraftSolicitation } from 'models';
import { ErrorHelper, MaskHelper } from 'helpers';
import {
    AircraftSolicitationType,
    AircraftSolicitationStatus,
} from 'utils/enums';
import { AIRCRAFT_SOLICITATION_PROCESS_ENABLED } from 'utils/constants';
import notify from 'components/Toast';
import Service from 'services';
import {
    HeaderPage,
    Button,
    StickyFooter,
    Input,
    LoadingFullPage,
    FileInputCard,
    BottomSheet,
    Image,
} from 'components';
import './styles.scss';
import { icoExclamationYellow } from 'assets/icons';

function EditAircraft() {
    const navigate = useNavigate();
    const location = useLocation();
    const { aircraftRegistration } = useParams();
    const [aircraft, setAircraft] = useState();
    const [aircraftSolicitation, setAircraftSolicitation] = useState();
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const isSolicitation = location.state?.isSolicitation;

    const getAircraftFromSolicitation = () => {
        Service.listAllAircraftSolicitations({
            registration: aircraftRegistration,
            [AircraftSolicitation.filterParams
                .status]: `${AircraftSolicitationStatus.incompleteRegistration},${AircraftSolicitationStatus.pendingValidation},${AircraftSolicitationStatus.disapprovedValidation}`,
        })
            .then((response) => {
                if (!response?.length) {
                    ErrorHelper.notifyError({
                        response: {
                            data: `No solicitation found with registration ${aircraftRegistration}`,
                        },
                    });
                }
                const solicitation = AircraftSolicitation.createFromResponse(
                    response[0]
                );
                const solicitationAircraft = new Aircraft({
                    ...solicitation.aircraft,
                    image: [solicitation.image],
                    document: [solicitation.document],
                });
                solicitationAircraft.solicitationStatus = solicitation.status;
                setAircraft(solicitationAircraft);
                setAircraftSolicitation(solicitation);
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
                navigate('/aircraft');
            });
    };

    const getAircraft = () => {
        Service.listAircraft({ registration: aircraftRegistration })
            .then((response) => {
                if (!response?.length) {
                    ErrorHelper.notifyError({
                        response: {
                            data: `No aircraft found with registration ${aircraftRegistration}`,
                        },
                    });
                }
                setAircraft(Aircraft.createFromResponse(response[0]));
                setLoading(false);
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            });
    };

    const patchAircraftInformation = async (aircraftData) => {
        setIsSubmitting(true);
        try {
            await Service.patchAircraft(aircraftData);
        } catch (error) {
            setIsSubmitting(false);
            ErrorHelper.notifyError(error);
            return;
        }

        if (aircraftData.image[0]) {
            try {
                const file = aircraftData.image[0];
                if (file.isUpdating) {
                    await Service.patchAircraftImage(
                        aircraftData.id,
                        file.id,
                        file
                    );
                } else if (file.isNew) {
                    await Service.createAircraftImage(aircraftData.id, file);
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        if (aircraftData.document[0]) {
            try {
                const file = aircraftData.document[0];
                if (file.isUpdating) {
                    await Service.patchAircraftDocument(
                        aircraftData.id,
                        file.id,
                        file
                    );
                } else if (file.isNew) {
                    await Service.createAircraftDocument(aircraftData.id, file);
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        setIsSubmitting(false);
        navigate(`/aircraft/${aircraftData.registration}`);
    };

    const patchAircraftSolicitation = async (
        aircraftSolicitationData,
        aircraftData
    ) => {
        setIsSubmitting(true);

        if (aircraftData.image[0]) {
            try {
                const file = aircraftData.image[0];
                if (file.isUpdating) {
                    await Service.patchAircraftSolicitationImage(
                        aircraftData.id,
                        file.id,
                        file
                    );
                } else if (file.isNew) {
                    await Service.createAircraftSolicitationImage(
                        aircraftSolicitationData.id,
                        file
                    );
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        if (aircraftData.document[0]) {
            try {
                const file = aircraftData.document[0];
                if (file.isUpdating) {
                    await Service.patchAircraftSolicitationDocument(
                        aircraftData.id,
                        file.id,
                        file
                    );
                } else if (file.isNew) {
                    await Service.createAircraftSolicitationDocument(
                        aircraftSolicitationData.id,
                        file
                    );
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        setIsSubmitting(false);
        navigate(`/aircraft/${aircraftData.registration}`, {
            state: location.state,
        });
    };

    const createAircraftSolicitation = async (aircraftData) => {
        setIsSubmitting(true);
        let solicitation;
        try {
            const solicitationData = new AircraftSolicitation({
                type: AircraftSolicitationType.edit,
                aircraft: aircraftData,
            });
            solicitation = await Service.postAircraftSolicitation(
                solicitationData
            );
        } catch (error) {
            setIsSubmitting(false);
            ErrorHelper.notifyError(error);
            return;
        }

        if (aircraftData.image[0]) {
            try {
                const file = aircraftData.image[0];
                if (file.isUpdating || file.isNew) {
                    await Service.createAircraftSolicitationImage(
                        solicitation.id,
                        {
                            filepath: file.filepath,
                            name: file.name,
                        }
                    );
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        if (aircraftData.document[0]) {
            try {
                const file = aircraftData.document[0];
                if (file.isUpdating || file.isNew) {
                    await Service.createAircraftSolicitationDocument(
                        solicitation.id,
                        {
                            filepath: file.filepath,
                            name: file.name,
                        }
                    );
                }
            } catch (error) {
                ErrorHelper.notifyError(error);
            }
        }

        setIsSubmitting(false);
        navigate(`/aircraft/${aircraftData.registration}`);
    };

    const handleAircraftFileChange = (e, formikField, acceptedExtensions) => {
        const { files } = e.target;
        const filesList = [];
        [...files].forEach((file) => {
            const auxFile = {
                name: file.name,
                filesize: file.size,
                filepath: file,
            };
            // Flag to track new or updating files
            if (aircraft[formikField]?.[0]?.id) {
                auxFile.id = aircraft[formikField]?.[0]?.id;
                auxFile.isUpdating = true;
            } else {
                auxFile.isNew = true;
            }
            filesList.push(auxFile);
        });

        // Prevents file removal when canceled or not selecting file on edit
        if (filesList.length === 0 && formik.values[formikField]) {
            return;
        }

        // Prevents unsupported file format
        if (acceptedExtensions) {
            const submitedFilename = filesList[0].name;
            let fileExtension = '';
            if (submitedFilename.lastIndexOf('.') > 0) {
                fileExtension = submitedFilename.substring(
                    submitedFilename.lastIndexOf('.') + 1,
                    submitedFilename.length
                );
            }
            if (!acceptedExtensions.includes(fileExtension)) {
                notify(Locale.pages.register.aircraft.invalidFileFormat);
                return;
            }
        }

        formik.setFieldValue(formikField, [filesList[0]]);
    };

    const clearFile = (fieldId) => {
        formik.setFieldValue(fieldId, undefined);
    };

    const handleAircraftDelete = async () => {
        if (aircraft.id) {
            Service.deleteAircraft(aircraft.id)
                .then(() => {
                    notify(
                        Locale.pages.aircraftEdit.information.aircraftRemoved
                    );
                    navigate('/aircraft');
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                });
        }
    };

    const formik = useFormik({
        initialValues: aircraft,
        validationSchema: Aircraft.noOperatorValidationSchema,
        onSubmit: (data) => {
            const aircraftData = new Aircraft(data);
            if (AIRCRAFT_SOLICITATION_PROCESS_ENABLED) {
                setShowEditModal(true);
            } else {
                patchAircraftInformation(aircraftData);
            }
        },
    });

    useEffect(() => {
        setLoading(true);

        if (isSolicitation) {
            getAircraftFromSolicitation();
        } else {
            getAircraft();
        }
    }, [aircraftRegistration]);

    useEffect(() => {
        formik.setValues(aircraft);
    }, [aircraft]);

    return loading ? (
        <LoadingFullPage />
    ) : (
        <div className="padding--lg height--full stack--lg edit-aircraft-wrapper">
            <BottomSheet
                show={showDeleteModal}
                title={Locale.actions.attention}
                onClose={() => setShowDeleteModal(false)}
            >
                <div className="stack--md padding--y-sm">
                    <Image
                        src={icoExclamationYellow}
                        size={32}
                        alt="ico-alert"
                    />
                    <p className="font-size--sm">
                        {
                            Locale.pages.aircraftEdit.information
                                .confirmDeleteMessage
                        }
                    </p>
                    <Button
                        type="button"
                        kind="primary"
                        onClick={handleAircraftDelete}
                    >
                        {Locale.pages.aircraftEdit.information.removeAircraft}
                    </Button>
                    <Button
                        type="button"
                        kind="outline"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        {Locale.actions.cancel}
                    </Button>
                </div>
            </BottomSheet>
            {AIRCRAFT_SOLICITATION_PROCESS_ENABLED && (
                <BottomSheet
                    show={showEditModal}
                    title={Locale.actions.attention}
                    onClose={() => setShowEditModal(false)}
                >
                    <div className="stack--md padding--y-sm">
                        <Image
                            src={icoExclamationYellow}
                            size={32}
                            alt="ico-alert"
                        />
                        <p className="font-size--sm">
                            {
                                Locale.pages.aircraftEdit.information
                                    .confirmEditMessage
                            }
                        </p>
                        <p className="font-size--sm">
                            {
                                Locale.pages.aircraftEdit.information
                                    .wishToContinue
                            }
                        </p>
                        <Button
                            isLoading={isSubmitting}
                            type="button"
                            kind="primary"
                            onClick={() => {
                                if (isSolicitation) {
                                    patchAircraftSolicitation(
                                        aircraftSolicitation,
                                        new Aircraft(formik.values)
                                    );
                                } else {
                                    createAircraftSolicitation(
                                        new Aircraft(formik.values)
                                    );
                                }
                            }}
                        >
                            {Locale.actions.proceed}
                        </Button>
                        <Button
                            type="button"
                            kind="outline"
                            onClick={() => setShowEditModal(false)}
                        >
                            {Locale.actions.cancel}
                        </Button>
                    </div>
                </BottomSheet>
            )}
            <form
                onSubmit={formik.handleSubmit}
                className="height--full stack--lg"
            >
                <HeaderPage
                    title={Locale.pages.aircraftEdit.information.title}
                    labelBtnBack={Locale.actions.back}
                    onClickBtnBack={() =>
                        navigate(`/aircraft/${aircraftRegistration}`, {
                            state: location.state,
                        })
                    }
                />
                <div className="stack--lg padding-bottom-sticky-footer">
                    <div className="stack--md">
                        <div className="row">
                            <div>
                                <Input
                                    id="registration"
                                    name="registration"
                                    label={
                                        Locale.fields.aircraft
                                            .aircraftRegistration
                                    }
                                    value={formik.values?.registration}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <Input
                                    id="icaoModel"
                                    name="icaoModel"
                                    label={Locale.fields.aircraft.model}
                                    value={formik.values?.icaoModel}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <Input
                                    type="number"
                                    id="mtow"
                                    name="mtow"
                                    label={Locale.fields.aircraft.mtow}
                                    value={formik.values?.mtow}
                                    after={
                                        <span className="body-2 color--grayscale-blue-gray">
                                            kg
                                        </span>
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <Input
                                    type="number"
                                    id="length"
                                    name="length"
                                    label={Locale.fields.aircraft.planeLength}
                                    value={formik.values?.length}
                                    after={
                                        <span className="body-2 color--grayscale-blue-gray">
                                            m
                                        </span>
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'length',
                                            MaskHelper.numeric(e.target.value)
                                        )
                                    }
                                    error={
                                        formik.touched.length &&
                                        formik.errors.length
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <Input
                                    type="number"
                                    id="wingspan"
                                    name="wingspan"
                                    label={Locale.fields.aircraft.wingspan}
                                    after={
                                        <span className="body-2 color--grayscale-blue-gray">
                                            m
                                        </span>
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'wingspan',
                                            MaskHelper.numeric(e.target.value)
                                        )
                                    }
                                    value={formik.values?.wingspan}
                                    error={
                                        formik.touched.wingspan &&
                                        formik.errors.wingspan
                                    }
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="stack--sm">
                        <div className="flex--row flex-items--center">
                            <p className="font-size--sm inline--sm">
                                {Locale.fields.file.airworthnessCertification}
                            </p>
                        </div>
                        <FileInputCard
                            id="aircraftDocument"
                            title={Locale.fields.file.attachCertification}
                            description={
                                formik.values?.document?.[0]
                                    ? formik.values?.document?.[0]?.name
                                    : 'PDF, JPG ou PNG'
                            }
                            file={formik.values?.document?.[0]}
                            clearFiles={() => clearFile('document')}
                            accept="application/pdf, image/png, image/jpeg, image/jpg"
                            onChange={(e) =>
                                handleAircraftFileChange(e, 'document', [
                                    'pdf',
                                    'jpg',
                                    'png',
                                ])
                            }
                            onBlur={() => {}}
                            disableClear
                        />
                    </div>
                    <div className="stack--sm">
                        <div className="flex--row flex-items--center">
                            <p className="font-size--sm inline--sm">
                                {Locale.fields.file.aircraftImage}
                            </p>
                        </div>
                        <FileInputCard
                            id="aircraftImage"
                            title={Locale.fields.file.attachImage}
                            description={
                                formik.values?.image?.[0]
                                    ? formik.values?.image?.[0]?.name
                                    : 'JPG ou PNG'
                            }
                            file={formik.values?.image?.[0]}
                            clearFiles={() => clearFile('image')}
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) =>
                                handleAircraftFileChange(e, 'image', [
                                    'jpg',
                                    'png',
                                ])
                            }
                            onBlur={() => {}}
                            disableClear
                        />
                    </div>
                    {!isSolicitation && (
                        <Button
                            kind="text"
                            type="button"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            {
                                Locale.pages.aircraftEdit.information
                                    .removeAircraft
                            }
                        </Button>
                    )}
                </div>
                <StickyFooter>
                    <div className="aircraft-list-footer">
                        <Button
                            isLoading={isSubmitting}
                            kind="primary"
                            type="submit"
                        >
                            {Locale.pages.aircraftEdit.information.saveChanges}
                        </Button>
                    </div>
                </StickyFooter>
            </form>
        </div>
    );
}

export default EditAircraft;
