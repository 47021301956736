import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Locale from 'locale';
import './styles.scss';
import PropTypes from 'prop-types';

import { Button, Image, Tour, Input, LoadingFullPage, Chip } from 'components';
import { icoFilterDuotone, icoSearch, icoFilterNoResult } from 'assets/icons';
import Debt from 'models/Debt';
import { setItem, getItem } from 'localStorage';
import Service from 'services';
import { statusFARM } from 'utils/enums';
import { ErrorHelper, DateHelper, FilterHelper } from 'helpers';
import moment from 'moment';
import { format } from 'date-fns';
import DebtCard from '../DebtCard';
import DebtListFilter from '../DebtListFilter';
import EmptyDebtList from '../EmptyDebtList';

export default function DebtHistory({ aircraftList, airportList }) {
    const HISTORY_TOUR_COMPLETED = 'HISTORY_TOUR_COMPLETED';

    const [groupedDebtList, setGroupedDebtList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [tourControl, setTourControl] = useState({
        run: false,
        steps: [
            {
                target: '.tab__history',
                content: {
                    title: Locale.pages.debt.tour.history.steps[0].title,
                    description:
                        Locale.pages.debt.tour.history.steps[0].description,
                },
                disableBeacon: true,
            },
            {
                target: '.debt-card',
                content: {
                    title: Locale.pages.debt.tour.history.steps[1].title,
                    description:
                        Locale.pages.debt.tour.history.steps[1].description,
                },
            },
        ],
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const getPreviousSearchFilters = () => {
        const airportIcao = searchParams.get('airportIcao') || undefined;
        const startDate = moment(searchParams.get('startDate')).isValid()
            ? moment(searchParams.get('startDate')).toDate()
            : undefined;
        const endDate = moment(searchParams.get('endDate')).isValid()
            ? moment(searchParams.get('endDate')).toDate()
            : undefined;
        const noDocFaturamento =
            searchParams.get('noDocFaturamento') || undefined;
        const previousFilters = {
            airportIcao,
            startDate,
            endDate,
            noDocFaturamento,
        };
        return previousFilters;
    };
    const [showFilterModal, setShowFilterModal] = useState(false);
    const noFilters = {
        startDate: undefined,
        endDate: undefined,
        airportIcao: undefined,
    };
    const [filters, setFilters] = useState(
        getPreviousSearchFilters() || noFilters
    );
    const [invoiceSearch, setInvoiceSearch] = useState(
        getPreviousSearchFilters()?.noDocFaturamento || ''
    );

    const closeFilterModal = (currentFilters) => {
        setFilters({
            ...filters,
            ...currentFilters,
        });
        setShowFilterModal(false);
    };

    const showableFilters = () => {
        const airportsValue = filters.airportIcao;
        const period =
            filters.startDate &&
            `${format(filters.startDate, 'dd/MM/yyyy')} - ${
                filters.endDate ? format(filters.endDate, 'dd/MM/yyyy') : ''
            }`;

        return [
            ...(airportsValue
                ? [
                      {
                          name: Locale.fields.reservation.airport,
                          value: airportsValue,
                          keys: ['airportIcao'],
                      },
                  ]
                : []),
            ...(period
                ? [
                      {
                          name: Locale.pages.invoiceDetails.invoiceDate,
                          value: period,
                          keys: ['startDate', 'endDate'],
                      },
                  ]
                : []),
        ];
    };

    const isFilterEmpty =
        Object.keys(filters).every((key) => filters[key] === undefined) &&
        !invoiceSearch;

    const removeFilter = (keys) => {
        const newFilters = { ...filters };
        keys.forEach((key) => {
            newFilters[key] = noFilters[key];
        });
        setFilters(newFilters);
    };

    const getDebtHistory = () => {
        setIsFetchingData(true);
        setSearchParams({
            airportIcao: filters.airportIcao || '',
            startDate: FilterHelper.parseDateField(filters.startDate),
            endDate: FilterHelper.parseDateField(filters.endDate),
            noDocFaturamento: invoiceSearch || '',
            activeTab: 'history',
        });

        const correctedFilter = { ...filters };
        correctedFilter.startDate = FilterHelper.parseDateField(
            correctedFilter.startDate
        );
        correctedFilter.endDate = FilterHelper.parseDateField(
            correctedFilter.endDate
        );

        Service.postFaturas({
            status: statusFARM.pago,
            group_by_month: true,
            only_known_operators: true,
            ...correctedFilter,
        })
            .then((response) => {
                if (response) {
                    setGroupedDebtList(response);
                }
            })
            .catch((error) => {
                ErrorHelper.notifyError(error);
            })
            .finally(() => {
                setLoading(false);
                setIsFetchingData(false);
            });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilters({
                ...filters,
                noDocFaturamento: invoiceSearch || undefined,
            });
        }
    };

    const handleTourFinished = () => {
        setItem(HISTORY_TOUR_COMPLETED, true);
    };

    useEffect(() => {
        const previousFilters = getPreviousSearchFilters();
        setFilters(previousFilters);
    }, [location.pathname]);

    useEffect(() => {
        getDebtHistory();
    }, []);

    useEffect(() => {
        const isTourCompleted = getItem(HISTORY_TOUR_COMPLETED);
        if (!groupedDebtList?.length && !isTourCompleted) {
            setTourControl({
                ...tourControl,
                run: true,
            });
        }
    }, [groupedDebtList]);

    useEffect(() => {
        getDebtHistory();
    }, [filters]);

    if (!loading && (!aircraftList || aircraftList.length === 0)) {
        return (
            <EmptyDebtList
                title={Locale.pages.debt.noAircraft.nothingHere}
                body={Locale.pages.debt.noAircraft.registerToConsult}
                buttonText={Locale.pages.debt.noAircraft.goToRegister}
                onClickButton={() =>
                    navigate('/aircraft', {
                        state: {
                            showModal: true,
                        },
                    })
                }
            />
        );
    }

    return !loading ? (
        <>
            <DebtListFilter
                filters={filters}
                onApplyClick={closeFilterModal}
                onCloseClick={closeFilterModal}
                showFilterModal={showFilterModal}
                airportList={airportList}
            />
            <Tour
                run={tourControl.run}
                steps={tourControl.steps}
                onTourFinish={handleTourFinished}
            />
            <div className="debt-header">
                <p className="title">
                    {Locale.pages.debt.content.invoiceHistory}
                </p>
                <Button
                    kind="outline"
                    className="debt-list-filter-btn"
                    icon={icoFilterDuotone}
                    onClick={() => setShowFilterModal(true)}
                >
                    {Locale.actions.filter}
                </Button>
            </div>
            <div className="filter-chips-group">
                {showableFilters().map((filter) => (
                    <div className="padding--t-sm padding--l-sm">
                        <Chip
                            key={filter.name || filter.value}
                            onClickCloseButton={() => removeFilter(filter.keys)}
                        >
                            <div>
                                {filter.name && <b>{`${filter.name}: `}</b>}
                                {filter.value}
                            </div>
                        </Chip>
                    </div>
                ))}
            </div>
            <div className="history-list">
                <Input
                    name="history-search"
                    placeholder={Locale.pages.debt.content.searchInvoices}
                    before={
                        <Image src={icoSearch} size={20} alt="ico-search" />
                    }
                    value={invoiceSearch}
                    onChange={(e) => setInvoiceSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={() => {
                        setFilters({
                            ...filters,
                            noDocFaturamento: invoiceSearch || undefined,
                        });
                    }}
                    className="search-field"
                />
                {isFetchingData ? (
                    <LoadingFullPage />
                ) : !groupedDebtList?.length ? (
                    <EmptyDebtList
                        title={
                            isFilterEmpty
                                ? Locale.pages.debt.noHistory.nothingHere
                                : Locale.pages.debt.noHistory.filtered
                                      .nothingHere
                        }
                        body={
                            isFilterEmpty
                                ? Locale.pages.debt.noHistory.noRegisters
                                : Locale.pages.debt.noHistory.filtered.noResults
                        }
                        buttonText={
                            isFilterEmpty
                                ? Locale.pages.debt.noHistory.goToHome
                                : ''
                        }
                        icon={isFilterEmpty ? undefined : icoFilterNoResult}
                        onClickButton={() =>
                            navigate('/aircraft', {
                                state: {
                                    showModal: true,
                                },
                            })
                        }
                    />
                ) : (
                    groupedDebtList.map((debtGroup) => (
                        <>
                            <p className="text-capitalize font-size--md month-name">
                                {DateHelper.getMonthName(
                                    debtGroup.date.slice(0, 2)
                                )}
                            </p>
                            {debtGroup.invoices.map((debt) => (
                                <DebtCard
                                    kind=""
                                    debt={new Debt(debt)}
                                    key={`fatura-${debt?.noDocFaturamento}`}
                                    isDebtActive={!debt.isMasked}
                                    isPaid
                                    onClick={() => {
                                        searchParams.set(
                                            'activeTab',
                                            'history'
                                        );
                                        setSearchParams(searchParams);
                                        navigate(
                                            `/invoice/${debt.noDocFaturamento}`,
                                            {
                                                state: { isPaid: true },
                                            }
                                        );
                                    }}
                                    className="history-card"
                                />
                            ))}
                        </>
                    ))
                )}
            </div>
        </>
    ) : (
        <LoadingFullPage />
    );
}

DebtHistory.propTypes = {
    aircraftList: PropTypes.array.isRequired,
    airportList: PropTypes.array.isRequired,
};

DebtHistory.defaultProps = {};
