import './styles.scss';
import { AircraftForm } from 'features/frontend';
import { HeaderPage, StickyFooter, Button } from 'components';
import { icoStep2x2 } from 'assets/icons';
import Locale from 'locale';
import { useNavigate } from 'react-router-dom';
import Service from 'services';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';

function RegisterAircraft() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/register/user');
    };

    const onSubmit = (data) => {
        Service.postAircraft(data)
            .then(() => {
                notify(Locale.pages.accountSettings.aircraft.createSuccess);
                navigate('/');
            })
            .catch(() => ErrorHelper.notifyError(Locale.errors.default));
    };

    return (
        <div className="padding--lg register-aircraft">
            <HeaderPage
                title={Locale.pages.register.aircraft.aircraftData}
                description={Locale.pages.register.aircraft.description}
                contentInfo={
                    <img src={icoStep2x2} alt="2/2" className="ico-step" />
                }
                labelBtnBack={Locale.actions.createAccount}
                onClickBtnBack={handleGoBack}
            />
            <AircraftForm isCreating onSubmit={onSubmit}>
                <StickyFooter>
                    <Button
                        type="button"
                        kind="outline"
                        className="d-none d-sm-block"
                        onClick={() => handleGoBack()}
                    >
                        {Locale.actions.back}
                    </Button>
                    <Button type="submit" kind="primary">
                        {Locale.actions.completeRegistration.toUpperCase()}
                    </Button>
                </StickyFooter>
            </AircraftForm>
        </div>
    );
}

export default RegisterAircraft;
