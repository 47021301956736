import styled from 'styled-components';

const defaultButtonSize = '40px';
const largeButtonSize = '60px';
const smallButtonSize = '30px';
const defaultIconSize = '24px';
const largeIconSize = '30px';
const smallIconSize = '16px';

export const Container = styled.button`
    width: ${defaultButtonSize};
    height: ${defaultButtonSize};
    border-radius: 50%;
    padding: ${(props) => props.theme.spacing.sm};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: ${(props) => props.iconSize || defaultIconSize};
        height: ${(props) => props.iconSize || defaultIconSize};
    }

    ${(props) =>
        props.size === 'large' &&
        `
        width: ${largeButtonSize};
        height: ${largeButtonSize};

        img {
            width: ${largeIconSize};
            height: ${largeIconSize};
        }
    `};
    }

    ${(props) =>
        props.size === 'small' &&
        `
        width: ${smallButtonSize};
        height: ${smallButtonSize};

        img {
            width: ${smallIconSize};
            height: ${smallIconSize};
        }
    `};

    ${(props) =>
        props.disabled &&
        `
        cursor: not-allowed;
    `};

    /* PRIMARY */
    ${(props) =>
        props.kind === 'primary' &&
        `
        background: ${props.theme.colors.brand.primary};
        color: ${props.theme.colors.grayscale.white};
        border: 1px solid ${props.theme.colors.brand.primary};

        &:active {
            background: ${props.theme.colors.brand.primaryDark};
        }

        ${
            props.disabled === true &&
            `
            background: ${props.theme.colors.grayscale.steelGray};
            border-color: ${props.theme.colors.grayscale.steelGray};

            &:active {
                background: ${props.theme.colors.grayscale.steelGray};
            }
            `
        };
    `};

    ${(props) =>
        props.kind === 'outline' &&
        `
        border: 1px solid ${props.theme.colors.grayscale.paleShaleGray};
        background: ${props.theme.colors.grayscale.white};

        &:active {
            background: ${props.theme.colors.grayscale.light};
            border-color: ${props.theme.colors.grayscale.light};
        }

        ${
            props.disabled === true &&
            `
            color: ${props.theme.colors.grayscale.steelGray};

            &:active {
                background: ${props.theme.colors.grayscale.white};
            }
          `
        };
    `};

    ${(props) =>
        props.kind === 'no-border' &&
        `
        border:  none;
        background: ${props.theme.colors.grayscale.white};

        &:hover {
            background-color: ${props.theme.colors.grayscale.light};
        }
    `}

    ${(props) =>
        props.float === true &&
        `
        border-radius: 50px;
        border: none;
        box-shadow: 0px 4px 12px #D2D3Df;
    `};

    ${(props) =>
        props.name &&
        `
        color: ${props.theme.colors.grayscale.blueGray};
        font-weight : ${props.theme.font_weights.medium};
        background-color: ${props.theme.colors.grayscale.light};
        font-size: ${props.theme.fonts.sm};
    `}
`;
