const DownloadFileHelper = {
    downloadB64asPDF(dataB64, filename) {
        const linkSource = `data:application/pdf;base64,${dataB64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = filename;
        downloadLink.click();
    },
};

export default DownloadFileHelper;
