import { useState, useEffect } from 'react';
import {
    DatePickerRange,
    Input,
    Image,
    Button,
    FullPageModal,
    Checkbox,
    Radio,
} from 'components';
import Locale from 'locale';
import { icoSearch, icoClose } from 'assets/icons';
import { useFormik } from 'formik';
import {
    AircraftSolicitationStatus,
    AircraftSolicitationType,
} from 'utils/enums';
import PropTypes from 'prop-types';
import './styles.scss';

const solicitationNature = {
    national: 'national',
    international: 'international',
    military: 'military',
};

const disabledFilter = {
    status: ['incompleteRegistration'],
};

function FilterOptions({ show, onClose, setFilters, filters }) {
    const formik = useFormik({
        initialValues: filters,
        onSubmit: (values) => {
            setFilters(values);
            onClose();
        },
    });

    useEffect(() => {
        formik.setValues(filters);
    }, [show]);

    return (
        <FullPageModal
            kind="rightSide"
            show={show}
            title={Locale.fields.filters}
            onClose={onClose}
            endPageButton={
                <Button
                    className="width--full"
                    type="submit"
                    onClick={(e) => {
                        formik.handleSubmit(e);
                        onClose();
                    }}
                >
                    {Locale.actions.filter}
                </Button>
            }
        >
            <div className="stack--xl padding-bottom-sticky-footer">
                <div className="stack--md">
                    <div className="headline-5">Status</div>
                    {Object.keys(AircraftSolicitationStatus)
                        .filter((key) => !disabledFilter?.status?.includes(key))
                        .map((key) => (
                            <Checkbox
                                key={`status.${key}`}
                                id={`status.${key}`}
                                name={`status.${key}`}
                                label={
                                    Locale.pages.backoffice.solicitations
                                        .status[key]
                                }
                                checked={formik.values.status[key]}
                                onChange={formik.handleChange}
                            />
                        ))}
                </div>
                <div className="stack--md">
                    <div className="headline-5">
                        {Locale.pages.backoffice.solicitations.aircraftNature}
                    </div>
                    <Radio
                        id={solicitationNature.national}
                        name="solicitationNature"
                        label={
                            Locale.pages.backoffice.solicitations.nature
                                .national
                        }
                        value={solicitationNature.national}
                        checked={
                            formik.values.nature === solicitationNature.national
                        }
                        onChange={() =>
                            formik.setFieldValue(
                                'nature',
                                solicitationNature.national
                            )
                        }
                    />
                    <Radio
                        id={solicitationNature.international}
                        name="solicitationNature"
                        label={
                            Locale.pages.backoffice.solicitations.nature
                                .international
                        }
                        value={solicitationNature.international}
                        checked={
                            formik.values.nature ===
                            solicitationNature.international
                        }
                        onChange={() =>
                            formik.setFieldValue(
                                'nature',
                                solicitationNature.international
                            )
                        }
                    />
                    <Radio
                        id={solicitationNature.military}
                        name="solicitationNature"
                        label={
                            Locale.pages.backoffice.solicitations.nature
                                .military
                        }
                        value={solicitationNature.military}
                        checked={
                            formik.values.nature === solicitationNature.military
                        }
                        onChange={() =>
                            formik.setFieldValue(
                                'nature',
                                solicitationNature.military
                            )
                        }
                    />
                </div>
                <div className="stack--md">
                    <div className="headline-5">
                        {Locale.pages.backoffice.solicitations.solicitationType}
                    </div>
                    {Object.keys(AircraftSolicitationType).map((key) => (
                        <Checkbox
                            key={`type.${key}`}
                            id={`type.${key}`}
                            name={`type.${key}`}
                            label={
                                Locale.pages.backoffice.solicitations.type[key]
                            }
                            checked={formik.values.type[key]}
                            onChange={formik.handleChange}
                        />
                    ))}
                </div>
            </div>
        </FullPageModal>
    );
}

FilterOptions.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};

function HeaderFilters({ setFilters, filters }) {
    const [showModal, setShowModal] = useState(false);
    const formik = useFormik({
        initialValues: {
            search: 'filters.search',
            dataFillingDate: filters.dataFillingDate,
            status: filters.status,
            type: filters.type,
            nature: filters.nature,
        },
    });

    useEffect(() => {
        formik.setValues(filters);
    }, [filters]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilters({
                ...filters,
                search: formik.values.search,
            });
        }
    };

    const clearSearch = () => {
        formik.setFieldValue('search', '');
        setFilters({ ...filters, search: '' });
    };

    return (
        <>
            <FilterOptions
                show={showModal}
                onClose={() => setShowModal(false)}
                setFilters={setFilters}
                filters={filters}
            />
            <div className="flex-justify--between width--full">
                <div className="inline--lg flex--1">
                    <div className="search-input">
                        <Input
                            label={Locale.actions.search}
                            name="search"
                            placeholder={
                                Locale.pages.backoffice.solicitations.search
                            }
                            value={formik.values?.search}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyDown}
                            before={
                                <Image
                                    src={icoSearch}
                                    alt={Locale.actions.search}
                                />
                            }
                            after={
                                <Image
                                    className="cursor--pointer"
                                    onClick={clearSearch}
                                    src={icoClose}
                                    alt=""
                                    size={16}
                                />
                            }
                        />
                    </div>
                    <DatePickerRange
                        name="range"
                        id="range"
                        label="Filtrar por período" // TODO: Alterar label
                        startDate={formik.values.dataFillingDate.startDate}
                        endDate={formik.values.dataFillingDate.endDate}
                        onChange={(dates) => {
                            const startDate = dates[0];
                            const endDate = dates[1];
                            formik.setFieldValue(
                                'dataFillingDate.startDate',
                                startDate
                            );
                            formik.setFieldValue(
                                'dataFillingDate.endDate',
                                endDate
                            );
                            setFilters({
                                ...filters,
                                dataFillingDate: {
                                    startDate,
                                    endDate,
                                },
                            });
                        }}
                    />
                    <div className="flex-items--end">
                        <Button
                            kind="outline"
                            onClick={() => setShowModal(true)}
                        >
                            {Locale.fields.filters}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

HeaderFilters.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.any.isRequired,
};

export default HeaderFilters;
