import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';
import { Image, Button } from 'components';
import { icoExclamationRedFilled } from 'assets/icons';

export default function PaymentError({ onClickDownloadBill }) {
    return (
        <div className="flex--column invoice-payment-error-wrapper">
            <div className="flex--column flex-items--center stack--lg">
                <Image src={icoExclamationRedFilled} size={56} />
                <div className="stack--md">
                    <p className="font-size--lg color--grayscale-dark fw-semibold text-align--center">
                        {Locale.pages.invoicePayments.paymentNotConfirmed}
                    </p>
                    <p className="font-size--sm color--grayscale-dark invoice-payment-error-explanation">
                        {
                            Locale.pages.invoicePayments
                                .paymentNotConfirmedExplanation
                        }
                    </p>
                </div>
            </div>
            <div className="footer-button-wrapper">
                {/* TODO: Implementar função de nova tentativa */}
                {/* <Button type="button" kind="outline">
                    {Locale.pages.invoicePayments.tryAgain}
                </Button> */}
                <Button
                    type="button"
                    kind="primary"
                    onClick={onClickDownloadBill}
                >
                    {Locale.pages.invoicePayments.downloadBill}
                </Button>
            </div>
        </div>
    );
}

PaymentError.propTypes = {
    onClickDownloadBill: PropTypes.func.isRequired,
};
