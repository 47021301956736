import { icoExpandLeft, icoExpandRight } from 'assets/icons';
import { Dropdown, ButtonIcon, Loading } from 'components';
import Locale from 'locale';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Pagination({
    totalPages,
    currentPage,
    onPageChange,
    pageSize,
    setPageSize,
    loading,
}) {
    const pageSizeOptions = [10, 20, 30, 40, 50].map((size) => ({
        label: `${size} ${Locale.pagination.perPage}`,
        value: size,
    }));

    const pageIndexOptions = Array.from(
        { length: totalPages },
        (_, i) => i + 1
    ); // Cria um array de 1 a totalPages, as in [1, 2, 3...]

    return loading ? (
        <div className="loading-container">
            <Loading />
        </div>
    ) : (
        <div
            className={
                setPageSize
                    ? 'pagination-with-page-size-selector'
                    : 'pagination-simple'
            }
        >
            <div />
            <div className="inline--sm">
                {currentPage !== 1 && (
                    <ButtonIcon
                        kind="no-border"
                        icon={icoExpandLeft}
                        onClick={() => onPageChange(currentPage - 1, pageSize)}
                        size={setPageSize ? 'medium' : 'small'}
                    />
                )}
                {pageIndexOptions.map((pageIndexOption) => {
                    const isFirstPage = pageIndexOption === 1;
                    const isLastPage = pageIndexOption === totalPages;
                    const isPreviousOrNext =
                        Math.abs(pageIndexOption - currentPage) < 2;
                    const ellipsisForOnePage =
                        (totalPages - currentPage === 3 &&
                            pageIndexOption > currentPage) ||
                        (currentPage === 4 && pageIndexOption < currentPage);
                    const showAllPages = totalPages <= 7;

                    if (
                        showAllPages ||
                        isFirstPage ||
                        isLastPage ||
                        isPreviousOrNext ||
                        ellipsisForOnePage // Impede exibição de reticências pra ocultar apenas uma página, como em 4 ... 6 e 1 ... 3
                    )
                        return (
                            <ButtonIcon
                                key={`page-${pageIndexOption}`}
                                kind={
                                    pageIndexOption === currentPage
                                        ? 'primary'
                                        : 'no-border'
                                }
                                text={pageIndexOption}
                                onClick={() =>
                                    onPageChange(pageIndexOption, pageSize)
                                }
                                size={setPageSize ? 'medium' : 'small'}
                            />
                        );
                    if (
                        pageIndexOption === 2 ||
                        pageIndexOption === totalPages - 1
                    ) {
                        return (
                            <div
                                key={`page-${pageIndexOption}`}
                                className={`ellipsis-pagination-${
                                    setPageSize ? 'medium' : 'small'
                                }`}
                            >
                                ...
                            </div>
                        );
                    }
                    return null;
                })}
                {currentPage !== totalPages && (
                    <ButtonIcon
                        kind="no-border"
                        icon={icoExpandRight}
                        onClick={() => onPageChange(currentPage + 1, pageSize)}
                        size={setPageSize ? 'medium' : 'small'}
                    />
                )}
            </div>
            {setPageSize && (
                <div className="dropdown-page-size">
                    <Dropdown
                        id="pageSize"
                        name="pageSize"
                        options={pageSizeOptions}
                        placeholder=""
                        value={pageSize}
                        required
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        showListOnTop
                    />
                </div>
            )}
        </div>
    );
}

Pagination.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func,
    loading: PropTypes.bool,
};

Pagination.defaultProps = {
    loading: false,
    setPageSize: undefined,
};
