import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useFormik } from 'formik';

import { TextInfo, Loading, Textarea, Line, Button } from 'components';
import Locale from 'locale';
import Service from 'services';
import { ErrorHelper } from 'helpers';
import notify from 'components/Toast';

function FormPayment({ data, updateData }) {
    const [loading, setLoading] = useState(true);
    const [pricingValues, setPricingValues] = useState({});

    useEffect(() => {
        if (data?.aircraft?.isForeign || data.hasPatioReservation) {
            Service.getBillingData(data.id)
                .then((response) => {
                    setPricingValues(response.pricing);
                    setLoading(false);
                })
                .catch((error) => {
                    ErrorHelper.notifyError(error);
                    setLoading(false);
                });
        }
        setLoading(false);
    }, []);

    const initialValues = {
        comments: data.comments,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            Service.editLandingRequest(data.id, values)
                .then(() => {
                    updateData({
                        ...data,
                        comments: values.comments,
                    });
                    notify(Locale.pages.backoffice.details.commentsEditSuccess);
                })
                .catch((error) => ErrorHelper.notifyError(error));
        },
    });

    const cancelEditComments = () => {
        formik.setFieldValue('comments', data.comments);
    };

    const isPricingValuesFilled = () =>
        pricingValues?.priceReal &&
        pricingValues?.priceDollar &&
        pricingValues?.priceEuro;

    return (
        <div className="padding--t-xxl padding--x-md">
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <h4 className="headline-4 color--grayscale-dark margin--b-sm">
                        {Locale.pages.backoffice.details.payment}
                    </h4>
                    {!loading ? (
                        <div className="row margin--b-sm">
                            {pricingValues.priceReal === 0 ? (
                                <div className="col-12 col-md-4">
                                    <TextInfo
                                        label={`${Locale.pages.backoffice.details.valueIn} R$`}
                                        // TODO: criar máscara de moeda
                                        value={`${Locale.fields.payment.accountHolderDisclaimer}`}
                                    />
                                </div>
                            ) : (
                                isPricingValuesFilled() && (
                                    <>
                                        <div className="col-12 col-md-4">
                                            <TextInfo
                                                label={`${Locale.pages.backoffice.details.valueIn} R$`}
                                                value={new Intl.NumberFormat(
                                                    'pt-BR',
                                                    {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }
                                                ).format(
                                                    pricingValues?.priceReal
                                                )}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <TextInfo
                                                label={`${Locale.pages.backoffice.details.valueIn} US$`}
                                                value={new Intl.NumberFormat(
                                                    'en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    }
                                                ).format(
                                                    pricingValues?.priceDollar
                                                )}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <TextInfo
                                                label={`${Locale.pages.backoffice.details.valueIn} €$`}
                                                value={new Intl.NumberFormat(
                                                    'de-DE',
                                                    {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }
                                                ).format(
                                                    pricingValues?.priceEuro
                                                )}
                                            />
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="flex-items--center flex-justify--center">
                            <Loading />
                        </div>
                    )}
                    <Line />
                    <div className="row">
                        <div className="col-12 margin--b-xs">
                            <Textarea
                                id="comments"
                                name="comments"
                                label={
                                    Locale.pages.backoffice.details
                                        .infoInternalControl
                                }
                                value={formik.values.comments}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <Line />
                    <div className="flex-justify--end margin--t-lg margin--b-sm">
                        <Button
                            kind="outline"
                            type="button"
                            className="margin--r-md"
                            onClick={cancelEditComments}
                        >
                            {Locale.actions.cancel}
                        </Button>
                        <Button type="submit" kind="primary">
                            {Locale.actions.send}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

FormPayment.propTypes = {
    data: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
};

export default FormPayment;
