import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image } from 'components';

import { icoCheckWhite, icoWarning } from 'assets/icons';

import './styles.scss';

function Steps({ items, stepActive, stepStatus }) {
    const step = stepActive - 1;
    const [currentStep, setCurrentStep] = useState(step);
    const [shownStep, setShownStep] = useState(step);
    const { length } = items;
    const icons = {
        success: icoCheckWhite,
        error: icoWarning,
    };

    useEffect(() => {
        setCurrentStep(stepActive - 1);
        setShownStep(stepActive - 1);
    }, [stepActive]);

    function calcProgressBar(number) {
        return `${(number / (length - 1)) * 100}%`;
    }

    return (
        <>
            <div className="container-steps">
                <div className="wrapper-steps">
                    {items.map((item, key) => {
                        const stepEnable = key <= step;

                        return (
                            <div
                                className={clsx('step', {
                                    checked: stepEnable,
                                    active:
                                        stepStatus === 'success' || key < step,
                                    error:
                                        stepStatus === 'error' && key === step,
                                    'current-showing': key === shownStep,
                                })}
                                data-label={item.label}
                                onClick={() => {
                                    if (stepEnable) {
                                        setShownStep(key);
                                        if (item.onClick) item.onClick();
                                    }
                                }}
                                key={`steps-${item.label}`}
                            >
                                {stepStatus !== 'pending' &&
                                    key === currentStep && (
                                        <Image
                                            src={icons[stepStatus]}
                                            alt={stepStatus}
                                        />
                                    )}
                                {key < currentStep && (
                                    <Image src={icons.success} alt="success" />
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="wrapper-progress-bar">
                    <div
                        className="progress-bar"
                        style={{ width: calcProgressBar(currentStep) }}
                    />
                </div>
            </div>
            <div className="container-steps-content">
                {items[shownStep].content}
            </div>
        </>
    );
}

Steps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            content: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.node),
                PropTypes.node,
                PropTypes.string,
            ]).isRequired,
            status: PropTypes.oneOf(['success', 'error']),
            onClick: PropTypes.func,
        })
    ).isRequired,
    stepActive: PropTypes.number.isRequired,
    stepStatus: PropTypes.string.isRequired,
};

export default Steps;
