import { useFormik } from 'formik';
import { HeaderPage, Input, Button, Checkbox } from 'components';
import Locale from 'locale';
import { useNavigate, useLocation } from 'react-router-dom';
import { Aircraft } from 'models';
import { MaskHelper } from 'helpers';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Service from 'services';
import './styles.scss';

function BottomSheetContent({ setShowVisible }) {
    return (
        <div className="stack--md">
            <p>{Locale.pages.register.aircraft.mtowDescription}</p>
            <Button
                kind="primary"
                type="button"
                onClick={() => {
                    setShowVisible(false);
                }}
            >
                {Locale.general.understood}
            </Button>
        </div>
    );
}

BottomSheetContent.propTypes = {
    setShowVisible: PropTypes.func.isRequired,
};

function NewAircraft() {
    const navigate = useNavigate();
    const location = useLocation();

    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [serverErrors, setServerErrors] = useState(false);
    const [isCheckingRegistration, setIsCheckingRegistration] = useState(false);

    const initialValues = { ...Aircraft.createEmpty() };
    const aircraft = location.state?.aircraft;

    const formik = useFormik({
        initialValues: aircraft || initialValues,
        validationSchema: Aircraft.noOperatorValidationSchema,
        onSubmit: (data) => {
            const NATIONAL_AIRCRAFT_REGEX = /^(PR|PS|PP|PT|PU)/g;
            const aircraftGeneralInfo = new Aircraft({
                ...data,
                isForeign:
                    !data.registration.match(NATIONAL_AIRCRAFT_REGEX) &&
                    !data.isMilitary,
            });
            navigate('/newaircraft/documents', {
                state: {
                    aircraft: aircraftGeneralInfo,
                },
            });
        },
    });

    const fetchExistingAircraft = (e) => {
        if (!e.target.value.trim()) {
            return;
        }
        setIsCheckingRegistration(true);

        Service.checkRegistration(e.target.value)
            .then((response) => {
                formik.setFieldValue('icaoModel', response.icaoModel);
                formik.setFieldValue('mtow', response.mtow);
                formik.setFieldValue('length', response.length);
                formik.setFieldValue('wingspan', response.wingspan);
            })
            .catch((error) => {
                if (
                    error.response?.data?.detail?.code === 'DUPLICATED_AIRCRAFT'
                ) {
                    setServerErrors({
                        registration: error.response.data.detail.message,
                    });
                    formik.setFieldTouched('registration', true);
                }
                formik.setFieldValue('icaoModel', '');
                formik.setFieldValue('mtow', '');
                formik.setFieldValue('length', '');
                formik.setFieldValue('wingspan', '');
            })
            .finally(() => {
                setIsCheckingRegistration(false);
            });
    };

    useEffect(() => {
        if (serverErrors) {
            formik.setErrors({ ...formik.errors, ...serverErrors });
        }
    }, [serverErrors]);

    return (
        <div className="padding--lg height--full stack--lg new-aircraft-wrapper">
            <HeaderPage
                title={Locale.pages.register.aircraft.registerAircraft}
                description={
                    Locale.pages.register.aircraft.registerAircraftDescription
                }
                labelBtnBack={Locale.actions.back}
                onClickBtnBack={() => navigate('/aircraft')}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="stack--lg height--full"
            >
                <div className="stack--md height--full">
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                id="registration"
                                name="registration"
                                label={
                                    Locale.fields.aircraft.aircraftRegistration
                                }
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'registration',
                                        MaskHelper.upperAlphanumeric(
                                            e.target.value
                                        )
                                    )
                                }
                                value={formik.values?.registration}
                                error={
                                    formik.touched.registration &&
                                    formik.errors.registration
                                }
                                maxLength={Aircraft.maxLength.registration}
                                onBlur={fetchExistingAircraft}
                                isLoading={isCheckingRegistration}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Checkbox
                            id="isMilitary"
                            name="isMilitary"
                            checked={formik.values?.isMilitary}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'isMilitary',
                                    !!e.target.checked
                                );
                            }}
                            label={Locale.fields.aircraft.isMilitary}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <Input
                                type="text"
                                label={Locale.fields.aircraft.model}
                                id="icaoModel"
                                name="icaoModel"
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'icaoModel',
                                        MaskHelper.upperAlphanumeric(
                                            e.target.value
                                        )
                                    )
                                }
                                value={formik.values?.icaoModel}
                                error={
                                    formik.touched.icaoModel &&
                                    formik.errors.icaoModel
                                }
                                maxLength={Aircraft.maxLength.icaoModel}
                                onBlur={formik.handleBlur}
                                disabled={isCheckingRegistration}
                                required
                                className="margin--b-md"
                            />
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <Input
                                type="number"
                                label={Locale.fields.aircraft.mtowShort}
                                name="mtow"
                                id="mtow"
                                after={
                                    <span className="body-2 color--grayscale-blue-gray">
                                        kg
                                    </span>
                                }
                                onChange={formik.handleChange}
                                value={formik.values?.mtow}
                                error={
                                    formik.touched.mtow && formik.errors.mtow
                                }
                                onBlur={formik.handleBlur}
                                bottomSheetContent={{
                                    title: Locale.fields.aircraft.mtow,
                                    content: (
                                        <BottomSheetContent
                                            setShowVisible={setShowBottomSheet}
                                        />
                                    ),
                                    visible: showBottomSheet,
                                    setVisible: setShowBottomSheet,
                                }}
                                disabled={isCheckingRegistration}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                type="number"
                                label={Locale.fields.aircraft.planeLength}
                                name="length"
                                id="length"
                                after={
                                    <span className="body-2 color--grayscale-blue-gray">
                                        m
                                    </span>
                                }
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'length',
                                        MaskHelper.numeric(e.target.value)
                                    )
                                }
                                value={formik.values?.length}
                                error={
                                    formik.touched.length &&
                                    formik.errors.length
                                }
                                onBlur={formik.handleBlur}
                                className="margin--b-md"
                                disabled={isCheckingRegistration}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="number"
                                label={Locale.fields.aircraft.wingspan}
                                name="wingspan"
                                id="wingspan"
                                after={
                                    <span className="body-2 color--grayscale-blue-gray">
                                        m
                                    </span>
                                }
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        'wingspan',
                                        MaskHelper.numeric(e.target.value)
                                    )
                                }
                                value={formik.values?.wingspan}
                                error={
                                    formik.touched.wingspan &&
                                    formik.errors.wingspan
                                }
                                onBlur={formik.handleBlur}
                                disabled={isCheckingRegistration}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="width--full">
                        <Button type="submit" className="width--full">
                            {Locale.actions.saveAndContinue}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NewAircraft;
