import { Button, ButtonIcon, Line, FullPageModal, Text } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

import {
    icoExpandLeft,
    icoHelpDuotone,
    icoInfoDuotone,
    icoFileLines,
    icoArrowLeftDuotone,
    icoPerson,
    icoPlane,
    icoPlusDuotone,
    icoFlagUk,
    icoFlagBr,
    icoFlagEs,
    icoExpandDown,
    icoDocumentSearch,
    icoMyReservations,
    icoChatDuotone,
} from 'assets/icons';
import Logo from 'assets/logo-aeroease.svg';
import { Link, useNavigate } from 'react-router-dom';
import Locale from 'locale';
import { useState } from 'react';
import { BILLING_INTEGRATION_ENABLED } from 'utils/constants';

export default function Sidemenu({
    show,
    toggle,
    isUserAuthenticated,
    logout,
    handleSwitchLanguage,
}) {
    const navigate = useNavigate();
    const logoutAndNavigate = () => {
        logout(() => {
            navigate('/');
        });
    };

    return (
        <>
            <div
                onClick={() => toggle()}
                className={`backdrop ${show ? 'sidemenu-active' : ''}`}
            />
            <div className={`sidemenu ${show ? 'sidemenu-active' : ''}`}>
                <SidemenuHeader toggle={toggle} />
                {isUserAuthenticated ? (
                    <LoggedInOptions
                        logout={logoutAndNavigate}
                        toggle={toggle}
                    />
                ) : (
                    <LogOutOptions toggle={toggle} />
                )}
                <SectionDivision />
                <DefaultOptions
                    onSwitchLanguage={handleSwitchLanguage}
                    toggle={toggle}
                />
                {!isUserAuthenticated && (
                    <>
                        <SectionDivision />
                        <SidemenuLoginButtons toggle={toggle} />
                    </>
                )}
            </div>
        </>
    );
}

Sidemenu.propTypes = {
    show: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    handleSwitchLanguage: PropTypes.func.isRequired,
};

Sidemenu.defaultProps = {
    show: false,
};

function SidemenuHeader({ toggle }) {
    return (
        <div className="inline--md width--full margin--b-xxl">
            <ButtonIcon
                kind="outline"
                icon={icoExpandLeft}
                onClick={() => toggle()}
            />
            <img
                className="sidemenu-logo"
                src={Logo}
                alt={Locale.general.CCRAirports}
            />
        </div>
    );
}

SidemenuHeader.propTypes = {
    toggle: PropTypes.func.isRequired,
};

function SectionDivision() {
    return (
        <div className="padding--t-lg padding--b-xs">
            <Line />
        </div>
    );
}

function LoggedInOptions({ logout, toggle }) {
    return (
        <div className="stack--md">
            {/* TODO: corrigir props path dos SidemenuLink quando tiver as telas */}
            <SidemenuLink
                icon={icoPlusDuotone}
                label={Locale.actions.newReservation}
                path="/newreservation/generalinfo"
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoPlane}
                label={Locale.actions.myAircraft}
                path="/aircraft"
                toggle={toggle}
            />
            {BILLING_INTEGRATION_ENABLED && (
                <SidemenuLink
                    icon={icoDocumentSearch}
                    label={Locale.actions.consultInvoice}
                    path="/debt-hub"
                    toggle={toggle}
                />
            )}
            <SidemenuLink
                icon={icoChatDuotone}
                label={Locale.actions.myFinances}
                path="https://ccraeroportos.omd.com.br/ccrfinanceiro/externo/cadastro.do"
                toggle={toggle}
                external
            />
            <SidemenuLink
                icon={icoPerson}
                label={Locale.actions.profile}
                path="/accountsettings"
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoMyReservations}
                label={Locale.actions.myReservations}
                path="/reservations"
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoPerson}
                label={Locale.pages.accountSettings.tabs.pilots}
                path="/pilots"
                toggle={toggle}
            />
            <div
                onClick={logout}
                className="inline--sm flex-items--center cursor--pointer"
            >
                <img src={icoArrowLeftDuotone} alt={Locale.actions.logout} />
                <div className="body-2">{Locale.actions.logout}</div>
            </div>
        </div>
    );
}

LoggedInOptions.propTypes = {
    logout: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
};

function LogOutOptions({ toggle }) {
    return (
        <div className="stack--md">
            <SidemenuLink
                icon={icoPlusDuotone}
                label={Locale.actions.newReservation}
                path="/login"
                state={{
                    redirectTo: '/newreservation/generalinfo',
                }}
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoPlane}
                label={Locale.actions.myAircraft}
                path="/login"
                state={{
                    redirectTo: '/aircraft',
                }}
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoDocumentSearch}
                label={Locale.actions.myFinances}
                path="https://ccraeroportos.omd.com.br/ccrfinanceiro/externo/cadastro.do"
                toggle={toggle}
                external
            />
        </div>
    );
}

LogOutOptions.propTypes = {
    toggle: PropTypes.func.isRequired,
};

function DefaultOptions({ onSwitchLanguage, toggle }) {
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);

    const language = {
        flag: {
            en: icoFlagUk,
            pt: icoFlagBr,
            es: icoFlagEs,
        },
        label: {
            en: 'English',
            pt: 'Português (Brasil)',
            es: 'Español',
        },
        acronym: {
            en: 'EN',
            pt: 'PT-BR',
            es: 'ES',
        },
    };

    return (
        <div className="stack--md">
            <FullPageModal
                kind="leftSide"
                title={Locale.pages.sidemenu.selectLanguage}
                show={showLanguageMenu}
                onClose={() => setShowLanguageMenu(false)}
            >
                <div className="stack--lg">
                    {Locale.getAvailableLanguages().map((lang) => (
                        <div
                            key={`option-languague-${lang}`}
                            className="d-flex cursor--pointer"
                            onClick={() => {
                                onSwitchLanguage(lang);
                                setShowLanguageMenu(false);
                            }}
                        >
                            <img src={language.flag[lang]} alt={lang} />
                            <Text
                                className={`padding--l-sm ${
                                    Locale.getLanguage() === lang
                                        ? 'fw-bold'
                                        : ''
                                }`}
                            >
                                {language.label[lang]}
                            </Text>
                        </div>
                    ))}
                </div>
            </FullPageModal>
            {/* TODO: corrigir props path dos SidemenuLink quando tiver as telas */}
            <SidemenuLink
                icon={icoHelpDuotone}
                label={Locale.actions.help}
                path="/help"
                toggle={toggle}
            />
            <SidemenuLink
                icon={icoFileLines}
                label={Locale.actions.termsAndConditions}
                path="https://www.ccraeroportos.com.br/corporativo/termos-de-uso"
                toggle={toggle}
                external
            />
            <SidemenuLink
                icon={icoInfoDuotone}
                label={Locale.pages.sidemenu.aboutCCR}
                path="https://www.ccraeroportos.com.br/"
                toggle={toggle}
                external
            />
            <div
                className="language-select"
                onClick={() => setShowLanguageMenu(true)}
            >
                <img src={language.flag[Locale.getLanguage()]} alt="" />
                <span className="margin--x-sm">
                    {language.acronym[Locale.getLanguage()]}
                </span>
                <img src={icoExpandDown} alt="expand" />
            </div>
        </div>
    );
}

DefaultOptions.propTypes = {
    onSwitchLanguage: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
};

function SidemenuLink({ icon, label, path, external, toggle, state }) {
    return external ? (
        <a
            href={path}
            target="_blank"
            rel="noreferrer"
            className="inline--sm flex-items--center"
        >
            <img src={icon} alt={label} />
            <div className="body-2">{label}</div>
        </a>
    ) : (
        <Link
            to={path}
            className="inline--sm flex-items--center"
            onClick={toggle}
            state={state}
        >
            <img src={icon} alt={label} />
            <div className="body-2">{label}</div>
        </Link>
    );
}

SidemenuLink.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    external: PropTypes.bool,
    state: PropTypes.object,
};

SidemenuLink.defaultProps = {
    external: false,
    state: {},
};

function SidemenuLoginButtons({ toggle }) {
    return (
        <div className="stack--md">
            <Link to="/login">
                <Button kind="primary" block onClick={() => toggle()}>
                    {Locale.actions.login}
                </Button>
            </Link>

            <Link to="/register/user">
                <Button kind="outline" block onClick={() => toggle()}>
                    {Locale.actions.createAccount}
                </Button>
            </Link>
        </div>
    );
}

SidemenuLoginButtons.propTypes = {
    toggle: PropTypes.func.isRequired,
};
