import PropTypes from 'prop-types';

import { Container } from './styles';

function Text({ kind, children, color, className, ...args }) {
    return (
        <Container kind={kind} color={color} className={className} {...args}>
            {children}
        </Container>
    );
}

Text.propTypes = {
    kind: PropTypes.oneOf([
        'body-1',
        'body-2',
        'subtitle-1',
        'overline-1',
        'overline-2',
    ]),
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
};

Text.defaultProps = {
    kind: 'body-1',
    color: '',
    className: '',
};

export default Text;
