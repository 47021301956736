import styled from 'styled-components';

import { Text, Image } from '..';

export const Container = styled.div`
    width: 100%;
    position: relative;

    label {
        text-transform: uppercase;
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${(props) => props.theme.colors.grayscale.light};
    border: 1px solid ${(props) => props.theme.colors.grayscale.paleShaleGray};
    border-radius: ${(props) => props.theme.spacing.sm};
    box-shadow: 0px 0px 4px
        ${(props) =>
            props.error
                ? props.theme.colors.support.error
                : props.theme.colors.opacity.transparent};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    select {
        ${(props) => props.before && `padding-left: 0;`}
    }

    &:focus-within {
        box-shadow: 0px 0px 4px
            ${(props) => props.theme.colors.brand.primaryLight};
    }

    ${(props) =>
        props.disabled === true &&
        `
        * {
            opacity: 0.5;
            cursor: not-allowed;
        }
    `}
`;

export const Span = styled.span`
    display: flex;
    align-items: center;
    pointer-events: none;
`;

export const Before = styled(Span)`
    left: ${(props) => props.theme.spacing.sm};
    margin: 0 ${(props) => props.theme.spacing.sm} 0 0.9rem;
    img {
        height: 24px;
    }
`;

export const After = styled(Span)`
    right: 0;
    margin: 0 ${(props) => props.theme.spacing.sm};
    position: absolute;
`;

export const Select = styled.select`
    width: 100%;
    height: 2.688rem;
    position: relative;
    flex: 1;
    border: none;
    background-color: ${(props) => props.theme.colors.opacity.transparent};
    outline: none;
    font-size: ${(props) => props.theme.fonts.md};
    color: ${(props) => props.theme.colors.grayscale.dark};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) => `padding: ${props.theme.spacing.sm}
        ${props.theme.spacing.md};`}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.grayscale.light}
            inset !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.colors.grayscale.dark} !important;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
`;

export const ScrollableList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 10.752rem;
    overflow-y: scroll;
    margin-top: ${(props) => props.theme.spacing.xs};
    padding: ${(props) => props.theme.spacing.sm};
    border-radius: ${(props) => props.theme.spacing.sm};
    box-shadow: 0px 0px 4px ${(props) => props.theme.colors.brand.primaryLight};
    position: absolute;
    ${(props) => (props.showListOnTop ? 'bottom: 2.688rem;' : '')}
    z-index: 2;
    background-color: ${(props) => props.theme.colors.grayscale.white};
`;

export const ListItem = styled.button`
    width: 100%;
    min-height: 2.688rem;
    border: none;
    background-color: ${(props) => props.theme.colors.grayscale.white};
    text-align: left;
    padding: ${(props) => props.theme.spacing.sm};
    border-radius: ${(props) => props.theme.spacing.sm};

    &:hover {
        background-color: ${(props) => props.theme.colors.grayscale.light};
    }
`;

export const TextInput = styled.input`
    border: none;
    width: 100%;
    height: 2.688rem;
    flex: 1;
    border: none;
    background-color: ${(props) => props.theme.colors.opacity.transparent};
    outline: none;
    font-size: ${(props) => props.theme.fonts.md};
    color: ${(props) =>
        props.disabled
            ? props.theme.colors.grayscale.blueGray
            : props.theme.colors.grayscale.dark};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) => `padding: ${props.theme.spacing.sm}
        ${props.theme.spacing.md};`}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.grayscale.light}
            inset !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.colors.grayscale.dark} !important;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
`;

export const TextDisplay = styled.p`
    border: none;
    width: 100%;
    height: 2.688rem;
    flex: 1;
    border: none;
    background-color: ${(props) => props.theme.colors.opacity.transparent};
    outline: none;
    font-size: ${(props) => props.theme.fonts.md};
    color: ${(props) =>
        props.disabled
            ? props.theme.colors.grayscale.blueGray
            : props.theme.colors.grayscale.dark};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) => `padding: ${props.theme.spacing.sm}
        ${props.theme.spacing.md};`}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.grayscale.light}
            inset !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.colors.grayscale.dark} !important;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
`;

export const TextError = styled(Text)`
    color: ${(props) => props.theme.colors.support.error};
    margin-top: ${(props) => props.theme.spacing.xs};
    margin-left: ${(props) => props.theme.spacing.md};
`;

export const ExpandIcon = styled(Image)`
    right: 0;
    margin: 0 ${(props) => props.theme.spacing.sm};
    position: absolute;
`;
