import { Input, Button } from 'components';
import Locale from 'locale';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Service from 'services';
import FullPageModal from 'components/FullPageModal';
import PropTypes from 'prop-types';
import notify from 'components/Toast';
import { ErrorHelper } from 'helpers';

export default function ForgotPassword({ show, onClose }) {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(Locale.errors.validEmail)
            .required(Locale.errors.required),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: (values) => {
            Service.passwordReset(values)
                .then(() => {
                    notify(Locale.pages.forgotPassword.success);
                    onClose();
                })
                .catch((response) => {
                    ErrorHelper.notifyError(response);
                });
        },
    });

    return (
        <FullPageModal
            show={show}
            title={Locale.actions.forgotPassword}
            onClose={onClose}
            endPageButton={
                <Button type="submit" onClick={() => formik.handleSubmit()}>
                    {Locale.actions.resetPassword}
                </Button>
            }
        >
            <div className="stack--md">
                <div className="subtitle-1 color--grayscale-carbono-gray">
                    {Locale.pages.forgotPassword.subtitle}
                </div>
                <form onSubmit={formik.handleSubmit} className="stack--lg">
                    <Input
                        label={Locale.fields.email}
                        type="email"
                        name="email"
                        required
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && formik.errors.email}
                    />
                </form>
            </div>
        </FullPageModal>
    );
}

ForgotPassword.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
