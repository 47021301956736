export default class BaseModel {
    constructor() {
        /* BaseModel deve ser abstract, mas JS não suporta. */
        if (this.constructor === BaseModel) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    static createListFromResponse(response) {
        return response.map((responseItem) =>
            this.createFromResponse(responseItem)
        );
    }

    static createFromResponse(response) {
        if (!response) {
            return response;
        }

        return new this(response);
    }

    static createEmpty() {
        return new this({});
    }

    static parseDropdownOptions(list) {
        return list.map((item) => ({ value: item.id, label: item.name }));
    }

    static getValueOrNew(data, Model = this) {
        if (!data) {
            return data;
        }
        return data instanceof Model ? data : new Model(data);
    }

    parseToRequest() {
        return { ...this };
    }
}
