import PropTypes from 'prop-types';

import { Container, After, Before } from './styles';
import { Text, Label } from '..';

function List({
    text,
    secondaryText,
    disabled,
    selected,
    before,
    beforeAvatar,
    after,
    showAfterIfSelected,
    onClick,
    ...args
}) {
    return (
        <Container
            disabled={disabled}
            selected={selected && !disabled}
            onClick={onClick}
            {...args}
        >
            <div className="left-content">
                {before && (
                    <Before avatar={beforeAvatar} selected={selected}>
                        {before}
                    </Before>
                )}
                <div>
                    <Text kind="body-2">{text}</Text>
                    {secondaryText && (
                        <Label>{secondaryText.toUpperCase()}</Label>
                    )}
                </div>
            </div>
            {after && !(showAfterIfSelected && !selected) && (
                <After selected={selected}>{after}</After>
            )}
        </Container>
    );
}

List.propTypes = {
    text: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    before: PropTypes.node || PropTypes.element,
    beforeAvatar: PropTypes.bool,
    after: PropTypes.node || PropTypes.element,
    showAfterIfSelected: PropTypes.bool,
    onClick: PropTypes.func,
};

List.defaultProps = {
    disabled: false,
    selected: false,
    secondaryText: '',
    before: undefined,
    beforeAvatar: false,
    after: undefined,
    showAfterIfSelected: false,
    onClick: undefined,
};

export default List;
