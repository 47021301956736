import PropTypes from 'prop-types';
import LoggedIn from './LoggedIn';
import NotLoggedIn from './NotLoggedIn';

function Home({ isAuthenticated }) {
    return isAuthenticated ? <LoggedIn /> : <NotLoggedIn />;
}

Home.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default Home;
